
const getters = {
    // !!! let all methods below as function to avoid store caching !!!
    getActiveCustomerSubscription: (state) => () => {
        return state.customer.customerSubscriptions.find((customerSubscription) => customerSubscription.active)
    },
    getActiveSubscription: (state, getters) => () => {
        return getters.getActiveCustomerSubscription(state).subscription
    },

    getProductOptionValue: (state, getters) => (handle) => {
        let productOption = getters.getActiveSubscription(state, getters).product.productOptions.find(productOption => productOption.handle === handle)
        return (productOption !== null && typeof productOption !== 'undefined') ? productOption.value : null
    },
    getMaxProjectCount: (state, getters) => () => {
        return getters.getProductOptionValue('max-project-count')
    },
    getMaxGeolocationCountPerProject: (state, getters) => () => {
        return getters.getProductOptionValue('max-geolocation-count-per-project')
    },
    getMaxRequestCount: (state, getters) => () => {
        return getters.getProductOptionValue('max-request-count')
    },
    getMaxRivalCountPerProject: (state, getters) => () => {
        return getters.getProductOptionValue('max-rival-count-per-project')
    },
    // subscriptionComponent getters
    getSubscriptionComponentCount: (state, getters) => (handle) => {
        let subscriptionComponent = getters.getActiveSubscription(state, getters).subscriptionComponents.find(subscriptionComponent => subscriptionComponent.component.handle === handle)
        return (subscriptionComponent !== null && typeof subscriptionComponent !== 'undefined') ? subscriptionComponent.count : null
    },
    getAdditionalProjectCount: (state, getters) => () => {
        return getters.getSubscriptionComponentCount('yooda-one-component-additional-projects')
    },
    getAdditionalRequestCount: (state, getters) => () => {
        return getters.getSubscriptionComponentCount('yooda-one-component-additional-requests')
    },
    getHasLinking: (state, getters) => () => {
        return getters.getSubscriptionComponentCount('yooda-one-component-linking')
    },
}

export default getters

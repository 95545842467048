import Vue from "vue"

Vue.filter('formatNumber', function (number, decimalCount=2) {
    return new Intl.NumberFormat('fr-FR',{maximumFractionDigits:decimalCount}).format(number)
})
Vue.filter('displayRank', function (rank) {
    if(!rank){return 'non positionné'}
    return (rank === 1) ? rank+'er' : rank+'ème'
})


Vue.filter('displayDateLocale', function (date) {
    return new Date(date).toLocaleDateString('fr-FR', { timeZone: 'UTC' })
})

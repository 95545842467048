import axios from '@/api/axios.js'
import MockAdapter from 'axios-mock-adapter'
import mockData from "@/api/mock/mockData"


const mock = new MockAdapter(axios ,{ delayResponse: 1000 }) // This sets the mock adapter on the default instance


mock.onGet('/configuration/projects/1/keywords').reply(() => [200, mockData.projectKeywords])
mock.onGet('/configuration/projects/1/keyword-groups').reply(() => [200, mockData.projectSegments])
mock.onGet('/configuration/subscription-metas/1/projects').reply(() => [200, mockData.projectList])
mock.onGet('/configuration/subscription-metas/1/projects/summaries').reply(() => [200, mockData.projectsSummary])
mock.onGet('/configuration/projects/1').reply(() => [200, mockData.projectInfo])
mock.onGet('/configuration/projects/1/rivals').reply(() => [200, mockData.projectCompetitors])
mock.onGet('configuration/search-engines').reply(() => [200, mockData.searchEngines])
mock.onGet('/configuration/projects/1/search-engines').reply(() => [200, mockData.projectLocation])
mock.onGet('configuration/search-engines/1/search-geolocations').reply(() => [200, mockData.searchGeoloc1])
mock.onGet('configuration/search-engines/2/search-geolocations').reply(() => [200, mockData.searchGeoloc2])
mock.onGet('configuration/suggest-keywords/domain/yooda.com').reply(() => [200, mockData.suggestedKeywords])

mock.onGet('configuration/search-engines/1/language').reply(() => [200, mockData.language1])
mock.onGet('configuration/search-engines/2/language').reply(() => [200, mockData.language2])

mock.onGet('/configuration/subscription-metas/1').reply(() => [200, mockData.subscriptionMeta])
mock.onPost('/configuration/projects/1/keywords').reply(() => [200, mockData.createKeyword])
mock.onDelete('/configuration/projects/1/keywords').reply(() => [200, mockData.deleteKeywords])
mock.onPatch('/configuration/keywords/1/keyword-groups').reply(() => [200, mockData.deleteKeywords])
mock.onPost('/configuration/keyword-groups/1/keywords').reply(() => [200, mockData.createKeyword])
mock.onPost('/configuration/1/analytics').reply(() => [200, mockData.analyticsViewConfiguration])



mock.onPost('/reporting/subscriptions/1/computed').reply(() => [200, mockData.computedReportingList])
mock.onPost('/reporting/subscriptions/1/scheduled').reply(() => [200, mockData.scheduledReportingList])


mock.onGet('/customers-manager/customers/john.doe@yooda.com', { params: { 'full-fetch-mode': true } }).reply(() => [200, mockData.customer])
mock.onPost('/subscription/1/analytics/refresh-token').reply(() => [200, mockData.analyticsRefreshToken])
mock.onPost('/customers-manager/token/refresh').reply(() => [204])


mock.onGet('/report/data/projects/1/dashboard/traffic/evolution-conversion').reply(() => [200, mockData.trafficEvolutionConversion])
mock.onGet('/report/data/projects/1/dashboard/traffic/acquisition-channel').reply(() => [200, mockData.trafficAcquisitionChanel])
mock.onGet('/report/data/projects/1/dashboard/competition/top-competitor').reply(() => [200, mockData.competitionTopCompetitor])
mock.onGet('/report/data/projects/1/dashboard/market-segment/evolution').reply(() => [200, mockData.marketSegmentEvolution])
mock.onGet('/report/data/projects/1/dashboard/market-segment/progression-potential').reply(() => [200, mockData.marketSegmentProgressionPotential])
mock.onGet('/report/data/projects/1/dashboard/advertising/performance').reply(() => [200, mockData.advertisingPerformance])
mock.onGet('/report/data/projects/1/dashboard/seo-visibility/presence-evolution').reply(() => [200, mockData.seoVisibilityPresenceEvolution])
mock.onGet('/report/data/projects/1/dashboard/local-visibility/by-location').reply(() => [200, mockData.localVisibilityByLocation])
mock.onGet('/report/data/projects/1/dashboard/website-page/best-pages').reply(() => [200, mockData.websitePagesBestPages])
mock.onGet('/report/data/projects/1/dashboard/market-segment/size').reply(() => [200, mockData.marketSegmentSize])


mock.onGet('/report/data/projects/1/dashboard/recommendations/global-google-visibility').reply(() => [200, mockData.globalGoogleVisibility])
mock.onGet('/report/data/projects/1/dashboard/recommendations/action-priorities').reply(() => [200, mockData.actionPriorities])
mock.onGet('/report/data/projects/1/dashboard/recommendations/most-visible-competitors').reply(() => [200, mockData.mostVisibleCompetitors])
mock.onGet('/report/data/projects/1/dashboard/recommendations/market-segment-visibility').reply(() => [200, mockData.dashboardRecommendationsMarketSegmentVisibility])
mock.onGet('/report/data/projects/1/dashboard/recommendations/best-pages').reply(() => [200, mockData.topBestPages])
mock.onGet('/report/data/projects/1/dashboard/recommendations/best-keywords').reply(() => [200, mockData.topBestKeywords])


mock.onGet('/report/data/projects/1/advertising/adwords-advertisers').reply(() => [200, mockData.advertisingAdwordsAdvertisers])
mock.onGet('/report/data/projects/1/advertising/adwords-competition-keywords').reply(() => [200, mockData.advertisingAdwordsCompetitionKeywords])
mock.onGet('/report/data/projects/1/advertising/adwords-performance').reply(() => [200, mockData.advertisingAdwordsPerformance])
mock.onGet('/report/data/projects/1/advertising/adwords-top-competitor').reply(() => [200, mockData.advertisingAdwordsTopCompetitor])
mock.onGet('/report/data/projects/1/advertising/conversion').reply(() => [200, mockData.advertisingConversion])
mock.onGet('/report/data/projects/1/advertising/incoming-traffic').reply(() => [200, mockData.advertisingIncomingTraffic])
mock.onGet('/report/data/projects/1/advertising/shopping-advertisers').reply(() => [200, mockData.advertisingShoppingAdvertisers])
mock.onGet('/report/data/projects/1/advertising/shopping-competition-keywords').reply(() => [200, mockData.advertisingShoppingCompetitionKeywords])
mock.onGet('/report/data/projects/1/advertising/shopping-performance').reply(() => [200, mockData.advertisingShoppingPerformance])
mock.onGet('/report/data/projects/1/advertising/shopping-top-competitor').reply(() => [200, mockData.advertisingShoppingTopCompetitor])
mock.onGet('/report/data/projects/1/advertising/turnover').reply(() => [200, mockData.advertisingTurnover])
mock.onGet('/report/data/projects/1/advertising/adwords-advertiser-keywords').reply(() => [200, mockData.advertisingAdwordsAdvertiserKeywords])
mock.onGet('/report/data/projects/1/advertising/adwords-advertiser-ads').reply(() => [200, mockData.advertisingAdwordsAdvertiserAds])
mock.onGet('/report/data/projects/1/advertising/adwords-keywords-ads/keywords/1').reply(() => [200, mockData.advertisingAdwordsKeywordsAds])
mock.onGet('/report/data/projects/1/advertising/shopping-advertiser-keywords').reply(() => [200, mockData.advertisingShoppingAdvertiserKeywords])
mock.onGet('report/data/projects/1/advertising/shopping-advertiser-ads').reply(() => [200, mockData.advertisingShoppingAdvertiserAds])
mock.onGet('/report/data/projects/1/advertising/shopping-keywords-ads/keywords/1').reply(() => [200, mockData.advertisingShoppingKeywordsAds])


mock.onGet('/report/data/projects/1/competition/best-progression').reply(() => [200, mockData.competitionBestProgression])
mock.onGet('/report/data/projects/1/competition/competitor-market-compare').reply(() => [200, mockData.competitorMarketCompare])
mock.onGet('/report/data/projects/1/competition/competitor-compare').reply(() => [200, mockData.competitorCompare])
mock.onGet('/report/data/projects/1/competition/market-leader').reply(() => [200, mockData.competitionMarketLeader])
mock.onGet('/report/data/projects/1/competition/market-leader/sparkline').reply(() => [200, mockData.sparkline])
mock.onGet('/report/data/projects/1/competition/my-rank').reply(() => [200, mockData.myRank])
mock.onGet('/report/data/projects/1/competition/new-competitor').reply(() => [200, mockData.competitionNewCompetitor])
mock.onGet('/report/data/projects/1/competition/visibility-evolution').reply(() => [200, mockData.competitionVisibilityEvolution])
mock.onGet('/report/data/projects/1/competition/market-leader-visibility-evolution').reply(() => [200, mockData.competitionMarketLeaderVisibilityEvolution])
mock.onGet('/report/data/projects/1/competition/compare-performance').reply(() => [200, mockData.competitionCompetitorCompare])
mock.onGet('/report/data/projects/1/competition/market-leader-keywords').reply(() => [200, mockData.competitionMarketLeaderKeywords])
mock.onGet('/report/data/projects/1/competition/market-leader-pages').reply(() => [200, mockData.competitionMarketLeaderPages])


mock.onGet('/report/data/projects/1/progression-axis/block-type').reply(() => [200, mockData.progressionAxisGoogleBlockTypeFrequency])
mock.onGet('/report/data/projects/1/progression-axis/block-performance').reply(() => [200, mockData.googleVisibilityBlockPerformance])
mock.onGet('/report/data/projects/1/progression-axis/improvement-way').reply(() => [200, mockData.progressionAxisImprovementWay])
mock.onGet('/report/data/projects/1/progression-axis/position-list').reply(() => [200, mockData.progressionAxisGooglePositionList])
mock.onGet('/report/data/projects/1/progression-axis/improvement-potential-keywords').reply(() => [200, mockData.progressionAxisImprovementPotentialKeywords])
mock.onGet('/report/data/projects/1/progression-axis/top-action-priorities').reply(() => [200, mockData.progressionAxisTopActionPriorities])


mock.onGet('/report/data/projects/1/local-visibility/average-note').reply(() => [200, mockData.averageNote])
mock.onGet('/report/data/projects/1/local-visibility/competitor').reply(() => [200, mockData.localVisibilityCompetitor])
mock.onGet('/report/data/projects/1/local-visibility/keywords-positions').reply(() => [200, mockData.localVisibilityKeywordsPositions])
mock.onGet('/report/data/projects/1/local-visibility/local-advertising-presence').reply(() => [200, mockData.localVisibilityLocalAdvertisingPresence])
mock.onGet('/report/data/projects/1/local-visibility/my-business-positions').reply(() => [200, mockData.localVisibilityMyBusinessKeywordsPositions])


mock.onGet('/report/data/projects/1/market-segment/best-progression-potential').reply(() => [200, mockData.marketSegmentBestProgressionPotential])
mock.onGet('/report/data/projects/1/market-segment/segment-performance').reply(() => [200, mockData.marketSegmentSegmentPerformance])
mock.onGet('/report/data/projects/1/market-segment/segment-size').reply(() => [200, mockData.marketSegmentSegmentSize])
mock.onGet('/report/data/projects/1/market-segment/visibility').reply(() => [200, mockData.marketSegmentVisibility])
mock.onGet('/report/data/projects/1/market-segment/best-progression-potential-keywords').reply(() => [200, mockData.marketSegmentBestProgressionPotentialKeywords])
mock.onGet('/report/data/projects/1/market-segment/competitor-market-compare').reply(() => [200, mockData.marketSegmentCompetitorCompare])
mock.onGet('/report/data/projects/1/market-segment/top-competitors?max-competitor-count=50').reply(() => [200, mockData.marketSegmentTopCompetitors])


mock.onGet('/report/data/projects/1/seo-visibility/visibility').reply(() => [200, mockData.seoVisibilityIncomingTraffic])
mock.onGet('/report/data/projects/1/seo-visibility/average-position').reply(() => [200, mockData.averagePosition])
mock.onGet('/report/data/projects/1/seo-visibility/compare-keywords-positions').reply(() => [200, mockData.seoVisibilityCompareKeywordsPositions])
mock.onGet('/report/data/projects/1/seo-visibility/conversion').reply(() => [200, mockData.seoVisibilityConversion])
mock.onGet('/report/data/projects/1/seo-visibility/incoming-traffic').reply(() => [200, mockData.seoVisibilityVisibilityScore])
mock.onGet('/report/data/projects/1/seo-visibility/turnover').reply(() => [200, mockData.seoVisibilitySeoTurnover])
mock.onGet('/report/data/projects/1/seo-visibility/natural-position-list').reply(() => [200, mockData.seoVisibilityNaturalPositionList])
mock.onGet('/report/data/projects/1/seo-visibility/position-repartition').reply(() => [200, mockData.seoVisibilityPositionRepartition])
mock.onGet('/report/data/projects/1/seo-visibility/seo-turnover').reply(() => [200, mockData.seoTurnover])
mock.onGet('/report/data/projects/1/seo-visibility/top-position-count').reply(() => [200, mockData.seoVisibilityTopPositionCount])
mock.onGet('/report/data/projects/1/seo-visibility/keyword-position-evolution/keywords/1').reply(() => [200, mockData.keywordPositionEvolution])
mock.onGet('/report/data/projects/1/seo-visibility/keyword-position-evolution/keywords/2').reply(() => [200, mockData.keywordPositionEvolution])
mock.onGet('/report/data/projects/1/seo-visibility/keyword-position-evolution/keywords/3').reply(() => [200, mockData.keywordPositionEvolution])
mock.onGet('/report/data/projects/1/seo-visibility/natural-position-list/sparkline').reply(() => [200, mockData.sparkline])


mock.onGet('/report/data/projects/1/traffic/quality/total-conversion').reply(() => [200, mockData.trafficQualityTotalConversion])
mock.onGet('/report/data/projects/1/traffic/quality/total-sale').reply(() => [200, mockData.trafficQualityTotalSale])
mock.onGet('/report/data/projects/1/traffic/quality/total-turnover').reply(() => [200, mockData.trafficQualityTotalTurnover])
mock.onGet('/report/data/projects/1/traffic/quality/conversion').reply(() => [200, mockData.trafficQualityConversion])
mock.onGet('/report/data/projects/1/traffic/quality/sale').reply(() => [200, mockData.trafficQualitySale])
mock.onGet('/report/data/projects/1/traffic/quality/turnover-by-visitor').reply(() => [200, mockData.trafficQualityTurnoverByVisitor])
mock.onGet('/report/data/projects/1/traffic/incoming').reply(() => [200, mockData.trafficIncomingWebsite])
mock.onGet('/report/data/projects/1/traffic/quality').reply(() => [200, mockData.trafficQuality])
mock.onGet('/report/data/projects/1/traffic/sources').reply(() => [200, mockData.trafficSource])


mock.onGet('/report/data/projects/1/website-page/positioned-pages-count-evolution').reply(() => [200, mockData.websitePagesPositionedCountEvolution])
mock.onGet('/report/data/projects/1/website-page/best-positioned-pages').reply(() => [200, mockData.websitePagesBestPositionedPages])
mock.onGet('/report/data/projects/1/website-page/best-positioned-page-positions/pages/1').reply(() => [200, mockData.websitePagesBestPositionedPagePositions])


mock.onGet('/report/data/projects/1/linking/popularity').reply(() => [200, mockData.linkingPopularityByWebsite])
mock.onGet('/report/data/projects/1/linking/authority').reply(() => [200, mockData.linkingWebsiteAuthority])
mock.onGet('/report/data/projects/1/linking/links-count').reply(() => [200, mockData.linkingLinkCount])
mock.onGet('/report/data/projects/1/linking/competitors-linking').reply(() => [200, mockData.linkingCompetitorsLinking])
mock.onGet('/report/data/projects/1/linking/competitors-linking/domain/amazon.fr').reply(() => [200, mockData.linkingCompetitorsLinkingReferralDomains])

mock.onGet('/report/data/projects/1/linking/project-topical-authorities').reply(() => [200, mockData.linkingMyThematicAuthority])
mock.onGet('/report/data/projects/1/linking/project-topical-authorities/thematic/Shopping').reply(() => [200, mockData.linkingMyThematicAutorityReferralDomains])

mock.onGet('/report/data/projects/1/linking/topical-authorities-comparison').reply(() => [200, mockData.linkingAuthorityCompare])

mock.onGet('/report/data/projects/1/linking/backlinks').reply(() => [200, mockData.linkingBestBacklinks])

mock.onGet('/report/data/projects/1/linking/ref-domains').reply(() => [200, mockData.linkingWebsiteBacklinks])


mock.onGet('/report/data/projects/1/linking/anchors').reply(() => [200, mockData.linkingAnchorBacklinks])

mock.onGet('/report/data/projects/1/linking/top-pages').reply(() => [200, mockData.linkingReferralBacklinks])


mock.onGet('/report/data/projects/1/online-dashboard/share-link').reply(() => [200, mockData.shareLink])
mock.onPost('/report/data/projects/1/online-dashboard/share-link').reply(() => [200, mockData.shareLink])


mock.onGet('/configuration/template/1/template-components').reply(() => [200, mockData.templateComponents1])
mock.onGet('/configuration/components').reply(() => [200, mockData.components])

mock.onPost('/configuration/template/1/template-components').reply(() => [200, {}])

mock.onGet('/report/configuration/projects/1/reports/complete').reply(() => [200, mockData.reportsList])



mock.onAny().passThrough() // forwards the matched request over network

export default mock



export default {
    "fr": {
        "component_componentFilter_title": "Filtres",
        "component_componentFilter_marketSegment_label": "Segment de marché",
        "component_componentFilter_website_label": "Site Web",
        "component_componentFilter_compareTwoDomainsOne_label": "Site Web 1",
        "component_componentFilter_compareTwoDomainsTwo_label": "Site Web 2",
        "component_componentFilter_compareTwoDomains_optLabel_followed": "Concurrent suivis",
        "component_componentFilter_compareTwoDomains_optLabel_other": "Autres concurrents",
        "component_componentFilter_resultType_label": "Type de résultat",
        "component_componentFilter_compareDate_label": "Date de comparaison",
        "component_componentFilter_advertiserPresence_label": "Présence d'annonceur",
        "component_componentFilter_keyword_label": "Mot-clé",
        "component_componentFilter_marketSegment_all": "Tous",
        "component_componentFilter_competitor_all": "Tous",
        "component_componentFilter_resultType_select_all": "Tous",
        "component_componentFilter_resultType_select_natural": "Naturel",
        "component_componentFilter_resultType_select_map": "Google Business Profile",
        "component_componentFilter_resultType_select_adwords": "Google Ads",
        "component_componentFilter_resultType_select_shopping": "Shopping",
        "component_componentFilter_resultType_select_image": "Image",
        "component_componentFilter_resultType_select_video": "Video",
        "component_componentFilter_resultType_select_actuality": "Actualité",
        "component_componentFilter_resultType_select_zero": "Position 0",
        "component_componentFilter_advertiserKeywordPresence_all": "Tous",
        "component_componentFilter_advertiserKeywordPresence_with": "Avec",
        "component_componentFilter_advertiserKeywordPresence_without": "Sans",
        "component_componentFilter_projectKeyword_select_all": "Tous",

        "components_tableMenu_fullscreenExpand_button": "Elargir le tableau",
        "components_tableMenu_fullscreenReduce_button": "Réduire le tableau",
        "components_tableMenu_downloadCsv_button": "Exporter en csv",

        "component_globalFilter_frequency_select_label": "Granularité",
        "component_globalFilter_frequency_select_1": "Par jour",
        "component_globalFilter_frequency_select_7": "Par semaine",
        "component_globalFilter_frequency_select_30": "Par mois",
        "component_globalFilter_frequency_select_365": "Par an",
        "component_globalFilter_frequency_tooltip_title": "<p>Gestion de la granularité</p>",
        "component_globalFilter_frequency_tooltip_text": "<p>Ce sélecteur vous permet d'adapter <strong>l'échelle de temps</strong> des données à vos besoins d'analyse.</p><br/><p>Par exemple, si vous souhaitez comparer cette semaine aux semaines précédentes, choisissez une granularité \"par semaine\".</p><br/><p>Les données de trafic présentées seront un cumul sur la période affichée (total du jour, de la semaine, du mois).</p><br/><p>Les données de position sont toujours un instantané à la date indiquée.</p><br/><p>Ce choix est global et se maintiendra même si vous naviguez d'une page à l'autre.</p><br/>",

        "component_globalFilter_location_select_label": "Localité",
        "component_globalFilter_location_tooltip_title": "<p>Choix de la localisation</p>",
        "component_globalFilter_location_tooltip_text": "<p>Vous pouvez mesurer votre visibilité sur plusieurs localités. Grâce à ce sélecteur, vous pouvez choisir <strong>quelle est la localité affichée dans les données</strong>.</p><br/><p>Ce choix ne s'applique qu'aux données issues des relevés de positions dans Google (visibilité, position, performance...) et pas aux données de trafic.</p><br/><p>Ce choix est global et se maintiendra même si vous naviguez d'une page à l'autre.</p><br/>",
    },
    "en": {
        "component_componentFilter_title": "Filters",
        "component_componentFilter_marketSegment_label": "Market Segment",
        "component_componentFilter_website_label": "Website",
        "component_componentFilter_compareTwoDomainsOne_label": "Website 1",
        "component_componentFilter_compareTwoDomainsTwo_label": "Website 2",
        "component_componentFilter_compareTwoDomains_optLabel_followed": "Concurrent follows",
        "component_componentFilter_compareTwoDomains_optLabel_other": "Other competitors",
        "component_componentFilter_resultType_label": "Result type",
        "component_componentFilter_compareDate_label": "Comparison date",
        "component_componentFilter_advertiserPresence_label": "Advertiser Presence",
        "component_componentFilter_keyword_label": "Keyword",
        "component_componentFilter_marketSegment_all": "All",
        "component_componentFilter_competitor_all": "All",
        "component_componentFilter_resultType_select_all": "All",
        "component_componentFilter_resultType_select_natural": "Natural",
        "component_componentFilter_resultType_select_map": "Google Business Profile",
        "component_componentFilter_resultType_select_adwords": "Google Ads",
        "component_componentFilter_resultType_select_shopping": "Shopping",
        "component_componentFilter_resultType_select_image": "Image",
        "component_componentFilter_resultType_select_video": "Video",
        "component_componentFilter_resultType_select_actuality": "News",
        "component_componentFilter_resultType_select_zero": "Position 0",
        "component_componentFilter_advertiserKeywordPresence_all": "All",
        "component_componentFilter_advertiserKeywordPresence_with": "With",
        "component_componentFilter_advertiserKeywordPresence_without": "Without",
        "component_componentFilter_projectKeyword_select_all": "All",

        "components_tableMenu_fullscreenExpand_button": "Expand table",
        "components_tableMenu_fullscreenReduce_button": "Reduce table",
        "components_tableMenu_downloadCsv_button": "Export to csv",

        "component_globalFilter_frequency_select_label": "Granularity",
        "component_globalFilter_frequency_select_1": "Per day",
        "component_globalFilter_frequency_select_7": "Per week",
        "component_globalFilter_frequency_select_30": "Per month",
        "component_globalFilter_frequency_select_365": "Per year",
        "component_globalFilter_frequency_tooltip_title":"<p>Granularity management</p>",
        "component_globalFilter_frequency_tooltip_text":"<p>This selector allows you to tailor <strong>the time scale</strong> of the data to your analysis needs.</p><br/><p>For example, if you want to compare this week to previous weeks, choose a granularity of \"by week.\" </p><br/><p>The traffic data presented will be a cumulative total over the time period displayed (day, week, month total).</p><br/><p>The position data is always a snapshot as of the date indicated.</p><br/><p>This choice is global and will hold even if you navigate from page to page.</p><br/>",

        "component_globalFilter_location_select_label": "Locality",
        "component_globalFilter_location_tooltip_title":"<p>Location selection</p>",
        "component_globalFilter_location_tooltip_text":"<p>You can measure your visibility on multiple locations. With this selector, you can choose <strong>which location is displayed in the data</strong>.</p><br/><p>This choice only applies to data from positional readings in Google (visibility, position, performance...) and not to traffic data.</p><br/><p>This choice is global and will hold even if you navigate from page to page.</p><br/>",
    },
}

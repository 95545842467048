import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                // primary: '#25A0D1',
                primary: '#008fe8',
                success: '#34a853',
                warning: '#fba505',
                error: '#ea4335'
            },
        },
        options: {
            customProperties: true,
        },
    },
})

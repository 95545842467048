<template>
    <div class="component-container" :class="{fullwidth: maximised, 'card-with-table':cardWithTable, 'no-shadow':noShadow}"  v-show="!hideOnNoConfig">
        <v-card :style="customStyle">

            <div v-if="displayNoConfigPlaceholder && componentConfig.placeholderLocation" class="placeholder-container mb-1 v-application">
                <p class="orange lighten-5" v-if="componentConfig.placeholderLocation">
                    <strong class="primary white--text">{{ (!componentConfig.placeholderTitle ? $t('component_componentContainer_fakeData_label') : componentConfig.placeholderTitle) }}</strong>
                    <span v-html="componentConfig.placeholderText.replace('[*CONFIG-LINK*]', componentConfig.redirectRoute)"></span>
                </p>
            </div>

            <div class="component-header-container">
                <div class="pr-2">
                    <table-density-selector v-if="tableDensitySelector"/>
                </div>
                <div>
                    <information-tooltip
                        v-if="informationTooltip"
                        :title="informationTooltip.title"
                        :text="informationTooltip.text"
                        :tooltip-width="informationTooltip.width ? informationTooltip.width : 440"
                    />
                </div>
                <div v-if="tableMenu && this.$router.currentRoute.params.projectId" class="pr-5">
                    <table-menu
                        :with-fullscreen="tableMenu.fullscreen"
                        :with-csv-export="tableMenu.csv"
                        @setTableMenuFullscreen="setTableMenuFullscreen"
                        @setTableMenuExportCsv="$emit('setTableMenuExportCsv')"
                    />
                </div>
            </div>


            <div v-if="displayNoConfigPlaceholder && !componentConfig.placeholderLocation" class="placeholder-blurred-background v-application">

                <v-card class="placeholder-link-container" :class="{'no-link-btn': $route.name === 'display_reporting'}">
                    <v-card-text>
                        <p class="text-body-1">{{ componentConfig.placeholderTitle }}</p>

                        <v-btn color="warning" :href="componentConfig.redirectRoute" v-if="$route.name !== 'display_reporting'">{{ componentConfig.placeholderText }}</v-btn>
                    </v-card-text>
                </v-card>
            </div>

            <v-card-text :style="{height:(height ? `${!hasFilter ? height : height+120}px` : '100%')}" :class="[noPadding ? 'px-0 py-5' : 'px-5 py-5']">
<!--            <v-card-text :class="[noPadding ? 'px-0 py-5' : 'px-5 py-5']">-->


                <div class="mb-4 v-application" :class="[noPadding ? 'px-5 py-0' : 'px-0 py-0']" v-if="cardTitle || cardSubtitle">
                    <h6 class="text-h6 mb-2" style="font-size: 22px !important;width: calc(100% - 60px)" v-html="cardTitle"/>
                    <h6 style="font-weight: 100; width: calc(100% - 60px)" v-html="cardSubtitle"/>
                </div>


                <div v-if="!componentData.response.isLoaded">
                    <div class="v-application text-center" style="width: calc(100% - 35px); top: 50%; position: absolute">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                    </div>
                </div>
                <div v-else-if="componentData.response.error">
                    <div class="no-component-data-message error-message">
                        <p>{{ $t(`component_componentContainer_errorData`) }}</p>
                    </div>
                </div>
                <div v-else class="main-content">
                    <slot name="filters"></slot>


                    <div v-if="!componentData.hasData" class="no-component-data-message">
                        <p>{{ componentData.noDataMessage ? componentData.noDataMessage : $t(`component_componentContainer_noData`) }}</p>
                    </div>
                    <div v-else class="slot-container" :class="tableDensitySelector ? 'tableDensitySelector' : ''">
                        <slot name="content"></slot>
                    </div>
                </div>

            </v-card-text>
        </v-card>
    </div>

</template>


<script>

import InformationTooltip from "@/components/yooda-components/InformationTooltip";
import TableMenu from "@/components/yooda-components/TableMenu";
import TableDensitySelector from "@/components/yooda-components/TableDensitySelector.vue";

export default {
    props: {
        cardTitle: {type: String,default: ''},
        cardSubtitle: {type: String,default: ''},
        customStyle: {type: String,default: ''},
        height: null,
        noPadding:{type: Boolean,default: false},
        noShadow:{type: Boolean,default: false},
        hasFilter:{type: Boolean,default: false},
        tableDensitySelector:{type: Boolean,default: false},
        informationTooltip: null,
        componentConfig:null,
        componentData:null,
        tableMenu:null
    },
    data(){
        return {
            maximised:false,
            cardWithTable:false,
        }
    },
    computed:{
        displayNoConfigPlaceholder(){
            if(this.projectCreationWaiting){
                return false
            }
            else{
                if(!this.componentConfig){
                    return false
                }
                else{
                    return !this.componentConfig.hasConfig
                    // return !!(this.componentConfig.placeholderTitle && !this.componentConfig.hasConfig);
                }
            }
        },
        hideOnNoConfig(){
            return !!(this.componentConfig && this.componentConfig.hideOnNoConfig && !this.componentConfig.hasConfig);
        }
    },
    methods:{
        setTableMenuFullscreen(){
            this.maximised = !this.maximised
            this.$emit('setTableMenuFullscreen')
        },
        addTableContainerClass(){
            this.cardWithTable = true
        }
    },
    components: {
        InformationTooltip,
        TableMenu,
        TableDensitySelector
    },

}
</script>

<style lang="scss" >

.placeholder-container{
    display: inline-block;
    height: 21px;
    p{
        font-size: 12px;
        padding-right: 8px;
        line-height: 21px;
        border-top-left-radius: 10px;
        strong{
            text-transform: uppercase;
            border-top-left-radius: 10px;
            padding: 3px 8px;
            margin-right: 6px;
        }
        a{
            display: inline-block;
        }
    }

}

.component-header-container{
    position: absolute;
    right: 0;
    top: 8px;
    >div{
        display: inline-block;
        vertical-align: middle;
    }
    .vbtn-for-yooda-icon, .table-menu-container{
        opacity: 0.65;
        color: #000;
        &:hover{opacity: 1;}
        transition:opacity 0.3s;
    }

}

.placeholder-blurred-background{
    position: absolute;
    z-index: 105;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: saturate(100%) blur(2px)!important;
    background-color: #0000004d;
    border-radius: 10px;
    .placeholder-link-container{
        position: absolute;
        left: calc(50% - 150px);
        top: calc(50% - 55px);
        width: 300px;
        height: 110px;
        text-align: center;
        &.no-link-btn{
            height: 70px;
            p{
                margin-top: 6px;
            }
        }
    }
}


</style>

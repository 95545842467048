

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18nData from '@/i18n/i18nData'

Vue.use(VueI18n)

let defaultLocale = 'fr'
if(localStorage.getItem('defaultLocale')){
  defaultLocale = localStorage.getItem('defaultLocale')
}else{
    if(navigator.language){
        defaultLocale = navigator.language.slice(0,2)
        localStorage.setItem('defaultLocale',defaultLocale)
    }
}

export default new VueI18n({
  locale: defaultLocale,
  messages: i18nData
})

import {mapActions, mapState} from 'pinia'
import { useComponentStore } from '@/stores/ComponentStore'



export const componentStoreHandler = {
    computed:{
        ...mapState(useComponentStore, {
            project: 'getProject',
            getProjectError: 'getProjectError',
            projectsSummary: 'getProjectsSummary',
            analyticsDataSource: 'getAnalyticsDataSource',
            projectSegments: 'getProjectSegments',
            projectKeywords: 'getProjectKeywords',
            projectCreationWaiting: 'getProjectCreationWaiting',
            getShowPopin: 'getShowPopin',
            globalFilterFrequency: 'getGlobalFilterFrequency',
            globalFilterLocation: 'getGlobalFilterLocation',
            subscriptionMeta: 'getSubscriptionMeta',
            analyticsViewConfiguration: 'getAnalyticsViewConfiguration',
            rivalsConfiguration: 'getRivalsConfiguration',
            keywordGroupsConfiguration: 'getKeywordGroupsConfiguration',
            getMarketSegmentConfiguration: 'getMarketSegmentConfiguration',
            getDefaultSearchGeolocation: 'getDefaultSearchGeolocation',
            projectUrl: 'getProjectUrl',
            projectReports: 'getProjectReports',
            projectInfoKeywords: 'getProjectInfoKeywords',
            searchGeolocationsConfiguration: 'getSearchGeolocationsConfiguration',
            getChartSelectedSeriesName: 'getChartSelectedSeriesName',
            getProjectCreationTmp: 'getProjectCreationTmp',
            setDefaultDashboardName: 'setDefaultDashboardName',
            getTableDensity: 'getTableDensity',
        })


    },
    methods:{
        ...mapActions(useComponentStore, [
            'setProjectError',
            'setDataComponent',
            'showAgGridPopin',
            'setGlobalFilterFrequency',
            'setGlobalFilterLocation',
            'resetCurrentProject',
            'setSegmentSizeSelected',
            'setProjectCreationTmpValue',
            'setTableDensity',

        ]),

        setDefaultLocationUsage(){
            const globalFilterLocation = localStorage.getItem('globalFilterLocation')
            const globalFilterLocationProject = localStorage.getItem('globalFilterLocationProject')
            const project = this.project.data

            if(
                globalFilterLocation
                && globalFilterLocationProject
                && project.id === parseInt(globalFilterLocationProject)
                && project.searchGeolocations.find((data)=> data.id === parseInt(globalFilterLocation))
            ){
                this.defaultLocationSelect = parseInt(globalFilterLocation)

            }
            else {
                this.defaultLocationSelect = project.searchGeolocations[0].id

                localStorage.setItem('globalFilterLocation', this.defaultLocationSelect.toString())
                localStorage.setItem('globalFilterLocationProject', project.id.toString())
            }

            this.setGlobalFilterLocation(this.defaultLocationSelect)
        }
    },

}

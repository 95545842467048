
export default {
    fr: {
        "component_i18nSwitcher_language_fr":"Français",
        "component_i18nSwitcher_language_en":"English",
    },
    en: {
        "component_i18nSwitcher_language_fr":"Français",
        "component_i18nSwitcher_language_en":"English",
    },
}



import vuetify from '@/plugins/vuetify'

import Vue from 'vue'
import App from './App.vue'


import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css'
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)


import VuePapaParse from 'vue-papa-parse'
Vue.use(VuePapaParse)


// axios
import axios from './axios.js'
Vue.prototype.$http = axios


// vue-cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// vue-logger
import VueLogger from 'vuejs-logger'


const options = {
    isEnabled: (process.env.VUE_APP_ENABLE_DEBUG_LOGGER === 'true'),
    logLevel : (process.env.NODE_ENV === 'production') ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
}
Vue.use(VueLogger, options)


// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'

// Pinia

import { createPinia, PiniaVuePlugin } from 'pinia'
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

import VueTypedJs from 'vue-typed-js'
Vue.use(VueTypedJs)

import i18n from './i18n/i18n'



// Feather font icon
require('./assets/css/iconfont.css')


Vue.config.productionTip = false

import './filters/commonFilters'


import YoodaIcon from "./components/yooda-components/YoodaIcon";
Vue.component('yooda-icon',YoodaIcon)

import ComponentContainer from "@/components/yooda-components/ComponentContainer";
Vue.component('component-container',ComponentContainer)

import ComponentLoader from "@/components/yooda-components/ComponentLoader";
Vue.component('component-loader',ComponentLoader)

import ConfirmDialog from "@/components/yooda-components/ConfirmDialog";
Vue.component('confirm-dialog',ConfirmDialog)

import {displayErrorMixin} from './mixins/displayErrorMixin'
import {themeColorMixin} from './mixins/themeColorMixin'
import {dataFormater} from './mixins/dataFormater'
import {displayScheduledReporting} from './mixins/scheduledReporting'
import {tagManagerMixin} from './mixins/tagManagerMixin'
import {componentStoreHandler} from './mixins/componentStoreManagerMixin'

Vue.mixin(displayErrorMixin)
Vue.mixin(themeColorMixin)
Vue.mixin(dataFormater)
Vue.mixin(displayScheduledReporting)
Vue.mixin(tagManagerMixin)
Vue.mixin(componentStoreHandler)


import toBoolean from 'to-boolean'
if (toBoolean(process.env.VUE_APP_AUTOLOGIN)) {
    require ('@/api/mock.js')
}

// import deployerSubscriberSSE from '@/sse/deployer/DeployerSubscriberSSE'
// deployerSubscriberSSE.subscribe({project: 'yooda-one-frontend'})


new Vue({
    router,
    vuetify,
    store,
    i18n,
    pinia,
    render: h => h(App)
}).$mount('#app')





export default {
    "fr": {
        "view_externalSourcesManagement_title": "Liste des comptes externes connectés",
        "view_externalSourcesManagement_subtitle": "Ajoutez ou supprimez une connexion à une source externe de données.",
        "view_externalSourcesManagement_informationTooltip_title": "Vos comptes externes",
        "view_externalSourcesManagement_informationTooltip_text": "<p>Connectez et paramétrez vos comptes externes pour enrichir et exporter vos données.</p>",

        "view_exportConfiguration_headerText": "<p class=\"my-1\"><strong>Exporter les données :</strong></p><p class=\"my-0\">Vous pouvez exporter les <strong>données brutes</strong> de ce projet vers <strong>Google Sheet</strong>, pour les réutiliser dans Google Data Studio par exemple.</p><p class=\"my-0\">Les données envoyées sont <strong>l'ensemble des positions relevées pour le site principal du projet</strong>.</p><p class=\"my-0\">Ce export de données est <strong>programmable</strong>. La Google Sheet s'enrichira selon la fréquence choisie..</p>",

        "component_googleSheetAccountManagement_connectButton": "Connecter Google Sheet",
        "component_googleSheetAccountManagement_addAccount_title": "Comptes Google Sheet :",
        "component_googleSheetAccountManagement_addAccount_tooltipTitle": "<strong>Ajouter un compte</strong><br>--------------------------------<br>Associer un compte Google supplémentaire <br>permettra d'exporter vos données de projet<br>vers votre compte Google Drive.",
        "component_googleSheetAccountManagement_confirmRemoveAssociation_title": "Confirmez la suppression",
        "component_googleSheetAccountManagement_confirmRemoveAccount_text": "Attention, vous allez supprimer la connexion avec ce compte Google Sheet.<br><br><strong>TOUS LES PROJETS qui l'utilisent ne pourront plus exporter vos données.</strong>",
        "component_googleSheetAccountManagement_confirmRemoveAssociation_btnConfirm": "Confirmer",
        "component_googleSheetAccountManagement_confirmRemoveAssociation_btnCancel": "Annuler",
        "component_googleSheetAccountManagement_revokeError": "Problème lors de la révocation de l'access à votre compte Google",

        "component_analyticsAccountConfiguration_connectButton": "Connecter Analytics",
        "component_analyticsAccountConfiguration_my_accounts": "Mes comptes Analytics connectés",
        "component_analyticsAccountConfiguration_connectButton_addAccount_title": "Comptes Analytics :",
        "component_analyticsAccountConfiguration_addAccount_tooltipTitle": "<strong>Ajouter un compte</strong><br>--------------------------------<br>Associer un compte Google supplémentaire <br>permettra de synchroniser vos projets <br>avec vos différents comptes Analytics.",
        "component_analyticsAccountConfiguration_confirmRemoveAssociation_title": "Confirmez la suppression",
        "component_analyticsAccountConfiguration_confirmRemoveAccount_text": "Attention, vous allez supprimer la connexion avec ce compte Analytics.<br><br><strong>TOUS LES PROJETS qui l'utilisent ne seront plus synchronisés.</strong>",
        "component_analyticsAccountConfiguration_confirmRemoveAssociation_btnConfirm": "Confirmer",
        "component_analyticsAccountConfiguration_confirmRemoveAssociation_btnCancel": "Annuler",

        "component_analyticsMatomoAccountConfiguration_connectButton": "Connecter Matomo",
        "component_analyticsMatomoAccountConfiguration_my_accounts": "Mes comptes Matomo connectés",
        "component_analyticsMatomoAccountConfiguration_addAccountTitle": "Ajouter un compte Matomo",
        "component_analyticsMatomoAccountConfiguration_addAccountTooltipTitle": "<strong>Ajouter un compte</strong><br>--------------------------------<br>Associer un compte Matomo supplémentaire <br>permettra de synchroniser vos projets <br>avec vos différents comptes Matomo.",
        "component_analyticsMatomoAccountConfiguration_updateAccountTitle": "Modifier un compte Matomo",
        "component_analyticsMatomoAccountConfiguration_addAccountConfirmButton": "Ajouter",
        "component_analyticsMatomoAccountConfiguration_updateAccountConfirmButton": "Modifier",
        "component_analyticsMatomoAccountConfiguration_addAccountCancelButton": "Annuler",
        "component_analyticsMatomoAccountConfiguration_confirmRemoveAccount_text": "Attention, vous allez supprimer la connexion avec ce compte Matomo.<br><br><strong>TOUS LES PROJETS qui l'utilisent ne seront plus synchronisés.</strong>",
        "component_analyticsMatomoAccountConfiguration_confirmRemoveAssociation_btnConfirm": "Confirmer",
        "component_analyticsMatomoAccountConfiguration_confirmRemoveAssociation_btnCancel": "Annuler",

        "component_analyticsProjectConfiguration_displayCurrentConfig_server": "Adresse du serveur",
        "component_analyticsProjectConfiguration_displayCurrentConfig_idSite": "ID du site",
        "component_analyticsProjectConfiguration_displayCurrentConfig_email": "Email",
        "component_analyticsProjectConfiguration_displayCurrentConfig_account": "Compte",
        "component_analyticsProjectConfiguration_displayCurrentConfig_property": "Propriété",
        "component_analyticsProjectConfiguration_displayCurrentConfig_view": "Vue",
        "component_analyticsProjectConfiguration_selectProperty_title": "Quelle propriété Analytics souhaitez vous associer ?",
        "component_analyticsProjectConfiguration_selectProperty_label": "Nom de la propriété",
        "component_analyticsProjectConfiguration_selectView_title": "Choisissez la vue Analytics à associer",
        "component_analyticsProjectConfiguration_selectView_label": "Nom de la vue",
        "component_analyticsProjectConfiguration_btnValidProperty": "Associer à votre projet",
        "component_analyticsProjectConfiguration_confirmRemoveAssociation_title": "Confirmez la suppression",
        "component_analyticsProjectConfiguration_confirmRemoveAssociation_text": "<strong>Attention</strong>, vous allez supprimer l'association de cette vue Analytics avec ce projet.<br>Les données ne seront plus synchronisées.",
        "component_analyticsMatomoProjectConfiguration_confirmRemoveAssociation_text": "<strong>Attention</strong>, vous allez supprimer l'association de Matomo avec ce projet.<br>Les données ne seront plus synchronisées.",
        "component_analyticsProjectConfiguration_confirmRemoveAssociation_btnConfirm": "Confirmer",
        "component_analyticsProjectConfiguration_confirmRemoveAssociation_btnCancel": "Annuler",

        "component_analyticsDisplayError_addAccount_error_alreadyExist": "Ce compte est déjà associé",
        "component_analyticsDisplayError_addAccount_error_authorization": "Droits insuffisants. Recommencez.",
        "component_analyticsDisplayError_renew_authorization": "Un de vos comptes nécessite un renouvellement d'autorisation.",
        "component_analyticsDisplayError_renew_account": "Ce compte nécessite un renouvellement d'autorisation.",

        "component_matomoAnalyticsProjectConfiguration_selectUrlLabel": "Sur quel serveur Matomo se trouve votre site ?",
        "component_matomoAnalyticsProjectConfiguration_selectUrlPlaceholder": "Adresse de votre Serveur Matomo",
        "component_matomoAnalyticsProjectConfiguration_siteIdLabel": "ID du site",
        "component_matomoAnalyticsProjectConfiguration_siteIdPlaceholder": "idSite",
        "component_matomoAnalyticsProjectConfiguration_saveBtnLabel": "Enregistrer",
        "component_matomoAnalyticsProjectConfiguration_checkOkMessage": "Vos statistiques sont synchronisées !",


    },
    "en": {
        "view_externalSourcesManagement_title": "List of connected external accounts.",
        "view_externalSourcesManagement_subtitle": "Add or remove a connection to an external data source.",
        "view_externalSourcesManagement_informationTooltip_title": "Your external accounts",
        "view_externalSourcesManagement_informationTooltip_text":"<p>Connect and set up your external accounts to enrich and export your data.</p>",

        "view_exportConfiguration_headerText":"<p class=\"my-1\"><strong>Export data:</strong></p><p class=\"my-0\">You can export the <strong>raw data</strong> from this project to <strong>Google Sheet</strong>, for reuse in Google Data Studio for example. </p><p class=\"my-0\">The data sent is <strong>all positions surveyed for the main project site</strong>.</p><p class=\"my-0\">This data export is <strong>programmable</strong>. The Google Sheet will grow according to the chosen frequency.</p>",

        "component_googleSheetAccountManagement_connectButton": "Connect Google Sheet",
        "component_googleSheetAccountManagement_addAccount_title": "Google Sheet Accounts:",
        "component_googleSheetAccountManagement_addAccount_tooltipTitle": "<strong>Add an account</strong><br>--------------------------------<br>Associating an additional Google account <br>will allow you to export your project data<br>to your Google Drive accounts.",
        "component_googleSheetAccountManagement_confirmRemoveAssociation_title": "Confirm the deletion",
        "component_googleSheetAccountManagement_confirmRemoveAccount_text": "Warning, you will remove the connection with this Google Sheet account.<br><br><strong>All projects that use it will no longer be able to export your data.</strong>",
        "component_googleSheetAccountManagement_confirmRemoveAssociation_btnConfirm": "Confirm",
        "component_googleSheetAccountManagement_confirmRemoveAssociation_btnCancel": "Cancel",
        "component_googleSheetAccountManagement_revokeError": "Problem revoking access to your Google Account",

        "component_analyticsAccountConfiguration_connectButton": "Connect Analytics",
        "component_analyticsAccountConfiguration_my_accounts": "My connected Analytics accounts",
        "component_analyticsAccountConfiguration_connectButton_addAccount_title": "Analytics Accounts:",
        "component_analyticsAccountConfiguration_addAccount_tooltipTitle":"<strong>Add Account</strong><br>--------------------------------<br>Associating an additional Google Account <br>will allow you to sync your projects <br>with your various Analytics accounts.",
        "component_analyticsAccountConfiguration_confirmRemoveAssociation_title": "Confirm removal",
        "component_analyticsAccountConfiguration_confirmRemoveAccount_text": "Warning, you will remove the connection with this Analytics account.<br><br><strong>ALL PROJECTS that use it will no longer be synchronized.</strong>",
        "component_analyticsAccountConfiguration_confirmRemoveAssociation_btnConfirm": "Confirm",
        "component_analyticsAccountConfiguration_confirmRemoveAssociation_btnCancel": "Cancel",

        "component_analyticsMatomoAccountConfiguration_connectButton": "Connect Matomo",
        "component_analyticsMatomoAccountConfiguration_my_accounts": "My connected Matomo accounts",
        "component_analyticsMatomoAccountConfiguration_addAccountTitle": "Add Matomo account",
        "component_analyticsMatomoAccountConfiguration_addAccountTooltipTitle": "<strong>Add Account</strong><br>--------------------------------<br>Associating an additional Matomo Account <br>will allow you to sync your projects <br>with your various Matomo accounts.",
        "component_analyticsMatomoAccountConfiguration_updateAccountTitle": "Update Matomo account",
        "component_analyticsMatomoAccountConfiguration_addAccountConfirmButton": "Add",
        "component_analyticsMatomoAccountConfiguration_updateAccountConfirmButton": "Update",
        "component_analyticsMatomoAccountConfiguration_addAccountCancelButton": "Cancel",
        "component_analyticsMatomoAccountConfiguration_confirmRemoveAccount_text": "Warning, you will remove the connection with this Matomo account.<br><br><strong>ALL PROJECTS that use it will no longer be synchronized.</strong>",
        "component_analyticsMatomoAccountConfiguration_confirmRemoveAssociation_btnConfirm": "Confirmer",
        "component_analyticsMatomoAccountConfiguration_confirmRemoveAssociation_btnCancel": "Annuler",

        "component_analyticsProjectConfiguration_displayCurrentConfig_server": "Serveur URL",
        "component_analyticsProjectConfiguration_displayCurrentConfig_idSite": "Website ID",
        "component_analyticsProjectConfiguration_displayCurrentConfig_email": "Email",
        "component_analyticsProjectConfiguration_displayCurrentConfig_account": "Account",
        "component_analyticsProjectConfiguration_displayCurrentConfig_property": "Property",
        "component_analyticsProjectConfiguration_displayCurrentConfig_view": "View",
        "component_analyticsProjectConfiguration_selectProperty_title": "Which Analytics property would you like to associate?",
        "component_analyticsProjectConfiguration_selectProperty_label": "Property name",
        "component_analyticsProjectConfiguration_selectView_title": "Choose the Analytics view to associate",
        "component_analyticsProjectConfiguration_selectView_label": "Name of the view",
        "component_analyticsProjectConfiguration_btnValidProperty": "Associate to your project",
        "component_analyticsProjectConfiguration_confirmRemoveAssociation_title": "Confirm the deletion",
        "component_analyticsProjectConfiguration_confirmRemoveAssociation_text":"<strong>Warning</strong>, you will remove the association of this Analytics view with this project.<br>The data will no longer be synchronized.",
        "component_analyticsMatomoProjectConfiguration_confirmRemoveAssociation_text": "<strong>Warning</strong>, you will remove the Matomo association view with this project.<br>The data will no longer be synchronized.",
        "component_analyticsProjectConfiguration_confirmRemoveAssociation_btnConfirm": "Confirm",
        "component_analyticsProjectConfiguration_confirmRemoveAssociation_btnCancel": "Cancel",

        "component_analyticsDisplayError_addAccount_error_alreadyExist": "This account is already associated",
        "component_analyticsDisplayError_addAccount_error_authorization": "Insufficient rights. Try again.",
        "component_analyticsDisplayError_renew_authorization": "One of your accounts requires authorization renewal.",
        "component_analyticsDisplayError_renew_account": "This account requires authorization renewal.",


        "component_matomoAnalyticsProjectConfiguration_selectUrlLabel": "Select Matomo server?",
        "component_matomoAnalyticsProjectConfiguration_selectUrlPlaceholder": "Matomo server URL",
        "component_matomoAnalyticsProjectConfiguration_siteIdLabel": "Web site ID",
        "component_matomoAnalyticsProjectConfiguration_siteIdPlaceholder": "idSite",
        "component_matomoAnalyticsProjectConfiguration_saveBtnLabel": "Save",
        "component_matomoAnalyticsProjectConfiguration_checkOkMessage": "Your statistics are synchronized !",
    },
}



export default {
    "fr": {
        "component_subscriptionExpiration_popin_list_option": "<ul><li>Analysez la visibilité de vos sites web</li><li>Surveillez vos marchés et vos concurrents</li><li>Mesurez l'efficacité de vos campagnes</li><li>Partagez vos résultats</li></ul>",
        "component_subscriptionExpiration_popin_title_trial": "Votre période d'essai est terminée",
        "component_subscriptionExpiration_popin_title_subscription": "Renouvelez votre abonnement",
        "component_subscriptionExpiration_popin_title_grace_period": "Désarchiver vos projets ?",
        "component_subscriptionExpiration_popin_title_inactive": "Heureux de vous revoir !",
        "component_subscriptionExpiration_popin_subtitle_trial": "Votre période d'essai est terminée, mais il est facile de continuer à profiter de YOODA One.",
        "component_subscriptionExpiration_popin_subtitle_subscription": "Votre abonnement a expiré, mais il est facile de continuer à en profiter.",
        "component_subscriptionExpiration_popin_subtitle_grace_period": "Votre abonnement a expiré et vos projets ont été archivés. Souhaitez-vous les restaurer ?",
        "component_subscriptionExpiration_popin_subtitle_inactive": "Vos projets n'ont pas pu être conservés aussi longtemps. Mais vous pouvez redémarrer avec un nouvel abonnement.",
        "component_subscriptionExpiration_popin_ignore_button_trial": "Plus tard",
        "component_subscriptionExpiration_popin_ignore_button_subscription": "Plus tard",
        "component_subscriptionExpiration_popin_ignore_button_grace_period": "Plus tard",
        "component_subscriptionExpiration_popin_ignore_button_inactive": "Plus tard",
        "component_subscriptionExpiration_popin_renew_button_trial": "S'abonner",
        "component_subscriptionExpiration_popin_renew_button_subscription": "Renouveler",
        "component_subscriptionExpiration_popin_renew_button_grace_period": "Restaurer",
        "component_subscriptionExpiration_popin_renew_button_inactive": "Renouveler",

        "component_subscriptionExpiration_alert_title_trialing": "Abonnez-vous et profitez dès maintenant de toute la puissance de YOODA One !",
        "component_subscriptionExpiration_alert_title_trial": "Votre période d'essai a expiré. Veuillez ",
        "component_subscriptionExpiration_alert_title_subscription": "Votre abonnement a expiré. Veuillez ",
        "component_subscriptionExpiration_alert_title_grace_period": "Votre abonnement a expiré. Veuillez ",
        "component_subscriptionExpiration_alert_title_inactive": "Votre abonnement a expiré. Veuillez ",
        "component_subscriptionExpiration_alert_renewLink_trial": "choisir un abonnement",
        "component_subscriptionExpiration_alert_renewLink_subscription": "renouveler",
        "component_subscriptionExpiration_alert_renewLink_grace_period": "renouveler",
        "component_subscriptionExpiration_alert_renewLink_inactive": "renouveler",
        "component_subscriptionExpiration_alert_project_update_trial": "Les projets ne seront plus mis à jour.",
        "component_subscriptionExpiration_alert_project_update_subscription": "Les projets ne seront plus mis à jour.",
        "component_subscriptionExpiration_alert_subtitle_trial": "Vos données seront conservées [*DAY-COUNT*] jour(s)",
        "component_subscriptionExpiration_alert_subtitle_subscription": "Vos données seront conservées [*DAY-COUNT*] jour(s)",
        "component_subscriptionExpiration_popin_conservation_time_trial": "Nous conserverons vos projets encore [*DAY-COUNT*] jour(s).",
        "component_subscriptionExpiration_popin_conservation_time_subscription": "Nous conserverons vos projets encore [*DAY-COUNT*] jour(s).",
        "component_subscriptionExpiration_popin_conservation_time_grace_period": "Votre backup est conservé pour encore [*DAY-COUNT*] jour(s).",


        "component_subscriptionPlanInfo_remainingData": "jusqu'au",
        "component_subscriptionPlanInfo_projectCount": "projet(s)",
        "component_subscriptionPlanInfo_keywordCount": "mots-clés",
        "component_subscriptionPlanInfo_competitorCount": "concurrents par projet",
        "component_subscriptionPlanInfo_geolocationCount": "géolocalisations par projet",
        "component_subscriptionPlanInfo_changeSubscription": "changer d'abonnement",
        "component_subscriptionPlanInfo_manageSubscription": "gérer mon compte",

        "component_subscriptionInfo_title": "Mon abonnement",
        "component_subscriptionInfo_limitTitle": "Limites de mon abonnement",
        "component_subscriptionInfo_limitProjectCount": "Projet(s) utilisé(s)",
        "component_subscriptionInfo_limitKeywordsCount": "Mots-clés utilisés",
        "component_subscriptionInfo_keywordList": "voir détail",

        "component_subscriptionInfo_table_header_project": "Nom du projet",
        "component_subscriptionInfo_table_header_keyword": "Nb mots-clés",
        "component_subscriptionInfo_table_header_location": "Nb localisations",
        "component_subscriptionInfo_table_header_request": "Mots-clés décomptés",

        "component_projectKeywordSummary_alert_title": "<p>Ce projet utilise <strong>[*TOTAL-REQUEST-COUNT*]</strong> mots-clés (<strong>[*PROJECT-KEYWORD-COUNT*]</strong> mots-clés X <strong>[*PROJECT-LOCATION-COUNT*]</strong> localisations).<br/>Vous disposez encore de <strong>[*AVAILABLE-REQUEST-COUNT*]</strong> mots-clés dans votre abonnement.</p>",
        "component_projectKeywordSummary_alert_tooltip": "Quota total de votre abonnement : <strong>[*SUBSCRIPTION-REQUEST-QUOTA*]</strong><br/>Utilisé par ce projet : <strong>[*CURRENT-PROJECT-REQUEST-COUNT*]</strong><br/>Utilisé par les autres projets : <strong>[*OTHER-PROJECT-REQUEST-COUNT*]</strong><br/>Votre quota restant : <strong>[*REQUEST-REMAINING-COUNT*]</strong>",
    },
    "en": {
        "component_subscriptionExpiration_popin_list_option":"<ul><li>Analyze the visibility of your websites</li><li>Monitor your markets and competitors</li><li>Measure the effectiveness of your campaigns</li><li>Share your results</li></ul>",
        "component_subscriptionExpiration_popin_title_trial": "Your trial period is over",
        "component_subscriptionExpiration_popin_title_subscription": "Renew your subscription",
        "component_subscriptionExpiration_popin_title_grace_period": "Unarchive your projects?",
        "component_subscriptionExpiration_popin_title_inactive": "Nice to see you again!",
        "component_subscriptionExpiration_popin_subtitle_trial": "Your trial period is over, but it's easy to continue enjoying YOODA One.",
        "component_subscriptionExpiration_popin_subtitle_subscription": "Your subscription has expired, but it's easy to continue enjoying it.",
        "component_subscriptionExpiration_popin_subtitle_grace_period": "Your subscription has expired and your projects have been archived. Would you like to restore them?",
        "component_subscriptionExpiration_popin_subtitle_inactive": "Your projects could not be kept for that long. But you can restart with a new subscription.",
        "component_subscriptionExpiration_popin_ignore_button_trial": "Later",
        "component_subscriptionExpiration_popin_ignore_button_subscription": "Later",
        "component_subscriptionExpiration_popin_ignore_button_grace_period": "Later",
        "component_subscriptionExpiration_popin_ignore_button_inactive": "Later",
        "component_subscriptionExpiration_popin_renew_button_trial": "Subscribe",
        "component_subscriptionExpiration_popin_renew_button_subscription": "Renew",
        "component_subscriptionExpiration_popin_renew_button_grace_period": "Restore",
        "component_subscriptionExpiration_popin_renew_button_inactive": "Renew",

        "component_subscriptionExpiration_alert_title_trialing": "Subscribe and enjoy the full power of YOODA One now!",
        "component_subscriptionExpiration_alert_title_trial": "Your trial period has expired. Please",
        "component_subscriptionExpiration_alert_title_subscription": "Your subscription has expired. Please",
        "component_subscriptionExpiration_alert_title_grace_period": "Your subscription has expired. Please",
        "component_subscriptionExpiration_alert_title_inactive": "Your subscription has expired. Please",
        "component_subscriptionExpiration_alert_renewLink_trial": "choose a subscription",
        "component_subscriptionExpiration_alert_renewLink_subscription": "renew",
        "component_subscriptionExpiration_alert_renewLink_grace_period": "renew",
        "component_subscriptionExpiration_alert_renewLink_inactive": "renew",
        "component_subscriptionExpiration_alert_project_update_trial": "Projects will no longer be updated",
        "component_subscriptionExpiration_alert_project_update_subscription": "Projects will no longer be updated.",
        "component_subscriptionExpiration_alert_subtitle_trial": "Your data will be kept [*DAY-COUNT*] day(s)",
        "component_subscriptionExpiration_alert_subtitle_subscription": "Your data will be kept for [*DAY-COUNT*] day(s)",
        "component_subscriptionExpiration_popin_conservation_time_trial": "We will keep your projects for another [*DAY-COUNT*] day(s)",
        "component_subscriptionExpiration_popin_conservation_time_subscription": "We will keep your projects for another [*DAY-COUNT*] day(s).",
        "component_subscriptionExpiration_popin_conservation_time_grace_period": "Your backup is kept for another [*DAY-COUNT*] day(s)",


        "component_subscriptionPlanInfo_remainingData": "until",
        "component_subscriptionPlanInfo_projectCount": "project(s)",
        "component_subscriptionPlanInfo_keywordCount": "keywords",
        "component_subscriptionPlanInfo_competitorCount": "competitors per project",
        "component_subscriptionPlanInfo_geolocationCount": "geolocations by project",
        "component_subscriptionPlanInfo_changeSubscription": "change subscription",
        "component_subscriptionPlanInfo_manageSubscription": "manage my account",

        "component_subscriptionInfo_title": "My subscription",
        "component_subscriptionInfo_limitTitle": "My subscription limits",
        "component_subscriptionInfo_limitProjectCount": "Project(s) used",
        "component_subscriptionInfo_limitKeywordsCount": "Keywords used",
        "component_subscriptionInfo_keywordList": "see details",

        "component_subscriptionInfo_table_header_project": "Project name",
        "component_subscriptionInfo_table_header_keyword": "Nb keywords",
        "component_subscriptionInfo_table_header_location": "Nb locations",
        "component_subscriptionInfo_table_header_request": "Keywords counted",

        "component_projectKeywordSummary_alert_title":"<p>This project uses <strong>[*TOTAL-REQUEST-COUNT*]</strong> keywords (<strong>[*PROJECT-KEYWORD-COUNT*]</strong> keywords X <strong>[*PROJECT-LOCATION-COUNT*]</strong> locations). <br/>You still have <strong>[*AVAILABLE-REQUEST-COUNT*]</strong> keywords in your subscription.</p>",
        "component_projectKeywordSummary_alert_tooltip": "Your total subscription quota: <strong>[*SUBSCRIPTION-REQUEST-QUOTA*]</strong><br/>Used by this project : <strong>[*CURRENT-PROJECT-REQUEST-COUNT*]</strong><br/>Used by other projects: <strong>[*OTHER-PROJECT-REQUEST-COUNT*]</strong><br/>Your remaining quota: <strong>[*REQUEST-REMAINING-COUNT*]</strong>",
    },
}

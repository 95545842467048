
export default {

    trafficQualityTotalTraffic : {
        context:{ inputs:{ dateRangeType:1, isReporting:false } },
        content:{
            traffics:[
                {startDate: "2020-01-01",endDate: "2020-01-01",traffic: 304},
                {startDate: "2020-02-01",endDate: "2020-02-01",traffic: 321},
                {startDate: "2020-03-01",endDate: "2020-03-01",traffic: 544},
                {startDate: "2020-03-02",endDate: "2020-03-02",traffic: 441},
                {startDate: "2020-03-09",endDate: "2020-03-09",traffic: 572},
                {startDate: "2020-03-10",endDate: "2020-03-10",traffic: 749},
                {startDate: "2020-03-11",endDate: "2020-03-11",traffic: 574},
                {startDate: "2020-03-12",endDate: "2020-03-12",traffic: 533},
                {startDate: "2020-03-13",endDate: "2020-03-13",traffic: 613},
                {startDate: "2020-03-14",endDate: "2020-03-14",traffic: 742},
                {startDate: "2020-03-15",endDate: "2020-03-15",traffic: 947},
            ],
            currentTraffic:947
        }

    },
    trafficQualityTotalConversion : {
        context:{ inputs:{ dateRangeType:1, isReporting:false } },
        content:{
            conversions:[
                {startDate: "2020-01-01",endDate: "2020-01-01",conversion: 304},
                {startDate: "2020-02-01",endDate: "2020-02-01",conversion: 321},
                {startDate: "2020-03-01",endDate: "2020-03-01",conversion: 544},
                {startDate: "2020-03-02",endDate: "2020-03-02",conversion: 441},
                {startDate: "2020-03-09",endDate: "2020-03-09",conversion: 572},
                {startDate: "2020-03-10",endDate: "2020-03-10",conversion: 749},
                {startDate: "2020-03-11",endDate: "2020-03-11",conversion: 574},
                {startDate: "2020-03-12",endDate: "2020-03-12",conversion: 533},
                {startDate: "2020-03-13",endDate: "2020-03-13",conversion: 613},
                {startDate: "2020-03-14",endDate: "2020-03-14",conversion: 742},
                {startDate: "2020-03-15",endDate: "2020-03-15",conversion: 947},
            ],
            currentConversion:947
        }

    },
    trafficQualityTotalSale : {
        context:{ inputs:{ dateRangeType:1, isReporting:false } },
        content:{
            transactionsCounts:[
                {startDate: "2020-01-01",endDate: "2020-01-01",transactionsCount: 239},
                {startDate: "2020-02-01",endDate: "2020-02-01",transactionsCount: 290},
                {startDate: "2020-03-01",endDate: "2020-03-01",transactionsCount: 425},
                {startDate: "2020-03-02",endDate: "2020-03-02",transactionsCount: 408},
                {startDate: "2020-03-09",endDate: "2020-03-09",transactionsCount: 439},
                {startDate: "2020-03-10",endDate: "2020-03-10",transactionsCount: 375},
                {startDate: "2020-03-11",endDate: "2020-03-11",transactionsCount: 479},
                {startDate: "2020-03-12",endDate: "2020-03-12",transactionsCount: 503},
                {startDate: "2020-03-13",endDate: "2020-03-13",transactionsCount: 530},
                {startDate: "2020-03-14",endDate: "2020-03-14",transactionsCount: 706},
                {startDate: "2020-03-15",endDate: "2020-03-15",transactionsCount: 838},
            ],
            currentTransactionsCount:838
        }

    },
    trafficQualityTotalTurnover : {
        context:{ inputs:{ dateRangeType:1, isReporting:false } },
        content:{
            turnovers:[
                {startDate: "2020-01-01",endDate: "2020-01-01",turnover: 22883},
                {startDate: "2020-02-01",endDate: "2020-02-01",turnover: 25082},
                {startDate: "2020-03-01",endDate: "2020-03-01",turnover: 37136},
                {startDate: "2020-03-02",endDate: "2020-03-02",turnover: 29206},
                {startDate: "2020-03-09",endDate: "2020-03-09",turnover: 35033},
                {startDate: "2020-03-10",endDate: "2020-03-10",turnover: 35821},
                {startDate: "2020-03-11",endDate: "2020-03-11",turnover: 37874},
                {startDate: "2020-03-12",endDate: "2020-03-12",turnover: 36397},
                {startDate: "2020-03-13",endDate: "2020-03-13",turnover: 42763},
                {startDate: "2020-03-14",endDate: "2020-03-14",turnover: 48730},
                {startDate: "2020-03-15",endDate: "2020-03-15",turnover: 70376},
            ],
            currentTurnover:70376
        }
    },
    trafficQualityTurnoverByVisitor : {
        context:{ inputs:{ dateRangeType:1, isReporting:false } },
        content:{ turnoverPerUser:3.52 }
    },
    trafficQualitySale : {
        context:{ inputs:{ dateRangeType:1, isReporting:false } },
        content:{ecommerceConversionRate:4.2}
    },
    trafficQualityConversion : {
        context:{ inputs:{ dateRangeType:1, isReporting:false } },
        content:{conversionRate:4.7}
    },
    trafficSource : {
        context:{ inputs:{ dateRangeType:1, isReporting:false } },
        content:[
            {
                acquisitionChannelType: "seo",
                traffics: [
                    {startDate: "2020-01-01",endDate: "2020-01-01",traffic: 2105},
                    {startDate: "2020-02-01",endDate: "2020-02-01",traffic: 2264},
                    {startDate: "2020-03-01",endDate: "2020-03-01",traffic: 2823},
                    {startDate: "2020-03-02",endDate: "2020-03-02",traffic: 3901},
                    {startDate: "2020-03-09",endDate: "2020-03-09",traffic: 4068},
                    {startDate: "2020-03-10",endDate: "2020-03-10",traffic: 4347},
                    {startDate: "2020-03-11",endDate: "2020-03-11",traffic: 4367},
                    {startDate: "2020-03-12",endDate: "2020-03-12",traffic: 4683},
                    {startDate: "2020-03-13",endDate: "2020-03-13",traffic: 5024},
                    {startDate: "2020-03-14",endDate: "2020-03-14",traffic: 5265},
                    {startDate: "2020-03-15",endDate: "2020-03-15",traffic: 6182},
                ]
            },
            {
                acquisitionChannelType: "ads",
                traffics: [
                    {startDate: "2020-01-01",endDate: "2020-01-01",traffic: 876},
                    {startDate: "2020-02-01",endDate: "2020-02-01",traffic: 1093},
                    {startDate: "2020-03-01",endDate: "2020-03-01",traffic: 1510},
                    {startDate: "2020-03-02",endDate: "2020-03-02",traffic: 1575},
                    {startDate: "2020-03-09",endDate: "2020-03-09",traffic: 1683},
                    {startDate: "2020-03-10",endDate: "2020-03-10",traffic: 1740},
                    {startDate: "2020-03-11",endDate: "2020-03-11",traffic: 1690},
                    {startDate: "2020-03-12",endDate: "2020-03-12",traffic: 1813},
                    {startDate: "2020-03-13",endDate: "2020-03-13",traffic: 1945},
                    {startDate: "2020-03-14",endDate: "2020-03-14",traffic: 2038},
                    {startDate: "2020-03-15",endDate: "2020-03-15",traffic: 2393},
                ]
            },
            {
                acquisitionChannelType: "social",
                traffics: [
                    {startDate: "2020-01-01",endDate: "2020-01-01",traffic: 657},
                    {startDate: "2020-02-01",endDate: "2020-02-01",traffic: 820},
                    {startDate: "2020-03-01",endDate: "2020-03-01",traffic: 1133},
                    {startDate: "2020-03-02",endDate: "2020-03-02",traffic: 1181},
                    {startDate: "2020-03-09",endDate: "2020-03-09",traffic: 1262},
                    {startDate: "2020-03-10",endDate: "2020-03-10",traffic: 1305},
                    {startDate: "2020-03-11",endDate: "2020-03-11",traffic: 1268},
                    {startDate: "2020-03-12",endDate: "2020-03-12",traffic: 1360},
                    {startDate: "2020-03-13",endDate: "2020-03-13",traffic: 1459},
                    {startDate: "2020-03-14",endDate: "2020-03-14",traffic: 1528},
                    {startDate: "2020-03-15",endDate: "2020-03-15",traffic: 1795},
                ]
            },
            {
                acquisitionChannelType: "referral",
                traffics: [
                    {startDate: "2020-01-01",endDate: "2020-01-01",traffic: 876},
                    {startDate: "2020-02-01",endDate: "2020-02-01",traffic: 1093},
                    {startDate: "2020-03-01",endDate: "2020-03-01",traffic: 1510},
                    {startDate: "2020-03-02",endDate: "2020-03-02",traffic: 1575},
                    {startDate: "2020-03-09",endDate: "2020-03-09",traffic: 1683},
                    {startDate: "2020-03-10",endDate: "2020-03-10",traffic: 1740},
                    {startDate: "2020-03-11",endDate: "2020-03-11",traffic: 1690},
                    {startDate: "2020-03-12",endDate: "2020-03-12",traffic: 1813},
                    {startDate: "2020-03-13",endDate: "2020-03-13",traffic: 1945},
                    {startDate: "2020-03-14",endDate: "2020-03-14",traffic: 2038},
                    {startDate: "2020-03-15",endDate: "2020-03-15",traffic: 1198},
                ]
            },
            {
                acquisitionChannelType: "email",
                traffics: [
                    {startDate: "2020-01-01",endDate: "2020-01-01",traffic: 876},
                    {startDate: "2020-02-01",endDate: "2020-02-01",traffic: 1093},
                    {startDate: "2020-03-01",endDate: "2020-03-01",traffic: 1510},
                    {startDate: "2020-03-02",endDate: "2020-03-02",traffic: 1575},
                    {startDate: "2020-03-09",endDate: "2020-03-09",traffic: 1683},
                    {startDate: "2020-03-10",endDate: "2020-03-10",traffic: 1740},
                    {startDate: "2020-03-11",endDate: "2020-03-11",traffic: 1690},
                    {startDate: "2020-03-12",endDate: "2020-03-12",traffic: 1813},
                    {startDate: "2020-03-13",endDate: "2020-03-13",traffic: 1945},
                    {startDate: "2020-03-14",endDate: "2020-03-14",traffic: 2038},
                    {startDate: "2020-03-15",endDate: "2020-03-15",traffic: 1197},
                ]
            },
            {
                acquisitionChannelType: "direct",
                traffics: [
                    {startDate: "2020-01-01",endDate: "2020-01-01",traffic: 2556},
                    {startDate: "2020-02-01",endDate: "2020-02-01",traffic: 3187},
                    {startDate: "2020-03-01",endDate: "2020-03-01",traffic: 4404},
                    {startDate: "2020-03-02",endDate: "2020-03-02",traffic: 4593},
                    {startDate: "2020-03-09",endDate: "2020-03-09",traffic: 4908},
                    {startDate: "2020-03-10",endDate: "2020-03-10",traffic: 5076},
                    {startDate: "2020-03-11",endDate: "2020-03-11",traffic: 4930},
                    {startDate: "2020-03-12",endDate: "2020-03-12",traffic: 5287},
                    {startDate: "2020-03-13",endDate: "2020-03-13",traffic: 5672},
                    {startDate: "2020-03-14",endDate: "2020-03-14",traffic: 5944},
                    {startDate: "2020-03-15",endDate: "2020-03-15",traffic: 6980},
                ]
            },
            {
                acquisitionChannelType: "affiliation",
                traffics: [
                    {startDate: "2020-01-01",endDate: "2020-01-01",traffic: 73},
                    {startDate: "2020-02-01",endDate: "2020-02-01",traffic: 91},
                    {startDate: "2020-03-01",endDate: "2020-03-01",traffic: 126},
                    {startDate: "2020-03-02",endDate: "2020-03-02",traffic: 131},
                    {startDate: "2020-03-09",endDate: "2020-03-09",traffic: 140},
                    {startDate: "2020-03-10",endDate: "2020-03-10",traffic: 145},
                    {startDate: "2020-03-11",endDate: "2020-03-11",traffic: 141},
                    {startDate: "2020-03-12",endDate: "2020-03-12",traffic: 151},
                    {startDate: "2020-03-13",endDate: "2020-03-13",traffic: 162},
                    {startDate: "2020-03-14",endDate: "2020-03-14",traffic: 170},
                    {startDate: "2020-03-15",endDate: "2020-03-15",traffic: 199},
                ]
            },
        ]
    },

    trafficQuality : {
        content:[
            {
                channel:"traffic",
                seo:6182,
                ads:2393,
                social:1795,
                referral:1198,
                email:1197,
                direct:6980,
                affiliation:199,
                total:19944
            },
            {
                channel:"conversion",
                seo:281,
                ads:124,
                social:75,
                referral:60,
                email:63,
                direct:342,
                affiliation:2,
                total:947
            },
            {
                channel:"conversion_rate",
                seo:4.5,
                ads:5.2,
                social:4.2,
                referral:5.0,
                email:5.3,
                direct:4.9,
                affiliation:1.0,
                total:4.7
            },
            {
                channel:"transactions_count",
                seo:245,
                ads:112,
                social:67,
                referral:51,
                email:55,
                direct:307,
                affiliation:1,
                total:838
            },
            {
                channel:"ecommerce_conversion_rate",
                seo:4,
                ads:4.7,
                social:3.7,
                referral:4.3,
                email:4.6,
                direct:4.4,
                affiliation:0.5,
                total:4.2
            },
            {
                channel:"turnover",
                seo:19826,
                ads:9445,
                social:5334,
                referral:6223,
                email:5212,
                direct:23632,
                affiliation:704,
                total:70376
            },
            {
                channel:"turnover_per_user",
                seo:3.2,
                ads:3.9,
                social:3.0,
                referral:5.2,
                email:4.4,
                direct:3.4,
                affiliation:3.5,
                total:3.52
            }
        ]
    },

    trafficIncomingWebsite: {
        content:[
            {
                "domainName": "alltricks.fr",
                "cumulatedTraffic": 231,
                "averageConversionRate": 3.1,
                "cumulatedTurnover": 574
            },
            {
                "domainName": "quechoisir.org",
                "cumulatedTraffic": 201,
                "averageConversionRate": 4.5,
                "cumulatedTurnover": 308
            },
            {
                "domainName": "my-velo.fr",
                "cumulatedTraffic": 188,
                "averageConversionRate": 4.5,
                "cumulatedTurnover": 185
            },
            {
                "domainName": "economie.gouv.fr",
                "cumulatedTraffic": 138,
                "averageConversionRate": 6.3,
                "cumulatedTurnover": 253
            },
            {
                "domainName": "troc-velo.com",
                "cumulatedTraffic": 111,
                "averageConversionRate": 2.9,
                "cumulatedTurnover": 203
            },
            {
                "domainName": "santafixie.fr",
                "cumulatedTraffic": 83,
                "averageConversionRate": 6.1,
                "cumulatedTurnover": 89
            },
            {
                "domainName": "commeunvelo.com",
                "cumulatedTraffic": 47,
                "averageConversionRate": 2.8,
                "cumulatedTurnover": 83
            },
            {
                "domainName": "lepetitpignon.com",
                "cumulatedTraffic": 35,
                "averageConversionRate": 2.8,
                "cumulatedTurnover": 38
            },
            {
                "domainName": "lejustechoix.fr",
                "cumulatedTraffic": 23,
                "averageConversionRate": 4.4,
                "cumulatedTurnover": 36
            },
            {
                "domainName": "meilleurtest.fr",
                "cumulatedTraffic": 11,
                "averageConversionRate": 6.2,
                "cumulatedTurnover": 21
            },
            {
                "domainName": "futura-sciences.com",
                "cumulatedTraffic": 30,
                "averageConversionRate": 8.52,
                "cumulatedTurnover": 5
            },
            {
                "domainName": "guidedesport.com",
                "cumulatedTraffic": 30,
                "averageConversionRate": 8.52,
                "cumulatedTurnover": 5
            },
            {
                "domainName": "velo-on-line.fr",
                "cumulatedTraffic": 30,
                "averageConversionRate": 8.52,
                "cumulatedTurnover": 5
            },
            {
                "domainName": "bfmtv.com",
                "cumulatedTraffic": 30,
                "averageConversionRate": 8.52,
                "cumulatedTurnover": 5
            },
            {
                "domainName": "redbull.com",
                "cumulatedTraffic": 30,
                "averageConversionRate": 8.52,
                "cumulatedTurnover": 5
            },
            {
                "domainName": "zeoutdoor.com",
                "cumulatedTraffic": 30,
                "averageConversionRate": 8.52,
                "cumulatedTurnover": 5
            },
            {
                "domainName": "lemeilleuravis.com",
                "cumulatedTraffic": 30,
                "averageConversionRate": 8.52,
                "cumulatedTurnover": 5
            },
            {
                "domainName": "sportifun.net",
                "cumulatedTraffic": 30,
                "averageConversionRate": 8.52,
                "cumulatedTurnover": 5
            }
        ]

    }






}

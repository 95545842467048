import themeConfig from "../../themeConfig";



const getThemeColors = (()=>{
    return  themeConfig.colors
})

const getThemeColorByName = ((name = 'primary', opacity=1)=>{

    let color = themeConfig.colors[name]
    if(!themeConfig.colors[name]){
        color = name
    }

    if(opacity !== 1){
        return  hexToRgbA(color,opacity)
    }
    return  color
})

const getLinkingThematicColor = ((thematicName='')=>{

    switch(thematicName){
        case 'Reference': return '#E91E93'
        case 'Recreation': return '#00969C'
        case 'Arts': return '#FF9507'
        case 'Society': return '#6D3EC0'
        case 'Business': return '#C2C667'
        case 'Computers': return '#FE7F69'
        case 'News': return '#2196F3'
        case 'Science': return '#8296FF'
        case 'Health': return '#0D47A1'
        case 'Sports': return '#AD3D7F'
        case 'Regional': return '#E551FF'
        case 'Home': return '#D95'
        case 'Shopping': return '#925E4D'
        case 'Games': return '#F5CD00'
        case 'World': return '#897F7F'
        case 'Adult': return '#3D3333'
        default: return '#fff'
    }

})


const getComponentColor = (()=>{
    return {

        getCompetitorColor(competitorNum, opacity=1){
            let color = getThemeColorByName('primary')
            if(competitorNum){
                color = getThemeColorByName('competitor'+(competitorNum),opacity)
            }
            return color
        },

        getProgressBarColor(value, decrease = false) {
            let color = getThemeColorByName('progressBar1')
            if(value<=75){ color = getThemeColorByName('progressBar2') }
            if(value<=50){ color = getThemeColorByName('progressBar3') }
            if(value<=25){ color = getThemeColorByName('progressBar4') }
            if(decrease){
                color = getThemeColorByName('progressBar4')
                if(value<=75){ color = getThemeColorByName('progressBar3') }
                if(value<=50){ color = getThemeColorByName('progressBar2') }
                if(value<=25){ color = getThemeColorByName('progressBar1') }
            }

            return color
        },

        getGoogleResultTypeColor(type){
            let color = getThemeColorByName('primary')

            switch(type){
                case 'natural':
                    color = getThemeColorByName('googleResultTypeNatural')
                    break
                case 'map':
                    color = getThemeColorByName('googleResultTypeMap')
                    break
                case 'adwords':
                    color = getThemeColorByName('googleResultTypeAdwords')
                    break
                case 'shopping':
                    color = getThemeColorByName('googleResultTypeShopping')
                    break
                case 'image':
                    color = getThemeColorByName('googleResultTypeImage')
                    break
                case 'snippet':
                    color = getThemeColorByName('googleResultTypeSnippet')
                    break
                case 'actuality':
                    color = getThemeColorByName('googleResultTypeActuality')
                    break
                case 'video':
                    color = getThemeColorByName('googleResultTypeVideo')
                    break

            }
            return color
        }
    }
})


const hexToRgbA = ((hex, opacity=1)=>{
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
    }
    throw new Error('Bad Hex');
})


export default {
    colors:{
        getThemeColors,
        getThemeColorByName,
        getComponentColor,
        getLinkingThematicColor
    }

}

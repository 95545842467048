
export default {
    customer: {
        content: {
            id: 99999,
            chargifyId: 666,
            email: 'john.doe@yooda.com',
            firstName: 'John',
            lastName: 'Doe',
            organization: 'Missing Inc.',
            createdAt: '2020-03-17T14:01:01+00:00',
            updatedAt: '2020-07-27T15:01:53+00:00',
            roles:[
                'ROLE_USER',
                'ROLE_ALLOWED_TO_SWITCH'
            ],
            billingPortal: {
                id: 98999,
                url: 'https://www.billingportal.com/manage/1254463/1599489669/1cc61986ff4f5acc',
                createdAt: '2020-09-07T14:41:09+00:00',
                newLinkAvailableAt: '2020-09-22T14:41:09+00:00',
                expiresAt: '2020-11-11T15:41:09+00:00'
            },

            customerSubscriptions: [
                {
                    id: 1,
                    owner: true,
                    subscription: {
                        id: 1,
                        chargifyId: 31986814,
                        state: 'active',
                        previousState:'trialing',
                        createdAt: '2020-03-17T14:01:01+01:00',
                        updatedAt: '2020-10-30T14:09:44+01:00',
                        trialStartedAt:'2021-12-17T10:24:48+01:00',
                        trialEndedAt:'2021-12-31T10:24:48+01:00',
                        activatedAt: '2020-03-17T14:01:03+01:00',
                        expiresAt: null,
                        canceledAt:null,
                        currentPeriodStartedAt:null,
                        currentPeriodEndsAt:null,
                        yoodaParameters: {
                            "computeGracePeriod": 0,
                            "stackRunningGracePeriod": 15,
                            "backupConservationGracePeriod": 30,
                            "backendDataConservationGracePeriod": 30
                        },
                        analyticsRefreshToken: "1//03s7skRfpvrjMCgYIARAAGAMSNwF-L9IrEsy4JZF4Zv0DCbgS7yG0G9S9JGpwzNd-mHb78WGOs64hcfo5T02hTTI3JztpU69n8aU",
                        product: {
                            id: 3,
                            chargifyId: 5204967,
                            name: 'Plan Agency',
                            handle: 'yooda-one-plan-agency',
                            description: null,
                            createdAt: '2020-02-26T11:14:13+01:00',
                            updatedAt: '2020-07-22T14:19:56+02:00',
                            archived: false,
                            priceInCents: 8900,
                            productOptions: [
                                {
                                    id: 11,
                                    name: 'maxGeolocationCountPerProject',
                                    handle: 'max-geolocation-count-per-project',
                                    value: '4',
                                    type: 'integer'
                                },
                                {
                                    id: 7,
                                    name: 'maxRequestCount',
                                    handle: 'max-request-count',
                                    value: '6000',
                                    type: 'integer'
                                },
                                {
                                    id: 3,
                                    name: 'maxProjectCount',
                                    handle: 'max-project-count',
                                    value: '30',
                                    type: 'integer'
                                },
                                {
                                    id: 15,
                                    name: 'maxRivalCountPerProject',
                                    handle: 'max-rival-count-per-project',
                                    value: '3',
                                    type: 'integer'
                                }
                            ],
                            publicSignupPages: [
                                {
                                    id: 4,
                                    chargifyId: 409286,
                                    url: 'https://yooda-toto.chargifypay.com/subscribe/bf66qxgddvz3/yooda-one-plan-agency'
                                }
                            ]
                        },
                        subscriptionComponents:[
                            {
                                id:391,
                                count:0,
                                component:{
                                    id:1,
                                    chargifyId:945679,
                                    name:'Mots-Clés supplémentaires',
                                    description:null,
                                    unitName:'requêtes supplémentaires',
                                    kind:'quantity_based_component',
                                    createdAt:'2020-02-26T11:14:59+01:00',
                                    archived:false,
                                    handle:'yooda-one-component-additional-requests'
                                }
                            },
                            {
                                id:392,
                                count:0,
                                component:{
                                    id:2,
                                    chargifyId:945680,
                                    name:'Projets supplémentaires',
                                    description:null,
                                    unitName:'projets supplémentaires',
                                    kind:'quantity_based_component',
                                    createdAt:'2020-02-26T11:14:59+01:00',
                                    archived:false,
                                    handle:'yooda-one-component-additional-projects'
                                }
                            },
                            {
                                id:393,
                                count:0,
                                component:{
                                    id:3,
                                    chargifyId:945681,
                                    name:'Utilisateurs supplémentaires',
                                    description:null,
                                    unitName:'utilisateurs supplémentaires',
                                    kind:'quantity_based_component',
                                    createdAt:'2020-02-26T11:15:00+01:00',
                                    archived:true,
                                    handle:'yooda-one-component-additional-users'
                                }
                            }
                        ]
                    },
                    ownerCustomer: {
                        id: 99999,
                        chargifyId: 666,
                        createdAt: '2020-03-17T14:01:01+00:00',
                        email: 'john.doe@yooda.com',
                        firstName: 'John',
                        lastName: 'DOE',
                        organization: 'Missing Inc.',
                        updatedAt: '2020-07-27T15:01:53+00:00',
                    }
                },
            ]
        },
        context: {},
        type: 'item'
    },
    analyticsRefreshToken:{
        content: {},
        context: {},
        type: 'item'
    }
    
}

<template>
    <v-dialog v-model="displayConfirmation">
        <v-card :style="{width:`${width}px`}">
            <v-card-title class="text-h6 py-2" style="background-color:#F8F8F8" :style="{color:`${titleColor}`}">{{ title }}</v-card-title>
            <v-card-text class="pt-4">
                <slot/>
                <div class="pt-4"><v-divider></v-divider></div>
            </v-card-text>
            <v-card-actions class="v-application">
                <v-spacer></v-spacer>
                <v-btn :color="buttonColorClass" @click="clickAccept" small>
                    <v-icon left>mdi-check</v-icon>
                    {{ $t('common_accept_label') }}
                </v-btn>
                <v-btn outlined :color="buttonColorClass" class="ml-4" @click="clickCancel" small>
                    <v-icon left>mdi-close</v-icon>
                    {{ $t('common_cancel_label') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>


<script>


export default {
    props: {
        title:{type:String, default:''},
        width:{type:Number, default:400},
        titleColor:{type:String, default:'#EA4335'},
        buttonColorClass:{type:String, default:'error'},
    },

    data(){
        return {
            displayConfirmation:false
        }
    },
    methods:{
        displayDialog(){
            this.displayConfirmation = true
        },
        clickAccept(){
            this.$emit('confirm-dialog-valid')
            this.displayConfirmation = false
        },
        clickCancel(){
            this.$emit('confirm-dialog-cancel')
            this.displayConfirmation = false
        }
    }


}
</script>

<style lang="scss" >


</style>

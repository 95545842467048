export default {
    "fr": {
        "view_dashboardRecommendations_helpMessage_keywords": "Pour vous faciliter la découverte de Yooda One, j'ai choisi pour vous quelques mots-clés, et je les ai regroupés dans plusieurs segments de marché. <br>Mais vous connaissez mieux votre métier que moi. Je vous conseille donc de <a href='keywords-configuration'>peaufiner votre liste de vos mots-clés</a> et d'<a href='segments-configuration'>affiner vos segments</a>.",

        "component_globalGoogleVisibility_title": 'Ma visibilité Google globale',
        "component_globalGoogleVisibility_subtitle": 'Leviers d\'optimisations qui auront le plus d\'impact sur votre trafic.',
        "component_globalGoogleVisibility_label_overwhelming": 'Ecrasante',
        "component_globalGoogleVisibility_label_dominating": 'Dominante',
        "component_globalGoogleVisibility_label_excellent": 'Excellente',
        "component_globalGoogleVisibility_label_correct": 'Correcte',
        "component_globalGoogleVisibility_label_null": 'Faible',
        "component_globalGoogleVisibility_visibility_status": 'Votre visibilité Google est [*VISIBILITY*].',
        "component_globalGoogleVisibility_overwhelming_paragraph": `<p>Vous écrasez la concurrence sur votre marché en captant <span class="font-weight-bold">[*SCORE*]%</span> du trafic Google qu'il est possible de capter en étant 1er partout. <br>Continuez votre croissance en attaquant d'autres segments de marchés.</p> <p class="mt-1 mb-0">Principaux leviers de visibilité Google :</p>`,
        "component_globalGoogleVisibility_dominating_paragraph": `<p>Vous dominez la concurrence en captant <span class="font-weight-bold">[*SCORE*]%</span> du trafic Google qu'il est possible de capter en étant premier partout. <br>Continuez votre croissance en attaquant d'autres segments de marchés.</p> <p class="mt-1 mb-0"><strong>Principaux leviers de visibilité Google</strong></p>`,
        "component_globalGoogleVisibility_excellent_paragraph": `<p>Malgré la concurrence, vous captez <span class="font-weight-bold">[*SCORE*]%</span> du trafic qu'il serait possible de capter sur Google en étant 1er partout.<br>Félicitations, c'est déjà beaucoup.</p> <p class="mt-1 mb-0"><strong>Principaux leviers de visibilité Google</strong></p>`,
        "component_globalGoogleVisibility_correct_paragraph": `<p>Vous captez <span class="font-weight-bold">[*SCORE*]%</span> du trafic qu'il serait possible de capter sur Google en étant 1er partout. <br>C'est perfectible, mais c'est un bon début.</p> <p class="mt-1 mb-0"><strong>Principaux leviers de visibilité Google</strong></p>`,
        "component_globalGoogleVisibility_null_paragraph": `<p>Vous captez seulement <span class="font-weight-bold">[*SCORE*]%</span> du trafic qu'il serait possible de capter sur Google en étant 1er partout.<br>Au boulot !</p> <p class="mt-1 mb-0"><strong>Principaux leviers de visibilité Google</strong></p>`,
        "component_globalGoogleVisibility_sea_visibility": 'Visibilité SEA',
        "component_globalGoogleVisibility_seo_visibility": 'Visibilité SEO',
        "component_globalGoogleVisibility_read_more": 'Voir détails',

        "component_actionPriorities_title": "Mes priorités d'action",
        "component_actionPriorities_subtitle": "Types de résultats Google et segments de marché à optimiser en priorité pour avoir le maximum d'impact sur votre trafic mensuel.",
        "component_actionPriorities_resultTypePriority": "Type de résultats à prioriser",
        "component_actionPriorities_resultTypeLabel": "Résultats [*TYPE*]",
        "component_actionPriorities_segmentPriority": "Segment de marché à prioriser",
        "component_actionPriorities_segmentLabel": "Segment ",
        "component_actionPriorities_potentialVisitor": "visiteurs potentiels",
        "component_actionPriorities_cta": "Voir toutes mes priorités d'action",

        "component_mostVisibleCompetitors_title": "Mes concurrents les plus visibles",
        "component_mostVisibleCompetitors_subtitle": "",
        "component_mostVisibleCompetitors_cta": "Choisir les concurrents à suivre",
        "component_mostVisibleCompetitors_tableHeader_competitor": "Concurrent",
        "component_mostVisibleCompetitors_tableHeader_rank": "Classement",
        "component_mostVisibleCompetitors_tableHeader_estimatedTraffic": "Trafic estimé",
        "component_mostVisibleCompetitors_tableHeader_keywordsCount": "Nb mots-clés",

        "component_marketSegmentVisibility_title": "Ma visibilité sur mes segments de marché",
        "component_marketSegmentVisibility_cta": "Choisir mes segments & mots-clés",
        "component_marketSegmentVisibility_tableHeader_segment": "Segment",
        "component_marketSegmentVisibility_tableHeader_estimatedTraffic": "Trafic estimé",
        "component_marketSegmentVisibility_tableHeader_keywordsCount": "Nb mots-clés",
        "component_marketSegmentVisibility_tableHeader_searchesCount": "Nb recherches",

        "component_topBestPages_title": "TOP 5 de mes meilleures pages",
        "component_topBestPages_subtitle": "",
        "component_topBestPages_cta": "Voir toutes mes pages positionnées",
        "component_topBestPages_tableHeader_url": "URL",
        "component_topBestPages_tableHeader_estimatedTraffic": "Trafic estimé",
        "component_topBestPages_tableHeader_analyticsTraffic": "Trafic",
        "component_topBestPages_tableHeader_seoPositionsCount": "Nb positions SEO",
        "component_topBestPages_tableHeader_adsPositionsCount": "Nb positions Ads",

        "component_topBestKeywords_title": "TOP 5 de mes meilleurs mots-clés",
        "component_topBestKeywords_subtitle": "",
        "component_topBestKeywords_cta": "Modifier mes mots-clés",
        "component_topBestKeywords_tableHeader_keyword": "Mot-clé",
        "component_topBestKeywords_tableHeader_seoPositionRank": "Position SEO",
        "component_topBestKeywords_tableHeader_adsPositionRank": "Position Ads",
        "component_topBestKeywords_tableHeader_searchesCount": "Nb recherches",

        "component_openPurchase_keywordsLimit": "<p>Votre essai est limité à [*KEYWORDS-LIMIT*] mots-clés. <br /> C'est peu pour analyser tout votre business en ligne.</p>",
        "component_openPurchase_offer": "<p>2 mois offerts <br /> pour un abonnement annuel !</p>",
        "component_openPurchase_cta": "Analysez tous vos mots-clés et segments",
    },
    "en": {
        "view_dashboardRecommendations_helpMessage_keywords": "To make it easier for you to discover Yooda One, I've chosen a few keywords for you, and grouped them into several market segments. <br>But you know your business better than I do. So my advice is to <a href='keywords-configuration'>fine your list of keywords</a> and <a href='segments-configuration'>refine your segments</a>.",

        "component_globalGoogleVisibility_title": 'My global Google visibility',
        "component_globalGoogleVisibility_subtitle": 'Optimization levers that will have the most impact on your traffic.',
        "component_globalGoogleVisibility_label_overwhelming": 'Overwhelming',
        "component_globalGoogleVisibility_label_dominating": 'Dominant',
        "component_globalGoogleVisibility_label_excellent": 'Excellent',
        "component_globalGoogleVisibility_label_correct": 'Correct',
        "component_globalGoogleVisibility_label_null": 'Poor',
        "component_globalGoogleVisibility_visibility_status": 'Your Google visibility is [*VISIBILITY*].',
        "component_globalGoogleVisibility_overwhelming_paragraph": `<p>You crush the competition in your market by capturing <span class="font-weight-bold">[*SCORE*]%</span> of the Google traffic that can be captured by being 1st everywhere. <br>Continue your growth by attacking other market segments.</p> <p class="mt-1 mb-0">Main Google visibility levers:</p>`,
        "component_globalGoogleVisibility_dominating_paragraph": `<p>You dominate the competition by capturing <span class="font-weight-bold">[*SCORE*]%</span> of the Google traffic that can be captured by being first everywhere. <br>Continue your growth by attacking other market segments.</p> <p class="mt-1 mb-0"><strong>Main Google visibility levers</strong></p>`,
        "component_globalGoogleVisibility_excellent_paragraph": `<p>Despite the competition, you are capturing <span class="font-weight-bold">[*SCORE*]%</span> of the traffic that would be possible to capture on Google by being 1st everywhere.<br>Congratulations, that's already a lot.</p> <p class="mt-1 mb-0"><strong>Principal levers of Google visibility</strong></p>`,
        "component_globalGoogleVisibility_correct_paragraph": `<p>You capture <span class="font-weight-bold">[*SCORE*]%</span> of the traffic it would be possible to capture on Google by being 1st everywhere. <br>This can be improved, but it's a good start.</p> <p class="mt-1 mb-0"><strong>Main Google visibility levers</strong></p>`,
        "component_globalGoogleVisibility_null_paragraph": `<p>You're only capturing <span class="font-weight-bold">[*SCORE*]%</span> of the traffic it would be possible to capture on Google by being 1st everywhere.<br>Get to work!</p> <p class="mt-1 mb-0"><strong>Main Google visibility levers</strong></p>`,
        "component_globalGoogleVisibility_sea_visibility": 'SEA visibility',
        "component_globalGoogleVisibility_seo_visibility": 'SEO visibility',
        "component_globalGoogleVisibility_read_more": 'View details',

        "component_actionPriorities_title": "My action priorities",
        "component_actionPriorities_subtitle": "Types of Google results and market segments to prioritize for maximum impact on your monthly traffic.",
        "component_actionPriorities_resultTypePriority": "Type of results to prioritize",
        "component_actionPriorities_resultTypeLabel": "[*TYPE*] results",
        "component_actionPriorities_segmentPriority": "Market segment to prioritize",
        "component_actionPriorities_segmentLabel": "Segment",
        "component_actionPriorities_potentialVisitor": "potential visitors",
        "component_actionPriorities_cta": "See all my action priorities",

        "component_mostVisibleCompetitors_title": "My most visible competitors",
        "component_mostVisibleCompetitors_subtitle": "",
        "component_mostVisibleCompetitors_cta": "Choose the competitors to follow",
        "component_mostVisibleCompetitors_tableHeader_competitor": "Competitor",
        "component_mostVisibleCompetitors_tableHeader_rank": "Rank",
        "component_mostVisibleCompetitors_tableHeader_estimatedTraffic": "Estimated Traffic",
        "component_mostVisibleCompetitors_tableHeader_keywordsCount": "Nb keywords",

        "component_marketSegmentVisibility_title": "My visibility on my market segments",
        "component_marketSegmentVisibility_cta": "Choose my segments & keywords",
        "component_marketSegmentVisibility_tableHeader_segment": "Segment",
        "component_marketSegmentVisibility_tableHeader_estimatedTraffic": "Estimated Traffic",
        "component_marketSegmentVisibility_tableHeader_keywordsCount": "Nb keywords",
        "component_marketSegmentVisibility_tableHeader_searchesCount": "Nb searches",

        "component_topBestPages_title": "Top 5 of my best pages",
        "component_topBestPages_subtitle": "",
        "component_topBestPages_cta": "See all my positioned pages",
        "component_topBestPages_tableHeader_url": "URL",
        "component_topBestPages_tableHeader_estimatedTraffic": "Estimated traffic",
        "component_topBestPages_tableHeader_analyticsTraffic": "Traffic",
        "component_topBestPages_tableHeader_seoPositionsCount": "Nb SEO positions",
        "component_topBestPages_tableHeader_adsPositionsCount": "Nb positions Ads",

        "component_topBestKeywords_title": "TOP 5 of my best keywords",
        "component_topBestKeywords_subtitle": "",
        "component_topBestKeywords_cta": "Change my keywords",
        "component_topBestKeywords_tableHeader_keyword": "Keyword",
        "component_topBestKeywords_tableHeader_seoPositionRank": "SEO Position",
        "component_topBestKeywords_tableHeader_adsPositionRank": "Position Ads",
        "component_topBestKeywords_tableHeader_searchesCount": "Nb searches",

        "component_openPurchase_keywordsLimit":"<p>Your trial is limited to [*KEYWORDS-LIMIT*] keywords. <br /> This is not much to analyze your entire online business.</p>",
        "component_openPurchase_offer":"<p>2 months free <br /> for an annual subscription!</p>",
        "component_openPurchase_cta": "Analyze all your keywords and segments",

    },
}


const getDefaultState = () => {
    return {
        subscriptionMeta:{  isLoaded:false, error:null,data:null },
        projectsSummary: { isLoaded:false, error:null,  data: null },
        analyticsDataSource: { isLoaded:false, error:null,  data: null },
        matomoDataSource: { isLoaded:false, error:null,  data: null },
        projectSegments: { isLoaded:false, error:null,  data: null },
        projectKeywords: { isLoaded:false, error:null,  data: null },
        projectError:false,
        projectInfo: {
            isLoaded:false,
            error:null,
            data: {
                url: '',
                analyticsViewConfiguration:null,
                defaultSearchGeolocation:null,
                keywords:[],
                rivals:[],
                projectLoaded:false,
                keywordGroups:[],
                searchGeolocations:[],
                reports:[],
                subscriptionMeta:null,
                reportingModel:null,
                thumbnail:null,
                errors:null
            }
        },
        showPopin:null,
        globalFilterFrequency: '1',
        globalFilterLocation: 1,
        chartSelectedSeriesName:'',
        projectCreationTmp:{
            websiteUrl:null,
            competitors:[],
            searchEngine:null,
            language:null,
            locations:[],
            keywords:[],
            keywordGroups:[],
            waitingKeywordSuggest:false,
        },
        defaultDashboardName:'dashboard_recommendations',
        tableDensity:'relaxed'
    }
}

export default {getDefaultState}

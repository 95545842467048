

export default {
    "fr": {
        "component_trafficQualityTotalTraffic_title": "Trafic",
        "component_trafficQualityTotalTraffic_subtitle": "Nombre de visites (source Analytics)",
        "component_trafficQualityTotalTraffic_series_name": "Trafic",
        "component_trafficQualityTotalTraffic_label": "Visites",
        "component_trafficQualityTotalTraffic_informationTooltip_title": "Trafic",
        "component_trafficQualityTotalTraffic_informationTooltip_text": "<p>Nombre de visites uniques total sur votre site.</p><p><br>Avec le menu, vous pouvez modifier la granularité de la courbe et afficher le trafic des derniers jours, des dernières semaines ou des derniers mois.</p><p><br>Vous modifiez également la période prise en compte par le KPI, et affichez le trafic de la période en cours : hier, cette semaine, ce mois-ci, cette année.</p><p><br><i>Source : Analytics - Nombre d'utilisateurs</i></p>",

        "component_trafficEvolution_title": "Trafic",
        "component_trafficEvolution_subtitle": "Nombre de visites (source Analytics)",
        "component_trafficEvolution_series_name": "Trafic",
        "component_trafficEvolution_label": "Visites",
        "component_trafficEvolution_informationTooltip_title": "Trafic",
        "component_trafficEvolution_informationTooltip_text": "<p>Nombre de visites uniques total sur votre site.</p><p><br>Avec le menu, vous pouvez modifier la granularité de la courbe et afficher le trafic des derniers jours, des dernières semaines ou des derniers mois.</p><p><br>Vous modifiez également la période prise en compte par le KPI, et affichez le trafic de la période en cours : hier, cette semaine, ce mois-ci, cette année.</p><p><br><i>Source : Analytics - Nombre d'utilisateurs</i></p>",


        "component_trafficEvolutionConversion_title": "Trafic et conversions",
        "component_trafficEvolutionConversion_subtitle": "Nombre de visites et nombre de conversions (source Analytics)",
        "component_trafficEvolutionConversion_series_name_1": "Nombre de visites",
        "component_trafficEvolutionConversion_series_label_1": "visites",
        "component_trafficEvolutionConversion_series_name_2": "Nombre de conversions",
        "component_trafficEvolutionConversion_series_label_2": "conversions",
        "component_trafficEvolutionConversion_informationTooltip_title": "Trafic et conversions",
        "component_trafficEvolutionConversion_informationTooltip_text": "<p>Evolution du nombre total de visites uniques sur le site ainsi que l'ensemble des conversions réalisées</p><p><br><i>Source : Analytics</i></p>",

        "component_trafficAcquisitionChannel_title": "Canaux d'acquisition",
        "component_trafficAcquisitionChannel_subtitle": "Provenance du trafic (source Analytics)",
        "component_trafficAcquisitionChannel_informationTooltip_title": "Canaux d'acquisition",
        "component_trafficAcquisitionChannel_informationTooltip_text": "<p>Permet d'identifier la provenance des visites qui arrivent sur le site (Google Ads, Résultats naturels Google, Mail, Réseaux Sociaux, autres sites...).</p><p><br><i>Source : Analytics</i></p>",
        "component_trafficAcquisitionChannel_label": "visites",
        "component_trafficAcquisitionChannel_seo": "SEO",
        "component_trafficAcquisitionChannel_ads": "Google Ads",
        "component_trafficAcquisitionChannel_social": "Social",
        "component_trafficAcquisitionChannel_referral": "Referral",
        "component_trafficAcquisitionChannel_email": "Email",
        "component_trafficAcquisitionChannel_direct": "Direct",
        "component_trafficAcquisitionChannel_affiliation": "Affiliation",
        "component_trafficAcquisitionChannel_detailButton": "Etudier les sources de trafic",

        "component_trafficSource_title": "Sources de trafic",
        "component_trafficSource_subtitle": "Evolution du nombre de visites entrants, par canal (source Analytics)",
        "component_trafficSource_informationTooltip_title": "Sources de trafic",
        "component_trafficSource_informationTooltip_text": "<p>Permet de suivre l'évolution du trafic entrant pour chaque canal d’acquisition.</p><p><br><i>Source : Analytics</i></p>",
        "component_trafficSource_series_label": "visites",
        "component_trafficSource_analytics_label_seo": "SEO",
        "component_trafficSource_analytics_label_ads": "Google Ads",
        "component_trafficSource_analytics_label_social": "Social",
        "component_trafficSource_analytics_label_referral": "Referral",
        "component_trafficSource_analytics_label_email": "Email",
        "component_trafficSource_analytics_label_direct": "Direct",
        "component_trafficSource_analytics_label_affiliation": "Affiliation",

        "component_trafficQualityTotalTurnover_title": "Chiffre d'affaires",
        "component_trafficQualityTotalTurnover_subtitle": "",
        "component_trafficQualityTotalTurnover_label": "Chiffre d'affaires",
        "component_trafficQualityTotalTurnover_informationTooltip_title": "Chiffre d'affaires total",
        "component_trafficQualityTotalTurnover_informationTooltip_text": "<p>Chiffre d'affaires e-commerce réalisé par l'ensemble des visites du site web.</p><p><br>Avec le menu, vous pouvez modifier la granularité de la courbe et afficher le chiffre d'affaires des derniers jours, des dernières semaines ou des derniers mois.</p><p><br>Vous modifiez également la période prise en compte par le KPI, et affichez le chiffre d'affaires de la période en cours : hier, cette semaine, ce mois-ci, cette année.</p> <p><br> <i>Source : Analytics - Revenu du e-commerce</i></p>",

        "component_trafficQualityTotalSale_title": "Ventes",
        "component_trafficQualityTotalSale_subtitle": "",
        "component_trafficQualityTotalSale_totalSale_label": "Ventes",
        "component_trafficQualityTotalSale_informationTooltip_title": "Ventes",
        "component_trafficQualityTotalSale_informationTooltip_text": "<p>Nombre de ventes réalisées par l'ensemble des visites du site web.</p><p><br>Avec le menu, vous pouvez modifier la granularité de la courbe et afficher le nombre de ventes des derniers jours, des dernières semaines ou des derniers mois.</p><p><br>Vous modifiez également la période prise en compte par le KPI, et affichez le chiffre d'affaires de la période en cours : hier, cette semaine, ce mois-ci, cette année.</p><p><br><i>Source : Analytics - Nombre de transactions</i></p>",

        "component_trafficQualitySale_title": "Ventes",
        "component_trafficQualitySale_subtitle": "",

        "component_trafficQualityTotalConversion_title": "Conversions des objectifs",
        "component_trafficQualityTotalConversion_subtitle": "",
        "component_trafficQualityTotalConversion_totalConversion_label": "Conversions",
        "component_trafficQualityTotalConversion_informationTooltip_title": "Conversion",
        "component_trafficQualityTotalConversion_informationTooltip_text": "<p>Nombre de Conversions des objectifs réalisées par l'ensemble des visites du site web.</p><p><br>Avec le menu, vous pouvez modifier la granularité de la courbe et afficher le nombre de ventes des derniers jours, des dernières semaines ou des derniers mois.</p><p><br>Vous modifiez également la période prise en compte par le KPI, et affichez le chiffre d'affaires de la période en cours : hier, cette semaine, ce mois-ci, cette année.</p><p><br><i>Source : Analytics - Nombre de transactions</i></p>",

        "component_trafficQualityTurnoverByVisitor_title": "Chiffre d'affaires par visite",
        "component_trafficQualityTurnoverByVisitor_subtitle": "",
        "component_trafficQualityTurnoverByVisitor_informationTooltip_title": "Chiffre d'affaires par visite",
        "component_trafficQualityTurnoverByVisitor_informationTooltip_text": "<p>Permet de connaître le revenu e-commerce moyen par visite.</p><p><br><i>Source : Analytics</i></p>",

        "component_trafficQualityConversion_title": "Taux de conversion des objectifs",
        "component_trafficQualityConversion_subtitle": "",
        "component_trafficQualityConversion_informationTooltip_title": "Taux de conversion des objectifs",
        "component_trafficQualityConversion_informationTooltip_text": "<p>Permet de connaître le pourcentage de visites ayant déclenché une conversion d'au moins un objectif.</p><p><br><i>Source : Analytics</i></p>",

        "component_trafficQuality_sale_title": "Taux de conversion ecommerce",
        "component_trafficQualitySale_informationTooltip_title": "Taux de conversion ecommerce",
        "component_trafficQualitySale_informationTooltip_text": "<p>Permet de connaître le pourcentage de visiteurs ayant déclenché une ou plusieurs ventes/transactions.</p><p><br><i>Source : Analytics</i></p>",

        "component_trafficIncomingWebsite_title": "Sites apportant du trafic",
        "component_trafficIncomingWebsite_subtitle": "Sites web qui envoient du trafic vers notre site (source Analytics)",
        "component_trafficIncomingWebsite_informationTooltip_title": "Sites apportant du trafic",
        "component_trafficIncomingWebsite_informationTooltip_text": "<p>Permet d'identifier les sites apportant du trafic, des conversions et du chiffre d'affaires à votre site.</p><p><br><i>Source : Analytics</i></p>",
        "component_trafficIncomingWebsite_table_header_website": "Site référent",
        "component_trafficIncomingWebsite_table_header_incomingTraffic": "Trafic envoyé",
        "component_trafficIncomingWebsite_table_header_conversion": "Conversions",
        "component_trafficIncomingWebsite_table_header_turnover": "Chiffre d'affaires",

        "component_trafficQuality_title": "Performance des canaux d'acquisition",
        "component_trafficQuality_subtitle": "Résultats générés par chaque source de trafic (source Analytics)",
        "component_trafficQuality_informationTooltip_title": "Performance des canaux d'acquisition",
        "component_trafficQuality_informationTooltip_text": "<p>Permet d'identifier le CA, la conversion et le taux de conversion et la durée d'une visite pour chaque canal d'acquisition (Google Ads, Mails, etc...).</p><p><br><i>Source : Analytics</i></p>",
        "component_trafficQuality_table_header_natural": "Naturel",
        "component_trafficQuality_table_header_adwords": "Google Ads",
        "component_trafficQuality_table_header_display": "Display",
        "component_trafficQuality_table_header_social": "Social",
        "component_trafficQuality_table_header_referral": "Referral",
        "component_trafficQuality_table_header_direct": "Direct",
        "component_trafficQuality_label_traffic": "Trafic",
        "component_trafficQuality_label_conversion": "Nombre de conversion des objectifs",
        "component_trafficQuality_label_conversion_rate": "Taux de conversion des objectifs",
        "component_trafficQuality_label_transactions_count": "Nombre de ventes",
        "component_trafficQuality_label_ecommerce_conversion_rate": "Taux de conversion ecommerce",
        "component_trafficQuality_label_turnover": "Chiffre d'affaires total",
        "component_trafficQuality_label_turnover_per_user": "Chiffre d'affaires par visite",
    },
    "en": {

        "component_trafficQualityTotalTraffic_title": "Traffic",
        "component_trafficQualityTotalTraffic_subtitle": "Number of visits (source Analytics)",
        "component_trafficQualityTotalTraffic_series_name": "Traffic",
        "component_trafficQualityTotalTraffic_label": "Visits",
        "component_trafficQualityTotalTraffic_informationTooltip_title": "Traffic",
        "component_trafficQualityTotalTraffic_informationTooltip_text":"<p>Total number of unique visits to your site.</p><p><br>With the menu, you can change the granularity of the curve and display the traffic of the last few days, weeks or months. </p><p><br>You also change the time period considered by the KPI, and display the traffic of the current period: yesterday, this week, this month, this year.</p><p><br><i>Source: Analytics - Number of users</i></p>",

        "component_trafficEvolution_title": "Traffic",
        "component_trafficEvolution_subtitle": "Number of visits (source Analytics)",
        "component_trafficEvolution_series_name": "Traffic",
        "component_trafficEvolution_label": "Visits",
        "component_trafficEvolution_informationTooltip_title": "Traffic",
        "component_trafficEvolution_informationTooltip_text":"<p>Total number of unique visits to your site.</p><p><br>With the menu, you can change the granularity of the curve and display the traffic of the last few days, weeks or months. </p><p><br>You also change the time period considered by the KPI, and display the traffic of the current period: yesterday, this week, this month, this year.</p><p><br><i>Source: Analytics - Number of users</i></p>",

        "component_trafficEvolutionConversion_title": "Traffic and conversions",
        "component_trafficEvolutionConversion_subtitle": "Number of visits and number of conversions (Analytics source)",
        "component_trafficEvolutionConversion_series_name_1": "Number of visits",
        "component_trafficEvolutionConversion_series_label_1": "visits",
        "component_trafficEvolutionConversion_series_name_2": "Number of conversions",
        "component_trafficEvolutionConversion_series_label_2": "conversions",
        "component_trafficEvolutionConversion_informationTooltip_title": "Traffic and conversions",
        "component_trafficEvolutionConversion_informationTooltip_text":"<p>Evolution of the total number of unique visits to the site as well as the total conversions achieved</p><p><br><i>Source: Analytics</i></p>",

        "component_trafficAcquisitionChannel_title": "Acquisition Channels",
        "component_trafficAcquisitionChannel_subtitle": "Traffic source (Analytics source)",
        "component_trafficAcquisitionChannel_informationTooltip_title": "Acquisition channels",
        "component_trafficAcquisitionChannel_informationTooltip_text":"<p>Allows you to identify the origin of visits that arrive on the site (Google Ads, Google Natural Results, Mail, Social Networks, other sites...).</p><p><br><i>Source: Analytics</i></p>",
        "component_trafficAcquisitionChannel_label": "visits",
        "component_trafficAcquisitionChannel_seo": "SEO",
        "component_trafficAcquisitionChannel_ads": "Google Ads",
        "component_trafficAcquisitionChannel_social": "Social",
        "component_trafficAcquisitionChannel_referral": "Referral",
        "component_trafficAcquisitionChannel_email": "Email",
        "component_trafficAcquisitionChannel_direct": "Direct",
        "component_trafficAcquisitionChannel_affiliation": "Affiliation",
        "component_trafficAcquisitionChannel_detailButton": "Study traffic sources",

        "component_trafficSource_title": "Traffic sources",
        "component_trafficSource_subtitle": "Evolution of the number of incoming visits, by channel (Analytics source)",
        "component_trafficSource_informationTooltip_title": "Traffic sources",
        "component_trafficSource_informationTooltip_text":"<p>Tracks inbound traffic for each acquisition channel.</p><p><br><i>Source: Analytics</i></p>",
        "component_trafficSource_series_label": "visits",
        "component_trafficSource_analytics_label_seo": "SEO",
        "component_trafficSource_analytics_label_ads": "Google Ads",
        "component_trafficSource_analytics_label_social": "Social",
        "component_trafficSource_analytics_label_referral": "Referral",
        "component_trafficSource_analytics_label_email": "Email",
        "component_trafficSource_analytics_label_direct": "Direct",
        "component_trafficSource_analytics_label_affiliation": "Affiliation",

        "component_trafficQualityTotalTurnover_title": "Turnover",
        "component_trafficQualityTotalTurnover_subtitle": "",
        "component_trafficQualityTotalTurnover_label": "Turnover",
        "component_trafficQualityTotalTurnover_informationTooltip_title": "Total turnover",
        "component_trafficQualityTotalTurnover_informationTooltip_text":"<p>E-commerce turnover achieved by all website visits.</p><p><br>With the menu, you can change the granularity of the curve and display the turnover of the last days, weeks or months. </p><p><br>You also change the time period considered by the KPI, and display the turnover of the current period: yesterday, this week, this month, this year.</p> <p><br> <i>Source: Analytics - E-commerce Revenue</i></p>",

        "component_trafficQualityTotalSale_title": "Sales",
        "component_trafficQualityTotalSale_subtitle": "",
        "component_trafficQualityTotalSale_totalSale_label": "Sales",
        "component_trafficQualityTotalSale_informationTooltip_title": "Sales",
        "component_trafficQualityTotalSale_informationTooltip_text":"<p>Number of sales made by all visits to the website.</p><p><br>With the menu, you can change the granularity of the curve and display the number of sales in the last few days, weeks or months. </p><p><br>You also change the time period considered by the KPI, and display the sales of the current period: yesterday, this week, this month, this year.</p><p><br><i>Source: Analytics - Number of transactions</i></p>",

        "component_trafficQualitySale_title": "Sales",
        "component_trafficQualitySale_subtitle": "",

        "component_trafficQualityTotalConversion_title": "Goal Conversions",
        "component_trafficQualityTotalConversion_subtitle": "",
        "component_trafficQualityTotalConversion_totalConversion_label": "Conversions",
        "component_trafficQualityTotalConversion_informationTooltip_title": "Conversion",
        "component_trafficQualityTotalConversion_informationTooltip_text":"<p>Number of Goal Conversions achieved by all website visits.</p><p><br>With the menu, you can change the granularity of the curve and display the number of sales in the last few days, weeks or months. </p><p><br>You also change the time period considered by the KPI, and display the sales of the current period: yesterday, this week, this month, this year.</p><p><br><i>Source: Analytics - Number of transactions</i></p>",

        "component_trafficQualityTurnoverByVisitor_title": "Revenue per visit",
        "component_trafficQualityTurnoverByVisitor_subtitle": "",
        "component_trafficQualityTurnoverByVisitor_informationTooltip_title": "Turnover per visit",
        "component_trafficQualityTurnoverByVisitor_informationTooltip_text":"<p>Allows you to know the average e-commerce revenue per visit.</p><p><br><i>Source: Analytics</i></p>",

        "component_trafficQualityConversion_title": "Goal conversion rate",
        "component_trafficQualityConversion_subtitle": "",
        "component_trafficQualityConversion_informationTooltip_title": "Goal conversion rate",
        "component_trafficQualityConversion_informationTooltip_text":"<p>Allows you to know the percentage of visits that triggered a conversion of at least one goal</p><p><br><i>Source: Analytics</i></p>",

        "component_trafficQuality_sale_title": "ecommerce conversion rate",
        "component_trafficQualitySale_informationTooltip_title": "Ecommerce conversion rate",
        "component_trafficQualitySale_informationTooltip_text":"<p>Allows you to know the percentage of visitors who triggered one or more sales/transactions.</p><p><br><i>Source: Analytics</i></p>",

        "component_trafficIncomingWebsite_title": "Sites bringing traffic",
        "component_trafficIncomingWebsite_subtitle": "Websites that send traffic to our site (Analytics source)",
        "component_trafficIncomingWebsite_informationTooltip_title": "Websites that send traffic to our site",
        "component_trafficIncomingWebsite_informationTooltip_text":"<p>Allows you to identify sites bringing traffic, conversions and revenue to your site.</p><p><br><i>Source: Analytics</i></p>",
        "component_trafficIncomingWebsite_table_header_website": "Referring site",
        "component_trafficIncomingWebsite_table_header_incomingTraffic": "Sent traffic",
        "component_trafficIncomingWebsite_table_header_conversion": "Conversions",
        "component_trafficIncomingWebsite_table_header_turnover": "Turnover",

        "component_trafficQuality_title": "Acquisition channel performance",
        "component_trafficQuality_subtitle": "Results generated by each traffic source (Analytics source)",
        "component_trafficQuality_informationTooltip_title": "Acquisition channel performance",
        "component_trafficQuality_informationTooltip_text":"<p>Allows to identify the CA, conversion and conversion rate and duration of a visit for each acquisition channel (Google Ads, Mails, etc...).</p><p><br><i>Source: Analytics</i></p>",
        "component_trafficQuality_table_header_natural": "Natural",
        "component_trafficQuality_table_header_adwords": "Google Ads",
        "component_trafficQuality_table_header_display": "Display",
        "component_trafficQuality_table_header_social": "Social",
        "component_trafficQuality_table_header_referral": "Referral",
        "component_trafficQuality_table_header_direct": "Direct",
        "component_trafficQuality_label_traffic": "Traffic",
        "component_trafficQuality_label_conversion": "Goal conversion number",
        "component_trafficQuality_label_conversion_rate": "Goal conversion rate",
        "component_trafficQuality_label_transactions_count": "Number of sales",
        "component_trafficQuality_label_ecommerce_conversion_rate": "ecommerce conversion rate",
        "component_trafficQuality_label_turnover": "Total sales",
        "component_trafficQuality_label_turnover_per_user": "Turnover per visit",
    },
}

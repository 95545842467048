export default {

    globalGoogleVisibility : {
        performance: 25,
        seoPerformance: 62,
        seaPerformance: 20
    },
    actionPriorities: {
        content: [
            {
                type: "position",
                name: "natural",
                potentialTrafficGain: 23746,
                occurrenceProbabilityPercent: 100,
                websiteOccurrenceProbabilityPercent: 91,
                potentialProgressionKeywordCount: 3
            },
            {
                type: "market_segment",
                id: 459,
                name: "cyclisme",
                keywordCount: 2,
                potentialTrafficGain: 26948,
                websiteOccurrenceProbabilityPercent: 100,
                potentialProgressionKeywordCount: 2
            }
        ]
    },
    mostVisibleCompetitors: {
        content: [
            {domainName: 'decathlon.com', competitorType: 'project', rank: 1, cumulatedVisibility: 59854, rankedKeywordCount: 54},
            {domainName: 'alltricks.fr', competitorType: 'watched', rank: 2, cumulatedVisibility: 55248, rankedKeywordCount: 47},
            {domainName: 'trails.com', competitorType: 'watched', rank: 8, cumulatedVisibility: 42198, rankedKeywordCount: 27},
            {domainName: 'plongeur.fr', competitorType: 'watched', rank: 9, cumulatedVisibility: 39168, rankedKeywordCount: 48},
            {domainName: 'chassepeche.fr', competitorType: 'watched', rank: 12, cumulatedVisibility: 28687, rankedKeywordCount: 19}
        ]
    },
    dashboardRecommendationsMarketSegmentVisibility: {
        content: [
            {keywordGroupName: 'vélo', cumulatedVisibility: 5985, rankedKeywordCount: 29, cumulatedSearchCount: 7104},
            {keywordGroupName: 'équitation', cumulatedVisibility: 4147, rankedKeywordCount: 70, cumulatedSearchCount: 11470},
            {keywordGroupName: 'plongée', cumulatedVisibility: 3401, rankedKeywordCount: 54, cumulatedSearchCount: 6010},
            {keywordGroupName: 'chasse', cumulatedVisibility: 3310, rankedKeywordCount: 40, cumulatedSearchCount: 7104},
            {keywordGroupName: 'golf', cumulatedVisibility: 2601, rankedKeywordCount: 14, cumulatedSearchCount: 6018}
        ]
    },
    topBestPages: {
        context:{ inputs:{ trafficDataType: 'Analytics' } },
        content: [
            {
                url: '/toto/tata.html',
                cumulatedTraffic: 10445,
                seoPositionCount: 50,
                adsPositionCount: 65
            },
            {
                url: '/toto/titi.html',
                cumulatedTraffic: 7520,
                seoPositionCount: 33,
                adsPositionCount: 33
            },
            {
                url: '/toto/tuut.html',
                cumulatedTraffic: 3242,
                seoPositionCount: 54,
                adsPositionCount: 32
            },
            {
                url: '/toto/tyty.html',
                cumulatedTraffic: 2473,
                seoPositionCount: 24,
                adsPositionCount: 17
            },
            {
                url: '/toto/tito.html',
                cumulatedTraffic: 2210,
                seoPositionCount: 27,
                adsPositionCount: 9
            }
        ]
    },
    topBestKeywords: {
        content: [
            {
                string: 'vélo route homme',
                seoRank: 1,
                adsRank: 5,
                searchCount: 10456
            },
            {
                string: 'vélo route femme',
                seoRank: 3,
                adsRank: null,
                searchCount: 7520
            },
            {
                string: 'VTT homme',
                seoRank: 2,
                adsRank: 3,
                searchCount: 3245
            },
            {
                string: 'VTT femme',
                seoRank: 4,
                adsRank: null,
                searchCount: 2473
            },
            {
                string: 'VTT enfant',
                seoRank: 1,
                adsRank: 1,
                searchCount: 2333
            },
        ]
    }
}

/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import apiCalls from '@/api/axios/customersManager/customer.js'
// import customerSubscriberSSE from '@/sse/customersManager/CustomerSubscriberSSE.js'

export default {

    /**
     * @param context Object
     * @param email String
     * @returns void
     */
    async setCustomer (context, email) {
        const { commit, state } = context;
        const readCustomerByEmailPromise = apiCalls.readCustomerByEmail(email);
        commit('setCustomer', await readCustomerByEmailPromise);

        if (state.customer.customerSubscriptions.length > 0) {
            commit('setCustomerSubscriptionActive', state.customer.customerSubscriptions[0]);
        }

        // we subscribe to customer update
        // customerSubscriberSSE.subscribe({email: email})
    },

    /**
     * @param context Object
     * @returns void
     */
    async unsetCustomer (context) {

        // we unsubscribe to customer update
        // customerSubscriberSSE.unsubscribe({email: email})

        const { commit } = context;
        commit('setCustomer', null);
    },

    /**
     * @param context Object
     * @param toActivateCustomerSubscription CustomerSubscription
     * @returns void
     */
    async setCustomerSubscriptionActive (context, toActivateCustomerSubscription) {
        const { commit } = context;
        commit('setCustomerSubscriptionActive', toActivateCustomerSubscription);
    },


};

import dataConfigurationProject from '@/api/mock/configuration/project.js'
import dataCustomersManagerCustomer from '@/api/mock/customersManager/customer.js'
import dataDataReportDashboard from '@/api/mock/dataReport/dashboard.js'
import dataDataReportDashboardRecommendations from '@/api/mock/dataReport/dashboardRecommendations.js'
import dataDataReportAdvertising from '@/api/mock/dataReport/advertising.js'
import dataDataReportCompetition from '@/api/mock/dataReport/competition.js'
import dataDataReportProgressionAxis from '@/api/mock/dataReport/progressionAxis.js'
import dataDataReportLocalVisibility from '@/api/mock/dataReport/localVisibility.js'
import dataDataReportMarketSegment from '@/api/mock/dataReport/marketSegment.js'
import dataDataReportSeoVisibility from '@/api/mock/dataReport/seoVisibility.js'
import dataDataReportTraffic from '@/api/mock/dataReport/traffic.js'
import dataDataReportWebsitePages from '@/api/mock/dataReport/websitePages.js'
import dataDataReportLinkingPages from '@/api/mock/dataReport/linking.js'
import dataDataOnlineDashboard from '@/api/mock/dataReport/onlineDashboard.js'
import dataReporting from '@/api/mock/dataReport/reporting.js'
import template from '@/api/mock/dataReport/template'
import report from '@/api/mock/dataReport/report.js'

export default {
    ...dataConfigurationProject,
    ...dataCustomersManagerCustomer,
    ...dataDataReportDashboard,
    ...dataDataReportDashboardRecommendations,
    ...dataDataReportAdvertising,
    ...dataDataReportCompetition,
    ...dataDataReportProgressionAxis,
    ...dataDataReportLocalVisibility,
    ...dataDataReportMarketSegment,
    ...dataDataReportSeoVisibility,
    ...dataDataReportTraffic,
    ...dataDataReportWebsitePages,
    ...dataDataReportLinkingPages,
    ...dataDataOnlineDashboard,
    ...dataReporting,
    ...template,
    ...report
}

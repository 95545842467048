

export default {
    "fr": {
        "component_websitePagesBestPages_title": "Meilleures pages",
        "component_websitePagesBestPages_subtitle": "Performance de visibilité",
        "component_websitePagesBestPages_informationTooltip_title": "Meilleures pages",
        "component_websitePagesBestPages_informationTooltip_text": "<p>Pages du site les plus visibles sur Google (tous types de résultats confondus : SEO, Google Ads, Shopping, Google Business Profile, etc...).</p>",
        "component_websitePagesBestPages_detailButton": "Identifiez vos meilleures pages",

        "component_websitePagesPositionedCountEvolution_title": "Nb de pages positionnées",
        "component_websitePagesPositionedCountEvolution_subtitle": "Nombre de pages différentes du site qui obtiennent au moins une position sur Google, tous type de résultats confondus",
        "component_websitePagesPositionedCountEvolution_informationTooltip_title": "Nb de pages positionnées",
        "component_websitePagesPositionedCountEvolution_informationTooltip_text": "<p>Evolution du nombre de pages positionnées de votre site sur Google et leur répartition par type de résultats (SEO, Google Ads, Google Business Profile, ...).</p>",
        "component_websitePagesPositionedCountEvolution_label": "pages positionnées",
        "component_websitePagesPositionedCountEvolution_series_name_1": "pages positionnées",

        "component_websitePagesBestPositionedPages_title": "Meilleures pages de mon site",
        "component_websitePagesBestPositionedPages_subtitle": "Pages du site qui contribuent le plus à l'acquisition du trafic et au chiffre d'affaires",
        "component_websitePagesBestPositionedPages_informationTooltip_title": "Meilleures pages de mon site",
        "component_websitePagesBestPositionedPages_informationTooltip_text": "<p>Permet d'identifier les pages qui génèrent le plus trafic entrant sur votre site, ainsi que les conversions et le chiffre d'affaires qui en résultent.</p><p><br><i>Source : Analytics</i></p>",
        "component_websitePagesBestPositionedPages_table_header_page": "Page",
        "component_websitePagesBestPositionedPages_table_header_positionCount": "Nb de Positions",
        "component_websitePagesBestPositionedPages_table_header_incomingTraffic": "Trafic entrant",
        "component_websitePagesBestPositionedPages_table_header_conversion": "Conversions",
        "component_websitePagesBestPositionedPages_table_header_turnover": "Chiffre d'affaires",

        "component_websitePagesBestPositionedPagePositions_title": "Positions de la page :",
        "component_websitePagesBestPositionedPagePositions_subtitle": "Liste les positions obtenues par cette page dans les différents types de résultats Google.",
        "component_websitePagesBestPositionedPagePositions_table_header_keyword": "Mot-clé",
        "component_websitePagesBestPositionedPagePositions_table_header_searchCount": "Nb recherches",
        "component_websitePagesBestPositionedPagePositions_table_header_position": "Position",
        "component_websitePagesBestPositionedPagePositions_table_header_evolution": "Variation",
        "component_websitePagesBestPositionedPagePositions_table_header_positionType": "Type de position",
    },
    "en": {

        "component_websitePagesBestPages_title": "Best pages",
        "component_websitePagesBestPages_subtitle": "Visibility Performance",
        "component_websitePagesBestPages_informationTooltip_title": "Best pages",
        "component_websitePagesBestPages_informationTooltip_text":"<p>The most visible pages of the site on Google (all types of results combined: SEO, Google Ads, Shopping, Google Business Profile, etc...).</p>",
        "component_websitePagesBestPages_detailButton": "Identify your best pages",

        "component_websitePagesPositionedCountEvolution_title": "Nb of positioned pages",
        "component_websitePagesPositionedCountEvolution_subtitle": "Number of different pages of the site that obtain at least one position on Google, all types of results combined",
        "component_websitePagesPositionedCountEvolution_informationTooltip_title": "Nb of positioned pages",
        "component_websitePagesPositionedCountEvolution_informationTooltip_text":"<p>Evolution of the number of positioned pages of your site on Google and their distribution by type of results (SEO, Google Ads, Google Business Profile, ...).</p>",
        "component_websitePagesPositionedCountEvolution_label": "positioned pages",
        "component_websitePagesPositionedCountEvolution_series_name_1": "positioned pages",

        "component_websitePagesBestPositionedPages_title": "Best pages of my site",
        "component_websitePagesBestPositionedPages_subtitle": "Pages of the site that contribute the most to traffic acquisition and sales",
        "component_websitePagesBestPositionedPages_informationTooltip_title": "Best pages of my site",
        "component_websitePagesBestPositionedPages_informationTooltip_text":"<p>Allows you to identify the pages that generate the most inbound traffic to your site, as well as the resulting conversions and revenue.</p><p><br><i>Source: Analytics</i></p>",
        "component_websitePagesBestPositionedPages_table_header_page": "Page",
        "component_websitePagesBestPositionedPages_table_header_positionCount": "Nb of Positions",
        "component_websitePagesBestPositionedPages_table_header_incomingTraffic": "Incoming Traffic",
        "component_websitePagesBestPositionedPages_table_header_conversion": "Conversions",
        "component_websitePagesBestPositionedPages_table_header_turnover": "Turnover",

        "component_websitePagesBestPositionedPagePositions_title": "Page Positions:",
        "component_websitePagesBestPositionedPagePositions_subtitle": "Lists the positions obtained by this page in the different types of Google results.",
        "component_websitePagesBestPositionedPagePositions_table_header_keyword": "Keyword",
        "component_websitePagesBestPositionedPagePositions_table_header_searchCount": "Nb searches",
        "component_websitePagesBestPositionedPagePositions_table_header_position": "Position",
        "component_websitePagesBestPositionedPagePositions_table_header_evolution": "Variation",
        "component_websitePagesBestPositionedPagePositions_table_header_positionType": "Position type",
    },
}

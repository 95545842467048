
export default {
    "fr": {
        "component_projectList_quota_project_title": "Quota atteint",
        "component_projectList_quota_project_text": "Nombre de projet maximum atteint. Vous ne pouvez plus créer de nouveaux projets",
        "component_projectList_action_open": "Consulter",
        "component_projectList_confirmDeleteTitle": "Confirmer la suppression",
        "component_projectList_confirmDeleteText": "Attention!<br/>Vous allez supprimer le projet sélectionné cette action est irréversible et vous allez perdre toutes les informations liées à ce projet.<br/>Etes-vous sur de vouloir continuer ?",
        "component_projectList_searchPlaceholder": "Rechercher un domaine",
        "component_projectList_titleEditProjectName": "Modifier le nom du projet",
        "component_projectList_validEditProjectName": "Enregistrer",
        "component_projectList_actionMenu_rename": "Renommer",
        "component_projectList_actionMenu_delete": "Supprimer",
        "component_projectList_actionMenu_clone": "Cloner",
        "component_projectList_newProject": 'Ajouter un domaine',
        "component_projectList_tableHeader_projectName": 'Domaines',

        "component_projectCreationWizard_nextStep": "Continuer",
        "component_projectCreationWizard_previousStep": "Retour",
        "component_projectCreationWizard_validateStep": "Créer mon projet",

        "component_projectCreationWizard_step1_Name": "Mon site web et mes concurrents",
        "component_projectCreationWizard_step1_WebSiteTitle": "Mon site web",
        "component_projectCreationWizard_step1_WebSiteText": "Indiquez le domaine de votre site web.",
        "component_projectCreationWizard_step1_WebSiteLabel": "URL de mon site web",
        "component_projectCreationWizard_step1_WebSiteBadUrlFormat": "Veuillez saisir un nom de domaine valide",
        "component_projectCreationWizard_step1_CheckboxSubdomainLabel": "Inclure l'analyse des sous domaines",
        "component_projectCreationWizard_step1_CompetitorSearchText": "Nous recherchons des concurrents à surveiller...",
        "component_projectCreationWizard_step1_CompetitorTitle": "Mes concurrents",
        "component_projectCreationWizard_step1_CompetitorText": "Indiquez les concurrents que vous souhaitez surveiller afin d'affiner vos analyses.<br/>Vous pouvez lister jusqu'à 10 concurrents suivis.",
        "component_projectCreationWizard_step1_CompetitorCantBeWebsite": "Le site web du projet ne peux pas être également un conncurent",
        "component_projectCreationWizard_step1_CompetitorAlreadyExist": "Concurrent déjà ajouté",
        "component_projectCreationWizard_step1_CompetitorMaxError": "Vous ne pouvez ajouter que 10 concurrents",
        "component_projectCreationWizard_step1_CompetitorSuggestedLabel": "Nous avons présélectionné quelques concurrents. Mais vous pouvez modifier librement cette liste !",
        "component_projectCreationWizard_step1_CompetitorSuggestedLabelError": "Pas de concurrent connu. Vous pouvez ajouter manuellement vos concurrents.",
        "component_projectCreationWizard_step1_CompetitorRemaining": "<strong>[*COMPETITOR-COUNT*]</strong> concurrents suivis sur <strong>10</strong> possibles.",
        "component_projectCreationWizard_step1_CompetitorClearList": "vider la liste",

        "component_projectCreationWizard_step2_Name": "Zone de chalandise",
        "component_projectCreationWizard_step2_LocationTitle": "Ma zone de chalandise",
        "component_projectCreationWizard_step2_LocationLabel": "Dans quel(s) lieu(x) devons-nous mesurer la visibilité de votre site sur Google ?",
        "component_projectCreationWizard_step2_LocationTips": "Votre visibilité est-elle nationale, locale ou les deux ?",
        "component_projectCreationWizard_step2_Location_tooltipTitle": "Choix de la localisation",
        "component_projectCreationWizard_step2_Location_tooltipText": "<p>Vos clients peuvent-être dans tous le pays (cas d'un site e-commerce) ou plus proches de vous.</p></br><p>Nous pouvons mesurer votre visibilité de manière globale sur tout le pays, ou localement, ou les deux.</p>",
        "component_projectCreationWizard_step2_ClearKeywordsButton": "Vider la liste",
        "component_projectCreationWizard_step2_KeywordUsageSummary": "Ce projet utilise <strong>[*COUNT*]</strong> mots-clés sur <strong>[*REMAINING*]</strong> disponibles.",
        "component_projectCreationWizard_step2_ProjectUsageSummary": "Ces <strong>[*TOTAL-COUNT*]</strong> mots-clés utilisés correspondant à :<br/><strong>[*LOCATION-COUNT*]</strong> localités x <strong>[*KEYWORD-COUNT*]</strong> mots-clés.<br/>-<br/>Quota total de votre abonnement : <strong>[*SUBSCRIPTION-KEYWORD-COUNT*]</strong><br/>Utilisé par les autres projets : <strong>[*PROJECT-KEYWORD-USED*]</strong><br/>Votre quota restant : <strong>[*PROJECT-KEYWORD-REMAINING*]</strong>",
        "component_projectCreationWizard_step2_Keywords_suggestError": "Suggestion de mots-clés impossible",
        "component_projectCreationWizard_step2_Keywords_noSuggestedKeyword": "Veuillez saisir vos mots-clés",

        "component_projectCreationWizard_step3_title": "Mes mots-clés",
        "component_projectCreationWizard_step3_subtitle": "Renseignez les mots-clés sur lesquels votre site doit être positionné.",
        "component_projectCreationWizard_step3_titleOverQuota": "Dépassement de quotas :-(",
        "component_projectCreationWizard_step3_subtitleOverQuota": "ET/OU",
        "component_projectCreationWizard_step3_keywordsSuggestedLabel": "Nous avons présélectionné quelques mots-clés. Mais vous pouvez modifier librement cette liste !",
        "component_projectCreationWizard_step3_keywordsRemaining": "Ce projet utilise <strong>[*KEYWORD-COUNT*]</strong> mots-clés sur <strong>[*KEYWORD-MAX*]</strong> disponibles.",
        "component_projectCreationWizard_step3_keywordsClearList": "vider la liste",
        "component_projectCreationWizard_step3_overQuotaSubtitle": "Votre projet dépasse votre quota restant sur votre abonnement.",
        "component_projectCreationWizard_step3_overQuotaLocationsListTitle": "Réduisez le nombre de localités",
        "component_projectCreationWizard_step3_overQuotaKeywordsListTitle": "Réduisez le nombre de mots-clés",
        "component_projectCreationWizard_step3_noKeywordsLabelError": "Veuillez saisir au moins un mot-clé pour créer votre projet.",
        "component_projectCreationWizard_step3_fullQuotaKeywordsLabel": "Vous avez atteint le quota du nombre de mots-clés pour votre projet.",
        "component_projectCreationWizard_step3_tooltipUsageTitle": "Décompte des mots-clés",
        "component_projectCreationWizard_step3_tooltipUsageText": "<div class='text-center'><p>Ces <strong>[*KEYWORD-TOTAL-COUNT*]</strong> mots-clés utilisés correspondent à :</p><p><strong>[*LOCATION-COUNT*]</strong> localités x <strong>[*KEYWORD-COUNT*]</strong> mots-clés</p></br><p>Vous pouvez adapter votre liste de mots-clés sur cet écran (étape 3), ou modifier votre sélection de localité à l'étape précédente.</p></br><p>Quota total de votre abonnement : <strong>[*QUOTA-TOTAL*]</strong></p><p>Utilisé par les autres projets : <strong>[*PROJECT-USAGE-COUNT*]</strong></p><p>Votre quota restant : <strong>[*REMAINING-COUNT*]</strong></p></div>",
        "component_projectCreationWizard_step3_name": "Mes mots-clés",

        "component_projectCreationWizard_step4_name": "Mon Analytics",

        "component_projectCreationWizard_step5_title": "Création du projet",
        "component_projectCreationWizard_step5_subtitle": "Nous créons votre projet. <br>Veuillez patienter...",

        "component_analyticsProjectManagement_title": "Données de trafic",
        "component_analyticsProjectManagement_title_create": "Données de trafic",
        "component_analyticsProjectManagement_subtitle": "Profitez d'une interface optimisée pour Analytics ou Matomo et redonnez du sens à vos données de trafic, de conversions et de chiffre d'affaires.",
        "component_analyticsProjectManagement_informationTooltip_title": "Vos comptes externes",
        "component_analyticsProjectManagement_informationTooltip_text": "<p>Connectez et paramétrez vos comptes externes pour enrichir et exporter vos données.</p>",
         "component_analyticsProjectManagement_AccountSyncText": "Vos statistiques sont synchronisées !",
        "component_analyticsProjectManagement_DeleteAccountConfiguration": "Modifier cette association",


        "component_keywordConfiguration_title": "Gestion des mots-clés",
        "component_keywordConfiguration_subtitle": "Ajouter, supprimer et segmenter mes mots-clés",
        "component_keywordConfiguration_informationTooltip_title": "Gestion des mots-clés",
        "component_keywordConfiguration_informationTooltip_text": "<p>Ajoutez ou supprimez des mots-clés pour votre projet. Il est possible de copier-coller une liste de mots-clés, ou de les importer via un fichier .csv.</p><p><br>Le fichier CSV permet d'importer les mots-clés déjà segmentés grâce au format :</p><br/><i><ul><li>mot-clé 1;Segment A</li><li>mot-clé 2;Segment B</li><li>mot-clé 3;Segment A</li></ul></i></p>",
        "component_keywordConfiguration_informationTooltip_marketSegment_text" : "Associez ou retirez des mots-clés d'un segment de marché (groupe de mots-clés). Un mot-clé peut appartenir à un ou plusieurs segments.<br><br><strong>Astuce</strong> : Vous pouvez visualiser via les filtres les mots-clés sans segment (non attribués).",
        "component_keywordConfiguration_addPlaceholder": "Saisir un ou plusieurs mots-clés séparés par des ;",
        "component_keywordConfiguration_filterKeywordLabel": "Filtrer par mot-clé",
        "component_keywordConfiguration_filterSegmentLabel": "Filtrer par segment de marché",
        "component_keywordConfiguration_filterSegmentAll": "Tous",
        "component_keywordConfiguration_filterSegmentNone": "Non attribués",
        "component_keywordConfiguration_table_header_keyword": "Mot-clé",
        "component_keywordConfiguration_table_header_segment": "Segments de marché",
        "component_keywordConfiguration_addSegmentToKeywordsLabel": "Ajouter à un segment",
        "component_keywordConfiguration_deleteKeywords": "Supprimer",
        "component_keywordConfiguration_confirmDeleteTitle": "Confirmer la suppression",
        "component_keywordConfiguration_confirmDeleteText": "Attention!<br/>Vous allez supprimer le(s) mot(s)-clé(s) sélectionné(s) cette action est irréversible et vous allez perdre toutes les informations liées à ce(s) mot(s)-clé(s).<br/>Etes-vous sur de vouloir continuer ?",
        "component_keywordConfiguration_alertNoKeywordSelectedTitle": "Sélectionnez un mot-clé",
        "component_keywordConfiguration_alertNoKeywordSelectedText": "Pour affecter des mots-clés à un segment veuillez sélectionner au moins un mot-clé",
        "component_keywordConfiguration_alertNinKeywordDeleteTitle": "Suppression impossible",
        "component_keywordConfiguration_alertNinKeywordDeleteText": "Votre projet doit contenir au moins un mot-clé.",

        "component_importCsvKeyword_importButton": "importez des mots-clés & segments",
        "component_importCsvKeyword_popinTitle": "Importez des mots-clés",
        "component_importCsvKeyword_popinSubtitle": "Vous pouvez importer un fichier Excel ou CSV, ou directement copier-coller votre liste.",
        "component_importCsvKeyword_popinTabCopyPaste": "Copier-coller",
        "component_importCsvKeyword_popinTabCopyPasteTitle": "Un mot-clé par ligne. Vous pouvez affecter le mot-clé à un segment en les séparant par un ;",
        "component_importCsvKeyword_popinTabCopyPastePlaceholder": "mot-clé 1;segment A\nmot-clé 2;\nmot-clé 3;segment B\n...",
        "component_importCsvKeyword_popinTabFile": "Fichier",
        "component_importCsvKeyword_informationTooltip_title": "Import de fichier",
        "component_importCsvKeyword_informationTooltip_text": "Vous pouvez importer un fichier de mots-clés au format .csv ou .txt. Le fichier doit lister un mot-clé par ligne. Vous pouvez également préciser un segment de marché pour chaque mot-clé en le rajoutant sur la même ligne, séparé par une virgule, un point virgule ou une tabulation.",
        "component_importCsvKeyword_popinTabFileTitlePreview": "Aperçu de ce qui sera importé",
        "component_importCsvKeyword_popinTabFileButtonChangeFile": "Changer de fichier",
        "component_importCsvKeyword_popinTabFileTitleImport": "Sélectionnez un fichier à importer",
        "component_importCsvKeyword_popinTabFileButtonImportFile": "Parcourir mon ordinateur",
        "component_importCsvKeyword_popinSave": "Importer ces mots-clés",

        "component_marketSegmentConfiguration_title": "Modifier mes segments de marché",
        "component_marketSegmentConfiguration_subtitle": "Ajouter ou supprimer des segments de marché",
        "component_marketSegmentConfiguration_informationTooltip_title": "Modifier mes segments de marché",
        "component_marketSegmentConfiguration_informationTooltip_text": "<p>Créez ou supprimez des segments de marché (groupe de mots-clés).</p><p><br>Une fois vos segments créés, vous pourrez y ajouter des mots-clés via la carte <strong>Gestion des mots-clés</strong>.</p>",
        "component_marketSegmentConfiguration_addLabel": "Ajouter des nouveaux segments",
        "component_marketSegmentConfiguration_addPlaceholder": "Saisir un ou plusieurs segments séparé par des ;",
        "component_marketSegmentConfiguration_selectAll": "Tout sélectionner",
        "component_marketSegmentConfiguration_removeAllKeywordFromSegment": "Retirer tous les mots-clés de se(s) segment(s)",
        "component_marketSegmentConfiguration_removeSegment": "Supprimer",
        "component_marketSegmentConfiguration_confirmDeleteTitle": "Confirmer la suppression",
        "component_marketSegmentConfiguration_confirmDeleteText": "Attention!<br/>Vous allez supprimer le(s) segment(s) de marché sélectionné(s) cette action est irréversible et vous allez perdre toutes les informations liées à ce(s) segment(s) de marché.<br/>Etes-vous sur de vouloir continuer ?",
        "component_marketSegmentConfiguration_confirmClearTitle": "Confirmer la suppression",
        "component_marketSegmentConfiguration_confirmClearText": "Attention!<br/>Vous allez retirer touts les mots-clés associés à se(s) segment(s) de marché .<br/>Etes-vous sur de vouloir continuer ?",

        "component_competitorConfiguration_title": "Gestion des concurrents",
        "component_competitorConfiguration_subtitle": "Ajouter ou supprimer des concurrents",
        "component_competitorConfiguration_informationTooltip_title": "Mes concurrents",
        "component_competitorConfiguration_informationTooltip_text": "<p>Ajoutez ou supprimez des concurrents suivis pour ce projet.</p>",
        "component_competitorConfiguration_addLabel": "Ajouter un nouveau concurrent",
        "component_competitorConfiguration_addPlaceholder": "Saisir l'url du concurrent à ajouter",
        "component_competitorConfiguration_confirmDeleteTitle": "Confirmer la suppression",
        "component_competitorConfiguration_confirmDeleteText": "Attention!<br/>Vous allez supprimer un concurrent cette action est irréversible et vous allez perdre toutes les informations liées à ce concurrent.<br/>Etes-vous sur de vouloir continuer ?",

        "component_locationConfiguration_title": "Gestion des localisations",
        "component_locationConfiguration_subtitle": "Ajouter ou supprimer des localisations",
        "component_locationConfiguration_informationTooltip_title": "Gestion des localisations",
        "component_locationConfiguration_informationTooltip_text": "<p>Déterminez une ou plusieurs géolocalisation pour les requêtes Google Search de ce projet. Vous pouvez ajouter une localisation Région, Département, ou Ville.</p><p><br><strong>Attention</strong> :  Ajouter une localisation consomme des mots-clés en plus.</p><p>Exemple : 50 mots-clés x 3 localisations = 150 mots-clés utilisés sur votre plan.</p><br><p><strong>Vous ne pouvez pas modifier le pays d'un projet.</strong>Vous pouvez cependant <strong>cloner un projet</strong> depuis la liste des projets.</p>",
        "component_locationConfiguration_addLabel": "Localisation",
        "component_locationConfiguration_addPlaceholder": "Selectionner une localisation a ajouter",
        "component_locationConfiguration_confirmDeleteTitle": "Confirmer la suppression",
        "component_locationConfiguration_confirmDeleteText": "Attention!\r\nVous allez supprimer une localisation cette action est irréversible et vous allez perdre toutes les informations liées à cette localisation.\r\nEtes-vous sur de vouloir continuer ?",

        "view_linking_unauthorized_title": "Que faites-vous ici ? 🤔",
        "view_linking_unauthorized_text": "Encore un peu de patience, le linking arrive prochainement dans YOODA One. Sachez que nous sommes aussi impatients que vous.",
        "view_linking_unauthorized_ps": "PS : C’était quand même bien tenté, vous êtes malin(e)... Mais nous aussi !",
    },
    "en": {
        "component_projectList_quota_project_title": "Quota reached",
        "component_projectList_quota_project_text": "Maximum number of projects reached. You cannot create any more projects",
        "component_projectList_action_open": "Consult",
        "component_projectList_confirmDeleteTitle": "Confirm deletion",
        "component_projectList_confirmDeleteText": "Warning!<br/>You are about to delete the selected project this action is irreversible and you will lose all information related to this project.<br/>Are you sure you want to continue?",
        "component_projectList_searchPlaceholder": "Search a domain",
        "component_projectList_titleEditProjectName": "Edit project name",
        "component_projectList_validEditProjectName": "Save",
        "component_projectList_actionMenu_rename": "Rename",
        "component_projectList_actionMenu_delete": "Delete",
        "component_projectList_actionMenu_clone": "Clone",
        "component_projectList_newProject": 'Add a domain',
        "component_projectList_tableHeader_projectName": 'Domains',

        "component_projectCreationWizard_nextStep": 'Continue',
        "component_projectCreationWizard_previousStep": 'Back',
        "component_projectCreationWizard_validateStep": "Create my project",

        "component_projectCreationWizard_step1_Name": "My website and my competitors",
        "component_projectCreationWizard_step1_WebSiteTitle": "My website",
        "component_projectCreationWizard_step1_WebSiteText": "Specify the domain of your website.",
        "component_projectCreationWizard_step1_WebSiteLabel": "URL of my website",
        "component_projectCreationWizard_step1_WebSiteBadUrlFormat": "Please enter a valid domain name",
        "component_projectCreationWizard_step1_CheckboxSubdomainLabel": "Include subdomain analysis",
        "component_projectCreationWizard_step1_CompetitorSearchText": "We are looking for competitors to monitor...",
        "component_projectCreationWizard_step1_CompetitorTitle": "My competitors",
        "component_projectCreationWizard_step1_CompetitorText": "Indicate the competitors you want to monitor in order to refine your analyses.<br/>You can list up to 10 monitored competitors.",
        "component_projectCreationWizard_step1_CompetitorCantBeWebsite": "The project website cannot also be a competitor",
        "component_projectCreationWizard_step1_CompetitorAlreadyExist": "Concurrent already added",
        "component_projectCreationWizard_step1_CompetitorMaxError": "You can only add 10 competitors",
        "component_projectCreationWizard_step1_CompetitorSuggestedLabel": "We have pre-selected some competitors. But you can freely modify this list!",
        "component_projectCreationWizard_step1_CompetitorSuggestedLabelError": "No known competitors. You can manually add your competitors.",
        "component_projectCreationWizard_step1_CompetitorRemaining":"<strong>[*COMPETITOR-COUNT*]</strong> competitors tracked out of <strong>10</strong> possible.",
        "component_projectCreationWizard_step1_CompetitorClearList": "clear list",

        "component_projectCreationWizard_step2_Name": "catchment area",
        "component_projectCreationWizard_step2_LocationTitle": "My catchment area",
        "component_projectCreationWizard_step2_LocationLabel": "In which location(s) should we measure the visibility of your site on Google?",
        "component_projectCreationWizard_step2_LocationTips": "Is your visibility national, local or both?",
        "component_projectCreationWizard_step2_Location_tooltipTitle": "Location choice",
        "component_projectCreationWizard_step2_Location_tooltipText":"<p>Your customers may be all over the country (case of an e-commerce site) or closer to you.</p></br><p>We can measure your visibility globally over the whole country, or locally, or both.</p>",
        "component_projectCreationWizard_step2_ClearKeywordsButton": "Clear the list",
        "component_projectCreationWizard_step2_KeywordUsageSummary": "This project uses <strong>[*COUNT*]</strong> keywords on <strong>[*REMAINING*]</strong> available.",
        "component_projectCreationWizard_step2_ProjectUsageSummary": "These <strong>[*TOTAL-COUNT*]</strong> used keywords corresponding to:<br/><strong>[*LOCATION-COUNT*]</strong> localities x <strong>[*KEYWORD-COUNT*]</strong> keywords. <br/>-<br/>Your total subscription quota: <strong>[*SUBSCRIPTION-KEYWORD-COUNT*]</strong><br/>Used by other projects: <strong>[*PROJECT-KEYWORD-USED*]</strong><br/>Your remaining quota: <strong>[*PROJECT-KEYWORD-REMAINING*]</strong>.",
        "component_projectCreationWizard_step2_Keywords_suggestError": "Keyword suggestion not possible",
        "component_projectCreationWizard_step2_Keywords_noSuggestedKeyword": "Please enter your keywords",

        "component_projectCreationWizard_step3_title": "My keywords",
        "component_projectCreationWizard_step3_subtitle": "Please enter the keywords on which your site should be positioned",
        "component_projectCreationWizard_step3_titleOverQuota": "Exceeding quotas :-(",
        "component_projectCreationWizard_step3_subtitleOverQuota": "AND/OR",
        "component_projectCreationWizard_step3_keywordsSuggestedLabel": "We have pre-selected some keywords. But you can freely modify this list!",
        "component_projectCreationWizard_step3_keywordsRemaining": "This project uses <strong>[*KEYWORD-COUNT*]</strong> keywords on <strong>[*KEYWORD-MAX*]</strong> available.",
        "component_projectCreationWizard_step3_keywordsClearList": "clear the list",
        "component_projectCreationWizard_step3_overQuotaSubtitle": "Your project exceeds your remaining quota on your subscription.",
        "component_projectCreationWizard_step3_overQuotaLocationsListTitle": "Reduce the number of locations",
        "component_projectCreationWizard_step3_overQuotaKeywordsListTitle": "Reduce the number of keywords",
        "component_projectCreationWizard_step3_noKeywordsLabelError": "Please enter at least one keyword to create your project.",
        "component_projectCreationWizard_step3_fullQuotaKeywordsLabel": "You have reached the quota of the number of keywords for your project.",
        "component_projectCreationWizard_step3_tooltipUsageTitle": "Keyword count",
        "component_projectCreationWizard_step3_tooltipUsageText":"<div class='text-center'><p>These <strong>[*KEYWORD-TOTAL-COUNT*]</strong> keywords used correspond to : </p><p><strong>[*LOCATION-COUNT*]</strong> localities x <strong>[*KEYWORD-COUNT*]</strong> keywords</p></br><p>You can adjust your keyword list on this screen (step 3), or change your locality selection in the previous step. </p></br><p>Your total subscription quota: <strong>[*QUOTA-TOTAL*]</strong></p><p>Used by other projects: <strong>[*PROJECT-USAGE-COUNT*]</strong></p><p>Your remaining quota: <strong>[*REMAINING-COUNT*]</strong></p></div>",
        "component_projectCreationWizard_step3_name": "My keywords",

        "component_projectCreationWizard_step4_name": "My Analytics",

        "component_projectCreationWizard_step5_title": "Project Creation",
        "component_projectCreationWizard_step5_subtitle": "We are creating your project. <br>Please wait...",

        "component_analyticsProjectManagement_title": "Traffic Data",
        "component_analyticsProjectManagement_title_create": "Traffic Data",
        "component_analyticsProjectManagement_subtitle": "Take advantage of an interface optimized for Analytics or Matomo and give meaning to your traffic, conversions and turnover data.",
        "component_analyticsProjectManagement_informationTooltip_title": "Your external accounts",
        "component_analyticsProjectManagement_informationTooltip_text":"<p>Connect and set up your external accounts to enrich and export your data.</p>",
        "component_analyticsProjectManagement_AccountSyncText": "Your statistics are synchronized!",
        "component_analyticsProjectManagement_DeleteAccountConfiguration": "Change this association",



        "component_keywordConfiguration_title": "Keyword management",
        "component_keywordConfiguration_subtitle": "Add, remove and segment my keywords",
        "component_keywordConfiguration_informationTooltip_title": "Keyword management",
        "component_keywordConfiguration_informationTooltip_text":"<p>Add or remove keywords for your project. It is possible to copy and paste a list of keywords, or import them via a .csv file.</p><p><br>The CSV file allows you to import keywords already segmented using the format:</p><br/><i><ul><li>Keyword 1;Segment A</li><li>Keyword 2;Segment B</li><li>Keyword 3;Segment A</li></ul></i></p>",
        "component_keywordConfiguration_informationTooltip_marketSegment_text" : "Associate or remove keywords from a market segment (group of keywords). A keyword can belong to one or more segments.<br><br> <strong>Tip</strong>: You can view keywords without a segment (unassigned) via the filters.",
        "component_keywordConfiguration_addPlaceholder": "Enter one or more keywords separated by ;",
        "component_keywordConfiguration_filterKeywordLabel": "Filter by keyword",
        "component_keywordConfiguration_filterSegmentLabel": "Filter by market segment",
        "component_keywordConfiguration_filterSegmentAll": "All",
        "component_keywordConfiguration_filterSegmentNone": "Not assigned",
        "component_keywordConfiguration_table_header_keyword": "Keyword",
        "component_keywordConfiguration_table_header_segment": "Market Segments",
        "component_keywordConfiguration_addSegmentToKeywordsLabel": "Add to a segment",
        "component_keywordConfiguration_deleteKeywords": "Delete",
        "component_keywordConfiguration_confirmDeleteTitle": "Confirm deletion",
        "component_keywordConfiguration_confirmDeleteText": "Warning!<br/>You are going to delete the selected keyword(s) this action is irreversible and you are going to lose all the information related to this/these keyword(s).<br/>Are you sure you want to continue?",
        "component_keywordConfiguration_alertNoKeywordSelectedTitle": "Select a keyword",
        "component_keywordConfiguration_alertNoKeywordSelectedText": "To assign keywords to a segment please select at least one keyword",
        "component_keywordConfiguration_alertNinKeywordDeleteTitle": "Deletion impossible",
        "component_keywordConfiguration_alertNinKeywordDeleteText": "Your project must contain at least one keyword.",

        "component_importCsvKeyword_importButton": "import keywords & segments",
        "component_importCsvKeyword_popinTitle": "Import keywords",
        "component_importCsvKeyword_popinSubtitle": "You can import an Excel or CSV file, or directly copy and paste your list.",
        "component_importCsvKeyword_popinTabCopyPaste": "Copy and paste",
        "component_importCsvKeyword_popinTabCopyPasteTitle": "One keyword per line. You can assign the keyword to a segment by separating them with a ;",
        "component_importCsvKeyword_popinTabCopyPastePlaceholder": "keyword 1;segment A;\nkeyword 2;\nkeyword 3;segment B\n...",
        "component_importCsvKeyword_popinTabFile": "File",
        "component_importCsvKeyword_informationTooltip_title": "Import file",
        "component_importCsvKeyword_informationTooltip_text": "You can import a keyword file in .csv or .txt format. The file must list one keyword per row. You can also specify a market segment for each keyword by adding it on the same line, separated by a comma, semicolon or tab.",
        "component_importCsvKeyword_popinTabFileTitlePreview": "Preview of what will be imported",
        "component_importCsvKeyword_popinTabFileButtonChangeFile": "Change file",
        "component_importCsvKeyword_popinTabFileTitleImport": "Select a file to import",
        "component_importCsvKeyword_popinTabFileButtonImportFile": "Browse my computer",
        "component_importCsvKeyword_popinSave": "Import these keywords",

        "component_marketSegmentConfiguration_title": "Modify my market segments",
        "component_marketSegmentConfiguration_subtitle": "Add or remove market segments",
        "component_marketSegmentConfiguration_informationTooltip_title": "Modify my market segments",
        "component_marketSegmentConfiguration_informationTooltip_text":"<p>Create or delete market segments (keyword group).</p><p><br>Once your segments are created, you will be able to add keywords to them via the <strong>Keyword Management</strong> card.</p>",
        "component_marketSegmentConfiguration_addLabel": "Add new segments",
        "component_marketSegmentConfiguration_addPlaceholder": "Enter one or more segments separated by ;",
        "component_marketSegmentConfiguration_selectAll": "Select all",
        "component_marketSegmentConfiguration_removeAllKeywordFromSegment": "Remove all keywords from segment(s)",
        "component_marketSegmentConfiguration_removeSegment": "Remove",
        "component_marketSegmentConfiguration_confirmDeleteTitle": "Confirm deletion",
        "component_marketSegmentConfiguration_confirmDeleteText": "Warning!<br/>You will delete the selected market segment(s) this action is irreversible and you will lose all information related to this/these market segment(s).<br/>Are you sure you want to continue?",
        "component_marketSegmentConfiguration_confirmClearTitle": "Confirm deletion",
        "component_marketSegmentConfiguration_confirmClearText": "Warning!<br/>You will remove all keywords associated with these market segment(s).<br/>Are you sure you want to continue?",

        "component_competitorConfiguration_title": "Competitor Management",
        "component_competitorConfiguration_subtitle": "Add or remove competitors",
        "component_competitorConfiguration_informationTooltip_title": "My competitors",
        "component_competitorConfiguration_informationTooltip_text":"<p>Add or remove tracked competitors for this project.</p>",
        "component_competitorConfiguration_addLabel": "Add a new competitor",
        "component_competitorConfiguration_addPlaceholder": "Enter the url of the competitor to add",
        "component_competitorConfiguration_confirmDeleteTitle": "Confirm the deletion",
        "component_competitorConfiguration_confirmDeleteText": "Warning!<br/>You are going to delete a competitor this action is irreversible and you are going to lose all the information related to this competitor.<br/>Are you sure you want to continue?",

        "component_locationConfiguration_title": "Location management",
        "component_locationConfiguration_subtitle": "Add or remove locations",
        "component_locationConfiguration_informationTooltip_title": "Location management",
        "component_locationConfiguration_informationTooltip_text":"<p>Determine one or more geolocations for Google Search queries for this project. You can add a Region, Department, or City location.</p><p><br><strong>Beware</strong>: Adding a location consumes extra keywords.</p><p>Example: 50 keywords x 3 locations = 150 keywords used on your map.</p><br><p><strong>You cannot change the country of a project.</strong>You can, however, <strong>clone a project</strong> from the project list.</p>",
        "component_locationConfiguration_addLabel": "Location",
        "component_locationConfiguration_addPlaceholder": "Select a location to add",
        "component_locationConfiguration_confirmDeleteTitle": "Confirm the deletion",
        "component_locationConfiguration_confirmDeleteText": "Warning! You are about to delete a location, this action is irreversible and you will lose all the information linked to this location. Are you sure you want to continue ?",

        "view_linking_unauthorized_title": "What are you doing here ? 🤔",
        "view_linking_unauthorized_text": "Just a little more patience, the netlinking feature will soon be available on YOODA One. We're as impatient as you are.",
        "view_linking_unauthorized_ps": "PS :  It was a good try though, you're a clever... But so are we !",
    },
}

import commonMessage from "@/i18n/messages/common.js"
import componentMessage from "@/i18n/messages/components.js"
import structureMessage from "@/i18n/messages/structure.js"
import projectsMessage from "@/i18n/messages/projects.js"


export default {
  fr: {
      ...commonMessage.fr,
      ...componentMessage.fr,
      ...structureMessage.fr,
      ...projectsMessage.fr
  },

  en: {
      ...commonMessage.en,
      ...componentMessage.en,
      ...structureMessage.en,
      ...projectsMessage.en
  },
}

<template>
    <div class="table-menu-container">

        <vs-dropdown class="table-menu-container" style="margin-top: 8px" :vs-trigger-click="true" >
            <yooda-icon name="ellipsis-v" :size="22"/>
            <vs-dropdown-menu class="table-menu-item">
                <vs-dropdown-item v-if="withFullscreen">
                    <div @click="maximise">
                        <yooda-icon :name="maximisedIcon" :size="18"/>
                        <span>{{ maximisedLabel }}</span>
                    </div>
                </vs-dropdown-item>
                <vs-dropdown-item>
                    <div @click="$emit('setTableMenuExportCsv')">
                        <yooda-icon name="download" :size="18"/>
                        <span>{{ $t('components_tableMenu_downloadCsv_button') }}</span>
                    </div>
                </vs-dropdown-item>
            </vs-dropdown-menu>
        </vs-dropdown>
    </div>

</template>


<script>


export default {
    props: {
        withCsvExport: {type: Boolean,default: false},
        withFullscreen: {type: Boolean,default: false},
    },
    data(){
        return {
            isMaximised:false,
        }
    },
    computed:{
        maximisedLabel(){
            return (this.isMaximised ? this.$t('components_tableMenu_fullscreenReduce_button') : this.$t('components_tableMenu_fullscreenExpand_button'))
        },
        maximisedIcon(){
            return (this.isMaximised ? 'compress' : 'expand')
        }
    },
    methods:{
        maximise(){
            this.$emit('setTableMenuFullscreen')
            this.isMaximised = !this.isMaximised
        },


    },
    components: {
    },
}
</script>

<style lang="scss" >
    .table-menu-item{
        .vs-dropdown--item-link{
            padding: 0 10px !important;
            >div{
                line-height: 50px;
            }
        }

         width: 180px;
        .vs-dropdown--item{
            margin: 0 3px;
            &:nth-of-type(2){
                .vs-dropdown--item-link{
                    border-top: 1px solid #dedede;
                }
            }
            .vs-dropdown--item-link{
                padding: 16px 10px;
                >div{
                    span{
                        display: inline-block;
                        vertical-align: middle;
                    }
                    .yooda-icon{
                        margin-right: 10px;
                    }
                }
            }
        }
    }


</style>

/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Customer from '@/models/customersManager/entity/Customer.js'


export default {

    /**
     * @param state Object
     * @param rawCustomer Object
     * @returns void
     */
    setCustomer (state, rawCustomer) {
        if (state.customer !== null) {
            delete state.customer
        }
        state.customer = null

        if (rawCustomer === null) {
            return;
        }

        state.customer = new Customer(rawCustomer.content)
    },

    /**
     * @param state Object
     * @param toActivateCustomerSubscription CustomerSubscription
     * @returns void
     */
    setCustomerSubscriptionActive(state, toActivateCustomerSubscription) {
        state.customer.customerSubscriptions.forEach((customerSubscription) => {
            customerSubscription.active = (customerSubscription === toActivateCustomerSubscription);
        })
    },

}

export const displayScheduledReporting = {
    computed:{
        isReportingRoute(){
            return ['report_template', 'reporting', 'display_reporting'].includes(this.$route.name);
        }
    },
    methods:{
        getNextReportingDate(period, date){
            let nextDate = new Date()

            if(!date){date = new Date()}

            switch (period){
                case 30:
                    nextDate = new Date(date.getFullYear(), date.getMonth()+1, 1,12);
                    break;
                case 365:
                    nextDate = new Date(date.getFullYear()+1, 0, 1,12);
                    break;
                case 7:
                    nextDate = date
                    nextDate.setDate(nextDate.getDate() + (((1 + 7 - nextDate.getDay()) % 7) || 7));
                    break;
                default:
                    nextDate = new Date(date.getFullYear(), date.getMonth(), date.getDate()+1,12);

                    break;
            }
            return nextDate.toLocaleDateString('fr-FR', { timeZone: 'UTC' })
        },
        getCurrentReportingDate(period, date){
            let nextDate = new Date()

            if(!date){date = new Date()}

            switch (period){
                case 30:
                    nextDate = new Date(date.getFullYear(), date.getMonth(), 1,12);
                    break;
                case 365:
                    nextDate = new Date(date.getFullYear(), 0, 1,12);
                    break;
                case 7:
                    nextDate = date
                    nextDate.setDate(nextDate.getDate() - (nextDate.getDay() + 6) % 7);

                    break;
                default:
                    nextDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(),12);

                    break;
            }
            return nextDate.toLocaleDateString('fr-FR', { timeZone: 'UTC' })
        },

    }
}

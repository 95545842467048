import {mapGetters} from "vuex";
import apiCalls from "@/api/axios/configuration/project";

export const tagManagerMixin = {
    computed:{
        ...mapGetters({
            getActiveCustomerSubscription:"customersManager/getActiveCustomerSubscription",
        }),
    },

    methods:{

        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let rowData = []
            jsonData.content.forEach(data => {
                rowData.push({
                    name:data.name,
                    distinctKeywordCount: data.distinctKeywordCount,
                    distinctSearchGeolocationCount: data.distinctSearchGeolocationCount,
                    requestCount: parseInt(data.requestCount),
                })
            })
            return rowData
        },

        analyticsIdentify(){
            this.setDataComponent({
                stateName:'subscriptionMeta',
                promise: apiCalls.getSubscriptionMeta(this.$route.params.subscriptionId)
            }).then(()=>{
                this.setDataComponent({
                    stateName:'projectsSummary',
                    promise: apiCalls.getProjectsSummary(this.$route.params.subscriptionId),
                    dataFormatter: this.formatData
                }).then(()=>{


                    let analyticsInfo = {
                        'event':'yoodaOneEvent',
                        'yoodaOneData': {
                            'customerId':this.getActiveCustomerSubscription().ownerCustomer.chargifyId,
                            'firstName': this.getActiveCustomerSubscription().ownerCustomer.firstName,
                            'lastName': this.getActiveCustomerSubscription().ownerCustomer.lastName,
                            'fullName': this.getActiveCustomerSubscription().ownerCustomer.firstName+' '+this.getActiveCustomerSubscription().ownerCustomer.lastName,
                            'email': this.getActiveCustomerSubscription().ownerCustomer.email,
                            'createdAt': (this.getActiveCustomerSubscription().ownerCustomer.createdAt ? this.getActiveCustomerSubscription().ownerCustomer.createdAt.toISOString() : null),
                            'state':this.getActiveCustomerSubscription().subscription.state,
                            'subscription_id':this.getActiveCustomerSubscription().subscription.id,
                            'created_Y1_at':(this.getActiveCustomerSubscription().subscription.createdAt ? this.getActiveCustomerSubscription().subscription.createdAt.toISOString() : null),
                            'trial_started_at':(this.getActiveCustomerSubscription().subscription.trialStartedAt ? this.getActiveCustomerSubscription().subscription.trialStartedAt.toISOString() : null),
                            'trial_ended_at':(this.getActiveCustomerSubscription().subscription.trialEndedAt ? this.getActiveCustomerSubscription().subscription.trialEndedAt.toISOString() : null),
                            'plan':this.getActiveCustomerSubscription().subscription.product.handle,
                            'current_period_started_at':(this.getActiveCustomerSubscription().subscription.currentPeriodStartedAt ? this.getActiveCustomerSubscription().subscription.currentPeriodStartedAt.toISOString() : null),
                            'current_period_ends_at':(this.getActiveCustomerSubscription().subscription.currentPeriodEndsAt ? this.getActiveCustomerSubscription().subscription.currentPeriodEndsAt.toISOString() : null),
                            'next_assessment_at':'ND',
                            'plan_period':this.getActiveCustomerSubscription().subscription.pricePoint.intervalUnit,
                            'project_nb':(this.projectsSummary.data ? this.projectsSummary.data.length : 0),
                            'keyword_nb':(this.projectsSummary.data ? this.projectsSummary.data.reduce((acc, project)=>acc + (project.distinctKeywordCount),0) : 0),
                            'geolocation_nb':(this.projectsSummary.data ? this.projectsSummary.data.reduce((acc, project)=>acc + (project.distinctSearchGeolocationCount),0) : 0),
                            'gg_analytics':!!this.subscriptionMeta.data.analyticsToken,
                            'competitor_nb':(this.projectsSummary.data ? this.projectsSummary.data.reduce((acc, project)=>acc + (project.distinctRivalCount),0) : 0),
                            'segment_nb':(this.projectsSummary.data ? this.projectsSummary.data.reduce((acc, project)=>acc + (project.distinctKeywordGroupCount),0) : 0),
                        }
                    }
                    window.dataLayer.push(analyticsInfo);

                    if(this.getActiveCustomerSubscription().subscription.state === 'trialing'){
                        let defaultLocale = 'fr'
                        if(localStorage.getItem('defaultLocale')){
                            defaultLocale = localStorage.getItem('defaultLocale')
                        }else{
                            if(navigator.language){
                                defaultLocale = navigator.language.slice(0,2)
                            }
                        }
                        let subscriptionPush = {
                            'typepage': 'tunnel_abonnement',
                            'pagename': this.$route.name,
                            'locale': defaultLocale,
                            'event': 'trial_start',
                            'email': this.getActiveCustomerSubscription().ownerCustomer.email,
                            'subscription_id':this.getActiveCustomerSubscription().subscription.id
                        }

                        window.dataLayer.push(subscriptionPush);
                    }

                })
            })
        }
    },
}


export default {
    "fr": {
        "component_projectCreationWizard_finish": "C'est fini !",
        "component_projectCreationWizard_createButton": "Créer l'analyse",
        "component_projectCreationWizard_assistantButton": "Etre assisté par Maitre Yooda",

        "component_projectCreationWizard_stepWebSite_title": "Mon site web",
        "component_projectCreationWizard_stepWebSite_subtitle": "Indiquez le domaine de votre site web.",
        "component_projectCreationWizard_stepWebSite_inputPlaceholder": "mon-site.com",
        "component_projectCreationWizard_stepWebSite_error_badUrlFormat": "Veuillez saisir un nom de domaine valide",


        "component_projectCreationWizard_stepLocation_title": "Ma zone de chalandise",
        "component_projectCreationWizard_stepLocation_subtitle": "Dans quel(s) lieu(x) devons-nous mesurer la visibilité de votre site sur Google ?",
        "component_projectCreationWizard_stepLocation_selectCountry_label": "Quel pays ciblez-vous ?",
        "component_projectCreationWizard_stepLocation_countrySelect_label" : "Pays",
        "component_projectCreationWizard_stepLocation_selectLanguage_label": "Dans quelle langue ?",
        "component_projectCreationWizard_stepLocation_languageSelect_label": "Langue",
        "component_projectCreationWizard_stepLocation_locationTips": "Votre visibilité est-elle nationale, locale ou les deux ?",
        "component_projectCreationWizard_stepLocation_location_tooltipTitle": "Choix de la localisation",
        "component_projectCreationWizard_stepLocation_location_tooltipText": "<p>Vos clients peuvent-être dans tous le pays (cas d'un site e-commerce) ou plus proches de vous.</p></br><p>Nous pouvons mesurer votre visibilité de manière globale sur tout le pays, ou localement, ou les deux.</p>",
        "component_projectCreationWizard_stepLocation_citySelect_label" : "Ville",
        "component_projectCreationWizard_stepLocation_error_maxCityQuota" : "5 localités au maximum",
        "component_projectCreationWizard_stepLocation_error_minCityQuota" : "Choisissez au moins une localité",
        "component_projectCreationWizard_stepLocation_error_fullQuota" : "Vous avez atteint votre quota",
        "component_projectCreationWizard_stepLocation_error_overQuota" : "Votre projet dépasse votre quota restant sur votre abonnement.",

        "component_projectCreationWizard_stepKeywords_title": "Mes mots-clés",
        "component_projectCreationWizard_stepKeywords_subtitle": "Renseignez les mots-clés sur lesquels votre site doit être positionné.",
        "component_projectCreationWizard_stepKeywords_addPlaceholder": "Saisir un ou plusieurs mots-clés séparés par des ;",
        "component_projectCreationWizard_stepKeywords_suggestKeyword": "Suggérer des mots-clés",
        "component_projectCreationWizard_stepKeywords_keywordsRemaining": "Ce projet utilise <strong>[*KEYWORD-COUNT*]</strong> mots-clés sur <strong>[*KEYWORD-MAX*]</strong> disponibles.",
        "component_projectCreationWizard_stepKeywords_tooltipUsageTitle": "Décompte des mots-clés",
        "component_projectCreationWizard_stepKeywords_tooltipUsageText": "<div class='text-center'><p>Ces <strong>[*KEYWORD-TOTAL-COUNT*]</strong> mots-clés utilisés correspondent à :</p><p><strong>[*LOCATION-COUNT*]</strong> localités x <strong>[*KEYWORD-COUNT*]</strong> mots-clés</p></br><p>Vous pouvez adapter votre liste de mots-clés sur cet écran (étape 3), ou modifier votre sélection de localité à l'étape précédente.</p></br><p>Quota total de votre abonnement : <strong>[*QUOTA-TOTAL*]</strong></p><p>Utilisé par les autres projets : <strong>[*PROJECT-USAGE-COUNT*]</strong></p><p>Votre quota restant : <strong>[*REMAINING-COUNT*]</strong></p></div>",
        "component_projectCreationWizard_stepKeywords_clearList": "Vider la liste",
        "component_projectCreationWizard_stepKeywords_shortListCount": "autres mots-clés",
        "component_projectCreationWizard_stepKeywords_importedSegment": "Ces segments seront aussi créés :",
        "component_projectCreationWizard_stepKeywords_import": "Importer des mots-clés & segments",
        "component_projectCreationWizard_stepKeywords_error_suggestError": "Pas de mots-clés trouvés pour ce site",
        "component_projectCreationWizard_stepKeywords_error_webSiteBadUrlFormat": "Vous devez d'abord renseigner le domaine de votre site à l'étape 1",
        "component_projectCreationWizard_stepKeywords_error_location": "Vous devez d'abord choisir une langue à l'étape 2",
        "component_projectCreationWizard_stepKeywords_error_searchEngine": "Vous devez d'abord renseigner la zone de chalandise à l'étape 2",
        "component_projectCreationWizard_stepKeywords_error_overQuotaKeywordsLabel": "Votre projet dépasse votre quota restant sur votre abonnement.",
        "component_projectCreationWizard_stepKeywords_error_fullQuotaKeywordsLabel": "Vous avez atteint votre quota.",



        "component_projectCreationWizard_stepCompetitors_title": "Mes concurrents",
        "component_projectCreationWizard_stepCompetitors_subtitle": "Indiquez les concurrents que vous souhaitez surveiller afin d'affiner vos analyses.<br/>Vous pouvez lister jusqu'à 10 concurrents suivis.",
        "component_projectCreationWizard_stepCompetitors_inputPlaceholder": "mon-concurrent.com",
        "component_projectCreationWizard_stepCompetitors_suggestCompetitor": "Suggérer des concurrents",
        "component_projectCreationWizard_stepCompetitors_competitorRemaining": "<strong>[*COMPETITOR-COUNT*]</strong> concurrents suivis sur <strong>10</strong> possibles.",
        "component_projectCreationWizard_stepCompetitors_clearList": "Vider la liste",

        "component_projectCreationWizard_stepCompetitors_error_competitorCantBeWebsite": "Le site web du projet ne peux pas être également un conncurent",
        "component_projectCreationWizard_stepCompetitors_error_alreadyExist": "Concurrent déjà ajouté",
        "component_projectCreationWizard_stepCompetitors_error_competitorMaxError": "Vous ne pouvez ajouter que 10 concurrents",
        "component_projectCreationWizard_stepCompetitors_error_webSiteBadUrlFormat": "Veuillez saisir un nom de domaine valide",
        "component_projectCreationWizard_stepCompetitors_error_competitorSuggested": "Pas de concurrent connu. Vous pouvez ajouter manuellement vos concurrents.",

        "component_projectCreationWizard_stepAnalytics_title": "Données de trafic",
        "component_projectCreationWizard_stepAnalytics_subtitle": "Profitez d'une interface optimisée pour Analytics ou Matomo et redonnez du sens à vos données de trafic, de conversions et de chiffre d'affaires.",
        "component_projectCreationWizard_stepAnalytics_selectDataSourceTypeTitle": "Quelle solution utilisez-vous ?",
        "component_projectCreationWizard_stepAnalytics_selectDataSourceChooseOr": "OU",
        "component_projectCreationWizard_stepAnalytics_selectDataSourceAdviceTitle": "Pour bénéficier d'une analyse plus complète, vous pouvez relier vos données Analytics à votre tableau de bord YOODA One.",
        "component_projectCreationWizard_stepAnalytics_selectDataSourceAdvice1": "Les informations essentielles d'Analytics simplifiées et regroupées",
        "component_projectCreationWizard_stepAnalytics_selectDataSourceAdvice2": "Des données Analytics traduites en informations utilisables.",
        "component_projectCreationWizard_stepAnalytics_selectDataSourceAdvice3": "Un croisement trafic & visibilité pour mesurer le résultat de vos actions",
    },
    "en": {
        "component_projectCreationWizard_finish": "It's finish !",
        "component_projectCreationWizard_createButton": "Create the analysis",
        "component_projectCreationWizard_assistantButton": "Be assisted by Master Yooda",

        "component_projectCreationWizard_stepWebSite_title": "My website",
        "component_projectCreationWizard_stepWebSite_subtitle": "Specify the domain of your website.",
        "component_projectCreationWizard_stepWebSite_inputPlaceholder": "my-website.com",
        "component_projectCreationWizard_stepWebSite_error_badUrlFormat": "Please enter a valid domain name",


        "component_projectCreationWizard_stepLocation_title": "My catchment area",
        "component_projectCreationWizard_stepLocation_subtitle": "In which location(s) should we measure the visibility of your site on Google?",
        "component_projectCreationWizard_stepLocation_selectCountry_label": "Which country are you targeting?",
        "component_projectCreationWizard_stepLocation_countrySelect_label" : "Country",
        "component_projectCreationWizard_stepLocation_selectLanguage_label": "In which language?",
        "component_projectCreationWizard_stepLocation_languageSelect_label": "Language",
        "component_projectCreationWizard_stepLocation_locationTips": "Is your visibility national, local or both?",
        "component_projectCreationWizard_stepLocation_location_tooltipTitle": "Location choice",
        "component_projectCreationWizard_stepLocation_location_tooltipText": "<p>Your customers may be all over the country (case of an e-commerce site) or closer to you.</p></br><p>We can measure your visibility globally over the whole country, or locally, or both.</p>",
        "component_projectCreationWizard_stepLocation_citySelect_label" : "City",
        "component_projectCreationWizard_stepLocation_error_maxCityQuota" : "Maximum 5 locations",
        "component_projectCreationWizard_stepLocation_error_minCityQuota" : "Choose at least one location",
        "component_projectCreationWizard_stepLocation_error_fullQuota": "You have reached your quota.",
        "component_projectCreationWizard_stepLocation_error_overQuota" : "Your project exceeds your remaining quota on your subscription.",

        "component_projectCreationWizard_stepKeywords_title": "My keywords",
        "component_projectCreationWizard_stepKeywords_subtitle": "Please enter the keywords on which your site should be positioned",
        "component_projectCreationWizard_stepKeywords_addPlaceholder": "Enter one or more keywords separated by ;",
        "component_projectCreationWizard_stepKeywords_suggestKeyword": "Suggest keywords",
        "component_projectCreationWizard_stepKeywords_keywordsRemaining": "This project uses <strong>[*KEYWORD-COUNT*]</strong> keywords on <strong>[*KEYWORD-MAX*]</strong> available.",
        "component_projectCreationWizard_stepKeywords_tooltipUsageTitle": "Keyword count",
        "component_projectCreationWizard_stepKeywords_tooltipUsageText":"<div class='text-center'><p>These <strong>[*KEYWORD-TOTAL-COUNT*]</strong> keywords used correspond to : </p><p><strong>[*LOCATION-COUNT*]</strong> localities x <strong>[*KEYWORD-COUNT*]</strong> keywords</p></br><p>You can adjust your keyword list on this screen (step 3), or change your locality selection in the previous step. </p></br><p>Your total subscription quota: <strong>[*QUOTA-TOTAL*]</strong></p><p>Used by other projects: <strong>[*PROJECT-USAGE-COUNT*]</strong></p><p>Your remaining quota: <strong>[*REMAINING-COUNT*]</strong></p></div>",
        "component_projectCreationWizard_stepKeywords_clearList": "Clear list",
        "component_projectCreationWizard_stepKeywords_shortListCount": "other keywords",
        "component_projectCreationWizard_stepKeywords_importedSegment": "These segments will also be created:",

        "component_projectCreationWizard_stepKeywords_import": "Import keywords & market-segments",
        "component_projectCreationWizard_stepKeywords_error_suggestError": "Keyword suggestion not possible",
        "component_projectCreationWizard_stepKeywords_error_webSiteBadUrlFormat": "You must first enter the domain of your site in step 1",
        "component_projectCreationWizard_stepKeywords_error_location": "You must first choose a language in step 2",
        "component_projectCreationWizard_stepKeywords_error_searchEngine": "You must first fill in the catchment area in step 2",
        "component_projectCreationWizard_stepKeywords_error_overQuotaKeywordsLabel": "Your project exceeds your remaining quota on your subscription.",
        "component_projectCreationWizard_stepKeywords_error_fullQuotaKeywordsLabel": "You have reached your quota.",

        "component_projectCreationWizard_stepCompetitors_title": "My competitors",
        "component_projectCreationWizard_stepCompetitors_subtitle": "Indicate the competitors you want to monitor in order to refine your analyses.<br/>You can list up to 10 monitored competitors.",
        "component_projectCreationWizard_stepCompetitors_inputPlaceholder": "my-competitor.com",
        "component_projectCreationWizard_stepCompetitors_suggestCompetitor": "Suggest competitors",
        "component_projectCreationWizard_stepCompetitors_competitorRemaining": "<strong>[*COMPETITOR-COUNT*]</strong> competitors tracked out of <strong>10</strong> possible.",
        "component_projectCreationWizard_stepCompetitors_clearList": "Clear list",

        "component_projectCreationWizard_stepCompetitors_error_competitorCantBeWebsite": "The project website cannot also be a competitor",
        "component_projectCreationWizard_stepCompetitors_error_alreadyExist": "Concurrent already added",
        "component_projectCreationWizard_stepCompetitors_error_competitorMaxError": "You can only add 10 competitors",
        "component_projectCreationWizard_stepCompetitors_error_webSiteBadUrlFormat": "Please enter a valid domain name",
        "component_projectCreationWizard_stepCompetitors_error_competitorSuggested": "No known competitors. You can manually add your competitors.",

        "component_projectCreationWizard_stepAnalytics_title": "Traffic data",
        "component_projectCreationWizard_stepAnalytics_subtitle": "Take advantage of an interface optimized for Analytics or Matomo and give meaning to your traffic, conversions and turnover data.",
        "component_projectCreationWizard_stepAnalytics_selectDataSourceTypeTitle": "What solution do you use?",
        "component_projectCreationWizard_stepAnalytics_selectDataSourceChooseOr": "OR",
        "component_projectCreationWizard_stepAnalytics_selectDataSourceAdviceTitle": "To benefit from a more complete analysis, you can link your Analytics data to your YOODA One dashboard.",
        "component_projectCreationWizard_stepAnalytics_selectDataSourceAdvice1": "Essential Analytics insights simplified and consolidated",
        "component_projectCreationWizard_stepAnalytics_selectDataSourceAdvice2": "Analytics data translated into usable information.",
        "component_projectCreationWizard_stepAnalytics_selectDataSourceAdvice3": "A crossroads of traffic and visibility to measure the results of your actions",
    },
}



export default {
    "fr": {
        "component_projectCreationWizardStepLocation_labelMessage_location_minCount": "Choisissez au moins une localité",
        "component_projectCreationWizardStepLocation_labelMessage_location_maxCount": "5 localités au maximum",
        "component_projectCreationWizardStepLocation_labelMessage_location_overQuota": "Ce projet risque de dépasser vos quotas à l'étape suivante",
        "component_projectCreationWizardStepLocation_country_label": "Quel pays ciblez-vous ?",
        "component_projectCreationWizardStepLocation_language_label": "Dans quelle langue ?",
    },
    "en": {
        "component_projectCreationWizardStepLocation_labelMessage_location_minCount": "Choose at least one location",
        "component_projectCreationWizardStepLocation_labelMessage_location_maxCount": "Maximum 5 locations",
        "component_projectCreationWizardStepLocation_labelMessage_location_overQuota": "This project may exceed your quotas in the next step",
        "component_projectCreationWizardStepLocation_country_label": "Which country are you targeting?",
        "component_projectCreationWizardStepLocation_language_label": "In which language?",
    },
}

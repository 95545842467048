/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "@/store/state"
import getters from "@/store/getters"
import mutations from "@/store/mutations"
import actions from "@/store/actions"

import moduleComponents from '@/store/components/components.js'
import moduleCustomersManager from '@/store/customersManager/customersManager.js'

Vue.use(Vuex)

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules:{
        customersManager: moduleCustomersManager,
        components: moduleComponents
    },
    strict: process.env.NODE_ENV !== 'production'
})



export default {
    "fr": {
        "component_marketSegmentEvolution_title": "Mes segments de marché",
        "component_marketSegmentEvolution_subtitle": "Marché avec la plus forte variation de la visibilité",
        "component_marketSegmentEvolution_informationTooltip_title": "Mes segments de marché",
        "component_marketSegmentEvolution_informationTooltip_text": "<p>Segments de marché (groupes de mots-clés) ayant eu la plus forte hausse ou baisse en termes de visibilité (tous types de résultats confondus : SEO, Google Ads, Shopping, Google Business Profile, etc...), comparé au rapport précédent.</p>",
        "component_marketSegmentEvolution_detailButton": "Etudier vos segments de marché",

        "component_marketSegmentProgressionPotential_title": "Potentiel de progression",
        "component_marketSegmentProgressionPotential_subtitle": "visites mensuels supplémentaires",
        "component_marketSegmentProgressionPotential_informationTooltip_title": "Potentiel de progression",
        "component_marketSegmentProgressionPotential_informationTooltip_text": "<p>Permet d'estimer le nombre de visites supplémentaires qu'il serait possible d'obtenir, en améliorant votre visibilité, pour chacun des types de résultats Google.</p>",
        "component_marketSegmentProgressionPotential_label": "visites",
        "component_marketSegmentProgressionPotential_detailButton": "Découvrir vos opportunités",

        "component_marketSegmentSize_title": "Taille du marché",
        "component_marketSegmentSize_subtitle": "Nombre de fois où vos mots-clés sont recherchés",
        "component_marketSegmentSize_informationTooltip_title": "Taille du marché",
        "component_marketSegmentSize_informationTooltip_text": "<p>Somme des volumes de recherche moyens mensuels des mots-clés du projet.</p>",
        "component_marketSegmentSize_legend": "recherches par mois",

        "component_marketSegmentCompetitorCompare_title": "Concurrence sur les segments de marché",
        "component_marketSegmentCompetitorCompare_subtitle": "Comparez les performances de vos concurrents sur les différents segments de marché.",
        "component_marketSegmentCompetitorCompare_informationTooltip_title": "Concurrence sur les segments de marché",
        "component_marketSegmentCompetitorCompare_informationTooltip_text": "<p>Permet de comparer et d'identifier les points forts et les points faibles en terme de visibilité du site principal face à un concurrent choisi sur les différents segments de marché (tous types de résultats confondus : SEO, Google Ads, Shopping, Google Business Profile, etc...).</p>",

        "component_marketSegmentSegmentSize_title": "Taille des segments de marché",
        "component_marketSegmentSegmentSize_subtitle": "Volume de recherche associé à vos différents segments de marché",
        "component_marketSegmentSegmentSize_informationTooltip_title": "Taille des segments de marché",
        "component_marketSegmentSegmentSize_informationTooltip_text": "<p>Permet d'identifier les groupes de mots-clés (segments) les plus demandés sur votre marché : la répartition est affichée en pourcentage et en volumes de recherche moyens mensuels.</p>",
        "component_marketSegmentSegmentSize_label": "recherches par mois",
        "component_marketSegmentSegmentSize_other": "Autres segments",

        "component_marketSegmentSegmentPerformance_title": "Ma performance par segment de marché",
        "component_marketSegmentSegmentPerformance_subtitle": "Efficacité de ma visibilité sur les différents segments de marché",
        "component_marketSegmentSegmentPerformance_informationTooltip_title": "Ma performance par segment de marché",
        "component_marketSegmentSegmentPerformance_informationTooltip_text": "<p>Permet d'identifier la performance de visibilité de votre site pour chacun des segments de marché.</p><p><br><strong>Exemple - 100%</strong> : Le site est en 1ère position sur tous les mots-clés du segment, tous types de résultats confondus : SEO, Google Ads, Shopping, Google Business Profile, etc...).</p>",
        "component_marketSegmentSegmentPerformance_label": "d'efficacité",
        "component_marketSegmentSegmentPerformance_other": "Autres segments",

        "component_marketSegmentVisibility_title": "Ma visibilité par segment de marché",
        "component_marketSegmentVisibility_subtitle": "Evolution de ma visibilité sur les différents segments de marché",
        "component_marketSegmentVisibility_informationTooltip_title": "Ma visibilité par segment de marché",
        "component_marketSegmentVisibility_informationTooltip_text": "<p>Permet de suivre l'évolution de la visibilité du site pour chacun des segments de marché (groupes de mots clés).</p>",
        "component_marketSegmentVisibility_series_label": "Visibilité",
        "component_marketSegmentVisibility_other": "Autres segments",

        "component_marketSegmentBestProgressionPotential_title": "Segments de marché à prioriser",
        "component_marketSegmentBestProgressionPotential_subtitle": "Gain potentiel de trafic pour une optimisation de vos différents segments de marché.",
        "component_marketSegmentBestProgressionPotential_informationTooltip_title": "Meilleurs potentiels de progression",
        "component_marketSegmentBestProgressionPotential_informationTooltip_text": "<p>Permet de cibler les segments de marché ayant le plus de potentiel de trafic.</p><p><br>Vous pourrez ensuite identifier par type de résultats (SEO, Google Ads, Google Business Profile, ...) et par mot-clé le nombre de visites potentiels supplémentaires.</p>",
        "component_marketSegmentBestProgressionPotential_potential_gain": "visites",
        "component_marketSegmentBestProgressionPotential_table_header_market": "Marché",
        "component_marketSegmentBestProgressionPotential_table_header_performance": "Ma performance",
        "component_marketSegmentBestProgressionPotential_table_header_potentialGain": "Gain potentiel de trafic",
        "component_marketSegmentBestProgressionPotential_table_header_keywords": "Mots-clés à optimiser",

        "component_marketSegmentBestProgressionPotentialKeywords_title": "Potentiels de progression sur le marché :",
        "component_marketSegmentBestProgressionPotentialKeywords_subtitle": "Indique les mots-clés à optimiser en priorité pour gagner un maximum de trafic sur votre segment de marché",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_keyword": "Mot-clé",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialTraffic": "Gains potentiels de trafic par canal",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialSeoTraffic": "SEO",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialAdwordsTraffic": "Google Ads",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialVideoTraffic": "Vidéo",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialGoogleBusinessTraffic": "Business Profile",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialZeroTraffic": "Position Zéro",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialShoppingTraffic": "Shopping",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialActualityTraffic": "Actualité",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialImageTraffic": "Image",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_searchCount": "Nb de recherches",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_totalPotentialTraffic": "Gain potentiel total",
    },
    "en": {
        "component_marketSegmentEvolution_title": "My market segments",
        "component_marketSegmentEvolution_subtitle": "Market with the highest variation in visibility",
        "component_marketSegmentEvolution_informationTooltip_title": "My market segments",
        "component_marketSegmentEvolution_informationTooltip_text":"<p>Market segments (keyword groups) that had the largest increase or decrease in visibility (all types of results combined: SEO, Google Ads, Shopping, Google Business Profile, etc...), compared to the previous report.</p>",
        "component_marketSegmentEvolution_detailButton": "Study your market segments",

        "component_marketSegmentProgressionPotential_title": "Progression potential",
        "component_marketSegmentProgressionPotential_subtitle": "additional monthly visits",
        "component_marketSegmentProgressionPotential_informationTooltip_title": "Progression potential",
        "component_marketSegmentProgressionPotential_informationTooltip_text":"<p>Estimates the number of additional visits that could be gained, by improving your visibility, for each of the Google result types.</p>",
        "component_marketSegmentProgressionPotential_label": "visits",
        "component_marketSegmentProgressionPotential_detailButton": "Discover your opportunities",

        "component_marketSegmentSize_title": "market size",
        "component_marketSegmentSize_subtitle": "Number of times your keywords are searched",
        "component_marketSegmentSize_informationTooltip_title": "Market size",
        "component_marketSegmentSize_informationTooltip_text":"<p>Sum of average monthly search volumes for project keywords.</p>",
        "component_marketSegmentSize_legend": "searches per month",

        "component_marketSegmentCompetitorCompare_title": "Market segment competition",
        "component_marketSegmentCompetitorCompare_subtitle": "Compare the performance of your competitors in the different market segments.",
        "component_marketSegmentCompetitorCompare_informationTooltip_title": "Competition in market segments",
        "component_marketSegmentCompetitorCompare_informationTooltip_text":"<p>Allows you to compare and identify the strengths and weaknesses in terms of visibility of the main site against a chosen competitor on the different market segments (all types of results combined: SEO, Google Ads, Shopping, Google Business Profile, etc...).</p>",

        "component_marketSegmentSegmentSize_title": "Market segment size",
        "component_marketSegmentSegmentSize_subtitle": "Search volume associated with your different market segments",
        "component_marketSegmentSegmentSize_informationTooltip_title": "Market segment size",
        "component_marketSegmentSegmentSize_informationTooltip_text":"<p>Allows you to identify the most in-demand keyword groups (segments) in your market: the breakdown is displayed as a percentage and in average monthly search volumes.</p>",
        "component_marketSegmentSegmentSize_label": "searches per month",
        "component_marketSegmentSegmentSize_other": "other segments",

        "component_marketSegmentSegmentPerformance_title": "My performance by market segment",
        "component_marketSegmentSegmentPerformance_subtitle": "My visibility effectiveness in the different market segments",
        "component_marketSegmentSegmentPerformance_informationTooltip_title": "My performance by market segment",
        "component_marketSegmentSegmentPerformance_informationTooltip_text":"<p>Allows you to identify your site's visibility performance for each market segment.</p><p><br><strong>Example - 100%</strong>: The site is in 1st position on all the keywords of the segment, all types of results combined: SEO, Google Ads, Shopping, Google Business Profile, etc...).</p>",
        "component_marketSegmentSegmentPerformance_label": "efficiency",
        "component_marketSegmentSegmentPerformance_other": "Others segments",

        "component_marketSegmentVisibility_title": "My visibility by market segment",
        "component_marketSegmentVisibility_subtitle": "Evolution of my visibility on the different market segments",
        "component_marketSegmentVisibility_informationTooltip_title": "My visibility by market segment",
        "component_marketSegmentVisibility_informationTooltip_text":"<p>Allows you to track the evolution of the site's visibility for each of the market segments (keyword groups).</p>",
        "component_marketSegmentVisibility_series_label": "Visibility",
        "component_marketSegmentVisibility_other": "Other segments",

        "component_marketSegmentBestProgressionPotential_title": "Best Progression Potential",
        "component_marketSegmentBestProgressionPotential_subtitle": "Market segments to optimize first to get more traffic",
        "component_marketSegmentBestProgressionPotential_informationTooltip_title": "Best progress potentials",
        "component_marketSegmentBestProgressionPotential_informationTooltip_text":"<p>Allows you to target the market segments with the most traffic potential.</p><p><br>You will then be able to identify by type of results (SEO, Google Ads, Google Business Profile, ...) and by keyword the number of additional potential visits.</p>",
        "component_marketSegmentBestProgressionPotential_potential_gain": "visits",
        "component_marketSegmentBestProgressionPotential_table_header_market": "Market",
        "component_marketSegmentBestProgressionPotential_table_header_performance": "My performance",
        "component_marketSegmentBestProgressionPotential_table_header_potentialGain": "Potential traffic gain",
        "component_marketSegmentBestProgressionPotential_table_header_keywords": "Keywords to optimize",

        "component_marketSegmentBestProgressionPotentialKeywords_title": "Market Progression Potentials:",
        "component_marketSegmentBestProgressionPotentialKeywords_subtitle": "Indicates which keywords should be optimized first to gain maximum traffic to your market segment.",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_keyword": "Keyword",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialSeoTraffic": "Potential SEO traffic",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialAdwordsTraffic": "Potential Google Ads traffic",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialVideoTraffic": "Potential Video Traffic",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialGoogleBusinessTraffic": "Potential Traffic Google Business Profile",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialZeroTraffic": "Potential Traffic Zero Position",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialShoppingTraffic": "Potential Shopping Traffic",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialActualityTraffic": "Potential News Traffic",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialImageTraffic": "Potential Image Traffic",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_searchCount": "Total number of searches",
        "component_marketSegmentBestProgressionPotentialKeywords_table_header_totalPotentialTraffic": "Total potential traffic gain",
    },
}

<template>
  <div class="table-density-selector" :class="density" @click="changeDensity">

  </div>
</template>


<script>

export default {

    data(){
        return{
            density:'relaxed'
        }
    },
    watch:{
        getTableDensity(){
            this.density = this.getTableDensity
        }
    },
    methods:{
        changeDensity(){
            if(this.density === 'relaxed'){
                this.density = 'regular'
            }
            else{
                if(this.density === 'regular'){
                    this.density = 'condensed'
                }
                else{
                    this.density = 'relaxed'
                }
            }
            this.setTableDensity(this.density)
            localStorage.setItem('tableDensity',this.density)
        }
    },
    beforeMount() {
        if(localStorage.getItem('tableDensity')){
            this.setTableDensity(localStorage.getItem('tableDensity'))
        }
        this.density = this.getTableDensity
    }

}
</script>

<style lang="scss" >
.table-density-selector{
    cursor: pointer;
    opacity: 0.8;
    width: 22px;
    height: 22px;
    background-size: cover;
    transition: opacity 0.3s;

    &:hover{
        opacity: 1;
    }


    &.relaxed{ background-image: url('~@/assets/images/table_density_relaxed.png')!important; }
    &.regular{ background-image: url('~@/assets/images/table_density_regular.png')!important; }
    &.condensed{ background-image: url('~@/assets/images/table_density_condensed.png')!important; }
}

</style>

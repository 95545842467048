

export default {
    "fr": {
        "component_localVisibilityByLocation_title": "Visibilité locale",
        "component_localVisibilityByLocation_subtitle": "Efficacité de votre visibilité Google dans différentes villes",
        "component_localVisibilityByLocation_informationTooltip_title": "Visibilité locale",
        "component_localVisibilityByLocation_informationTooltip_text": "<p>Visibilité du site pour chacune des géolocalisations paramétrées (tous types de résultats confondus : SEO, Google Ads, Shopping, Google Business Profile, etc...).</p>",
        "component_localVisibilityByLocation_detailButton": "Détailler votre visibilité locale",

        "component_localVisibilityCompetitor_title": "Visibilité par ville",
        "component_localVisibilityCompetitor_subtitle": "Efficacité de la visibilité des concurrents sur chaque localité",
        "component_localVisibilityCompetitor_informationTooltip_title": "Visibilité par ville",
        "component_localVisibilityCompetitor_informationTooltip_text": "<p>Permet de comparer la visibilité de votre site à celle des autres sites suivis dans les différentes localités choisies.</p>",
        "component_localVisibilityCompetitor_chartTooltip": "Répartition de la visibilité entre les sites sur ",

        "component_localVisibilityKeywordsPositions_title": "Positions dans les résultats naturels par ville",
        "component_localVisibilityKeywordsPositions_subtitle": "Variations locales de nos positions Google, en fonction de la géolocalisation de l'internaute",
        "component_localVisibilityKeywordsPositions_informationTooltip_title": "Position par ville dans les résultats naturels",
        "component_localVisibilityKeywordsPositions_informationTooltip_text": "<p>Permet de connaître la position de votre site sur chaque mot-clé suivi, par localité, en résultat naturel.</p>",
        "component_localVisibilityKeywordsPositions_table_header_keyword": "Mot-clé",
        "component_localVisibilityKeywordsPositions_table_header_city": "Position à ",

        "component_localVisibilityMyBusinessKeywordsPositions_title": "Positions Google Business Profile",
        "component_localVisibilityMyBusinessKeywordsPositions_subtitle": "Mots-clés où votre fiche Google Business Profile apparait, et classement de votre site dans le bloc de résultats Carte Locale",
        "component_localVisibilityMyBusinessKeywordsPositions_informationTooltip_title": "Position Google Business Profile",
        "component_localVisibilityMyBusinessKeywordsPositions_informationTooltip_text": "<p>Permet de connaître la position de votre site sur chaque mot-clé suivi, par localité, sur Google Business Profile</p>",
        "component_localVisibilityMyBusinessKeywordsPositions_table_header_keyword": "Mot-clé",
        "component_localVisibilityMyBusinessKeywordsPositions_table_header_position": "Position",
        "component_localVisibilityMyBusinessKeywordsPositions_table_header_geoloc": "Géoloc",
        "component_localVisibilityMyBusinessKeywordsPositions_table_header_website": "Site Web",

        "component_localVisibilityLocalAdvertisingPresence_title": "Présence publicitaire locale",
        "component_localVisibilityLocalAdvertisingPresence_subtitle": "Mots-clés où vos publicités apparaissent, pour les différentes localités analysées",
        "component_localVisibilityLocalAdvertisingPresence_informationTooltip_title": "Présence publicitaire locale",
        "component_localVisibilityLocalAdvertisingPresence_informationTooltip_text": "<p>Permet de connaître la position de votre site sur chaque mot-clé suivi, par localité, en Google Ads.</p>",
        "component_localVisibilityLocalAdvertisingPresence_table_header_keyword": "Mot-clé",
        "component_localVisibilityLocalAdvertisingPresence_table_header_adwords": "Position Google Ads",
        "component_localVisibilityLocalAdvertisingPresence_table_header_shopping": "Position Shopping",
        "component_localVisibilityLocalAdvertisingPresence_table_header_geoloc": "Géoloc",
        "component_localVisibilityLocalAdvertisingPresence_table_header_website": "Site Web",
    },
    "en": {
        "component_localVisibilityByLocation_title": "Local visibility",
        "component_localVisibilityByLocation_subtitle": "Effectiveness of your Google visibility in different cities",
        "component_localVisibilityByLocation_informationTooltip_title": "Local visibility",
        "component_localVisibilityByLocation_informationTooltip_text":"<p>Site visibility for each of the geolocations set up (all types of results combined: SEO, Google Ads, Shopping, Google Business Profile, etc...).</p>",
        "component_localVisibilityByLocation_detailButton": "Detail your local visibility",

        "component_localVisibilityCompetitor_title": "Visibility by city",
        "component_localVisibilityCompetitor_subtitle": "Competitor visibility effectiveness on each location",
        "component_localVisibilityCompetitor_informationTooltip_title": "Visibility by city",
        "component_localVisibilityCompetitor_informationTooltip_text":"<p>Allows you to compare your site's visibility to that of other tracked sites in the various locations selected.</p>",
        "component_localVisibilityCompetitor_chartTooltip": "Breakdown of visibility between sites on ",

        "component_localVisibilityKeywordsPositions_title": "Positions in natural results by city",
        "component_localVisibilityKeywordsPositions_subtitle": "Local variations of our Google positions, depending on the geolocation of the web user",
        "component_localVisibilityKeywordsPositions_informationTooltip_title": "Position by city in natural results",
        "component_localVisibilityKeywordsPositions_informationTooltip_text":"<p>Allows you to see your site's position on each tracked keyword, by location, in natural results.</p>",
        "component_localVisibilityKeywordsPositions_table_header_keyword": "Keyword",
        "component_localVisibilityKeywordsPositions_table_header_city": "Position at",

        "component_localVisibilityMyBusinessKeywordsPositions_title": "Google Business Profile positions",
        "component_localVisibilityMyBusinessKeywordsPositions_subtitle": "Keywords where your Google Business Profile listing appears, and your site's ranking in the Local Map results block",
        "component_localVisibilityMyBusinessKeywordsPositions_informationTooltip_title": "Google Business Profile position",
        "component_localVisibilityMyBusinessKeywordsPositions_informationTooltip_text":"<p>Allows you to see your site's position on each tracked keyword, by locality, on Google Business Profile</p>",
        "component_localVisibilityMyBusinessKeywordsPositions_table_header_keyword": "Keyword",
        "component_localVisibilityMyBusinessKeywordsPositions_table_header_position": "Position",
        "component_localVisibilityMyBusinessKeywordsPositions_table_header_geoloc": "Geoloc",
        "component_localVisibilityMyBusinessKeywordsPositions_table_header_website": "Website",

        "component_localVisibilityLocalAdvertisingPresence_title": "Local advertising presence",
        "component_localVisibilityLocalAdvertisingPresence_subtitle": "Keywords where your ads appear, for the different localities analyzed",
        "component_localVisibilityLocalAdvertisingPresence_informationTooltip_title": "Local advertising presence",
        "component_localVisibilityLocalAdvertisingPresence_informationTooltip_text":"<p>Allows you to see your site's position on each keyword tracked, by locality, in Google Ads.</p>",
        "component_localVisibilityLocalAdvertisingPresence_table_header_keyword":"Keyword",
        "component_localVisibilityLocalAdvertisingPresence_table_header_adwords": "Google Ads position",
        "component_localVisibilityLocalAdvertisingPresence_table_header_shopping": "Position Shopping",
        "component_localVisibilityLocalAdvertisingPresence_table_header_geoloc": "Geoloc",
        "component_localVisibilityLocalAdvertisingPresence_table_header_website": "Website",
    },
}

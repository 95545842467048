

export default {
    "fr": {
        "view_reportingConfiguration_headerText": "<p class=\"my-1\"><strong>Rapports programmés figés :</strong></p><p class=\"my-0\">Cette fonctionnalité génère un rapport partageable via un <strong>lien qui ne change jamais</strong>.</p><p class=\"my-0\">Les données de ce rapport seront <strong>mises à jour selon la fréquence que vous choisissez</strong>. Les données intermédiaires entre 2 rapports programmés n'apparaitront pas.</p><p class=\"my-0\">Par exemple, si vous choisissez un rapport mensuel. Les données du rapport seront mises à jour le 1er de chaque mois. Les courbes et les calculs de variations se feront avec les données du mois précédent.</p>",

        "component_reportingTemplateList_placeholderTitle": "Ce modèle contient <strong>[*COMPONENT-COUNT*]</strong> indicateurs",
        "component_reportingTemplateList_usageLabel": "utilisé par les projets :",
        "component_reportingTemplateList_title": "Vos modèles de rapports",
        "component_reportingTemplateList_subtitle": "Créez ou modifiez les modèles de rapport que vous utilisez dans vos projets",
        "component_reportingTemplateList_inputSearch_placeholder": "Chercher un modèle dans ma liste",
        "component_reportingTemplateList_checkbox_favorite": "Mes favoris",
        "component_reportingTemplateList_checkbox_myModels": "Mes modèles",
        "component_reportingTemplateList_checkbox_yoodaModels": "Modèles Standards",
        "component_reportingTemplateList_btn_preview": "PREVISUALISER",
        "component_reportingTemplateList_label_notEditable": "Modèle non-personnalisable",
        "component_reportingTemplateList_label_editBtn": "Modifier ce modèle",
        "component_reportingTemplateList_btn_create": "CREER UN NOUVEAU MODELE",
        "component_reportingTemplateList_editConfirm_title": "Attention",
        "component_reportingTemplateList_editConfirm_text_single": "La modification du modèle entrainera une modification du rapport pour le projet suivant :",
        "component_reportingTemplateList_editConfirm_text": "La modification du modèle entrainera une modification du rapport pour les projets suivants :",
        "component_reportingTemplateList_editConfirm_btnOk": "Continuer",
        "component_reportingTemplateList_editConfirm_btnCancel": "annuler",

        "component_reportingProjectTemplateSelector_placeholderTitle": "Ce modèle contient <strong>[*COMPONENT-COUNT*]</strong> indicateurs",
        "component_reportingProjectTemplateSelector_templateName_simple": "Rapport simple",
        "component_reportingProjectTemplateSelector_templateName_seo_without_analytics": "Rapport SEO (sans analytics)",
        "component_reportingProjectTemplateSelector_templateName_seo_with_analytics": "Rapport SEO (avec analytics)",
        "component_reportingProjectTemplateSelector_templateName_sea_without_analytics": "Rapport SEA (sans analytics)",
        "component_reportingProjectTemplateSelector_templateName_sea_with_analytics": "Rapport SEA (avec analytics)",
        "component_reportingProjectTemplateSelector_templateName_full": "Complet",
        "component_reportingProjectTemplateSelector_title": "Choisissez le modèle de ce rapport",
        "component_reportingProjectTemplateSelector_subtitle": "Chaque modèle de rapport contient des indicateurs différents.",
        "component_reportingProjectTemplateSelector_selectModelLabel": "Modèle de rapport sélectionné",
        "component_reportingProjectTemplateSelector_modelSelectUsages": "projets l'utilisent",
        "component_reportingProjectTemplateSelector_usageLabel": "utilisé par les projets :",
        "component_reportingProjectTemplateSelector_btnPreview": "Previsualiser",
        "component_reportingProjectTemplateSelector_linkToTemplate": "Nouveau modèle de rapport",

        "component_headerReportModel_headerLabel": "Aperçu avec les données du ",
        "component_headerReportModel_preview_title" : "Rapport pour",
        "component_headerReportModel_scheduledReport_period_1": "Dashboard",
        "component_headerReportModel_scheduledReport_period_7": "Rapport Hebdomadaire",
        "component_headerReportModel_scheduledReport_period_30": "Rapport Mensuel",
        "component_headerReportModel_scheduledReport_period_365": "Rapport Annuel",

        "component_reportingLogoConfig_title": "Afficher mon logo dans ce rapport",
        "component_reportingLogoConfig_subtitle": "En activant cette option, votre logo s'affichera dans les rapports.",
        "component_reportingLogoConfig_switchLabel": "Ajouter mon logo à ce rapport",
        "component_reportingLogoConfig_changeLogoBtn": "Modifier le logo",
        "component_reportingLogoConfig_changeLogoInformation": "2 Mo maximum<br>Formats JPEG, PNG ou SVG<br>Le logo est identique pour tous les projets.",
        "component_reportingLogoConfig_errorSize": "Fichier trop lourd (2 Mo max)",
        "component_reportingLogoConfig_errorFormat": "Format de fichier non supporté",
        "component_reportingLogoConfig_errorNoLogo": "Ajoutez votre logo",
        "component_reportingLogoConfig_errorUnknown": "Erreur inconnue",
        "component_reportingLogoConfig_tooltip_title": "Ajouter mon logo à ce rapport",
        "component_reportingLogoConfig_tooltip_text": "<p class=\"my-0\">Ajoutez votre logo dans l'entête de vos rapports en activant cette option et en ajoutant un fichier image au format JPEG, PNG ou SVG.</p><p class=\"my-1\">L'activation de cette option est spécifique à ce projet. Si vous activez l'option ici, elle ne sera PAS activée dans les autres projets.</p><p class=\"my-0\">Ce logo est commun à tous vos projets. Si vous changez d'image ici, elle sera modifiée pour tous vos projets.</p><p class=\"my-1\">Réservé aux utilisateurs bénéficiant d'une <strong>licence ENTREPRISE</strong>.</p>",

        "component_reportingFrequencyConfig_scheduleConfigTitle": "Fréquence de mise à jour du rapport",
        "component_reportingFrequencyConfig_scheduleConfigSubtitle": "Choisissez la fréquence de mise à jour de ce rapport.  Les données intermédiaires entre 2 rapports programmés n'apparaîtront pas",
        "component_reportingFrequencyConfig_informationTooltip_title": "Programmer un rapport",
        "component_reportingFrequencyConfig_informationTooltip_text": "<p>Si vous choisissez un rapport mensuel. Les données du rapport seront mises à jour le 1er de chaque mois. Les courbes et les calculs de variations se feront avec les données du mois précédent.</p><p><strong>Le lien reste identique.</strong></p>",
        "component_reportingFrequencyConfig_scheduleConfigPeriodLabel": "fréquence sélectionnée",
        "component_reportingFrequencyConfig_scheduleConfigPeriodSummary": "Un rapport chaque [*PERIOD*], avec les données [*DATA*].<br/><br/>Le rapport actuellement en ligne est daté au [*DATE-CURRENT*]<br/> Prochain rapport le [*DATE*].",
        "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_period_1": "jour",
        "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_data_1": "de la veille",
        "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_period_7": "lundi",
        "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_data_7": "de la semaine écoulée",
        "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_period_30": "premier du mois",
        "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_data_30": "du mois écoulé",
        "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_period_365": "1er janvier",
        "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_data_365": "de l'année écoulée",

        "component_exportSetting_betaText": "<p class=\"my-1\"><strong>Fonctionnalité en version bêta</strong></p><p class=\"my-0\">Actuellement, les données sont uniquement exportées la nuit, pendant la mise à jour de vos données quotidiennes.</p><p class=\"my-0\">Vous devrez donc attendre demain avant de pouvoir exploiter le fichier exporté.</p>",
        "component_exportSetting_title": "<p>Export vers Google Sheet <span class='red--text caption' style='vertical-align: super'>BETA</span></p>",
        "component_exportSetting_subtitle": "Exportez les données brutes du projet vers Google Sheet",
        "component_exportSetting_switchUseExportLabel": "Activer l'export vers Google Sheet",
        "component_exportSetting_selectAccountLabel": "Exporter vers le drive de :",
        "component_exportSetting_selectFrequencyLabel": "Fréquence d'exportation",
        "component_exportSetting_scheduleConfigPeriodSummary": "Exporté vers la Google Sheet : <span class=\"primary--text font-bold\">[*ACCOUNT*] > YOODA One > [*PROJECT*]</span><br/><br/>Un export chaque [*PERIOD*], comparé avec le [*PERIOD-COMPARE*] précédent.<br/><br/>Le dernier export est daté au [*DATE-LAST-EXPORT*]<br/>Prochain export le [*DATE-NEXT-EXPORT*].",
        "component_exportSetting_scheduleConfigPeriodSelect_period_1": "Jour",
        "component_exportSetting_scheduleConfigPeriodSelect_period_7": "Lundi",
        "component_exportSetting_scheduleConfigPeriodSelect_period_30": "1er du mois",
        "component_exportSetting_scheduleConfigPeriodSelect_period_365": "1er jour de l'année",

        "component_reportingShareAndRegenerate_tooltip_title": "Domaine neutre",
        "component_reportingShareAndRegenerate_tooltip_text": "<p class=\"my-0\">Votre rapport peut-être affiché sur un nom de domaine sans marque reconnaissable : <strong>digitalreport.one</strong>.</p><p class=\"my-0\">Réservé aux utilisateurs bénéficiant d'une <strong>licence ENTREPRISE</strong>.</p>",

        "component_planningScheduledReporting_scheduleConfigModelName_seo_without_analytics": "SEO (sans Analytics)",
        "component_planningScheduledReporting_scheduleConfigModelName_seo_with_analytics": "SEO (avec Analytics)",
        "component_planningScheduledReporting_scheduleConfigModelName_sea_without_analytics": "SEA (sans Analytics)",
        "component_planningScheduledReporting_scheduleConfigModelName_sea_with_analytics": "SEA (avec Analytics)",
        "component_planningScheduledReporting_scheduleConfigModelName_simple": "Simple",
        "component_planningScheduledReporting_scheduleConfigModelName_full": "Complet",
        "component_planningScheduledReporting_title": "Liste des rapports",
        "component_planningScheduledReporting_tabTitle1": "Rapports générés",
        "component_planningScheduledReporting_tabTitle2": "Rapports à venir",
        "component_planningScheduledReporting_tab1_searchLabel": "Rechercher",
        "component_planningScheduledReporting_computedColumnDefProject": "Projet",
        "component_planningScheduledReporting_computedColumnDefModel": "Modèle",
        "component_planningScheduledReporting_computedColumnDefFrequency": "Fréquence",
        "component_planningScheduledReporting_computedColumnDefDate": "Dernier rapport",
        "component_planningScheduledReporting_computedColumnDefAction": "Voir",
        "component_planningScheduledReporting_tab2_searchLabel": "Rechercher",
        "component_planningScheduledReporting_scheduledColumnDefProject": "Projet",
        "component_planningScheduledReporting_scheduledColumnDefFrequency": "Fréquence",
        "component_planningScheduledReporting_scheduledColumnDefDate": "Prochain rapport",
    },
    "en": {
        "view_reportingConfiguration_headerText":"<p class=\"my-1\"><strong>Fixed Scheduled Reports:</strong></p><p class=\"my-0\">This feature generates a report that can be shared via a <strong>link that never changes</strong>.</p><p class=\"my-0\">The data in this report will <strong>update based on the frequency you choose</strong>. Intermediate data between 2 scheduled reports will not appear.</p><p class=\"my-0\">For example, if you choose a monthly report. The report data will be updated on the 1st of each month. The curves and variation calculations will be done with the previous month's data.</p>",

"component_reportingTemplateList_placeholderTitle": "This template contains <strong>[*COMPONENT-COUNT*]</strong> indicators",
    "component_reportingTemplateList_usageLabel": "used by projects:",
    "component_reportingTemplateList_title": "Your report templates",
    "component_reportingTemplateList_subtitle": "Create or edit the report templates you use in your projects",
    "component_reportingTemplateList_inputSearch_placeholder": "Search a template in my list",
    "component_reportingTemplateList_checkbox_favorite": "My favorites",
    "component_reportingTemplateList_checkbox_myModels": "My templates",
    "component_reportingTemplateList_checkbox_yoodaModels": "Standard templates",
    "component_reportingTemplateList_btn_preview": "PREVIEW",
    "component_reportingTemplateList_label_notEditable": "Non-customizable template",
    "component_reportingTemplateList_label_editBtn": "Edit this template",
    "component_reportingTemplateList_btn_create": "CREATE A NEW TEMPLATE",
    "component_reportingTemplateList_editConfirm_title": "Attention",
    "component_reportingTemplateList_editConfirm_text_single": "The modification of the template will result in a modification of the report for the following project:",
    "component_reportingTemplateList_editConfirm_text": "The modification of the template will lead to a modification of the report for the following projects:",
    "component_reportingTemplateList_editConfirm_btnOk": "Continue",
    "component_reportingTemplateList_editConfirm_btnCancel": "cancel",

    "component_reportingProjectTemplateSelector_placeholderTitle": "This template contains <strong>[*COMPONENT-COUNT*]</strong> indicators",
    "component_reportingProjectTemplateSelector_templateName_simple": "Simple report",
    "component_reportingProjectTemplateSelector_templateName_seo_without_analytics": "SEO report (without analytics)",
    "component_reportingProjectTemplateSelector_templateName_seo_with_analytics": "SEO Report (with analytics)",
    "component_reportingProjectTemplateSelector_templateName_sea_without_analytics": "SEA report (without analytics)",
    "component_reportingProjectTemplateSelector_templateName_sea_with_analytics": "SEA Report (with analytics)",
    "component_reportingProjectTemplateSelector_templateName_full": "Full",
    "component_reportingProjectTemplateSelector_title": "Select the template for this report",
    "component_reportingProjectTemplateSelector_subtitle": "Each report template contains different indicators.",
    "component_reportingProjectTemplateSelector_selectModelLabel": "Selected report template",
    "component_reportingProjectTemplateSelector_modelSelectUsages": "projects use it",
    "component_reportingProjectTemplateSelector_usageLabel": "used by projects:",
    "component_reportingProjectTemplateSelector_btnPreview": "preview",
    "component_reportingProjectTemplateSelector_linkToTemplate": "New report template",

    "component_headerReportModel_headerLabel": "Preview with data from",
    "component_headerReportModel_preview_title": "Report for",
    "component_headerReportModel_scheduledReport_period_1": "Dashboard",
    "component_headerReportModel_scheduledReport_period_7": "Weekly Report",
    "component_headerReportModel_scheduledReport_period_30": "Monthly Report",
    "component_headerReportModel_scheduledReport_period_365": "Annual Report",

    "component_reportingLogoConfig_title": "Display my logo in this report",
    "component_reportingLogoConfig_subtitle": "Enabling this option will display your logo in the reports.",
    "component_reportingLogoConfig_switchLabel": "Add my logo to this report",
    "component_reportingLogoConfig_changeLogoBtn": "Change the logo",
    "component_reportingLogoConfig_changeLogoInformation": "2 MB maximum<br>JPEG, PNG or SVG formats<br>The logo is the same for all projects.",
"component_reportingLogoConfig_errorSize": "File too large (2 MB max)",
    "component_reportingLogoConfig_errorFormat": "File format not supported",
    "component_reportingLogoConfig_errorNoLogo": "Add your logo",
    "component_reportingLogoConfig_errorUnknown": "Unknown error",
    "component_reportingLogoConfig_tooltip_title": "Add my logo to this report",
    "component_reportingLogoConfig_tooltip_text":"<p class=\"my-0\">Add your logo to the header of your reports by enabling this option and adding an image file in JPEG, PNG or SVG format.</p><p class=\"my-1\">Enabling this option is specific to this project. If you enable the option here, it will NOT be enabled in other projects.</p><p class=\"my-0\">This logo is common to all of your projects. If you change the image here, it will be changed for all of your projects.</p><p class=\"my-1\">Only available to users with a <strong>ENTERPRISE license</strong>.</p>",

"component_reportingFrequencyConfig_scheduleConfigTitle": "Report update frequency",
    "component_reportingFrequencyConfig_scheduleConfigSubtitle": "Choose how often this report is updated.  Intermediate data between 2 scheduled reports will not appear.",
"component_reportingFrequencyConfig_informationTooltip_title": "Schedule a report",
    "component_reportingFrequencyConfig_informationTooltip_text":"<p>If you choose a monthly report. The report data will be updated on the 1st of each month. The curves and variation calculations will be done with the previous month's data.</p><p><strong>The link remains the same.</strong></p>",
"component_reportingFrequencyConfig_scheduleConfigPeriodLabel": "selected frequency",
    "component_reportingFrequencyConfig_scheduleConfigPeriodSummary": "One report each [*PERIOD*], with [*DATA*] data.<br/><br/>The report currently online is dated [*DATE-CURRENT*]<br/> Next report on [*DATE*].",
    "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_period_1": "day",
    "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_data_1": "from the day before",
    "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_period_7": "Monday",
    "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_data_7": "from the past week",
    "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_period_30": "first of the month",
    "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_data_30": "of the past month",
    "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_period_365": "January 1st",
    "component_reportingFrequencyConfig_scheduleConfigPeriodSummary_data_365": "of the past year",

    "component_exportSetting_betaText": "<p class=\"my-1\"><strong>Feature in beta version</strong></p><p class=\"my-0\">Currently, the data is only exported at night, while your daily data is being updated.</p><p class=\"my-0\">So you will have to wait until tomorrow before you can exploit the exported file.</p>",
"component_exportSetting_title":"<p>Export to Google Sheet <span class='red--text caption' style='vertical-align: super'>BETA</span></p>",
"component_exportSetting_subtitle": "Export raw project data to Google Sheet",
    "component_exportSetting_switchUseExportLabel": "Enable export to Google Sheet",
    "component_exportSetting_selectAccountLabel": "Export to the drive of:",
    "component_exportSetting_selectFrequencyLabel": "Export frequency",
    "component_exportSetting_scheduleConfigPeriodSummary": "Exported to Google Sheet: <span class=\"primary--text font-bold\">[*ACCOUNT*] > YOODA One > [*PROJECT*]</span><br/><br/>An export every [*PERIOD*], compared with the previous [*PERIOD-COMPARE*]. <br/><br/>Last export is dated at [*DATE-LAST-EXPORT*]<br/>Next export on [*DATE-NEXT-EXPORT*].",
"component_exportSetting_scheduleConfigPeriodSelect_period_1": "Day",
    "component_exportSetting_scheduleConfigPeriodSelect_period_7": "Monday",
    "component_exportSetting_scheduleConfigPeriodSelect_period_30": "1st of the month",
    "component_exportSetting_scheduleConfigPeriodSelect_period_365": "1st day of the year",

    "component_reportingShareAndRegenerate_tooltip_title": "Domain neutral",
    "component_reportingShareAndRegenerate_tooltip_text":"<p class=\"my-0\">Your report can be displayed on an unrecognizable domain name: <strong>digitalreport.one</strong>.</p><p class=\"my-0\">Only available to users with a <strong>EnTERPRISE license</strong>.</p>",

"component_planningScheduledReporting_scheduleConfigModelName_seo_without_analytics": "SEO (without Analytics)",
    "component_planningScheduledReporting_scheduleConfigModelName_seo_with_analytics": "SEO (with Analytics)",
    "component_planningScheduledReporting_scheduleConfigModelName_sea_without_analytics": "SEA (without Analytics)",
    "component_planningScheduledReporting_scheduleConfigModelName_sea_with_analytics": "SEA (with Analytics)",
    "component_planningScheduledReporting_scheduleConfigModelName_simple": "Simple",
    "component_planningScheduledReporting_scheduleConfigModelName_full": "Complete",
    "component_planningScheduledReporting_title": "Report List",
    "component_planningScheduledReporting_tabTitle1": "Generated reports",
    "component_planningScheduledReporting_tabTitle2": "Upcoming reports",
    "component_planningScheduledReporting_tab1_searchLabel": "Search",
    "component_planningScheduledReporting_computedColumnDefProject": "Project",
    "component_planningScheduledReporting_computedColumnDefModel": "Model",
    "component_planningScheduledReporting_computedColumnDefFrequency": "Frequency",
    "component_planningScheduledReporting_computedColumnDefDate": "Last Report",
    "component_planningScheduledReporting_computedColumnDefAction": "View",
    "component_planningScheduledReporting_tab2_searchLabel": "Search",
    "component_planningScheduledReporting_scheduledColumnDefProject": "Project",
    "component_planningScheduledReporting_scheduledColumnDefFrequency": "Frequency",
    "component_planningScheduledReporting_scheduledColumnDefDate": "Next Report",
    },
}

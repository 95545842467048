<template>
    <v-menu nudge-bottom="40" left class="information-tooltip" content-class="v-menu__content-tooltip" :close-on-content-click="false" style="width: 100%" :max-width="this.tooltipWidth" z-index="100000" transition="scale-transition" origin="top right">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" :ripple="false" class="vbtn-for-yooda-icon" style="margin:0 10px; padding: 0; min-width: 40px !important">
                <yooda-icon name="info-circle" :style="iconStyle"></yooda-icon>
            </v-btn>
        </template>
            <h6 v-html="this.title" class="py-2 mb-5"></h6>
            <div v-html="this.text"></div>
    </v-menu>
</template>


<script>
import YoodaIcon from "@/components/yooda-components/YoodaIcon";

export default {
    name: 'InformationTooltip',
    props: {
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        iconColor: {
            type: String,
            default: 'greyDark'
        },
        iconSize: {
            type: Number,
            default: 24
        },
        tooltipWidth:{
            type: Number,
            default: 440
        },
    },
    data:() => ({
    }),
    computed: {
        iconStyle(){
            let color = this.iconColor
            if(this.iconColor !== 'inherit'){
                color = this.getThemeColorByName(this.iconColor)
            }
            return {
                fontSize: this.iconSize+'px',
                color: color,
            }
        },

    },
    components: {
        YoodaIcon
    },
}
</script>

<style lang="scss" >

.v-menu__content {
    background-color: #fff !important;
    opacity: 1 !important;
    text-align: justify;
    padding: 8px 12px !important;
    margin-right: 24px;
    min-width: 260px;
    &tooltip {
        width: 100%;
    }
    h6{
        border-bottom: 1px solid #dedede;
        padding-bottom: 5px;
        margin-bottom: 10px;
        text-align: center;
    }
    p{
        color: #2c2c2c !important;
    }

}
.vbtn-for-yooda-icon{
    &.v-btn {
        background-color: transparent !important;
        &::before {
            display: none;
        }
    }

    &.v-btn--is-elevated {
        box-shadow: none !important;
    }
}

</style>

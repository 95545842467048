

export default {
    "fr": {
        "view_firstProject_wizardMessage_title": "Paramétrons votre analyse",
        "view_firstProject_wizardMessage_welcome": "Bienvenue sur Yooda One !<br>^200Je suis Maître Yooda et je vais vous guider dans le paramétrage de votre analyse.",
        "view_firstProject_wizardMessage_firstQuestion": "Commençons ! <br><strong>^200Quels types de produits ou services vendez-vous ? </strong><i>(jusqu'à 5 réponses)</i><br>^200Cela me permettra de présélectionner des mots-clés, identifier vos segments de marché et vos concurrents. Vous pourrez les affiner par la suite !",
        "view_firstProject_wizardMessage_secondQuestion": "<strong>Quels types de produits ou services vendez-vous ?</strong>  (jusqu'à 5 réponses) <br>^200Cela me permettra de présélectionner des mots-clés, identifier vos segments de marché et concurrents. Vous pourrez les affiner par la suite !",
        "view_firstProject_wizardMessage_product_placeholder": "type de produits/services",
        "view_firstProject_wizardMessage_secondQuestion_valid": "C'est tout",
        "view_firstProject_wizardMessage_thirdQuestion": "<strong>Quelle est l'adresse de votre site web ?</strong><br>^200Cela me permettra de vérifier vos positions dans Google et de les comparer à celles de vos concurrents.",
        "view_firstProject_wizardMessage_website_placeholder": "monsite.com",
        "view_firstProject_wizardMessage_fourthQuestion": "<strong>Quel est le concurrent que vous suivez le plus ? </strong><br>^200Je sais que c'est important pour vous. Ce concurrent sera signalé dans les classements.",
        "view_firstProject_wizardMessage_competitor_placeholder": "concurrent.com",
        "view_firstProject_wizardMessage_competitor_skipLabel": "Je ne connais pas de concurrent",
        "view_firstProject_wizardMessage_competitor_skipLink": "Passer cette étape",
        "view_firstProject_wizardMessage_fifthQuestion": "<strong>Dans quel pays vendez-vous majoritairement ?</strong> (1 choix possible)<br>^200Pour la clarté des analyses, nous ne mélangerons pas les langues et les pays.",
        "view_firstProject_wizardMessage_selectLanguage": "Dans quel langue ?",
        "view_firstProject_wizardMessage_sixQuestion": "<strong>Souhaitez-vous connecter votre compte Analytics ?</strong><br>^200Vous pourrez identifier facilement les canaux d'acquisition et les contenus qui génèrent le plus de trafic, de conversions et de CA.<br>^200Ces données sont en sécurité dans une base de données isolée auxquelles vous seul avez accès.",
        "view_firstProject_wizardMessage_confirmCreation": "<strong>J'ai tout ce qu'il me faut. C'est parti !</strong><br>^200Il me faut un peu de temps pour créer votre compte, et pour collecter et traiter toutes les données.<br>^200Environ 5 minutes pour tout faire.",
        "view_firstProject_wizardMessage_confirmCreation_btn": "Créer mon projet",
        "view_firstProject_wizardMessage_remaining_0": "Environ 2 minutes",
        "view_firstProject_wizardMessage_remaining_20": "Environ 1 minute 40",
        "view_firstProject_wizardMessage_remaining_40": "Environ 1 minute 10",
        "view_firstProject_wizardMessage_remaining_60": "Environ 45 secondes",
        "view_firstProject_wizardMessage_remaining_80": "Environ 25 secondes",
        "view_firstProject_wizardMessage_remaining_100": "Terminé",
        "view_firstProject_waitingCompute_title": "Configuration et collecte des données",
        "view_firstProject_waitingCompute_step": "étape",
        "view_firstProject_waitingCompute_step1": "Création de votre compte",
        "view_firstProject_waitingCompute_step2": "Création de votre serveur de données",
        "view_firstProject_waitingCompute_step3": "Recherche des mots-clés pertinents",
        "view_firstProject_waitingCompute_step4": "Analyse de vos segments de marché",
        "view_firstProject_waitingCompute_step5": "Recherche de concurrents",
        "view_firstProject_waitingCompute_step6": "Vérification de vos positions dans Google",
        "view_firstProject_waitingCompute_step7": "Identification des opportunités",
        "view_firstProject_waitingCompute_remaining_1": "Environ 5 minutes",
        "view_firstProject_waitingCompute_remaining_2": "Environ 4 minutes 10",
        "view_firstProject_waitingCompute_remaining_3": "Environ 3 minutes 20",
        "view_firstProject_waitingCompute_remaining_4": "Environ 2 minutes 30",
        "view_firstProject_waitingCompute_remaining_5": "Environ 1 minutes 40",
        "view_firstProject_waitingCompute_remaining_6": "Environ 1 minute",
        "view_firstProject_waitingCompute_remaining_7": "Environ 30 secondes",
        "view_firstProject_waitingCompute_advice": "Pour patienter, je vais vous conseiller sur <strong>comment bien utiliser vos premières minutes sur Yooda One.</strong>",
        "view_firstProject_wizard_validationError_products": "Veuillez saisir au moins deux produits/services",
        "view_firstProject_wizard_validationError_website": "Veuillez saisir une url valide",
        "view_firstProject_wizard_validationError_rival": "Veuillez saisir une url valide différente de l'url de votre site web",
        "view_firstProject_wizard_validationError_language": "Veuillez sélectionner une langue",

        "component_wizardConnectedAnalytics_connect_analytics": "Connecter Analytics",
        "component_wizardConnectedAnalytics_connect_matomo": "Connecter Matomo",
        "component_wizardConnectedAnalytics_skip_analytics": "Non merci, je verrai ça plus tard !",
        "component_wizardConnectedAnalytics_selectAnalyticsAccount": "Propriété Analytics",
        "component_wizardConnectedAnalytics_errorNoSelectAnalyticsAccount": "Veuillez séléctionner une propriété",
        "component_wizardConnectedAnalytics_matomoUrl": "Url du server",
        "component_wizardConnectedAnalytics_matomoUrlLabel": "Url du server Matomo",
        "component_wizardConnectedAnalytics_matomoToken": "Token d'authentification",
        "component_wizardConnectedAnalytics_matomoTokenLabel": "Jeton d'authentification (token_auth)",
        "component_wizardConnectedAnalytics_matomoId": "ID du site",
        "component_wizardConnectedAnalytics_matomoSave": "Enregistrer",
        "component_wizardConnectedAnalytics_matomoHelpToken": "Où trouver le token ?",
        "component_wizardConnectedAnalytics_matomoHelpId": "Où trouver l'ID ?",
        "component_wizardConnectedAnalytics_matomoErrorUrl": "Adresse invalide",
        "component_wizardConnectedAnalytics_matomoErrorToken": "Token invalide",
        "component_wizardConnectedAnalytics_matomoErrorId": "Id Site invalide",

        "component_wizardCarousel_waitingCompute_1_title": "1. Evaluez votre position sur le marché",
        "component_wizardCarousel_waitingCompute_1_text": "Commencez par faire un bilan de votre visibilité Google.<br/>Yooda One mesure votre visibilité SEO & Google Ads, mais aussi Google Shopping, Google Business Profile, Actualité et images.",
        "component_wizardCarousel_waitingCompute_2_title": "2. Gardez un oeil sur la concurrence, et inspirez vous !",
        "component_wizardCarousel_waitingCompute_2_text": "Analysez la stratégie de marché vos concurrents à travers les mots-clés qu'ils ciblent, leurs pages qu'ils mettent en avant, et leurs annonces Google Ads ou Shopping.",
        "component_wizardCarousel_waitingCompute_3_title": "3. Identifiez vos priorités d'action",
        "component_wizardCarousel_waitingCompute_3_text": "Obtenez une liste de priorités d'action pour augmenter votre trafic.<br/>Yooda One vous aide à prendre de meilleures décisions et à piloter votre stratégie  avec plus de certitude.",
        "component_wizardCarousel_waitingCompute_4_title": "4. Passez à l'action",
        "component_wizardCarousel_waitingCompute_4_text": "Identifiez les mots-clés et les pages à optimiser, et appuyez vos actions sur un maximum des données complètes et précises.",
    },
    "en": {
        "view_firstProject_wizardMessage_title": "Let's set up your analysis",
        "view_firstProject_wizardMessage_welcome": "Welcome to Yooda One!<br>^200I am Master Yooda and I will guide you through setting up your analysis.",
        "view_firstProject_wizardMessage_firstQuestion": "Let's get started! <br><strong>^200What types of products or services do you sell? </strong><i>(up to 5 answers)</i><br>^200This will allow me to pre-select keywords, identify your market segments and competitors. You can refine them later!",
        "view_firstProject_wizardMessage_secondQuestion":"<strong>What types of products or services do you sell?</strong> (up to 5 answers) <br>^200This will allow me to pre-select keywords, identify your market segments and competitors. You can refine them later!",
        "view_firstProject_wizardMessage_product_placeholder": "product/service type",
        "view_firstProject_wizardMessage_secondQuestion_valid": "That's it",
        "view_firstProject_wizardMessage_thirdQuestion":"<strong>What is your website address?</strong><br>^200This will allow me to check your positions in Google and compare them to your competitors.",
        "view_firstProject_wizardMessage_website_placeholder": "monsite.com",
        "view_firstProject_wizardMessage_fourthQuestion":"<strong>What competitor do you follow the most? </strong><br>^200I know this is important to you. This competitor will be reported in the rankings.",
        "view_firstProject_wizardMessage_competitor_placeholder": "competitor.com",
        "view_firstProject_wizardMessage_competitor_skipLabel": "I don't know any competitor",
        "view_firstProject_wizardMessage_competitor_skipLink": "Skip this step",
        "view_firstProject_wizardMessage_fifthQuestion":"<strong>In which country do you sell mostly?</strong> (1 possible choice)<br>^200For clarity of analysis, we will not mix languages and countries.",
        "view_firstProject_wizardMessage_selectLanguage": "In which language?",
        "view_firstProject_wizardMessage_sixQuestion":"<strong>Do you want to connect your Analytics account?</strong><br>^200You will be able to easily identify the acquisition channels and content that generate the most traffic, conversions, and CA.<br>^200This data is securely stored in a secluded database that only you have access to.",
        "view_firstProject_wizardMessage_confirmCreation":"<strong>I have everything I need. Here we go!</strong><br>^200I need a little time to create your account, and to collect and process all the data.<br>^200About 5 minutes to do everything.",
        "view_firstProject_wizardMessage_confirmCreation_btn": "Create my project",
        "view_firstProject_wizardMessage_remaining_0": "About 2 minutes",
        "view_firstProject_wizardMessage_remaining_20": "About 1 minute 40",
        "view_firstProject_wizardMessage_remaining_40": "About 1 minute 10",
        "view_firstProject_wizardMessage_remaining_60": "About 45 seconds",
        "view_firstProject_wizardMessage_remaining_80": "About 25 seconds",
        "view_firstProject_wizardMessage_remaining_100": "Completed",
        "view_firstProject_waitingCompute_title": "Configuration and data collection",
        "view_firstProject_waitingCompute_step": "step",
        "view_firstProject_waitingCompute_step1": "Creating your account",
        "view_firstProject_waitingCompute_step2": "Creating your data server",
        "view_firstProject_waitingCompute_step3": "Search for relevant keywords",
        "view_firstProject_waitingCompute_step4": "Analysis of your market segments",
        "view_firstProject_waitingCompute_step5": "Research of competitors",
        "view_firstProject_waitingCompute_step6": "Checking your positions in Google",
        "view_firstProject_waitingCompute_step7": "Identification of opportunities",
        "view_firstProject_waitingCompute_remaining_1": "About 5 minutes",
        "view_firstProject_waitingCompute_remaining_2": "About 4 minutes 10",
        "view_firstProject_waitingCompute_remaining_3": "About 3 minutes 20",
        "view_firstProject_waitingCompute_remaining_4": "About 2 minutes 30",
        "view_firstProject_waitingCompute_remaining_5": "About 1 minute 40",
        "view_firstProject_waitingCompute_remaining_6": "About 1 minute",
        "view_firstProject_waitingCompute_remaining_7": "About 30 seconds",
        "view_firstProject_waitingCompute_advice": "To hold off, I'll advise you on <strong>how to properly use your first few minutes on Yooda One.</strong>",
        "view_firstProject_wizard_validationError_products": "Please enter at least two products/services",
        "view_firstProject_wizard_validationError_website": "Please enter a valid url",
        "view_firstProject_wizard_validationError_rival": "Please enter a valid url different from the url of your website",
        "view_firstProject_wizard_validationError_language": "Please select a language",

        "component_wizardConnectedAnalytics_connect_analytics": "Connect Analytics",
        "component_wizardConnectedAnalytics_connect_matomo": "Connect Matomo",
        "component_wizardConnectedAnalytics_skip_analytics": "No thanks, I'll see about that later!",
        "component_wizardConnectedAnalytics_selectAnalyticsAccount": "Analytics property",
        "component_wizardConnectedAnalytics_errorNoSelectAnalyticsAccount": "Please select a property",
        "component_wizardConnectedAnalytics_matomoUrl": "Server Url",
        "component_wizardConnectedAnalytics_matomoUrlLabel": "Matomo server Url",
        "component_wizardConnectedAnalytics_matomoToken": "Authentication Token",
        "component_wizardConnectedAnalytics_matomoTokenLabel": "Authentication Token (token_auth)",
        "component_wizardConnectedAnalytics_matomoId": "Website ID",
        "component_wizardConnectedAnalytics_matomoSave": "Save",
        "component_wizardConnectedAnalytics_matomoHelpToken": "Where to find the token ?",
        "component_wizardConnectedAnalytics_matomoHelpId": "Where to find the ID ?",
        "component_wizardConnectedAnalytics_matomoErrorUrl": "Invalid url",
        "component_wizardConnectedAnalytics_matomoErrorToken": "Invalid Token",
        "component_wizardConnectedAnalytics_matomoErrorId": "Invalid ID",

        "component_wizardCarousel_waitingCompute_1_title": "1. Evaluate your market position",
        "component_wizardCarousel_waitingCompute_1_text": "Start by taking stock of your Google visibility.<br/>Yooda One measures your SEO & Google Ads visibility, but also Google Shopping, Google Business Profile, News and images.",
        "component_wizardCarousel_waitingCompute_2_title": "2. Keep an eye on the competition, and get inspired!",
        "component_wizardCarousel_waitingCompute_2_text": "Analyze the market strategy of your competitors through the keywords they target, their pages they promote, and their Google Ads or Shopping ads.",
        "component_wizardCarousel_waitingCompute_3_title": "3. Identify your priorities for action",
        "component_wizardCarousel_waitingCompute_3_text": "Get a list of action priorities to increase your traffic.<br/>Yooda One helps you make better decisions and drive your strategy with more certainty.",
        "component_wizardCarousel_waitingCompute_4_title": "4. Take action.",
        "component_wizardCarousel_waitingCompute_4_text": "Identify the keywords and pages to optimize, and base your actions on a maximum of complete and accurate data."
    },
}



export default {
    "fr": {
        "component_reportingShareAndRegenerate_title": "Partagez le rapport de ",
        "component_reportingShareAndRegenerate_subtitle": "Partagez le lien ci-dessous avec vos clients ou collègues pour leur donner accès à une sélection d'indicateurs mis à jour selon la fréquence choisie. Le lien ne change pas lors de la génération d'un rapport, seules les données sont mises à jour.",
        "component_reportingShareAndRegenerate_linkLabel": "Lien permanent à partager",
        "component_reportingShareAndRegenerate_copyBtn": "Copier le lien",
        "component_reportingShareAndRegenerate_generateLink": "Regénérer le lien",
        "component_reportingShareAndRegenerate_generateLink_confirmTitle": "Confirmation",
        "component_reportingShareAndRegenerate_generateLink_confirmText": "Attention, le rapport ne sera plus accessible avec l'ancien lien.",
        "component_reportingShareAndRegenerate_whiteMark_title": "Domaine neutre",
        "component_reportingShareAndRegenerate_whiteMark_switchLabel": "Héberger le rapport sur digitalreport.one",


        "template_customTitle_modelFull_dashboard": "Tableau de bord",
        "template_customTitle_modelFull_marketSegment": "Segment de marché",
        "template_customTitle_modelFull_competition": "Concurrence",
        "template_customTitle_modelFull_seo": "SEO",
        "template_customTitle_modelFull_advertising": "Publicité",
        "template_customTitle_modelFull_website": "Pages du site",

    },
    "en": {
        "component_reportingShareAndRegenerate_title": "Share the report from",
        "component_reportingShareAndRegenerate_subtitle": "Share the link below with your clients or colleagues to give them access to a selection of indicators updated according to the chosen frequency. The link does not change when a report is generated, only the data is updated.",
        "component_reportingShareAndRegenerate_linkLabel": "Permanent link to share",
        "component_reportingShareAndRegenerate_copyBtn": "Copy link",
        "component_reportingShareAndRegenerate_generateLink": "Regenerate link",
        "component_reportingShareAndRegenerate_generateLink_confirmTitle": "Confirmation",
        "component_reportingShareAndRegenerate_generateLink_confirmText": "Warning, the report will no longer be accessible with the old link.",
        "component_reportingShareAndRegenerate_whiteMark_title": "Neutral Domain",
        "component_reportingShareAndRegenerate_whiteMark_switchLabel": "Host the report on digitalreport.one",


        "template_customTitle_modelFull_dashboard": "Dashboard",
        "template_customTitle_modelFull_marketSegment": "Market Segments",
        "template_customTitle_modelFull_competition": "Competition",
        "template_customTitle_modelFull_seo": "SEO",
        "template_customTitle_modelFull_advertising": "Advertising",
        "template_customTitle_modelFull_website": "Website pages",
    },
}

<template>
    <div id="app" data-app class="v-application--is-ltr" :class="customClasses" >
        <router-view @setAppClasses="setAppClasses" />
    </div>
</template>

<script>


export default {
    data() {
        return {
            customClasses: [],
        }
    },

    methods: {
        setAppClasses(classesStr){
            this.customClasses.push(classesStr)
        }
    },
    beforeMount() {
        document.documentElement.setAttribute("dir", "ltr")
    },
}

</script>

<style lang="scss">
#app {
    position: relative;
}
</style>

const getters = {

    //
    // getSubscriptionMeta : state => state.subscriptionMeta,
    //
    //
    //
    // getAnalyticsViewConfiguration : state => state.projectInfo.data.analyticsViewConfiguration,
    // getRivalsConfiguration : state => state.projectInfo.data.rivals,
    // getKeywordGroupsConfiguration : state => state.projectInfo.data.keywordGroups,
    // getSearchGeolocationsConfiguration : state => state.projectInfo.data.searchGeolocations,
    // getMarketSegmentConfiguration : state => state.projectInfo.data.keywordGroups,
    // getDefaultSearchGeolocation : state => state.projectInfo.data.defaultSearchGeolocation,
    // getProjectUrl : state => state.projectInfo.data.url,
    // getProjectReports : state => state.projectInfo.data.reports,
    // getProjectInfoKeywords : state => state.projectInfo.data.keywords,
    //
    // getGlobalFilterFrequency : state => state.globalFilterFrequency,
    // getGlobalFilterLocation : state => state.globalFilterLocation,

}

export default getters

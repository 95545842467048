

export default {
    "fr": {
        "component_competitionTopCompetitor_title": "Analyser ma concurrence",
        "component_competitionTopCompetitor_subtitle": "Comparaison de la visibilité des concurrents suivis",
        "component_competitionTopCompetitor_informationTooltip_title": "Analyser ma concurrence",
        "component_competitionTopCompetitor_informationTooltip_text": "<p>Top 5 des concurrents suivis classés par visibilité sur Google (tous types de résultats confondus : SEO, Google Ads, Shopping, Google Business Profile, etc...).</p><p><br>Visibilité calculée par rapport aux mots-clés du projet.</p>",
        "component_competitionTopCompetitor_detailButton": "Analyser la concurrence",
        "component_competitionTopCompetitor_noDataMessage": "Aucuns concurrent à analyser",

        "component_competitionVisibilityEvolution_title": "Visibilité des concurrents suivis",
        "component_competitionVisibilityEvolution_subtitle": "Visibilité générée par les concurrents via les différents canaux d'acquisition",
        "component_competitionVisibilityEvolution_informationTooltip_title": "Visibilité des concurrents suivis",
        "component_competitionVisibilityEvolution_informationTooltip_text": "<p>Comparez l'évolution de la visibilité de votre site et celles des concurrents suivis pour chaque localité, segment de marché et type de résultats Google.</p>",
        "component_competitionVisibilityEvolution_series_label": "Visibilité",

        "component_competitionBestProgression_title": "Plus fortes progressions",
        "component_competitionBestProgression_subtitle": "Concurrents qui ont le plus progressé",
        "component_competitionBestProgression_informationTooltip_title": "Plus fortes progressions",
        "component_competitionBestProgression_informationTooltip_text": "<p>Identifiez les sites dont la visibilité a le plus progressé sur la période, tous types de résultats Google confondus (SEO, Google Ads, Google Business Profile, ...).</p>",
        "component_competitionBestProgression_chartTooltipLabel": "places gagnées",

        "component_competitionNewCompetitor_title": "Nouveaux concurrents",
        "component_competitionNewCompetitor_subtitle": "Concurrents détectés récemment",
        "component_competitionNewCompetitor_informationTooltip_title": "Nouveaux concurrents",
        "component_competitionNewCompetitor_informationTooltip_text": "<p>Détectez l'apparition de nouveaux acteurs sur le marché, tous types de résultats Google confondus (SEO, Google Ads, Google Business Profile, ...).</p>",
        "component_competitionNewCompetitor_noDataMessage": "Pas de nouveau concurrent détecté récemment",

        "component_competitionCompetitorCompare_title": "Comparer les concurrents",
        "component_competitionCompetitorCompare_subtitle": "Comparez les performances de vos concurrents",
        "component_competitionCompetitorCompare_informationTooltip_title": "Comparer les concurrents",
        "component_competitionCompetitorCompare_informationTooltip_text": "<p>Comparez la visibilité de votre site et celles des concurrents suivis par localité, segments de marché et type de résultats Google.</p>",

        "component_competitionMarketLeader_title": "Leaders du marché",
        "component_competitionMarketLeader_subtitle": "Classement des concurrents les plus performants (tous concurrents, suivis et non suivis)",
        "component_competitionMarketLeader_informationTooltip_title": "Leaders du marché",
        "component_competitionMarketLeader_informationTooltip_text": "<p>Identifiez les sites les plus visibles  sur le marché (tous types de résultats Google confondus SEO, Google Ads, Google Business Profile, ...) ainsi que les mots-clés sur lesquels ils se positionnent.</p>",
        "component_competitionMarketLeader_table_header_rank": "Classement",
        "component_competitionMarketLeader_table_header_rankEvolution": "Evolution du classement",
        "component_competitionMarketLeader_table_header_website": "Site",
        "component_competitionMarketLeader_table_header_evolution": "Evolution de la visibilité",
        "component_competitionMarketLeader_table_header_traffic": "Trafic estimé",
        "component_competitionMarketLeader_table_header_keywordCount": "Nb Mots-clés",
        "component_competitionMarketLeader_table_header_pagesCount": "Nb de pages positionnées",

        "component_competitionMarketLeaderVisibilityEvolution_title": "Evolution de la visibilité du concurrent :",
        "component_competitionMarketLeaderVisibilityEvolution_subtitle": "Evolution de la visibilité du site sur Google (tous types de résultats confondus : SEO, Google Ads, Shopping, Google Business Profile, etc...) pour les mots-clés du projet.",
        "component_competitionMarketLeaderVisibilityEvolution_label": "Score de visibilité",

        "component_competitionMarketLeaderKeywords_title": "Mots-clés du concurrent :",
        "component_competitionMarketLeaderKeywords_subtitle": "Liste les mots-clés sur lesquels le concurrent est positionné dans les différents types de résultats Google.",
        "component_competitionMarketLeaderKeywords_table_header_keyword": "Mot-clé",
        "component_competitionMarketLeaderKeywords_table_header_seo": "SEO",
        "component_competitionMarketLeaderKeywords_table_header_image": "Image",
        "component_competitionMarketLeaderKeywords_table_header_map": "Google Business Profile",
        "component_competitionMarketLeaderKeywords_table_header_zero": "Position 0",
        "component_competitionMarketLeaderKeywords_table_header_video": "Vidéo",
        "component_competitionMarketLeaderKeywords_table_header_adwords": "Google Ads",
        "component_competitionMarketLeaderKeywords_table_header_shopping": "Shopping",

        "component_competitionMarketLeaderPages_title": "Meilleures pages du concurrent :",
        "component_competitionMarketLeaderPages_subtitle": "Classe les pages du concurrent selon leur visibilité dans les différents types de résultats Google sur les mots-clés du projet.",
        "component_competitionMarketLeaderPages_table_header_rank": "Classement",
        "component_competitionMarketLeaderPages_table_header_page": "Page",
        "component_competitionMarketLeaderPages_table_header_estimatedTraffic": "Trafic estimé",
        "component_competitionMarketLeaderPages_table_header_performance": "Performance",
        "component_competitionMarketLeaderPages_table_header_keywordCount": "Nb MC",
    },
    "en": {
        "component_competitionTopCompetitor_title": "Analyze my competition",
        "component_competitionTopCompetitor_subtitle": "Comparing the visibility of tracked competitors",
        "component_competitionTopCompetitor_informationTooltip_title": "Analyze my competition",
        "component_competitionTopCompetitor_informationTooltip_text":"<p>Top 5 tracked competitors ranked by visibility on Google (all types of results combined: SEO, Google Ads, Shopping, Google Business Profile, etc...).</p><p><br>Visibility calculated in relation to project keywords.</p>",
        "component_competitionTopCompetitor_detailButton": "Analyze the competition",
        "component_competitionTopCompetitor_noDataMessage": "No competitors to analyze",

        "component_competitionVisibilityEvolution_title": "Visibility of tracked competitors",
        "component_competitionVisibilityEvolution_subtitle": "Visibility generated by competitors via different acquisition channels",
        "component_competitionVisibilityEvolution_informationTooltip_title": "Visibility of tracked competitors",
        "component_competitionVisibilityEvolution_informationTooltip_text":"<p>Compare the visibility evolution of your site and that of tracked competitors for each locality, market segment and Google result type.</p>",
        "component_competitionVisibilityEvolution_series_label": "Visibility",

        "component_competitionBestProgression_title": "Strongest Progressions",
        "component_competitionBestProgression_subtitle": "Competitors who have progressed the most",
        "component_competitionBestProgression_informationTooltip_title": "Highest Progression",
        "component_competitionBestProgression_informationTooltip_text":"<p>Identify the sites whose visibility has increased the most over the period, all types of Google results combined (SEO, Google Ads, Google Business Profile, ...).</p>",
        "component_competitionBestProgression_chartTooltipLabel": "places gained",

        "component_competitionNewCompetitor_title": "New competitors",
        "component_competitionNewCompetitor_subtitle": "Recently detected competitors",
        "component_competitionNewCompetitor_informationTooltip_title": "New competitors",
        "component_competitionNewCompetitor_informationTooltip_text":"<p>Detect the appearance of new players in the market, all types of Google results combined (SEO, Google Ads, Google Business Profile, ...).</p>",
        "component_competitionNewCompetitor_noDataMessage": "No new competitors detected recently",

        "component_competitionCompetitorCompare_title": "Compare competitors",
        "component_competitionCompetitorCompare_subtitle": "Compare the performance of your competitors",
        "component_competitionCompetitorCompare_informationTooltip_title": "Compare competitors",
        "component_competitionCompetitorCompare_informationTooltip_text":"<p>Compare your site's visibility with that of tracked competitors by locality, market segments, and Google result type.</p>",

        "component_competitionMarketLeader_title": "Market Leaders",
        "component_competitionMarketLeader_subtitle": "Top performing competitor rankings (all competitors, tracked and unfollowed)",
        "component_competitionMarketLeader_informationTooltip_title": "Market Leaders",
        "component_competitionMarketLeader_informationTooltip_text":"<p>Identify the most visible sites in the market (all types of Google results including SEO, Google Ads, Google Business Profile, ...) as well as the keywords they rank for.</p>",
        "component_competitionMarketLeader_table_header_rank": "ranking",
        "component_competitionMarketLeader_table_header_rankEvolution": "Ranking evolution",
        "component_competitionMarketLeader_table_header_website": "Site",
        "component_competitionMarketLeader_table_header_evolution": "Visibility evolution",
        "component_competitionMarketLeader_table_header_traffic": "Estimated traffic",
        "component_competitionMarketLeader_table_header_keywordCount": "Nb Keywords",
        "component_competitionMarketLeader_table_header_pagesCount": "Nb positioned pages",

        "component_competitionMarketLeaderVisibilityEvolution_title": "Evolution of competitor visibility:",
        "component_competitionMarketLeaderVisibilityEvolution_subtitle": "Evolution of the visibility of the site on Google (all types of results: SEO, Google Ads, Shopping, Google Business Profile, etc. ...) for the keywords of the project.",
        "component_competitionMarketLeaderVisibilityEvolution_label": "Visibility score",

        "component_competitionMarketLeaderKeywords_title": "Competitor keywords:",
        "component_competitionMarketLeaderKeywords_subtitle": "Lists the keywords on which the competitor is positioned in the different types of Google results.",
        "component_competitionMarketLeaderKeywords_table_header_keyword": "Keyword",
        "component_competitionMarketLeaderKeywords_table_header_seo": "SEO",
        "component_competitionMarketLeaderKeywords_table_header_image": "Image",
        "component_competitionMarketLeaderKeywords_table_header_map": "Google Business Profile",
        "component_competitionMarketLeaderKeywords_table_header_zero": "Position 0",
        "component_competitionMarketLeaderKeywords_table_header_video": "Video",
        "component_competitionMarketLeaderKeywords_table_header_adwords": "Google Ads",
        "component_competitionMarketLeaderKeywords_table_header_shopping": "Shopping",

        "component_competitionMarketLeaderPages_title": "Best competitor pages:",
        "component_competitionMarketLeaderPages_subtitle": "Ranks the competitor's pages according to their visibility in different types of Google results on the project's keywords.",
        "component_competitionMarketLeaderPages_table_header_rank": "Ranking",
        "component_competitionMarketLeaderPages_table_header_page": "Page",
        "component_competitionMarketLeaderPages_table_header_estimatedTraffic": "Estimated Traffic",
        "component_competitionMarketLeaderPages_table_header_performance": "Performance",
        "component_competitionMarketLeaderPages_table_header_keywordCount": "Nb KW",
    },
}

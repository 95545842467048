
// import mock from "@/api/mock/mockData";


const actions = {


   //  async setDataComponent(store, payload){
   //
   //      store.commit('SET_COMPONENT_LOADING',payload.stateName)
   //
   //      if(!payload.promise){
   //          store.commit('SET_COMPONENT_DATA',{
   //              stateName:payload.stateName,
   //              data: (!payload.dataFormatter ? mock[payload.stateName] : payload.dataFormatter(mock[payload.stateName])),
   //              error:null
   //          })
   //          return true
   //      }
   //      return payload.promise.then((response) => {
   //          store.commit('SET_COMPONENT_DATA',{
   //              stateName:payload.stateName,
   //              data: (!payload.dataFormatter ? response.content : payload.dataFormatter(response)),
   //              error:null
   //          })
   //          return true
   //      }).catch((error) => {
   //          store.commit('SET_COMPONENT_DATA',{
   //              stateName:payload.stateName,
   //              data: null,
   //              error:error
   //          })
   //          store.commit('SET_PROJECT_ERROR',true)
   //
   //          return false
   //      })
   //
   //  },
   //
   //  setProjectComputeStatus(store,payload){
   //      store.commit('SET_PROJECT_COMPUTE_STATUS',payload)
   //  },
   //
   //  setGlobalFilterFrequency(store, payload){
   //      store.commit('SET_GLOBAL_FILTER_FREQUENCY',payload)
   //  },
   //  setGlobalFilterLocation(store, payload){
   //      store.commit('SET_GLOBAL_FILTER_LOCATION',payload)
   //  },
   //  showAgGridPopin(store, payload){
   //      store.commit('SHOW_AGGRID_POPIN',payload)
   //  },
   //  displayAgGridPopin(store, payload){
   //      store.commit('DISPLAY_AGGRID_POPIN',payload)
   //  },
   //  setSegmentSizeSelected(store, payload){
   //      store.commit('SELECT_SEGMENT_SIZE',payload)
   //  },
   //
   // setSubscriptionAnalyticsRefreshToken (store, payload) {
   //     store.commit('SET_ANALYTICS_REFRESH_TOKEN',payload)
   //  },
   // setSeoVisibilityNaturalPositionListSparkline (store, payload) {
   //     store.commit('SET_SEO_VISIBILITY_NATURAL_POSITION_LIST_SPARKLINE',payload)
   //  },
   // setCompetitionMarketLeaderListSparkline (store, payload) {
   //     store.commit('SET_COMPETITION_MARKET_LEADER_LIST_SPARKLINE',payload)
   //  },
   //
   // resetCurrentProject (store) {
   //     store.commit('RESET_CURRENT_PROJECT')
   //  },
}


export default actions

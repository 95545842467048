import filters from "@/i18n/messages/components/filters";
import subscriptionInfo from "@/i18n/messages/components/subscriptionInfo";
import trafficComponentMessages from "@/i18n/messages/components/traffic";
import googleVisibilityMessages from "@/i18n/messages/components/googleVisibility";
import seoVisibilityComponentMessages from "@/i18n/messages/components/seoVisibility";
import localVisibilityComponentMessages from "@/i18n/messages/components/localVisibility";
import advertisingComponentMessages from "@/i18n/messages/components/advertising";
import marketSegmentComponentMessages from "@/i18n/messages/components/marketSegment";
import competitionComponentMessages from "@/i18n/messages/components/competition";
import websitePagesComponentMessages from "@/i18n/messages/components/websitePages";
import linkingComponentMessages from "@/i18n/messages/components/linking";
import componentContainer from "@/i18n/messages/components/componentContainer";
import selectProjectLocation from "@/i18n/messages/components/selectProjectLocation";
import onlineDashboard from "@/i18n/messages/components/onlineDashboard";
import reportingConfiguration from "@/i18n/messages/components/reportingConfiguration";
import analytics from "@/i18n/messages/components/analytics";
import reportingBuilder from "@/i18n/messages/components/reportingBuilder";
import dashboardRecommendations from "@/i18n/messages/components/dashboardRecommendations";
import onboarding from "@/i18n/messages/components/onboarding";
import firstProject from "@/i18n/messages/components/firstProject";
import i18nSwitcher from "@/i18n/messages/components/i18nSwitcher";
import projectCreationWizard from "@/i18n/messages/components/projectCreationWizard";


export default {
  fr: {
    ...filters.fr,
    ...subscriptionInfo.fr,
    ...trafficComponentMessages.fr,
    ...googleVisibilityMessages.fr,
    ...seoVisibilityComponentMessages.fr,
    ...localVisibilityComponentMessages.fr,
    ...advertisingComponentMessages.fr,
    ...marketSegmentComponentMessages.fr,
    ...competitionComponentMessages.fr,
    ...websitePagesComponentMessages.fr,
    ...linkingComponentMessages.fr,
    ...componentContainer.fr,
    ...selectProjectLocation.fr,
    ...onlineDashboard.fr,
    ...reportingConfiguration.fr,
    ...analytics.fr,
    ...reportingBuilder.fr,
    ...dashboardRecommendations.fr,
    ...onboarding.fr,
    ...firstProject.fr,
    ...i18nSwitcher.fr,
    ...projectCreationWizard.fr,
  },
  en: {
    ...filters.en,
    ...subscriptionInfo.en,
    ...trafficComponentMessages.en,
    ...googleVisibilityMessages.en,
    ...seoVisibilityComponentMessages.en,
    ...localVisibilityComponentMessages.en,
    ...advertisingComponentMessages.en,
    ...marketSegmentComponentMessages.en,
    ...competitionComponentMessages.en,
    ...websitePagesComponentMessages.en,
    ...linkingComponentMessages.en,
    ...componentContainer.en,
    ...selectProjectLocation.en,
    ...onlineDashboard.en,
    ...reportingConfiguration.en,
    ...analytics.en,
    ...reportingBuilder.en,
    ...dashboardRecommendations.en,
    ...onboarding.en,
    ...firstProject.en,
    ...i18nSwitcher.en,
    ...projectCreationWizard.en,
  },
}


import themeUtilities from "@/components/themeUtilities";

export const themeColorMixin = {
    methods:{
        getThemeColorByName: themeUtilities.colors.getThemeColorByName,
        getThemeColors: themeUtilities.colors.getThemeColors,
        getComponentColor:  themeUtilities.colors.getComponentColor,
        getLinkingThematicColor:  themeUtilities.colors.getLinkingThematicColor
    },
}

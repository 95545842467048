
export default {

    trafficEvolutionConversion : {
        context:{ inputs:{ dateRangeType:1, isReporting:false } },
        content:{
            traffics:[
                {startDate: "2020-01-01",endDate: "2020-01-01",traffic: 7303,conversion: 304},
                {startDate: "2020-02-01",endDate: "2020-02-01",traffic: 9107,conversion: 321},
                {startDate: "2020-03-01",endDate: "2020-03-01",traffic: 12584,conversion: 544},
                {startDate: "2020-03-02",endDate: "2020-03-02",traffic: 13123,conversion: 441},
                {startDate: "2020-03-09",endDate: "2020-03-09",traffic: 14024,conversion: 571},
                {startDate: "2020-03-10",endDate: "2020-03-10",traffic: 14503,conversion: 749},
                {startDate: "2020-03-11",endDate: "2020-03-11",traffic: 14087,conversion: 574},
                {startDate: "2020-03-12",endDate: "2020-03-12",traffic: 15106,conversion: 533},
                {startDate: "2020-03-13",endDate: "2020-03-13",traffic: 16207,conversion: 613},
                {startDate: "2020-03-14",endDate: "2020-03-14",traffic: 16983,conversion: 742},
                {startDate: "2020-03-15",endDate: "2020-03-15",traffic: 19943,conversion: 947},
            ],
            currentTraffic:19943
        }
    },
    trafficEvolution : {
        context:{ inputs:{ dateRangeType:1, isReporting:false } },
        content:{
            traffics:[
                {startDate: "2020-01-01",endDate: "2020-01-01",traffic: 7303,conversion: 304},
                {startDate: "2020-02-01",endDate: "2020-02-01",traffic: 9107,conversion: 321},
                {startDate: "2020-03-01",endDate: "2020-03-01",traffic: 12584,conversion: 544},
                {startDate: "2020-03-02",endDate: "2020-03-02",traffic: 13123,conversion: 441},
                {startDate: "2020-03-09",endDate: "2020-03-09",traffic: 14024,conversion: 571},
                {startDate: "2020-03-10",endDate: "2020-03-10",traffic: 14503,conversion: 749},
                {startDate: "2020-03-11",endDate: "2020-03-11",traffic: 14087,conversion: 574},
                {startDate: "2020-03-12",endDate: "2020-03-12",traffic: 15106,conversion: 533},
                {startDate: "2020-03-13",endDate: "2020-03-13",traffic: 16207,conversion: 613},
                {startDate: "2020-03-14",endDate: "2020-03-14",traffic: 16983,conversion: 742},
                {startDate: "2020-03-15",endDate: "2020-03-15",traffic: 19943,conversion: 947},
            ],
            currentTraffic:19943
        }
    },


    trafficAcquisitionChanel: {
        content:[
            {startDate: "2022-12-20",endDate: "2022-12-20",acquisitionChannelType: "seo",traffic: 6182,cumulatedTrafficPercent: 31},
            {startDate: "2022-12-20",endDate: "2022-12-20",acquisitionChannelType: "ads",traffic: 2393,cumulatedTrafficPercent: 12},
            {startDate: "2022-12-20",endDate: "2022-12-20",acquisitionChannelType: "social",traffic: 1795,cumulatedTrafficPercent: 9},
            {startDate: "2022-12-20",endDate: "2022-12-20",acquisitionChannelType: "referral",traffic: 1197,cumulatedTrafficPercent: 6},
            {startDate: "2022-12-20",endDate: "2022-12-20",acquisitionChannelType: "email",traffic: 1198,cumulatedTrafficPercent: 6},
            {startDate: "2022-12-20",endDate: "2022-12-20",acquisitionChannelType: "direct",traffic: 6980,cumulatedTrafficPercent: 35},
            {startDate: "2022-12-20",endDate: "2022-12-20",acquisitionChannelType: "affiliation",traffic: 199,cumulatedTrafficPercent: 1},
        ]

    },
    competitionTopCompetitor : {
        content:[
            {
                "websiteId": 2,
                "domainName": "cyclable.com",
                "competitorType": "watched",
                "rank": 5,
                "performance": 41.92
            },
            {
                "websiteId": 3,
                "domainName": "intersport.fr",
                "competitorType": "other",
                "rank": 10,
                "performance": 31
            },
            {
                "websiteId": 1,
                "domainName": "culturevelo.com",
                "competitorType": "project",
                "rank": 12,
                "performance": 28.21
            },
            {
                "websiteId": 4,
                "domainName": "bikester.fr",
                "competitorType": "watched",
                "rank": 20,
                "performance": 13.01
            },
            {
                "websiteId": 5,
                "domainName": "pureelectric.fr",
                "competitorType": "watched",
                "rank": 24,
                "performance": 8.08
            }
        ]

    },
    marketSegmentEvolution : {
        content:[
            {
                "keywordGroupName": "Vélos électriques",
                "currentCumulatedVisibility": 1595,
                "referenceCumulatedVisibility": 1256,
                "cumulatedVisibilityEvolutionPercent": 27,
            },
            {
                "keywordGroupName": "VTT",
                "currentCumulatedVisibility": 2259,
                "referenceCumulatedVisibility": 1867,
                "cumulatedVisibilityEvolutionPercent": 21,
            },
            {
                "keywordGroupName": "Vélos ville",
                "currentCumulatedVisibility": 402,
                "referenceCumulatedVisibility": 478,
                "cumulatedVisibilityEvolutionPercent": -16,
            },
            {
                "keywordGroupName": "Vélos route",
                "currentCumulatedVisibility": 657,
                "referenceCumulatedVisibility": 722,
                "cumulatedVisibilityEvolutionPercent": -9,
            },
        ]

    },


    marketSegmentProgressionPotential : {
        content:[
            {
                "positionType": "natural",
                "potentialTrafficGain": 25388,
                "cumulatedPotentialTrafficGainPercent": 42
            },
            {
                "positionType": "adwords",
                "potentialTrafficGain": 7185,
                "cumulatedPotentialTrafficGainPercent": 17
            },
            {
                "positionType": "map",
                "potentialTrafficGain": 3952,
                "cumulatedPotentialTrafficGainPercent": 23
            },
            {
                "positionType": "shopping",
                "potentialTrafficGain": 5319,
                "cumulatedPotentialTrafficGainPercent": 18
            }
        ]

    },
    advertisingPerformance : {
        "content": {
            "currentPeriodStartDate": "2022-12-01T00:00:00+00:00",
            "currentPeriodEndDate": "2022-12-20T00:00:00+00:00",
            "previousPeriodStartDate": "2022-11-01T00:00:00+00:00",
            "previousPeriodEndDate": "2022-11-30T00:00:00+00:00",
            "currentTraffic": 811,
            "previousTraffic": 1709,
            "trafficEvolutionRate": -52.55,
            "currentConversion": 76,
            "previousConversion": 128,
            "conversionEvolutionRate": -40.63,
            "currentTurnover": 0,
            "previousTurnover": 0,
            "turnoverEvolutionRate": 0
        }
    },
    seoVisibilityPresenceEvolution : {
        content:[
            {date: "2020-01-01",distinctRankedKeywordCount: 32,distinctTotalKeywordCount: 100},
            {date: "2020-02-01",distinctRankedKeywordCount: 38,distinctTotalKeywordCount: 100},
            {date: "2020-03-01",distinctRankedKeywordCount: 40,distinctTotalKeywordCount: 100},
            {date: "2020-03-02",distinctRankedKeywordCount: 41,distinctTotalKeywordCount: 100},
            {date: "2020-03-09",distinctRankedKeywordCount: 43,distinctTotalKeywordCount: 100},
            {date: "2020-03-10",distinctRankedKeywordCount: 46,distinctTotalKeywordCount: 100},
            {date: "2020-03-11",distinctRankedKeywordCount: 46,distinctTotalKeywordCount: 100},
            {date: "2020-03-12",distinctRankedKeywordCount: 44,distinctTotalKeywordCount: 100},
            {date: "2020-03-13",distinctRankedKeywordCount: 52,distinctTotalKeywordCount: 100},
            {date: "2020-03-14",distinctRankedKeywordCount: 58,distinctTotalKeywordCount: 100},
            {date: "2020-03-15",distinctRankedKeywordCount: 62,distinctTotalKeywordCount: 100},
        ]
    },


    localVisibilityByLocation : {
        content:[
            {
                "name": "France",
                "cumulatedVisibility": 3566
            },
            {
                "name": "Marseille",
                "cumulatedVisibility": 4682
            },
            {
                "name": "Paris",
                "cumulatedVisibility": 5211
            },
            {
                "name": "Lyon",
                "cumulatedVisibility": 2278
            }
        ]

    },
    websitePagesBestPages : {
        content:[
            {url: "/velos/velos-electriques/",performance: 82.37},
            {url: "/velos/vtt/",performance: 78},
            {url: "/velos/ville/",performance: 69},
            {url: "/velos/route/",performance: 42},
            {url: "/velos/velos-electriques/lapierre",performance: 25},
        ]
    },
    marketSegmentSize:{
        content:{
            cumulatedSearchCount:324687
        }

    },

}


let themeColors = {
    base:{
        // primary: '#25A0D1',
        primary: '#008fe8',
        success: '#34a853',
        warning: '#fbbc05',
        danger: '#ea4335',
        contrast: '#7740ce',
        dark: '#1E1E1E',
        semiDark: '#2C2C2C',
        greyDark: '#626262',
        blueDark: '#22292f',
        grey: '#CDD6E1',
        greyLight: '#dae1e7',
        white: '#ffffff',

        competitor1: '#65A42A',
        competitor2: '#4BB7D4',
        competitor3: '#4F46E5',
        competitor4: '#E95934',
        competitor5: '#53B982',
        competitor6: '#E13749',
        competitor7: '#EAB441',
        competitor8: '#7D48EE',
        competitor9: '#78716C',
        competitor10: '#C249D4',
        genericCompetitor: '#A1A1AA',

        acquisitionChannelSeo: '#ED5183',
        acquisitionChannelAds: '#AF622A',
        acquisitionChannelSocial: '#35787E',
        acquisitionChannelReferral: '#EDB467',
        acquisitionChannelEmail: '#5AA2B0',
        acquisitionChannelDirect: '#D37795',
        acquisitionChannelAffiliation: '#35787E',

        marketSegmentColor1: '#68D7FF',
        marketSegmentColor2: '#6CBEF1',
        marketSegmentColor3: '#6EA4E5',
        marketSegmentColor4: '#6D84D7',
        marketSegmentColor5: '#7365CC',
        marketSegmentColor6: '#814AC1',
        marketSegmentColor7: '#8D3BAD',
        marketSegmentColor8: '#94299E',

        progressBar1:'#34a853',
        progressBar2:'#efc21e',
        progressBar3:'#e56932',
        progressBar4:'#c61c1c',

        googleResultTypeNatural: '#4285f4',
        googleResultTypeMap: '#34a853',
        googleResultTypeAdwords: '#fbbc05',
        googleResultTypeShopping: '#ea4335',
        googleResultTypeImage: '#7740ce',
        googleResultTypeSnippet: '#777777',
        googleResultTypeVideo: '#c61c1c',
        googleResultTypeActuality: '#35787E',

        positiveVariation: '#3CC846',
        negativeVariation: '#DC5432',
        noVariation: '#777777',

        linkingPopularity: '#6900ff',
        linkingAuthority: '#06c4d8',
        linkingLinkCompetitorCount: '#FF7AEA',
    },
    colorblind:{
        primary: '#25A0D1',
        success: '#34a853',
        warning: '#fbbc05',
        danger: '#ea4335',
        contrast: '#7740ce',
        dark: '#1E1E1E',
        semiDark: '#2C2C2C',
        greyDark: '#626262',
        blueDark: '#22292f',
        grey: '#CDD6E1',
        greyLight: '#dae1e7',
        white: '#ffffff',

        competitor1: '#65A42A',
        competitor2: '#4BB7D4',
        competitor3: '#4F46E5',
        competitor4: '#E95934',
        competitor5: '#53B982',
        competitor6: '#E13749',
        competitor7: '#EAB441',
        competitor8: '#7D48EE',
        competitor9: '#78716C',
        competitor10: '#C249D4',
        genericCompetitor: '#A1A1AA',

        acquisitionChannelNatural: '#ED5183',
        acquisitionChannelDirect: '#D37795',
        acquisitionChannelAdwords: '#AF622A',
        acquisitionChannelReferral: '#EDB467',
        acquisitionChannelSocial: '#35787E',
        acquisitionChannelDisplay: '#5AA2B0',

        marketSegmentColor1: '#68D7FF',
        marketSegmentColor2: '#6CBEF1',
        marketSegmentColor3: '#6EA4E5',
        marketSegmentColor4: '#6D84D7',
        marketSegmentColor5: '#7365CC',
        marketSegmentColor6: '#814AC1',
        marketSegmentColor7: '#8D3BAD',
        marketSegmentColor8: '#94299E',

        progressBar1:'#34a853',
        progressBar2:'#efc21e',
        progressBar3:'#e56932',
        progressBar4:'#c61c1c',

        googleResultTypeNatural: '#4285f4',
        googleResultTypeMap: '#34a853',
        googleResultTypeAdwords: '#fbbc05',
        googleResultTypeShopping: '#ea4335',
        googleResultTypeImage: '#7740ce',
        googleResultTypeSnippet: '#777777',
        googleResultTypeVideo: '#c61c1c',
        googleResultTypeActuality: '#35787E',

        positiveVariation: '#3CC846',
        negativeVariation: '#DC5432',
        noVariation: '#777777',

        linkingPopularity: '#6900ff',
        linkingAuthority: '#06c4d8',
        linkingLinkCount: '#1e88e5',
    }
}

let colors = themeColors.base

// CONFIGS
const themeConfig = {
    colors,
    disableCustomizer : true,       // options[Boolean] : true, false(default)
    disableThemeTour  : true,       // options[Boolean] : true, false(default)
    footerType        : "hidden",    // options[String]  : static(default) / sticky / hidden
    hideScrollToTop   : false,       // options[Boolean] : true, false(default)
    mainLayoutType    : "vertical",  // options[String]  : vertical(default) / horizontal
    navbarColor       : "#fff",      // options[String]  : HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
    navbarType        : "floating",  // options[String]  : floating(default) / static / sticky / hidden
    routerTransition  : "zoom-fade", // options[String]  : zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
    rtl               : false,       // options[Boolean] : true, false(default)
    sidebarCollapsed  : false,       // options[Boolean] : true, false(default)
    theme             : "light",     // options[String]  : "light"(default), "dark", "semi-dark"

    // Not required yet - WIP
    userInfoLocalStorageKey: "userInfo",

    // NOTE: themeTour will be disabled in screens < 1200. Please refer docs for more info.
}

import Vue from 'vue'
import Vuesax from 'vuesax'
Vue.use(Vuesax, { theme:{ colors:themeConfig.colors }, rtl: themeConfig.rtl })

export default themeConfig



export default {
    "fr": {
        "component_componentContainer_noAnalytics_title": "Pas d'analytics connecté",
        "component_componentContainer_noAnalytics_text": "Connecter",
        "component_componentContainer_noMarketSegment_title": "Pas de segments de marché",
        "component_componentContainer_noMarketSegment_text": "Créer",
        "component_componentContainer_noCompetitor_title": "Pas de concurrents suivis",
        "component_componentContainer_noCompetitor_text": "Définir",
        "component_componentContainer_noLocation_title": "Attention !",
        "component_componentContainer_noLocation_text": "<a href=\"[*CONFIG-LINK*]\">Ajouter des localité</a> pour les comparer.",
        "component_componentContainer_fakeData_label": "Données factices",
        "component_componentContainer_noData": "Aucune donnée à afficher pour l'instant.",
        "component_componentContainer_errorData": "Erreur lors du chargement des données",
    },
    "en": {
        "component_componentContainer_noAnalytics_title": "No analytics connected",
        "component_componentContainer_noAnalytics_text": "Connect",
        "component_componentContainer_noMarketSegment_title": "No market segments",
        "component_componentContainer_noMarketSegment_text": "Create",
        "component_componentContainer_noCompetitor_title": "No competitors tracked",
        "component_componentContainer_noCompetitor_text": "Define",
        "component_componentContainer_noLocation_title": "Warning!",
        "component_componentContainer_noLocation_text":"<a href=\"[*CONFIG-LINK*]`\">Add location</a> to compare.",
        "component_componentContainer_fakeData_label": "dummy data",
        "component_componentContainer_noData": "No data to display at this time.",
        "component_componentContainer_errorData": "Error while loading data",
    },
}



export default {
    "fr": {
        "component_seoVisibilityPresenceEvolution_title": "Résultats SEO",
        "component_seoVisibilityPresenceEvolution_subtitle": "",
        "component_seoVisibilityPresenceEvolution_informationTooltip_title": "Résultats SEO",
        "component_seoVisibilityPresenceEvolution_informationTooltip_text": "<p>Permet de suivre l'évolution du pourcentage des mots-clés positionnés en naturel en première page de Google.</p>",
        "component_seoVisibilityPresenceEvolution_legend": "de mots-clés en 1er page Google",
        "component_seoVisibilityPresenceEvolution_detailButton": "Suivre vos résultats SEO",
        "component_seoVisibilityPresenceEvolution_series_name": "Présence dans le Top 10",
        "component_seoVisibilityPresenceEvolution_label": "mots-clés",

        "component_seoVisibilitySeoTurnover_title": "Chiffre d'affaires SEO",
        "component_seoVisibilitySeoTurnover_subtitle": "",
        "component_seoVisibilitySeoTurnover_informationTooltip_title": "Chiffre d'affaires SEO",
        "component_seoVisibilitySeoTurnover_informationTooltip_text": "<p>Chiffre d'affaires réalisé par les visites en provenance des résultats naturels de Google.</p><p><br>Avec le menu, vous pouvez modifier la granularité de la courbe et afficher le chiffre d'affaires des derniers jours, des dernières semaines ou des derniers mois.</p><p><br>Vous modifiez également la période prise en compte par le KPI, et affichez le chiffre d'affaires de la période en cours : hier, cette semaine, ce mois-ci, cette année.</p><p><br><i>Source : Analytics - Revenu du e-commerce</i></p>",
        "component_seoVisibilitySeoTurnover_label": "Chiffre d'affaires SEO",
        "component_seoVisibilitySeoTurnover_series_name": "Chiffre d'affaires SEO",

        "component_seoVisibilityVisibilityScore_title": "Visibilité SEO",
        "component_seoVisibilityVisibilityScore_subtitle": "Score de visibilité du site web dans les résultats naturels de Google.",
        "component_seoVisibilityVisibilityScore_informationTooltip_title": "Score de visibilité SEO",
        "component_seoVisibilityVisibilityScore_informationTooltip_text": "<p>Le score de visibilité prend en compte la position de votre site dans les résultats naturels de Google et le volume de recherche moyen mensuel de chaque mot-clé.</p><p><br>Sur la carte, la courbe affiche l'évolution du score de visibilité de votre site sur les dernières semaines.</p><p><br>Le KPI affiche le score de visibilité obtenu lors du dernier rapport.</p>",
        "component_seoVisibilityVisibilityScore_label": "Score de visibilité SEO",

        "component_seoVisibilityIncomingTraffic_title": "Visites SEO",
        "component_seoVisibilityIncomingTraffic_subtitle": "",
        "component_seoVisibilityIncomingTraffic_informationTooltip_title": "Visites SEO",
        "component_seoVisibilityIncomingTraffic_informationTooltip_text": "<p>Nombre de visites uniques sur votre site en provenance des résultats naturels de Google.</p><p><br>Avec le menu, vous pouvez modifier la granularité de la courbe et afficher le trafic des derniers jours, des dernières semaines ou des derniers mois.</p><p><br>Vous modifiez également la période prise en compte par le KPI, et affichez le trafic de la période en cours : hier, cette semaine, ce mois-ci, cette année.</p><p><br><i>Source : Analytics - Nombre d'utilisateurs</i></p>",
        "component_seoVisibilityIncomingTraffic_label": "Visites SEO",

        "component_seoVisibilityConversion_title": "Conversion SEO",
        "component_seoVisibilityConversion_subtitle": "",
        "component_seoVisibilityConversion_informationTooltip_title": "Conversions SEO",
        "component_seoVisibilityConversion_informationTooltip_text": "<p>Nombre de conversions des objectifs réalisés par les visites en provenance des résultats naturels de Google.</p><p><br>Avec le menu, vous pouvez modifier la granularité de la courbe et afficher le nombre d'objectifs réalisés des derniers jours, des dernières semaines ou des derniers mois.</p><p><br>Vous modifiez également la période prise en compte par le KPI, et affichez le nombre de réalisations d'objectifs de la période en cours : hier, cette semaine, ce mois-ci, cette année.</p><p><br><i>Source : Analytics - Nombre d'objectifs réalisés</i></p>",
        "component_seoVisibilityConversion_label": "Conversion SEO",

        "component_seoVisibilityNaturalPositionList_title": "Positions naturelles sur Google",
        "component_seoVisibilityNaturalPositionList_subtitle": "Détail des positions dans les résultat naturels de Google",
        "component_seoVisibilityNaturalPositionList_informationTooltip_title": "Positions naturelles sur Google",
        "component_seoVisibilityNaturalPositionList_informationTooltip_text": "<p>Permet de suivre l'évolution des positions naturelles de chaque mot clé analysé.</p>",
        "component_seoVisibilityNaturalPositionList_table_header_keyword": "Mot-clé",
        "component_seoVisibilityNaturalPositionList_table_header_evo": "Evolution",
        "component_seoVisibilityNaturalPositionList_table_header_position": "Position",
        "component_seoVisibilityNaturalPositionList_table_header_variation": "Variation",
        "component_seoVisibilityNaturalPositionList_table_header_page": "Page",
        "component_seoVisibilityNaturalPositionList_table_header_searchCount": "Volume de recherches",
        "component_seoVisibilityNaturalPositionList_table_header_seoTraffic": "Trafic SEO estimé",
        "component_seoVisibilityNaturalPositionList_table_visitorLabel": "visites",

        "component_seoVisibilityKeywordVisibilityEvolution_title": "Evolution de la position du mot-clé :",
        "component_seoVisibilityKeywordVisibilityEvolution_subtitle": "Evolution de la position du site web dans les résultats naturels de Google pour la recherche",
        "component_seoVisibilityKeywordVisibilityEvolution_series_name": "Position",

        "component_seoVisibilityCompareKeywordsPositions_title": "Comparer les positions Google",
        "component_seoVisibilityCompareKeywordsPositions_subtitle": "Comparaison des positions des concurrents sur les mots-clés du projet",
        "component_seoVisibilityCompareKeywordsPositions_informationTooltip_title": "Comparer les positions Google",
        "component_seoVisibilityCompareKeywordsPositions_informationTooltip_text": "<p>Permet de comparer l'évolution des mots clés positionnés en naturel de votre site à celles des autres sites suivis</p>",
        "component_seoVisibilityCompareKeywordsPositions_table_header_keyword": "Mot-clé",

        "component_seoVisibilityTopPositionCount_title": "Présence en Top positions",
        "component_seoVisibilityTopPositionCount_subtitle": "Pourcentage de mots-clés bien positionnés sur Google et marge de progression",
        "component_seoVisibilityTopPositionCount_informationTooltip_title": "Nombre de TOP positions",
        "component_seoVisibilityTopPositionCount_informationTooltip_text": "<p>Permet de connaître le pourcentage de mots-clés positionnés de votre site en 1ère position (Top1), dans les 3 premières positions (Top3) et les 10 premières positions (Top 10).</p>",
        "component_seoVisibilityTopPositionCount_serie_name_1": "TOP 1",
        "component_seoVisibilityTopPositionCount_serie_name_2": "TOP 3",
        "component_seoVisibilityTopPositionCount_serie_name_3": "TOP 10",
        "component_seoVisibilityTopPositionCount_serie_name_progression": "Marge de progression",

        "component_seoVisibilityPositionRepartition_title": "Répartition des positions",
        "component_seoVisibilityPositionRepartition_subtitle": "Distribution de nos classements en 1ère page Google",
        "component_seoVisibilityPositionRepartition_informationTooltip_title": "Répartition des positions",
        "component_seoVisibilityPositionRepartition_informationTooltip_text": "<p>Permet d'identifier le nombre de mots-clés présents sur chaque position naturelle.</p>",
        "component_seoVisibilityPositionRepartition_axis_label": "Position",
        "component_seoVisibilityPositionRepartition_serie_label": "mots-clés",
    },
    "en": {

        "component_seoVisibilityPresenceEvolution_title": "SEO results",
        "component_seoVisibilityPresenceEvolution_subtitle": "",
        "component_seoVisibilityPresenceEvolution_informationTooltip_title": "SEO results",
        "component_seoVisibilityPresenceEvolution_informationTooltip_text":"<p>Allows you to track the evolution of the percentage of keywords positioned naturally on the first page of Google.</p>",
        "component_seoVisibilityPresenceEvolution_legend": "of keywords on the 1st page of Google",
        "component_seoVisibilityPresenceEvolution_detailButton": "Track your SEO results",
        "component_seoVisibilityPresenceEvolution_series_name": "Presence in the Top 10",
        "component_seoVisibilityPresenceEvolution_label": "keywords",

        "component_seoVisibilitySeoTurnover_title": "SEO Turnover",
        "component_seoVisibilitySeoTurnover_subtitle": "",
        "component_seoVisibilitySeoTurnover_informationTooltip_title": "SEO Turnover",
        "component_seoVisibilitySeoTurnover_informationTooltip_text":"<p>Turnover achieved by visits from Google natural results.</p><p><br>With the menu, you can change the granularity of the curve and display the turnover of the last days, weeks or months. </p><p><br>You also change the time period taken into account by the KPI, and display the turnover of the current period: yesterday, this week, this month, this year.</p><p><br><i>Source: Analytics - E-commerce Revenue</i></p>",
        "component_seoVisibilitySeoTurnover_label": "SEO Revenue",
        "component_seoVisibilitySeoTurnover_series_name": "SEO Revenue",

        "component_seoVisibilityVisibilityScore_title": "SEO Visibility",
        "component_seoVisibilityVisibilityScore_subtitle": "Website visibility score in Google natural results.",
        "component_seoVisibilityVisibilityScore_informationTooltip_title": "SEO visibility score",
        "component_seoVisibilityVisibilityScore_informationTooltip_text":"<p>The visibility score takes into account your site's position in Google's natural results and the average monthly search volume of each keyword.</p><p><br>On the map, the curve displays the evolution of your site's visibility score over the last few weeks.</p><p><br>The KPI displays the visibility score obtained in the last report.</p>",
        "component_seoVisibilityVisibilityScore_label": "SEO visibility score",

        "component_seoVisibilityIncomingTraffic_title": "SEO visits",
        "component_seoVisibilityIncomingTraffic_subtitle": "",
        "component_seoVisibilityIncomingTraffic_informationTooltip_title": "SEO Visits",
        "component_seoVisibilityIncomingTraffic_informationTooltip_text":"<p>Number of unique visits to your site from Google's natural results.</p><p><br>With the menu, you can change the granularity of the curve and display traffic for the last few days, weeks or months. </p><p><br>You also change the time period considered by the KPI, and display the traffic of the current period: yesterday, this week, this month, this year.</p><p><br><i>Source: Analytics - Number of users</i></p>",
        "component_seoVisibilityIncomingTraffic_label": "SEO visits",

        "component_seoVisibilityConversion_title": "SEO Conversion",
        "component_seoVisibilityConversion_subtitle": "",
        "component_seoVisibilityConversion_informationTooltip_title": "SEO Conversions",
        "component_seoVisibilityConversion_informationTooltip_text":"<p>Number of goal conversions achieved by visits from Google's natural results.</p><p><br>With the menu, you can change the granularity of the curve and display the number of goals achieved from the last few days, weeks or months. </p><p><br>You also change the time period considered by the KPI, and display the number of goal achievements of the current period: yesterday, this week, this month, this year.</p><p><br><i>Source: Analytics - Number of Goals Achieved</i></p>",
        "component_seoVisibilityConversion_label": "SEO Conversion",

        "component_seoVisibilityNaturalPositionList_title": "Natural positions on Google",
        "component_seoVisibilityNaturalPositionList_subtitle": "Detail of positions in Google natural results",
        "component_seoVisibilityNaturalPositionList_informationTooltip_title": "Natural positions on Google",
        "component_seoVisibilityNaturalPositionList_informationTooltip_text":"<p>Allows you to track the evolution of natural positions for each analyzed keyword.</p>",
        "component_seoVisibilityNaturalPositionList_table_header_keyword":"Keyword",
        "component_seoVisibilityNaturalPositionList_table_header_evo": "Evolution",
        "component_seoVisibilityNaturalPositionList_table_header_position": "Position",
        "component_seoVisibilityNaturalPositionList_table_header_variation": "Variation",
        "component_seoVisibilityNaturalPositionList_table_header_page": "Page",
        "component_seoVisibilityNaturalPositionList_table_header_searchCount": "Search volume",
        "component_seoVisibilityNaturalPositionList_table_header_seoTraffic": "Estimated SEO traffic",
        "component_seoVisibilityNaturalPositionList_table_visitorLabel": "visits",

        "component_seoVisibilityKeywordVisibilityEvolution_title": "Evolution of keyword position:",
        "component_seoVisibilityKeywordVisibilityEvolution_subtitle": "Evolution of the position of the website in Google's natural search results",
        "component_seoVisibilityKeywordVisibilityEvolution_series_name": "Position",

        "component_seoVisibilityCompareKeywordsPositions_title": "Compare Google positions",
        "component_seoVisibilityCompareKeywordsPositions_subtitle": "Comparison of competitors' positions on the project's keywords",
        "component_seoVisibilityCompareKeywordsPositions_informationTooltip_title": "Compare Google positions",
        "component_seoVisibilityCompareKeywordsPositions_informationTooltip_text":"<p>Allows you to compare the evolution of your site's naturally positioned keywords to those of other tracked sites</p>",
        "component_seoVisibilityCompareKeywordsPositions_table_header_keyword": "Keyword",

        "component_seoVisibilityTopPositionCount_title": "Presence in Top positions",
        "component_seoVisibilityTopPositionCount_subtitle": "Percentage of keywords well positioned on Google and margin of progression",
        "component_seoVisibilityTopPositionCount_informationTooltip_title": "Number of TOP positions",
        "component_seoVisibilityTopPositionCount_informationTooltip_text":"<p>Allows you to know the percentage of keywords positioned on your site in the 1st position (Top1), in the first 3 positions (Top3) and the first 10 positions (Top 10).</p>",
        "component_seoVisibilityTopPositionCount_serie_name_1": "TOP 1",
        "component_seoVisibilityTopPositionCount_serie_name_2": "TOP 3",
        "component_seoVisibilityTopPositionCount_serie_name_3": "TOP 10",
        "component_seoVisibilityTopPositionCount_serie_name_progression": "Margin of progress",

        "component_seoVisibilityPositionRepartition_title": "Position distribution",
        "component_seoVisibilityPositionRepartition_subtitle": "Distribution of our rankings on the 1st page of Google",
        "component_seoVisibilityPositionRepartition_informationTooltip_title": "Position distribution",
        "component_seoVisibilityPositionRepartition_informationTooltip_text":"<p>Allows you to identify the number of keywords present on each natural position.</p>",
        "component_seoVisibilityPositionRepartition_axis_label": "Position",
        "component_seoVisibilityPositionRepartition_serie_label": "keywords",
    },
}

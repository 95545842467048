export default {
    "fr": {
        "component_title": "Créer un modèle de rapport",
        "component_title_label_error": "Veuillez saisir un nom pour ce modèle",
        "component_title_back": "Revenir à la liste des modèles",
        "component_titlePlaceholder": "Mon nom de modèle personnalisé",
        "component_indicatorsTitle": "Choix des indicateurs",
        "component_indicators_label_error": "Veuillez ajouter au moins un indicateur",
        "component_structureTitle": "Structure du rapport",
        "component_previewModel": "Aperçu du modèle",
        "component_saveModel": "Enregistrer",
        "component_addComponent": "Ajouter au modèle",
        "component_deleteComponent": "Retirer du modèle",
        "component_table": "Tableau",
        "component_chart": "Graphique",
        "component_widthPercent": "Largeur : [*WIDTH*]%",
        "component_freeText": "Sections & textes libres",
        "component_reportingBuilderCustomTitle_title": "Titre de section",
        "component_reportingBuilderCustomTitle_subtitle": "",
        "component_reportingBuilderCustomText_title": "Paragraphe de texte libre",
        "component_reportingBuilderCustomText_subtitle": "",
    },
    "en": {
        "component_title": "Create a report template",
        "component_title_label_error": "Please enter a name for this template",
        "component_title_back": "Return to the list of templates",
        "component_titlePlaceholder": "My custom template name",
        "component_indicatorsTitle": "Choice of indicators",
        "component_indicators_label_error": "Please add at least one indicator",
        "component_structureTitle": "Report structure",
        "component_previewModel": "Model preview",
        "component_saveModel": "Save",
        "component_addComponent": "Add to model",
        "component_deleteComponent": "Remove from model",
        "component_table": "Table",
        "component_chart": "Chart",
        "component_widthPercent": "Width: [*WIDTH*]%",
        "component_freeText": "Sections & free text",
        "component_reportingBuilderCustomTitle_title": "Section title",
        "component_reportingBuilderCustomTitle_subtitle": "",
        "component_reportingBuilderCustomText_title": "Free text paragraph",
        "component_reportingBuilderCustomText_subtitle": "",
    },
}



export default {
    "fr": {
        "component_linkingPopularityByWebsite_title": "Popularité de mon site",
        "component_linkingPopularityByWebsite_subtitle": "Popularité mesurée selon la quantité et puissance des liens pointant vers votre site <em>(Citation Flow)</em>",
        "component_linkingPopularityByWebsite_informationTooltip_title": "Popularité de mon site",
        "component_linkingPopularityByWebsite_informationTooltip_text": "<p>La popularité du site est un score sur 100 servant à évaluer sa notoriété. Elle dépend du nombre de liens pointant vers ce site et de leur puissance, sans tenir compte de leur qualité.</p><br/><p>Comparaison avec la popularité médiane des 30 sites obtenant les meilleures positions sur les mots-clés du projet.</p><br/><p><em>Source : CitationFlow - Majestic</em></p>",
        "component_linkingPopularityByWebsite_seriesWebsite": "Mon site",
        "component_linkingPopularityByWebsite_seriesCompetitors": "Concurrents",

        "component_linkingWebsiteAuthority_title": "Autorité de mon site",
        "component_linkingWebsiteAuthority_subtitle": "Capital-confiance mesuré selon la fiabilité des liens pointant vers votre site <em>(Trust Flow)</em>",
        "component_linkingWebsiteAuthority_informationTooltip_title": "Autorité de mon site",
        "component_linkingWebsiteAuthority_informationTooltip_text": "<p>L'autorité du site est un score sur 100 servant à évaluer la qualité des liens pointant vers ce site. Elle est hérité des sites qui vous font des liens. C'est un indicateur de confiance aux yeux des moteurs de recherche, notamment Google. Plus la note est élevée, plus votre site est considéré comme fiable et sérieux.</p><br/><p>Comparaison avec l'autorité médiane des 30 sites obtenant les meilleures positions sur les mots-clés du projet.</p><br/><p><em>Source : TrustFlow - Majestic</em></p>",
        "component_linkingWebsiteAuthority_seriesWebsite": "Mon site",
        "component_linkingWebsiteAuthority_seriesCompetitors": "Concurrents",

        "component_linkingLinkCount_title": "Nombre de liens",
        "component_linkingLinkCount_subtitle": "",
        "component_linkingLinkCount_informationTooltip_title": "Nombre de lien",
        "component_linkingLinkCount_informationTooltip_text": "<p>Nombre de sites qui font au moins un lien vers le vôtre.</p><br/><p>Nombre total de liens pointant vers votre site, tous sites référents confondus.</p><br/><p>Comparaison avec le nombre de liens et de sites référents médian, des 30 sites obtenant les meilleures positions sur les mots-clés du projet.</p>",
        "component_linkingLinkCount_websiteCount": "sites renvoient vers le vôtre",
        "component_linkingLinkCount_linkCount": "liens externes vers votre site",
        "component_linkingLinkCount_websiteLabel": "Mon site",
        "component_linkingLinkCount_competitorsLabel": "Concurrents",

        "component_linkingCompetitorsLinking_title": "Linking de mes concurrents",
        "component_linkingCompetitorsLinking_subtitle": "Comparaison de la popularité et de l’autorité de vos concurrents les plus visibles sur les mots-clés de votre projet",
        "component_linkingCompetitorsLinking_informationTooltip_title": "Linking de mes concurrents",
        "component_linkingCompetitorsLinking_informationTooltip_text": "<p>Le tableau liste le top 30 des sites concurrents détectés automatiquement.</p><br/><p><strong>Popularité : </strong>La popularité du site est un score sur 100 servant à évaluer sa notoriété. Elle dépend du nombre de liens pointant vers un site, sans tenir compte de leur qualité. <em>(CitationFlow Majestic)</em></p><br/><p><strong>Autorité : </strong>L'autorité du site est un score sur 100 servant à évaluer la qualité des liens pointant vers ce site. Elle est hérité des sites référents. <em>(TrustFlow Majestic)</em></p><br/><p><strong>Thématique du site : </strong>La colonne peut être triée selon le score d'autorité thématique du site dans sa thématique principale. La thématique principale du site est la thématique dans laquelle le site possède le plus haut degré d'autorité. Les thématiques sont héritées des sites référents. <em>(Topical TrustFlow Majestic)</em></p>",
        "component_linkingCompetitorsLinking_table_header_ranking": "Classement",
        "component_linkingCompetitorsLinking_table_header_rank": "Site web",
        "component_linkingCompetitorsLinking_table_header_popularity": "Popularité",
        "component_linkingCompetitorsLinking_table_header_authority": "Autorité",
        "component_linkingCompetitorsLinking_table_header_thematic": "Thématiques du site",
        "component_linkingCompetitorsLinking_table_header_referralDomains": "Sites référents",
        "component_linkingCompetitorsLinking_table_cellrenderer_tooltip_title1": "Thématique principale",
        "component_linkingCompetitorsLinking_table_cellrenderer_tooltip_title2": "Autres thématiques",

        "component_linkingCompetitorsLinkingReferralDomains_title": "Sites qui font des liens vers : ",
        "component_linkingCompetitorsLinkingReferralDomains_subtitle": "Liste les sites les plus influents sur le linking de votre concurrent",
        "component_linkingCompetitorsLinkingReferralDomains_table_header_domainName": "Nom de domaine",


        "component_linkingMyThematicAuthority_title": "Autorité thématique de mon site",
        "component_linkingMyThematicAuthority_subtitle": "Liste des thématiques dans lesquelles votre site a de l’autorité.",
        "component_linkingMyThematicAuthority_informationTooltip_title": "Autorité thématique de mon site",
        "component_linkingMyThematicAuthority_informationTooltip_text": "<p>Score sur 100 mesurant l’autorité d’un site dans sa ou ses thématiques phares.</p><br/><p>Les thématiques avec le plus d’autorité doivent être cohérentes avec votre domaine d'activité.</p><br/><p><em>Source : Topical TrustFlow - Majestic</em></p>",
        "component_linkingMyThematicAuthority_table_header_thematic": "Thématique",
        "component_linkingMyThematicAuthority_table_header_authority": "Autorité thématique",
        "component_linkingMyThematicAuthority_table_header_referralDomains": "Sites référents",
        "component_linkingMyThematicAuthority_no_data_text": "Votre site n’a pas d’autorité thématique.",

        "component_linkingMyThematicAuthorityReferralDomains_title": "Sites qui me font des liens dans la thématique :",
        "component_linkingMyThematicAuthorityReferralDomains_subtitle": "Liste des sites qui vous transmettent le plus d'autorité dans une thématique en particulier",


        "component_linkingAuthorityCompare_title": "Comparaison de l'autorité thématique",
        "component_linkingAuthorityCompare_subtitle": "Comparaison des thématiques dans lesquelles votre site et un concurrent ont le plus d'autorité.",
        "component_linkingAuthorityCompare_informationTooltip_title": "Comparaison de l'autorité thématique",
        "component_linkingAuthorityCompare_informationTooltip_text": "<p>L’autorité thématique est un score sur 100, mesurant l’autorité d'un site dans sa ou ses thématiques phares.</p><br/><p>Vous pouvez consulter vos scores ou ceux du concurrent dans chaque thématique en survolant le graphique.</p><br/><p><em>Source : Topical TrustFlow - Majestic</em></p>",
        "component_linkingAuthorityCompare_noData_text": "Les sites web comparés n’ont pas d’autorité thématique",
        "component_linkingAuthorityCompare_noEnoughData_text": "Pas assez de thématiques pour effectuer une comparaison (<3).",
        "component_linkingAuthorityCompare_rivalLabel": "Concurrents suivis",
        "component_linkingAuthorityCompare_competitorLabel": "Autres concurrents",
        "component_linkingAuthorityCompare_noSearchResult": "Pas de conccurents",

        "component_linkingTablesContainer_tabTitle_backlink": "Backlinks",
        "component_linkingTablesContainer_tabTitle_website": "Sites Référents",
        "component_linkingTablesContainer_tabTitle_anchor": "Ancres de liens",
        "component_linkingTablesContainer_tabTitle_page": "Pages liées",

        "component_linkingBacklinks_title": "Meilleurs backlinks de mon site",
        "component_linkingBacklinks_subtitle": "Liste des pages qui font un lien vers votre site.",
        "component_linkingBestBacklinks_informationTooltip_title": "Backlinks",
        "component_linkingBestBacklinks_informationTooltip_text": "<p>Liste des backlinks qui transmettent le plus de popularité à votre site (popularité transmise). Lorsqu'une page A fait un lien vers une page B, elle lui envoie une partie de sa popularité. Plus la page A crée de liens vers d’autres pages, plus sa notoriété se répartit.<br/><br/><strong>Popularité transmise :</strong> Popularité transmise par la page référente via son ou ses lien(s). Elle est calculée en fonction de la popularité de la page référente et du nombre total de liens qui en sortent.</p>",
        "component_linkingBestBacklinks_table_header_refererPage": "Page référente",
        "component_linkingBestBacklinks_table_header_popularity": "Popularité transmise",
        "component_linkingBestBacklinks_table_header_linkingPage": "Page liée",
        "component_linkingBestBacklinks_table_header_thematic": "Thématiques de la page référente",
        "component_linkingBestBacklinks_table_header_anchor": "Ancre du lien",
        "component_linkingBestBacklinks_table_header_refererPage_tooltip": "Page référente",
        "component_linkingBestBacklinks_table_header_popularity_tooltip": "Popularité transmise",
        "component_linkingBestBacklinks_table_header_linkingPage_tooltip": "Page liée",
        "component_linkingBestBacklinks_table_header_thematic_tooltip": "Thématiques de la page référente",
        "component_linkingBestBacklinks_table_header_anchor_tooltip": "Ancre du lien",
        "component_linkingBestBacklinks_no_anchor_text": "ø Ancre vide",


        "component_linkingRefDomains_title": "Sites qui me font des liens",
        "component_linkingRefDomains_subtitle": "Liste les sites les plus influents sur votre linking",
        "component_linkingWebsiteBacklinks_informationTooltip_title": "Sites référents",
        "component_linkingWebsiteBacklinks_informationTooltip_text": "<p>Un site référent est un site qui vous fait des liens.<br/><br/>Les sites considérés comme les plus influents sur votre linking sont : <br/><br/>- Les sites qui détiennent un niveau d’autorité significatif et établissent un ou plusieurs liens vers votre site.<br/>- Les sites qui génèrent un nombre élevé de liens vers vos pages.</p>",
        "component_linkingWebsiteBacklinks_table_header_refererWebsite": "Site référent",
        "component_linkingWebsiteBacklinks_table_header_popularity": "Popularité",
        "component_linkingWebsiteBacklinks_table_header_authority": "Autorité",
        "component_linkingWebsiteBacklinks_table_header_thematic": "Thématiques du site référent",
        "component_linkingWebsiteBacklinks_table_header_linkCount": "Nb de liens",
        "component_linkingWebsiteBacklinks_table_header_refererWebsite_tooltip": "Site référent",
        "component_linkingWebsiteBacklinks_table_header_popularity_tooltip": "Popularité",
        "component_linkingWebsiteBacklinks_table_header_authority_tooltip": "Autorité",
        "component_linkingWebsiteBacklinks_table_header_thematic_tooltip": "Thématiques du site référent",
        "component_linkingWebsiteBacklinks_table_header_linkCount_tooltip": "Nb de liens",


        "component_linkingAnchors_title": "Ancres des liens vers mon site",
        "component_linkingAnchors_subtitle": "Liste des textes d’ancrage les plus utilisés par les sites référents, pour faire des liens vers mon site.",
        "component_linkingAnchorBacklinks_informationTooltip_title": "Ancres de liens",
        "component_linkingAnchorBacklinks_informationTooltip_text": "<p>Liste des textes d'ancrage les plus utilisés dans les liens pointant vers votre site.<br/><br/><strong>Popularité transmise : </strong>Popularité transmise à votre site par les sites référents utilisant cette ancre de lien.<br/><br/><strong>Autorité transmise : </strong>Autorité transmise à votre site par les sites référents utilisant cette ancre de lien.</p>",
        "component_linkingAnchorBacklinks_table_header_anchor": "Ancre",
        "component_linkingAnchorBacklinks_table_header_popularity": "Popularité transmise",
        "component_linkingAnchorBacklinks_table_header_authority": "Autorité transmise",
        "component_linkingAnchorBacklinks_table_header_thematic": "Thématiques des liens",
        "component_linkingAnchorBacklinks_table_header_linkCount": "Nb de liens",
        "component_linkingAnchorBacklinks_table_header_referralCount": "Sites référents",
        "component_linkingAnchorBacklinks_table_header_anchor_tooltip": "Ancre de lien",
        "component_linkingAnchorBacklinks_table_header_popularity_tooltip": "Popularité transmise",
        "component_linkingAnchorBacklinks_table_header_authority_tooltip": "Autorité transmise",
        "component_linkingAnchorBacklinks_table_header_thematic_tooltip": "Thématique des liens",
        "component_linkingAnchorBacklinks_table_header_linkCount_tooltip": "Nombre de liens",
        "component_linkingAnchorBacklinks_table_header_referralCount_tooltip": "Sites référents",



        "component_linkingAnchorsLinks_title": "Pages qui me font des liens avec l'ancre :",
        "component_linkingAnchorsLinks_subtitle": "Liste des pages qui font un lien vers votre site avec un texte d'ancre en particulier. Le tableau indique la page ciblée par le lien et la popularité transmise à celle-ci.",
        "component_linkingAnchorsLinks_informationTooltip_title": "titre",
        "component_linkingAnchorsLinks_informationTooltip_text": "<p>texte</p>",
        "component_linkingAnchorsLinks_table_header_referral": "Page référente",
        "component_linkingAnchorsLinks_table_header_popularity": "Popularité transmise",
        "component_linkingAnchorsLinks_table_header_linkedPage": "Page liée",
        "component_linkingAnchorsLinks_table_header_thematic": "Thématiques de la page référente",



        "component_linkingAnchorsDomains_title": "Sites qui me font des liens avec l'ancre :",
        "component_linkingAnchorsDomains_subtitle": "Liste des sites qui utilisent ce texte d'ancre pour vous faire des liens.",
        "component_linkingAnchorsDomains_informationTooltip_title": "titre",
        "component_linkingAnchorsDomains_informationTooltip_text": "<p>texte</p>",
        "component_linkingAnchorsDomains_table_header_referral": "Site référent",
        "component_linkingAnchorsDomains_table_header_popularity": "Popularité",
        "component_linkingAnchorsDomains_table_header_authority": "Autorité",
        "component_linkingAnchorsDomains_table_header_thematic": "Thématiques du site référent",
        "component_linkingAnchorsDomains_table_header_linkCount": "Nb de liens",

        "component_linkingTopPages_title": "Pages recevant des liens",
        "component_linkingTopPages_subtitle": "Liste les pages de votre site qui reçoivent des liens provenant d'autres sites.",
        "component_linkingReferralBacklinks_informationTooltip_title": "Pages liées",
        "component_linkingReferralBacklinks_informationTooltip_text": "<p>Liste de vos pages qui reçoivent des liens depuis d'autres sites. L’indicateur indique l’autorité et la popularité de chaque page ainsi que le nombre de mots-clés sur lesquels elles se positionnent dans les SERP de Google.<br/><br/>Par défaut, vos pages sont triées par popularité. L’indicateur affiche au maximum les 1000 pages liées les plus populaires de votre site.</p>",
        "component_linkingReferralBacklinks_table_header_refererPage": "Page liée",
        "component_linkingReferralBacklinks_table_header_popularity": "Popularité",
        "component_linkingReferralBacklinks_table_header_authority": "Autorité",
        "component_linkingReferralBacklinks_table_header_thematic": "Thématiques de la page liée",
        "component_linkingReferralBacklinks_table_header_linkCount": "Nb de liens",
        "component_linkingReferralBacklinks_table_header_positionCount": "Nb de positions",
        "component_linkingReferralBacklinks_table_header_refererPage_tooltip": "Page liée",
        "component_linkingReferralBacklinks_table_header_popularity_tooltip": "Popularité",
        "component_linkingReferralBacklinks_table_header_authority_tooltip": "Autorité",
        "component_linkingReferralBacklinks_table_header_thematic_tooltip": "Thématiques de la page liée",
        "component_linkingReferralBacklinks_table_header_linkCount_tooltip": "Nb de liens",
        "component_linkingReferralBacklinks_table_header_positionCount_tooltip": "Nb de positions",

        "component_linkingReferralBacklinksPositions_title": "Positions SEO de la page :",
        "component_linkingReferralBacklinksPositions_subtitle": "Liste des positions obtenues par cette page dans les résultats naturels de Google.",
        "component_linkingReferralBacklinksPositions_informationTooltip_title": "titre",
        "component_linkingReferralBacklinksPositions_informationTooltip_text": "<p>texte.</p>",
        "component_linkingReferralBacklinksPositions_table_header_keyword": "Mot-clé",
        "component_linkingReferralBacklinksPositions_table_header_position": "Position",
        "component_linkingReferralBacklinksPositions_table_header_searchVolume": "Volume de recherches",
        "component_linkingReferralBacklinksPositions_table_header_estimatedTraffic": "Trafic SEO estimé",

        "component_linkingReferralBacklinksLinks_title": "Liste des liens vers la page :",
        "component_linkingReferralBacklinksLinks_subtitle": "Liste des liens pointant vers une page spécifique de votre site",
        "component_linkingReferralBacklinksLinks_informationTooltip_title": "titre",
        "component_linkingReferralBacklinksLinks_informationTooltip_text": "<p>texte.</p>",
        "component_linkingReferralBacklinksLinks_table_header_refererPage": "Page référente",
        "component_linkingReferralBacklinksLinks_table_header_popularity": "Popularité transmise",
        "component_linkingReferralBacklinksLinks_table_header_thematic": "Thématique de la page référente",
        "component_linkingReferralBacklinksLinks_table_header_anchor": "Ancre du lien",


        "component_linkingRefDomainsLinks_title": "Pages du site [*DOMAIN-NAME*] qui me font des liens",
        "component_linkingRefDomainsLinks_subtitle": "Liste les pages du site référent qui font des liens vers votre site. Le tableau indique la page ciblée par le lien et la popularité transmise à celle-ci.",
        "component_linkingRefDomainsLinks_informationTooltip_title": "Titre",
        "component_linkingRefDomainsLinks_informationTooltip_text": "<p>texte.</p>",
        "component_linkingRefDomainsLinks_table_header_referralPage": "Page référente",
        "component_linkingRefDomainsLinks_table_header_popularity": "Popularité transmise",
        "component_linkingRefDomainsLinks_table_header_linkedPage": "Page liée",
        "component_linkingRefDomainsLinks_table_header_thematic": "Thématiques de la page référente",
        "component_linkingRefDomainsLinks_table_header_anchor": "Ancre du lien",


        "component_cellrenderer_popularityDetail_authority": "La page référente a une popularité <br/>de <strong>[*POPULARITY*]</strong> qui se répartit entre <strong>[*LINK-COUNT*]</strong> liens sortants",



        "linking_thematic_label_all": "Toutes les thematiques",
        "linking_thematic_label_1": "Reference",
        "linking_thematic_label_2": "Recreation",
        "linking_thematic_label_3": "Arts",
        "linking_thematic_label_4": "Society",
        "linking_thematic_label_5": "Business",
        "linking_thematic_label_6": "Computers",
        "linking_thematic_label_7": "News",
        "linking_thematic_label_8": "Sciences",
        "linking_thematic_label_9": "Health",
        "linking_thematic_label_10": "Sports",
        "linking_thematic_label_11": "Regional",
        "linking_thematic_label_12": "Home",
        "linking_thematic_label_13": "Shopping",
        "linking_thematic_label_14": "Games",
        "linking_thematic_label_15": "World",
        "linking_thematic_label_16": "Adult",
    },
    "en": {
        "component_linkingPopularityByWebsite_title": "Popularity of my website",
        "component_linkingPopularityByWebsite_subtitle": "Popularity measured by the quantity and strength of links pointing to your website <em>(Citation Flow)</em>",
        "component_linkingPopularityByWebsite_informationTooltip_title": "Popularity of my website",
        "component_linkingPopularityByWebsite_informationTooltip_text": "<p>The popularity of a website is a score out of 100 used to assess its notoriety. It depends on the number and strength of links pointing to the website, regardless of their quality.</p><br/><p>Comparison with the median popularity of the 30 websites with the best positions on the project's keywords.</p><br/><p><em>Source : CitationFlow - Majestic</em></p>",
        "component_linkingPopularityByWebsite_seriesWebsite": "My website",
        "component_linkingPopularityByWebsite_seriesCompetitors": "Competitors",

        "component_linkingWebsiteAuthority_title": "Authority of my website",
        "component_linkingWebsiteAuthority_subtitle": "Trust capital measured according to the reliability of links pointing to your website <em>(Trust Flow)</em>",
        "component_linkingWebsiteAuthority_informationTooltip_title": "Authority of my website",
        "component_linkingWebsiteAuthority_informationTooltip_text": "<p>Website authority is a score out of 100 used to evaluate the quality of the links pointing to this website. It is inherited from the websites that link to you. It's an indicator of trust in the eyes of search engines, especially Google. The higher the score, the more reliable and trustworthy your website is.</p><br/><p>Comparison with the median authorithy of the 30 websites with the best positions on the project's keywords.</p><br/><p><em>Source : TrustFlow - Majestic</em></p>",
        "component_linkingWebsiteAuthority_seriesWebsite": "My website",
        "component_linkingWebsiteAuthority_seriesCompetitors": "Competitors",

        "component_linkingLinkCount_title": "Number of links",
        "component_linkingLinkCount_subtitle": "",
        "component_linkingLinkCount_informationTooltip_title": "Number of links",
        "component_linkingLinkCount_informationTooltip_text": "<p>Number of websites linking to your website.</p><br/><p>Total number of links pointing to your website, all referring websites combined.</p><br/><p>Comparison with the median number of links and referring websites, of the 30 sites with the best positions on the project's keywords.</p>",
        "component_linkingLinkCount_websiteCount": "websites link to you",
        "component_linkingLinkCount_linkCount": "external links to your website",
        "component_linkingLinkCount_websiteLabel": "My website",
        "component_linkingLinkCount_competitorsLabel": "Competitors",

        "component_linkingCompetitorsLinking_title": "Linking of my competitors",
        "component_linkingCompetitorsLinking_subtitle": "Comparison of the popularity and authority of your most visible competitors on your project's keywords",
        "component_linkingCompetitorsLinking_informationTooltip_title": "Linking of my competitors",
        "component_linkingCompetitorsLinking_informationTooltip_text": "<p>The table lists the top 30 competitor websites automatically detected.</p><br/><p><strong>Popularity : </strong>Website popularity is a score out of 100 used to evaluate the notoriety of a website. It depends on the number of links pointing to a website, regardless of their quality. <em>(CitationFlow Majestic)</em></p><br/><p><strong>Authority : </strong>Website authority is a score out of 100 used to evaluate the quality of links pointing to the website. It is inherited from referring websites. <em>(TrustFlow Majestic)</em></p><br/><p><strong>Main Topics : </strong>The column can be sorted according to the website's authority score in its Main Topic. The Main Topics is the topics in which the website has the highest degree of authority. Themes are inherited from referring websites. <em>(Topical TrustFlow Majestic)</em></p>",
        "component_linkingCompetitorsLinking_table_header_ranking": "Ranking",
        "component_linkingCompetitorsLinking_table_header_rank": "Website",
        "component_linkingCompetitorsLinking_table_header_popularity": "Popularity",
        "component_linkingCompetitorsLinking_table_header_authority": "Authority",
        "component_linkingCompetitorsLinking_table_header_thematic": "Main Topics",
        "component_linkingCompetitorsLinking_table_header_referralDomains": "Referring websites",
        "component_linkingCompetitorsLinking_table_cellrenderer_tooltip_title1": "Main Topics",
        "component_linkingCompetitorsLinking_table_cellrenderer_tooltip_title2": "Other Topics",

        "component_linkingCompetitorsLinkingReferralDomains_title": "Websites that link to : ",
        "component_linkingCompetitorsLinkingReferralDomains_subtitle": "List of websites most influential on your competitor's linking",
        "component_linkingCompetitorsLinkingReferralDomains_table_header_domainName": "Nom de domaine",


        "component_linkingMyThematicAuthority_title": "Topical authority of my website",
        "component_linkingMyThematicAuthority_subtitle": "List of topics in which your website has authority.",
        "component_linkingMyThematicAuthority_informationTooltip_title": "Topical authority of my website",
        "component_linkingMyThematicAuthority_informationTooltip_text": "<p>Score out of 100 measuring a website's authority in its main topics.</p><br/><p>The most authoritative topics should be consistent with your field of activity.</p><br/><p><em>Source : Topical TrustFlow - Majestic</em></p>",
        "component_linkingMyThematicAuthority_table_header_thematic": "Topic",
        "component_linkingMyThematicAuthority_table_header_authority": "Topical Authority",
        "component_linkingMyThematicAuthority_table_header_referralDomains": "Referring websites",
        "component_linkingMyThematicAuthority_no_data_text": "Your website doesn't have topical authority.",

        "component_linkingMyThematicAuthorityReferralDomains_title": "Websites that provide me with links on the topic :",
        "component_linkingMyThematicAuthorityReferralDomains_subtitle": "List of websites that transmit the most authority to you in a particular topic",

        "component_linkingAuthorityCompare_title": "Topical authority comparison",
        "component_linkingAuthorityCompare_subtitle": "Comparison of the topics in which your website and a competitor have the most authority.",
        "component_linkingAuthorityCompare_informationTooltip_title": "Topical authority comparison",
        "component_linkingAuthorityCompare_informationTooltip_text": "<p>Topical authority is a score out of 100, measuring a website's authority in its main topic(s).</p><br/><p>You can view your scores or those of the competitor in each topic by hovering over the graph.</p><br/><p><em>Source : Topical TrustFlow - Majestic</em></p>",
        "component_linkingAuthorityCompare_noData_text": "Compared websites do not have topical authority.",
        "component_linkingAuthorityCompare_noEnoughData_text": "Not enough topics to make a comparison (<3).",
        "component_linkingAuthorityCompare_rivalLabel": "Tracked competitors ",
        "component_linkingAuthorityCompare_competitorLabel": "Other competitors",
        "component_linkingAuthorityCompare_noSearchResult": "No competitors found",

        "component_linkingTablesContainer_tabTitle_backlink": "Backlinks",
        "component_linkingTablesContainer_tabTitle_website": "Referring websites",
        "component_linkingTablesContainer_tabTitle_anchor": "Link anchors",
        "component_linkingTablesContainer_tabTitle_page": "Linked pages",

        "component_linkingBacklinks_title": "Best backlinks to your website",
        "component_linkingBacklinks_subtitle": "List of pages that link to your website",
        "component_linkingBestBacklinks_informationTooltip_title": "Backlinks",
        "component_linkingBestBacklinks_informationTooltip_text": "<p>List of backlinks that transmit the most popularity to your website (transmitted popularity). When a page A links to a page B, it sends a portion of its popularity to that page. The more links page A creates to other pages, the more its popularity is distributed.<br/><br/><strong>Transmitted popularity : </strong>Popularity transmitted by the referring page via its link(s). It is calculated as a function of the referring page's popularity and the total number of links coming from it.</p>",
        "component_linkingBestBacklinks_table_header_refererPage": "Referring page",
        "component_linkingBestBacklinks_table_header_popularity": "Transmitted popularity",
        "component_linkingBestBacklinks_table_header_linkingPage": "Linked page",
        "component_linkingBestBacklinks_table_header_thematic": "Referring page topics",
        "component_linkingBestBacklinks_table_header_anchor": "Anchor link",
        "component_linkingBestBacklinks_table_header_refererPage_tooltip": "Referring page",
        "component_linkingBestBacklinks_table_header_popularity_tooltip": "Transmitted popularity",
        "component_linkingBestBacklinks_table_header_linkingPage_tooltip": "Linked page",
        "component_linkingBestBacklinks_table_header_thematic_tooltip": "Referring page topics",
        "component_linkingBestBacklinks_table_header_anchor_tooltip": "Anchor link",
        "component_linkingBestBacklinks_no_anchor_text": "ø Empty anchor",


        "component_linkingRefDomains_title": "Sites that link to me",
        "component_linkingRefDomains_subtitle": "List of the most influential websites on your linking",
        "component_linkingWebsiteBacklinks_informationTooltip_title": "Referring websites",
        "component_linkingWebsiteBacklinks_informationTooltip_text": "<p>A referral website is a website that links to you.<br/><br/>The websites considered most influential on your linking are : <br/><br/>- Websites that have a significant level of authority and establish one or more links to your website.<br/>- Websites that generate a high number of links to your pages.</p>",
        "component_linkingWebsiteBacklinks_table_header_refererWebsite": "Referring website",
        "component_linkingWebsiteBacklinks_table_header_popularity": "Popularity",
        "component_linkingWebsiteBacklinks_table_header_authority": "Authority",
        "component_linkingWebsiteBacklinks_table_header_thematic": "Referring website topics",
        "component_linkingWebsiteBacklinks_table_header_linkCount": "Nb of links",
        "component_linkingWebsiteBacklinks_table_header_refererWebsite_tooltip": "Referring website",
        "component_linkingWebsiteBacklinks_table_header_popularity_tooltip": "Popularity",
        "component_linkingWebsiteBacklinks_table_header_authority_tooltip": "Authority",
        "component_linkingWebsiteBacklinks_table_header_thematic_tooltip": "Referring website topics",
        "component_linkingWebsiteBacklinks_table_header_linkCount_tooltip": "Nb of links",


        "component_linkingAnchors_title": "Anchor links to my website",
        "component_linkingAnchors_subtitle": "List of anchor texts most used by referring sites, to make links to my website.",
        "component_linkingAnchorBacklinks_informationTooltip_title": "Anchor links to my website",
        "component_linkingAnchorBacklinks_informationTooltip_text": "<p>List of anchor texts most frequently used in links pointing to your website. <br/><br/><strong>Transmitted popularity : </strong>Popularity transmitted to your site by referring websites using this link anchor.</p><br/><br/><strong>Transmitted authority : </strong>Authority transmitted to your website by referring websites using this link anchor.",
        "component_linkingAnchorBacklinks_table_header_anchor": "Anchor",
        "component_linkingAnchorBacklinks_table_header_popularity": "Transmitted popularity",
        "component_linkingAnchorBacklinks_table_header_authority": "Transmitted authority",
        "component_linkingAnchorBacklinks_table_header_thematic": "Links topics",
        "component_linkingAnchorBacklinks_table_header_linkCount": "Nb of links",
        "component_linkingAnchorBacklinks_table_header_referralCount": "Referring websites",
        "component_linkingAnchorBacklinks_table_header_anchor_tooltip": "Link anchor",
        "component_linkingAnchorBacklinks_table_header_popularity_tooltip": "Transmitted popularity",
        "component_linkingAnchorBacklinks_table_header_authority_tooltip": "Transmitted authority",
        "component_linkingAnchorBacklinks_table_header_thematic_tooltip": "Links topics",
        "component_linkingAnchorBacklinks_table_header_linkCount_tooltip": "Number of links",
        "component_linkingAnchorBacklinks_table_header_referralCount_tooltip": "Referring websites",

        "component_linkingAnchorsLinks_title": "Pages that link to me with the anchor :",
        "component_linkingAnchorsLinks_subtitle": "List of pages linking to your website with a specific anchor text. The table shows the page targeted by the link and the popularity transmitted to it.",
        "component_linkingAnchorsLinks_informationTooltip_title": "titre",
        "component_linkingAnchorsLinks_informationTooltip_text": "<p>texte</p>",
        "component_linkingAnchorsLinks_table_header_referral": "Referring page",
        "component_linkingAnchorsLinks_table_header_popularity": "Transmitted popularity",
        "component_linkingAnchorsLinks_table_header_linkedPage": "Linked page",
        "component_linkingAnchorsLinks_table_header_thematic": "Referring page topics",

        "component_linkingAnchorsDomains_title": "Webites that link to me with the anchor :",
        "component_linkingAnchorsDomains_subtitle": "List of websites that use this anchor text to link to you.",
        "component_linkingAnchorsDomains_informationTooltip_title": "titre",
        "component_linkingAnchorsDomains_informationTooltip_text": "<p>texte</p>",
        "component_linkingAnchorsDomains_table_header_referral": "Referring website",
        "component_linkingAnchorsDomains_table_header_popularity": "Popularity",
        "component_linkingAnchorsDomains_table_header_authority": "Authority",
        "component_linkingAnchorsDomains_table_header_thematic": "Referring website topics",
        "component_linkingAnchorsDomains_table_header_linkCount": "Nb of links",

        "component_linkingTopPages_title": "Pages that receive links",
        "component_linkingTopPages_subtitle": "List of pages on your website that receive links from other websites",
        "component_linkingReferralBacklinks_informationTooltip_title": "Linked pages",
        "component_linkingReferralBacklinks_informationTooltip_text": "<p>List of your pages that receive links from other websites. The indicator shows the authority and popularity of each page, as well as the number of keywords on which they rank in Google SERP.<br/><br/>By default, your pages are sorted by popularity. The indicator displays a maximum of the 1000 most popular linked pages on your website.</p>",
        "component_linkingReferralBacklinks_table_header_refererPage": "Linked page",
        "component_linkingReferralBacklinks_table_header_popularity": "Popularity",
        "component_linkingReferralBacklinks_table_header_authority": "Authority",
        "component_linkingReferralBacklinks_table_header_thematic": "Linked page topic",
        "component_linkingReferralBacklinks_table_header_linkCount": "Nb of links",
        "component_linkingReferralBacklinks_table_header_positionCount": "Nb of positions",
        "component_linkingReferralBacklinks_table_header_refererPage_tooltip": "Linked page",
        "component_linkingReferralBacklinks_table_header_popularity_tooltip": "Popularity",
        "component_linkingReferralBacklinks_table_header_authority_tooltip": "Authority",
        "component_linkingReferralBacklinks_table_header_thematic_tooltip": "Linked page topic",
        "component_linkingReferralBacklinks_table_header_linkCount_tooltip": "Nb of links",
        "component_linkingReferralBacklinks_table_header_positionCount_tooltip": "Nb of positions",


        "component_linkingReferralBacklinksPositions_title": "Page SEO positions :",
        "component_linkingReferralBacklinksPositions_subtitle": "List of positions obtained by this page in Google's natural results.",
        "component_linkingReferralBacklinksPositions_informationTooltip_title": "titre",
        "component_linkingReferralBacklinksPositions_informationTooltip_text": "<p>texte.</p>",
        "component_linkingReferralBacklinksPositions_table_header_keyword": "Keyword",
        "component_linkingReferralBacklinksPositions_table_header_position": "Position",
        "component_linkingReferralBacklinksPositions_table_header_searchVolume": "Search volumes",
        "component_linkingReferralBacklinksPositions_table_header_estimatedTraffic": "Estimated SEO traffic",

        "component_linkingReferralBacklinksLinks_title": "List of links to the page :",
        "component_linkingReferralBacklinksLinks_subtitle": "List of links pointing to a specific page on your website",
        "component_linkingReferralBacklinksLinks_informationTooltip_title": "titre",
        "component_linkingReferralBacklinksLinks_informationTooltip_text": "<p>texte.</p>",
        "component_linkingReferralBacklinksLinks_table_header_refererPage": "Referring page",
        "component_linkingReferralBacklinksLinks_table_header_popularity": "Transmitted popularity",
        "component_linkingReferralBacklinksLinks_table_header_thematic": "Referring page topics",
        "component_linkingReferralBacklinksLinks_table_header_anchor": "Anchor link",


        "component_linkingRefDomainsLinks_title": "Pages on [*DOMAIN-NAME*] that link to my site",
        "component_linkingRefDomainsLinks_subtitle": "Lists the pages of the referring website that link to your site. The table shows the page targeted by the link and the popularity transmitted to it.",
        "component_linkingRefDomainsLinks_informationTooltip_title": "Titre",
        "component_linkingRefDomainsLinks_informationTooltip_text": "<p>texte.</p>",
        "component_linkingRefDomainsLinks_table_header_referralPage": "Referring page",
        "component_linkingRefDomainsLinks_table_header_popularity": "Transmitted popularity",
        "component_linkingRefDomainsLinks_table_header_linkedPage": "Linked page",
        "component_linkingRefDomainsLinks_table_header_thematic": "Referring page topics",
        "component_linkingRefDomainsLinks_table_header_anchor": "Anchor link",

        "component_cellrenderer_popularityDetail_authority": "The referring page has a popularity<br/>of <strong>[*POPULARITY*]</strong> which is divided between <strong>[*LINK-COUNT*]</strong> outbound links",


        "linking_thematic_label_all": "All topics",
        "linking_thematic_label_1": "Reference",
        "linking_thematic_label_2": "Recreation",
        "linking_thematic_label_3": "Arts",
        "linking_thematic_label_4": "Society",
        "linking_thematic_label_5": "Business",
        "linking_thematic_label_6": "Computers",
        "linking_thematic_label_7": "News",
        "linking_thematic_label_8": "Sciences",
        "linking_thematic_label_9": "Health",
        "linking_thematic_label_10": "Sports",
        "linking_thematic_label_11": "Regional",
        "linking_thematic_label_12": "Home",
        "linking_thematic_label_13": "Shopping",
        "linking_thematic_label_14": "Games",
        "linking_thematic_label_15": "World",
        "linking_thematic_label_16": "Adult",
    },
}



export default {
    "fr": {
        "component_advertisingPerformance_title": "Performance publicitaire",
        "component_advertisingPerformance_subtitle": "Evolution des résultats de nos campagnes publicitaires",
        "component_advertisingPerformance_informationTooltip_title": "Performance publicitaire",
        "component_advertisingPerformance_informationTooltip_text": "<p>Variation du nombre de visites, de conversions et du chiffre d'affaires générés par les campagnes publicitaires (Google Ads et Google Shopping) par rapport à la période précédente.</p><p><br><i>Source : Analytics</i></p>",
        "component_advertisingPerformance_detailButton": "Mesurer l'efficacité de vos pubs",
        "component_advertisingPerformance_serie_name_1": "Visites",
        "component_advertisingPerformance_serie_name_2": "Conversions",
        "component_advertisingPerformance_serie_name_3": "CA",

        "component_advertisingIncomingTraffic_title": "Trafic provenant de la publicité",
        "component_advertisingIncomingTraffic_subtitle": "Evolution du nombre de visites provenant de vos campagnes publicitaires (source Analytics)",
        "component_advertisingIncomingTraffic_informationTooltip_title": "Trafic provenant de la publicité",
        "component_advertisingIncomingTraffic_informationTooltip_text": "<p>Le nombre de visites publicitaires provenant de vos campagnes Google Ads et Shopping est cumulé, et calculé en fonction de la période sélectionnée.</p><p><br>La courbe permet de suivre l'évolution du nombre total de visites publicitaires uniques sur le site en fonction de la période sélectionnée.</p><p><br><strong>Exemple</strong> : Avec une sélection \"par mois\" vous aurez le total cumulé des visites uniques en payant depuis le 1er du mois en cours.</p><p><br><i>Source : Analytics</i></p>",
        "component_advertisingIncomingTraffic_label": "Visites publicitaires",
        "component_advertising_incoming_traffic_series_name_1": "Trafic",

        "component_advertisingConversion_title": "Conversions publicitaires",
        "component_advertisingConversion_subtitle": "",
        "component_advertisingConversion_informationTooltip_title": "Conversions publicitaires",
        "component_advertisingConversion_informationTooltip_text": "<p>Le nombre de conversions publicitaires provenant de vos campagnes Google Ads et Shopping est cumulé, et calculé en fonction de la période sélectionnée.</p><p><br>La courbe permet de suivre l'évolution du nombre total de conversions publicitaires sur le site en fonction de la période sélectionnée.</p><p><br><strong>Exemple</strong> : Avec une sélection \"par mois\" vous aurez le total cumulé des conversions depuis le 1er du mois en cours, tous objectifs confondus.</p><p><br><i>Source : Analytics</i></p>",
        "component_advertisingConversion_label": "Conversions publicitaires",

        "component_advertisingTurnover_title": "Chiffre d'affaires publicitaires",
        "component_advertisingTurnover_subtitle": "",
        "component_advertisingTurnover_informationTooltip_title": "Chiffre d'affaires publicitaires",
        "component_advertisingTurnover_informationTooltip_text": "<p>Le chiffre d'affaires publicitaire provenant de vos campagnes Google Ads et Shopping est cumulé, et calculé en fonction de la période sélectionnée.</p><p><br>La courbe permet de suivre l'évolution du nombre total du chiffre d'affaires publicitaire sur le site en fonction de la période sélectionnée.</p><p><br><strong>Exemple</strong> : Avec une sélection \"par mois\" vous aurez le total cumulé du chiffre d'affaires depuis le 1er du mois en cours, tous objectifs confondus.</p><p><br><i>Source : Analytics</i></p>",
        "component_advertisingTurnover_label": "Chiffre d'affaires publicitaires",

        "component_advertisingAdwordsPerformance_noData": "Aucun de vos mots-clés n'affiche d'Google Ads",
        "component_advertisingAdwordsPerformance_title": "Ma performance Google Ads",
        "component_advertisingAdwordsPerformance_subtitle": "Efficacité de vos campagnes Google Ads",
        "component_advertisingAdwordsAveragePosition_informationTooltip_title": "Performance Google Ads",
        "component_advertisingAdwordsAveragePosition_informationTooltip_text": "<p>Mesurez la visibilité de vos campagnes Google Ads sur les mots-clés du projet affichant des résultats Google Ads.</p><p><br>Une performance de 100% signifie être 1er sur tous les mots-clés qui affichent un résultat Google Ads.</p><p><br>La position moyenne correspond au classement moyen de vos annonces dans le bloc Google Ads.</p>",
        "component_advertisingAdwordsAveragePosition_label": "position moyenne",

        "component_advertisingShoppingPerformance_noData": "Aucun de vos mots-clés n'affiche de Shopping",
        "component_advertisingShoppingPerformance_title": "Ma performance Shopping",
        "component_advertisingShoppingPerformance_subtitle": "Efficacité de vos campagnes Google Shopping",
        "component_advertisingShoppingPerformance_informationTooltip_title": "Performance Shopping",
        "component_advertisingShoppingPerformance_informationTooltip_text": "<p>Mesurez la visibilité de vos campagnes Google Shopping sur les mots-clés du projet affichant des résultats Google Shopping.</p><p><br>Une performance de 100% signifie être 1er sur tous les mots-clés qui affichent un résultat Google Shopping.</p><p><br>La position moyenne correspond au classement moyen de vos annonces dans le bloc Google Shopping.</p>",
        "component_advertisingShoppingAveragePosition_label": "position moyenne",

        "component_advertisingAdwordsTopCompetitor_title": "Top concurrents Google Ads",
        "component_advertisingAdwordsTopCompetitor_subtitle": "Site les plus visibles sur Google Ads",
        "component_advertisingAdwordsTopCompetitor_informationTooltip_title": "Top concurrents Google Ads",
        "component_advertisingAdwordsTopCompetitor_informationTooltip_text": "<p>Permet d'identifier les sites les plus visibles en Google Ads sur les mots-clés du projet.</p><p><br>Une visibilité de 100% signifie être 1er sur tous les mots-clés qui affichent un résultat Google Ads.</p>",

        "component_advertisingShoppingTopCompetitor_title": "Top concurrents Shopping",
        "component_advertisingShoppingTopCompetitor_subtitle": "Site les plus visibles sur Google Shopping",
        "component_advertisingShoppingTopCompetitor_informationTooltip_title": "Top concurrents Shopping",
        "component_advertisingShoppingTopCompetitor_informationTooltip_text": "<p>Permet d'identifier les sites les plus visibles en Google Shopping sur les mots-clés du projet.<p><p><br>Une visibilité de 100% signifie être 1er sur tous les mots-clés qui affichent un résultat Google Shopping.</p>",

        "component_advertisingAdwordsAdvertisers_title": "Annonceurs Google Ads sur mon marché",
        "component_advertisingAdwordsAdvertisers_subtitle": "Analyse des sites qui achètent des publicités sur mes mots-clés",
        "component_advertisingAdwordsAdvertisers_informationTooltip_title": "Annonceurs Google Ads sur mon marché",
        "component_advertisingAdwordsAdvertisers_informationTooltip_text": "<p>Identifiez la liste des annonceurs Google Ads présents sur les mots-clés du projet. Vous pourrez découvrir le détail des mots-clés de leurs campagnes et les annonces qui y sont associées.</p>",
        "component_advertisingAdwordsAdvertisers_table_header_advertiser": "Annonceur",
        "component_advertisingAdwordsAdvertisers_table_header_adwordsPerformance": "Performance Google Ads",
        "component_advertisingAdwordsAdvertisers_table_header_keywordCount": "Nb Mots-clés",
        "component_advertisingAdwordsAdvertisers_table_header_averagePosition": "Position moyenne",
        "component_advertisingAdwordsAdvertisers_table_header_adCount": "Nb annonces",

        "component_advertisingAdwordsAdvertiserKeywords_title": "Positions Google Ads de l'annonceur :",
        "component_advertisingAdwordsAdvertiserKeywords_subtitle": "Liste les mots-clés du projet achetés par l'annonceur pour sa campagne Google Ads.",
        "component_advertisingAdwordsAdvertiserKeywords_table_header_keyword": "Mot-clé",
        "component_advertisingAdwordsAdvertiserKeywords_table_header_position": "Position",
        "component_advertisingAdwordsAdvertiserKeywords_table_header_searchCount": "Nombre de recherches",
        "component_advertisingAdwordsAdvertiserKeywords_table_header_estimatedTrafic": "Trafic Google Ads estimé",
        "component_advertisingAdwordsAdvertiserKeywords_table_header_ad": "Annonce",

        "component_advertisingAdwordsAdvertiserAds_title": "Annonces de :",
        "component_advertisingAdwordsAdvertiserAds_subtitle": "Liste les différentes annonces utilisées par l'annonceur pour sa campagne Google Ads sur les mots-clés du projet.",
        "component_advertisingAdwordsAdvertiserAds_footer": "Nombre d'impression totale : ",
        "component_advertisingAdwordsAdvertiserAds_table_header_ad": "Annonce",
        "component_advertisingAdwordsAdvertiserAds_table_header_keywordCount": "Nb mots-clés ciblés",
        "component_advertisingAdwordsAdvertiserAds_table_header_printCount": "Nb impressions potentielles",

        "component_advertisingAdwordsCompetitionKeywords_title": "Concurrence Google Ads sur mon marché",
        "component_advertisingAdwordsCompetitionKeywords_subtitle": "Analyse de la concurrence publicitaire sur mes mots-clés",
        "component_advertisingAdwordsCompetitionKeywords_informationTooltip_title": "Concurrence Google Ads sur mon marché",
        "component_advertisingAdwordsCompetitionKeywords_informationTooltip_text": "<p>Permet d'identifier pour chaque mot-clé l'ensemble des annonceurs présents sur Google Ads.</p>",
        "component_advertisingAdwordsCompetitionKeywords_table_header_keyword": "Mot-clé",
        "component_advertisingAdwordsCompetitionKeywords_table_header_advertiserCount": "Nb d'annonces",
        "component_advertisingAdwordsCompetitionKeywords_table_header_searchCount": "Nb recherches",
        "component_advertisingAdwordsCompetitionKeywords_table_header_competition": "Degré de concurrence",
        "component_advertisingAdwordsCompetitionKeywords_table_header_averageCpc": "CPC moyen",

        "component_advertisingShoppingAdvertisers_title": "Annonceurs Google Shopping sur mon marché",
        "component_advertisingShoppingAdvertisers_subtitle": "Liste des sites qui mettent en avant des produits via Google Shopping sur mes mots-clés",
        "component_advertisingShoppingAdvertisers_informationTooltip_title": "Annonceurs Google Shopping sur mon marché",
        "component_advertisingShoppingAdvertisers_informationTooltip_text": "Identifiez la liste des annonceurs Google Shopping présents sur les mots-clés du projet. Vous pourrez découvrir le détail des mots-clés de leurs campagnes et les annonces qui y sont associées.",
        "component_advertisingShoppingAdvertisers_table_header_advertiser": "Annonceur",
        "component_advertisingShoppingAdvertisers_table_header_shoppingPerformance": "Performance Shopping",
        "component_advertisingShoppingAdvertisers_table_header_keywordCount": "Nb Mc",
        "component_advertisingShoppingAdvertisers_table_header_averagePosition": "Position moyenne",
        "component_advertisingShoppingAdvertisers_table_header_adCount": "Nb annonces",

        "component_advertisingShoppingCompetitionKeywords_title": "Concurrence Google Shopping sur mon marché",
        "component_advertisingShoppingCompetitionKeywords_subtitle": "Concurrence Google Shopping sur mes mots-clés",
        "component_advertisingShoppingCompetitionKeywords_informationTooltip_title": "Concurrence Google Shopping sur mon marché",
        "component_advertisingShoppingCompetitionKeywords_informationTooltip_text": "Permet d'identifier pour chaque mot-clé l'ensemble des annonceurs présents sur Google Shopping.",
        "component_advertisingShoppingCompetitionKeywords_table_header_keyword": "Mot-clé",
        "component_advertisingShoppingCompetitionKeywords_table_header_advertiserCount": "Nb d'annonces shopping",
        "component_advertisingShoppingCompetitionKeywords_table_header_searchCount": "Nb recherches",

        "component_advertisingAdwordsKeywordsAds_title": "Annonceurs Google Ads sur le mot-clé :",
        "component_advertisingAdwordsKeywordsAds_subtitle": "Liste les annonces Google Ads présentes sur le mot-clé sélectionné.",
        "component_advertisingAdwordsKeywordsAds_table_header_rank": "Position",
        "component_advertisingAdwordsKeywordsAds_table_header_ad": "Annonce",

        "component_advertisingShoppingAdvertiserKeywords_title": "Positions Google Shopping de l'annonceur :",
        "component_advertisingShoppingAdvertiserKeywords_subtitle": "Liste les mots-clés du projet achetés par l'annonceur pour sa campagne Google Shopping.",
        "component_advertisingShoppingAdvertiserKeywords_table_header_keyword": "Mot-clé",
        "component_advertisingShoppingAdvertiserKeywords_table_header_position": "Position",
        "component_advertisingShoppingAdvertiserKeywords_table_header_searchCount": "Nombre de recherches",
        "component_advertisingShoppingAdvertiserKeywords_table_header_estimatedTrafic": "Trafic shopping estimé",
        "component_advertisingShoppingAdvertiserKeywords_table_header_ad": "Annonce",

        "component_advertisingShoppingAdvertiserAds_title": "Annonces Shopping de :",
        "component_advertisingShoppingAdvertiserAds_subtitle": "Liste les différentes annonces utilisées par l'annonceur pour sa campagne Google Shopping sur les mots-clés du projet.",
        "component_advertisingShoppingAdvertiserAds_table_header_ad": "Annonce",
        "component_advertisingShoppingAdvertiserAds_table_header_keywordCount": "Nb mots-clés ciblés",
        "component_advertisingShoppingAdvertiserAds_table_header_printCount": "Nb impressions potentielles",

        "component_advertisingShoppingKeywordsAds_title": "Annonces Shopping sur le mot-clé :",
        "component_advertisingShoppingKeywordsAds_subtitle": "Liste les annonces Google Shopping présentes sur le mot-clé sélectionné.",
        "component_advertisingShoppingKeywordsAds_table_header_rank": "Position",
        "component_advertisingShoppingKeywordsAds_table_header_ad": "Annonce",
    },
    "en": {
        "component_advertisingPerformance_title": "Advertising performance",
        "component_advertisingPerformance_subtitle": "Evolution of the results of our advertising campaigns",
        "component_advertisingPerformance_informationTooltip_title": "Advertising performance",
        "component_advertisingPerformance_informationTooltip_text":"<p>Variation in the number of visits, conversions and revenue generated by advertising campaigns (Google Ads and Google Shopping) compared to the previous period.</p><p><br><i>Source: Analytics</i></p>",
        "component_advertisingPerformance_detailButton": "Measure the effectiveness of your ads",
        "component_advertisingPerformance_serie_name_1": "Visits",
        "component_advertisingPerformance_serie_name_2": "Conversions",
        "component_advertisingPerformance_serie_name_3": "CA",

        "component_advertisingIncomingTraffic_title": "Traffic from advertising",
        "component_advertisingIncomingTraffic_subtitle": "Evolution of the number of visits coming from your advertising campaigns (source Analytics)",
        "component_advertisingIncomingTraffic_informationTooltip_title": "Traffic from advertising",
        "component_advertisingIncomingTraffic_informationTooltip_text":"<p>The number of ad visits from your Google Ads and Shopping campaigns is aggregated, and calculated based on the selected time period. </p><p><br>The curve allows you to follow the evolution of the total number of unique advertising visits on the site according to the selected period.</p><p><br><strong>Example</strong>: With a selection \"by month\" you will have the cumulative total of unique visits by paying since the 1st of the current month.</p><p><br><i>Source: Analytics</i></p>",
        "component_advertisingIncomingTraffic_label": "Advertising visits",
        "component_advertising_incoming_traffic_series_name_1": "Traffic",

        "component_advertisingConversion_title": "Advertising Conversions",
        "component_advertisingConversion_subtitle": "",
        "component_advertisingConversion_informationTooltip_title": "Ad conversions",
        "component_advertisingConversion_informationTooltip_text":"<p>The number of ad conversions from your Google Ads and Shopping campaigns is accumulated, and calculated based on the selected time period.</p><p><br>The curve allows you to follow the evolution of the total number of ad conversions on the site based on the selected time period. </p><p><br><strong>Example</strong>: With a selection \"by month\" you will have the cumulative total of conversions since the 1st of the current month, all objectives combined.</p><p><br><i>Source: Analytics</i></p>",
        "component_advertisingConversion_label": "Advertising Conversions",

        "component_advertisingTurnover_title": "Advertising revenue",
        "component_advertisingTurnover_subtitle": "",
        "component_advertisingTurnover_informationTooltip_title": "Advertising revenue",
        "component_advertisingTurnover_informationTooltip_text":"<p>Advertising revenue from your Google Ads and Shopping campaigns is accumulated, and calculated based on the selected time period.</p><p><br>The curve allows you to follow the evolution of the total number of advertising revenue on the site based on the selected time period. </p><p><br><strong>Example</strong>: With a selection \"by month\" you will have the cumulative total of the turnover since the 1st of the current month, all objectives combined.</p><p><br><i>Source: Analytics</i></p>",
        "component_advertisingTurnover_label": "Advertising revenue",

        "component_advertisingAdwordsPerformance_noData": "None of your keywords are showing Google Ads",
        "component_advertisingAdwordsPerformance_title": "My Google Ads performance",
        "component_advertisingAdwordsPerformance_subtitle": "Effectiveness of your Google Ads campaigns",
        "component_advertisingAdwordsAveragePosition_informationTooltip_title": "Google Ads performance",
        "component_advertisingAdwordsAveragePosition_informationTooltip_text":"<p>Measure the visibility of your Google Ads campaigns on project keywords displaying Google Ads results.</p><p><br>100% performance means being 1st on all keywords displaying a Google Ads result.</p><p><br>Average position is the average ranking of your ads in the Google Ads block.</p>",
        "component_advertisingAdwordsAveragePosition_label": "average position",

        "component_advertisingShoppingPerformance_noData": "None of your keywords show Shopping",
        "component_advertisingShoppingPerformance_title": "My Shopping performance",
        "component_advertisingShoppingPerformance_subtitle": "Effectiveness of your Google Shopping campaigns",
        "component_advertisingShoppingPerformance_informationTooltip_title": "Performance Shopping",
        "component_advertisingShoppingPerformance_informationTooltip_text":"<p>Measure the visibility of your Google Shopping campaigns on project keywords displaying Google Shopping results.</p><p><br>100% performance means being 1st on all keywords displaying a Google Shopping result.</p><p><br>Average position is the average ranking of your ads in the Google Shopping block.</p>",
        "component_advertisingShoppingAveragePosition_label": "average position",

        "component_advertisingAdwordsTopCompetitor_title": "Top Google Ads competitors",
        "component_advertisingAdwordsTopCompetitor_subtitle": "Most visible site on Google Ads",
        "component_advertisingAdwordsTopCompetitor_informationTooltip_title": "Top Google Ads competitors",
        "component_advertisingAdwordsTopCompetitor_informationTooltip_text":"<p>Allows you to identify the most visible sites in Google Ads on the project's keywords.</p><p><br>100% visibility means being 1st on all keywords that display a Google Ads result.</p>",

        "component_advertisingShoppingTopCompetitor_title": "Top competitors Shopping",
        "component_advertisingShoppingTopCompetitor_subtitle": "Most visible site on Google Shopping",
        "component_advertisingShoppingTopCompetitor_informationTooltip_title": "Top Shopping competitors",
        "component_advertisingShoppingTopCompetitor_informationTooltip_text":"<p>Allows you to identify the most visible sites in Google Shopping on the project's keywords.</p><p><br>100% visibility means being 1st on all keywords that display a Google Shopping result.</p>",

        "component_advertisingAdwordsAdvertisers_title": "Google Ads advertisers in my market",
        "component_advertisingAdwordsAdvertisers_subtitle": "Analysis of sites that buy ads on my keywords",
        "component_advertisingAdwordsAdvertisers_informationTooltip_title": "Google Ads advertisers in my market",
        "component_advertisingAdwordsAdvertisers_informationTooltip_text":"<p>Identify the list of Google Ads advertisers present on the project keywords. You will be able to find out the details of their campaign keywords and the ads associated with them.</p>",
        "component_advertisingAdwordsAdvertisers_table_header_advertiser": "Advertiser",
        "component_advertisingAdwordsAdvertisers_table_header_adwordsPerformance": "Google Ads Performance",
        "component_advertisingAdwordsAdvertisers_table_header_keywordCount": "Nb Keywords",
        "component_advertisingAdwordsAdvertisers_table_header_averagePosition": "Average position",
        "component_advertisingAdwordsAdvertisers_table_header_adCount": "Nb Ads",

        "component_advertisingAdwordsAdvertiserKeywords_title": "Advertiser's Google Ads positions:",
        "component_advertisingAdwordsAdvertiserKeywords_subtitle": "Lists the project keywords purchased by the advertiser for its Google Ads campaign.",
        "component_advertisingAdwordsAdvertiserKeywords_table_header_keyword": "Keyword",
        "component_advertisingAdwordsAdvertiserKeywords_table_header_position": "Position",
        "component_advertisingAdwordsAdvertiserKeywords_table_header_searchCount": "Number of searches",
        "component_advertisingAdwordsAdvertiserKeywords_table_header_estimatedTrafic": "Estimated Google Ads traffic",
        "component_advertisingAdwordsAdvertiserKeywords_table_header_ad": "Ad",

        "component_advertisingAdwordsAdvertiserAds_title": "Ads from:",
        "component_advertisingAdwordsAdvertiserAds_subtitle": "Lists the different ads used by the advertiser for its Google Ads campaign on the project keywords.",
        "component_advertisingAdwordsAdvertiserAds_footer": "Total number of impressions: ",
        "component_advertisingAdwordsAdvertiserAds_table_header_ad": "Ad",
        "component_advertisingAdwordsAdvertiserAds_table_header_keywordCount": "Nb targeted keywords",
        "component_advertisingAdwordsAdvertiserAds_table_header_printCount": "Nb potential impressions",

        "component_advertisingAdwordsCompetitionKeywords_title": "Google Ads competition in my market",
        "component_advertisingAdwordsCompetitionKeywords_subtitle": "Analysis of the advertising competition on my keywords",
        "component_advertisingAdwordsCompetitionKeywords_informationTooltip_title": "Google Ads competition on my market",
        "component_advertisingAdwordsCompetitionKeywords_informationTooltip_text":"<p>Allows you to identify for each keyword all the advertisers present on Google Ads.</p>",
        "component_advertisingAdwordsCompetitionKeywords_table_header_keyword": "Keyword",
        "component_advertisingAdwordsCompetitionKeywords_table_header_advertiserCount": "# of ads",
        "component_advertisingAdwordsCompetitionKeywords_table_header_searchCount": "Nb searches",
        "component_advertisingAdwordsCompetitionKeywords_table_header_competition": "Degree of competition",
        "component_advertisingAdwordsCompetitionKeywords_table_header_averageCpc": "Average CPC",

        "component_advertisingShoppingAdvertisers_title": "Google Shopping advertisers in my market",
        "component_advertisingShoppingAdvertisers_subtitle": "List of sites that feature products via Google Shopping on my keywords",
        "component_advertisingShoppingAdvertisers_informationTooltip_title": "Google Shopping advertisers in my market",
        "component_advertisingShoppingAdvertisers_informationTooltip_text": "Identify the list of Google Shopping advertisers present on the project keywords. You will be able to find out the details of their campaign keywords and the ads associated with them.",
        "component_advertisingShoppingAdvertisers_table_header_advertiser": "Advertiser",
        "component_advertisingShoppingAdvertisers_table_header_shoppingPerformance": "Performance Shopping",
        "component_advertisingShoppingAdvertisers_table_header_keywordCount": "Nb Mc",
        "component_advertisingShoppingAdvertisers_table_header_averagePosition": "Average Position",
        "component_advertisingShoppingAdvertisers_table_header_adCount": "Nb ads",

        "component_advertisingShoppingCompetitionKeywords_title": "Google Shopping competition in my market",
        "component_advertisingShoppingCompetitionKeywords_subtitle": "Google Shopping competition on my keywords",
        "component_advertisingShoppingCompetitionKeywords_informationTooltip_title": "Google Shopping competition in my market",
        "component_advertisingShoppingCompetitionKeywords_informationTooltip_text": "Allows you to identify for each keyword all the advertisers present on Google Shopping",
        "component_advertisingShoppingCompetitionKeywords_table_header_keyword": "Keyword",
        "component_advertisingShoppingCompetitionKeywords_table_header_advertiserCount": "Nb of shopping ads",
        "component_advertisingShoppingCompetitionKeywords_table_header_searchCount": "Nb searches",

        "component_advertisingAdwordsKeywordsAds_title": "Google Ads advertisers on keyword:",
        "component_advertisingAdwordsKeywordsAds_subtitle": "Lists the Google Ads present on the selected keyword.",
        "component_advertisingAdwordsKeywordsAds_table_header_rank": "Position",
        "component_advertisingAdwordsKeywordsAds_table_header_ad": "Ad",

        "component_advertisingShoppingAdvertiserKeywords_title": "Advertiser's Google Shopping positions:",
        "component_advertisingShoppingAdvertiserKeywords_subtitle": "Lists the project keywords purchased by the advertiser for its Google Shopping campaign.",
        "component_advertisingShoppingAdvertiserKeywords_table_header_keyword": "Keyword",
        "component_advertisingShoppingAdvertiserKeywords_table_header_position": "Position",
        "component_advertisingShoppingAdvertiserKeywords_table_header_searchCount": "Number of searches",
        "component_advertisingShoppingAdvertiserKeywords_table_header_estimatedTrafic": "Estimated shopping traffic",
        "component_advertisingShoppingAdvertiserKeywords_table_header_ad": "Ad",

        "component_advertisingShoppingAdvertiserAds_title": "Shopping Ads from:",
        "component_advertisingShoppingAdvertiserAds_subtitle": "Lists the different ads used by the advertiser for their Google Shopping campaign on the project keywords.",
        "component_advertisingShoppingAdvertiserAds_table_header_ad": "Ad",
        "component_advertisingShoppingAdvertiserAds_table_header_keywordCount": "Nb targeted keywords",
        "component_advertisingShoppingAdvertiserAds_table_header_printCount": "Nb potential impressions",

        "component_advertisingShoppingKeywordsAds_title": "Shopping ads on keyword:",
        "component_advertisingShoppingKeywordsAds_subtitle": "Lists the Google Shopping ads present on the selected keyword.",
        "component_advertisingShoppingKeywordsAds_table_header_rank": "Position",
        "component_advertisingShoppingKeywordsAds_table_header_ad": "Ad",
    },
}


// import defaultState from "@/store/components/componentsState";



const mutations = {
    // SET_COMPONENT_DATA(state, value) {
    //     state[value.stateName].data = value.data;
    //     state[value.stateName].error = value.error;
    //     state[value.stateName].isLoaded = true;
    // },
    // SET_COMPONENT_LOADING(state,value) {
    //     state[value].isLoaded = false;
    // },
    //
    // SET_GLOBAL_FILTER_FREQUENCY(state, value){
    //     state.globalFilterFrequency = value
    // },
    // SET_GLOBAL_FILTER_LOCATION(state, value){
    //     state.globalFilterLocation = value
    // },
    //
    // DISPLAY_AGGRID_POPIN(state, value) {
    //     state[value.stateName].show = value.show;
    //     if(value.promise) {
    //         state[value.stateName].promise = value.promise;
    //     }
    //     if(value.elementName){
    //         state[value.stateName].elementName = value.elementName;
    //     }
    //     if(value.elementId){
    //         state[value.stateName].elementId = value.elementId;
    //     }
    //     if(value.defaultFilter){
    //         state[value.stateName].defaultFilter = value.defaultFilter;
    //     }
    // },
    //
    // SET_ANALYTICS_REFRESH_TOKEN(state, refreshToken) {
    //     if(!state.projectInfo.data.subscriptionMeta){
    //         state.projectInfo.data.subscriptionMeta = {...state.projectInfo.data.subscriptionMeta, ...{analyticsToken:refreshToken}}
    //     }
    //     else{
    //         state.projectInfo.data.subscriptionMeta.analyticsToken = refreshToken
    //     }
    //
    // },
    // RESET_CURRENT_PROJECT(state) {
    //     Object.assign(state, defaultState.getDefaultState())
    // },
    // SET_PROJECT_TEMPORIZE_LOADER(state, temporize){
    //     state.temporizeProjectLoading = temporize
    // },
    //
    //
    // SET_SEO_VISIBILITY_NATURAL_POSITION_LIST_SPARKLINE(state, value){
    //
    //     state.seoVisibilityNaturalPositionList.data[value.externalKeywordId].evo.chartData = value.data
    //
    //
    //
    //
    // },
    // SET_COMPETITION_MARKET_LEADER_LIST_SPARKLINE(state, value){
    //     state.competitionMarketLeader.data[value.websiteId].evolution.chartData = value.data
    // },
    //
    // SELECT_SEGMENT_SIZE(state, name) {
    //     if(state.marketSegmentSegmentPerformance.data.series){
    //         state.marketSegmentSegmentPerformance.data.series.data.forEach((data)=>{
    //             if(data.name === name){
    //                 data.itemStyle.borderWidth = 2
    //             }
    //             else{
    //                 data.itemStyle.borderWidth = 0
    //             }
    //         })
    //     }
    //
    //     if(state.marketSegmentSegmentSize.data.series){
    //         state.marketSegmentSegmentSize.data.series[0].data.forEach((data)=>{
    //             if(data.name === name){
    //                 data.itemStyle.borderWidth = 2
    //             }
    //             else{
    //                 data.itemStyle.borderWidth = 0
    //             }
    //         })
    //     }
    //
    // },
}

export default mutations


export default {
    components:{
        content:[
            {
                id: 1,
                name: 'TrafficEvolutionConversion',
                category: 'chart',
                width: 12,
                section: 'dashboard',
                templateComponents: {
                    posX: null,
                    posY: null,
                    data: {}
                }
            },
            {
                id: 2,
                name: 'TrafficAcquisitionChannel',
                category: 'chart',
                width: 12,
                section: 'dashboard',
                templateComponents: {
                    posX: null,
                    posY: null,
                    data: {}
                }
            },
            {
                id: 4,
                name: 'TrafficEvolution',
                category: 'chart',
                width: 12,
                section: 'traffic',
                templateComponents: {
                    posX: null,
                    posY: null,
                    data: {}
                }
            },
            {
                id: 5,
                name: 'TrafficQualityTotalConversion',
                category: 'chart',
                width: 4,
                section: 'traffic',
                templateComponents: {
                    posX: null,
                    posY: null,
                    data: {}
                }
            },
            {
                id: 6,
                name: 'MarketSegmentSize',
                category: 'chart',
                width: 4,
                section: 'market-segment',
                templateComponents: {
                    posX: null,
                    posY: null,
                    data: {}
                }
            },
            {
                id: 7,
                name: 'MarketSegmentCompetitorCompare',
                category: 'chart',
                width: 8,
                section: 'market-segment',
                templateComponents: {
                    posX: null,
                    posY: null,
                    data: {}
                }
            },
            {
                id: 8,
                name: 'TitleBlock',
                category: 'title',
                width: 12,
                section: 'Sections & textes libres',
                templateComponents: {
                    posX: null,
                    posY: null,
                    data: {}
                }
            },
            {
                id: 9,
                name: 'TextBlock',
                category: 'text',
                width: 12,
                section: 'Sections & textes libres',
                templateComponents: {
                    posX: null,
                    posY: null,
                    data: {}
                }
            },
        ]
    },

    templateComponents1 : {
        content:[
            {
                id:1,
                pos_x:1,
                pos_y:1,
                data:{}
            }
        ],
    }
}

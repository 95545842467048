
import state from '@/store/components/componentsState.js'
import mutations from '@/store/components/componentsMutations.js'
import actions from '@/store/components/componentsActions.js'
import getters from '@/store/components/componentsGetters.js'




export default {
    namespaced: true,
    state: state.getDefaultState(),
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default {

    progressionAxisTopActionPriorities: {
        content:[
            {
                type: "position",
                name: "natural",
                potentialTrafficGain: 10,
                occurrenceProbabilityPercent: 100,
                websiteOccurrenceProbabilityPercent: 100,
                potentialProgressionKeywordCount: 178
            },
            {
                type: "market_segment",
                id: 1,
                name: "foo",
                keywordCount: 11,
                potentialTrafficGain: 4521,
                websiteOccurrenceProbabilityPercent: 10,
                potentialProgressionKeywordCount: 11
            }
        ]
    },


    progressionAxisGoogleBlockTypeFrequency: {
        content:[
            {positionType:'natural', occurrenceProbabilityPercent:100, performance:28},
            {positionType:'adwords', occurrenceProbabilityPercent:43, performance:41},
            {positionType:'video', occurrenceProbabilityPercent:8, performance:26},
            {positionType:'map', occurrenceProbabilityPercent:16, performance:41},
            {positionType:'zero', occurrenceProbabilityPercent:4, performance:21},
            {positionType:'shopping', occurrenceProbabilityPercent:35, performance:43},
            {positionType:'actuality', occurrenceProbabilityPercent:5, performance:0},
            {positionType:'image', occurrenceProbabilityPercent:11, performance:23},
        ]
    },


    progressionAxisImprovementWay: {
        content:[
            {positionType: 'natural', performance:28, occurrenceProbabilityPercent: 100, potentialTrafficGain:9300, potentialProgressionKeywordCount:13},
            {positionType: 'map', performance:41, occurrenceProbabilityPercent: 90, potentialTrafficGain:2570, potentialProgressionKeywordCount:9},
            {positionType: 'adwords', performance:41, occurrenceProbabilityPercent: 72, potentialTrafficGain:2220, potentialProgressionKeywordCount:11},
            {positionType: 'image', performance:23, occurrenceProbabilityPercent: 58, potentialTrafficGain:3060, potentialProgressionKeywordCount:15},
            {positionType: 'shopping', performance:43, occurrenceProbabilityPercent: 51, potentialTrafficGain:2570, potentialProgressionKeywordCount:8},
            {positionType: 'zero', performance:21, occurrenceProbabilityPercent: 50, potentialTrafficGain:2570, potentialProgressionKeywordCount:14},
        ]

    },


    progressionAxisImprovementPotentialKeywords: {

        content: [
            {
                "string": "magasin vélo",
                "rank": 5,
                "searchCount": 40500,
                "potentialTrafficGain": 11340
            },
            {
                "string": "vélo d'occasion",
                "rank": null,
                "searchCount": 22200,
                "potentialTrafficGain": 7992
            },
            {
                "string": "vélo homme",
                "rank": 3,
                "searchCount": 9900,
                "potentialTrafficGain": 3267
            }
        ]

    },

    progressionAxisGooglePositionList: {
        content:[
            {
                "string": "magasin vélo",
                "video": {
                    "rank": 5,
                    "rankEvolution": 1
                },
                "map": {
                    "rank": 7,
                    "rankEvolution": 3
                },
                "zero": true,
                "shopping": {
                    "rank": 4,
                    "rankEvolution": -2
                },
                "image": {
                    "rank": 2,
                    "rankEvolution": 0
                }
            },
            {
                "string": "vélo d'occasion",
                "natural": {
                    "rank": 5,
                    "rankEvolution": 1
                },
                "adwords": {
                    "rank": 1,
                    "rankEvolution": 0
                },
                "zero": false,
                "shopping": {
                    "rank": 3,
                    "rankEvolution": -2
                },
                "actuality": {
                    "rank": 7,
                    "rankEvolution": -1
                },
                "image": {
                    "rank": 7,
                    "rankEvolution": -5
                }
            },
            {
                "string": "vélo homme",
                "natural": {
                    "rank": 2,
                    "rankEvolution": -1
                },
                "adwords": {
                    "rank": 1,
                    "rankEvolution": 1
                },
                "zero": true,
                "shopping": {
                    "rank": 6,
                    "rankEvolution": 3
                },
                "actuality": {
                    "rank": 4,
                    "rankEvolution": -2
                },
                "image": {
                    "rank": 2,
                    "rankEvolution": 1
                }
            },
            {
                "string": "troc vélo",
                "natural": {
                    "rank": 9,
                    "rankEvolution": -1
                },
                "adwords": {
                    "rank": 4,
                    "rankEvolution": 0
                },
                "zero": false,
                "shopping": {
                    "rank": 3,
                    "rankEvolution": -2
                },
                "actuality": {
                    "rank": 7,
                    "rankEvolution": 1
                },
                "image": {
                    "rank": 3,
                    "rankEvolution": -1
                }
            }
        ]
    },
}

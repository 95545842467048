import { defineStore } from 'pinia'
import mock from "@/api/mock/mockData";
import defaultState from "@/stores/DefaultState";


export const useComponentStore = defineStore('ComponentStore', {
    state: () => (defaultState.getDefaultState()),
    getters: {
        getProjectError: state => state.projectError,
        getProjectsSummary: state => state.projectsSummary,
        getAnalyticsDataSource: state => state.analyticsDataSource,
        getProjectSegments: state => state.projectSegments,
        getProjectKeywords: state => state.projectKeywords,

        getProject: state => state.projectInfo,
        getSearchGeolocationsConfiguration: state => state.projectInfo.data.searchGeolocations,
        getAnalyticsViewConfiguration : state => state.projectInfo.data.analyticsViewConfiguration,
        getRivalsConfiguration : state => state.projectInfo.data.rivals,
        getKeywordGroupsConfiguration : state => state.projectInfo.data.keywordGroups,
        getMarketSegmentConfiguration : state => state.projectInfo.data.keywordGroups,
        getDefaultSearchGeolocation : state => state.projectInfo.data.defaultSearchGeolocation,
        getProjectUrl : state => state.projectInfo.data.url,
        getProjectReports : state => state.projectInfo.data.reports,
        getProjectInfoKeywords : state => state.projectInfo.data.keywords,

        getProjectCreationWaiting : state => ((state.subscriptionMeta.data && !state.subscriptionMeta.data.stackCreated) || (state.projectInfo.error && state.projectInfo.error.response.status === 404) || !(state.projectInfo.data.reports && state.projectInfo.data.reports.filter(report => report.complete).length > 0)),
        getShowPopin : state => state.showPopin,
        getGlobalFilterFrequency : state => state.globalFilterFrequency,
        getGlobalFilterLocation : state => state.globalFilterLocation,
        getSubscriptionMeta : state => state.subscriptionMeta,

        getChartSelectedSeriesName : state => state.chartSelectedSeriesName,

        getProjectCreationTmp : state => state.projectCreationTmp,
        getDefaultDashboardName : state => state.defaultDashboardName,
        getTableDensity : state => state.tableDensity,

    },

    actions: {
        async setDataComponent(payload){

            if(!payload.promise){
                this[payload.stateName].data = (!payload.dataFormatter ? mock[payload.stateName] : payload.dataFormatter(mock[payload.stateName]))
                this[payload.stateName].error = null
                this[payload.stateName].isLoaded = true

                return true
            }


            if(payload.asyncFormatter){
               return payload.promise.then((response) => {
                   return payload.dataFormatter(response).then((res)=>{
                        this[payload.stateName].data = res
                        this[payload.stateName].error = null
                        this[payload.stateName].isLoaded = true

                       return true
                    })
                }).catch((error) => {
                   this.projectError = true

                   this[payload.stateName].data = null
                   this[payload.stateName].error = error
                   this[payload.stateName].isLoaded = true

                   return false
               })
            }else{
                return payload.promise.then((response) => {
                    this[payload.stateName].data = (!payload.dataFormatter ? response.content : payload.dataFormatter(response))
                    this[payload.stateName].error = null
                    this[payload.stateName].isLoaded = true

                    return true
                }).catch((error) => {
                    this.projectError = true

                    this[payload.stateName].data = null
                    this[payload.stateName].error = error
                    this[payload.stateName].isLoaded = true

                    return false
                })
            }



        },
        setProjectError(status) {
            this.projectError = status
        },
        showAgGridPopin(status) {
            this.showPopin = status
        },
        setGlobalFilterFrequency(value) {
            this.globalFilterFrequency = value
        },
        setGlobalFilterLocation(value) {
            this.globalFilterLocation = value
        },
        resetCurrentProject(){
            Object.assign(this.$state, defaultState.getDefaultState())
        },
        setSegmentSizeSelected(name){
            this.chartSelectedSeriesName = name
        },

        setProjectCreationTmpValue(key, value){
            this.projectCreationTmp[key] = value
            localStorage.setItem('projectWizardTmp',JSON.stringify(this.projectCreationTmp))
        },
        setDefaultDashboardName(value){
            this.defaultDashboardName = value
        },
        setTableDensity(value){
            this.tableDensity = value
        }

    }
})

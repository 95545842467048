
import thumbnail from "@/api/mock/configuration/thumbnail";

export default {

    projectKeywords:{
        content: [
            {
                "id": 10985,
                "string": "2015 seo",
                "keywordGroups": [
                    {
                        "id": 82,
                        "name": "seo"
                    }
                ]
            },
            {
                "id": 10991,
                "string": "blog entreprise exemple",
                "keywordGroups": [
                    {
                        "id": 83,
                        "name": "blog"
                    }
                ]
            },
            {
                "id": 10975,
                "string": "buzzea",
                "keywordGroups": [
                    {
                        "id": 86,
                        "name": "autre"
                    }
                ]
            },
            {
                "id": 10989,
                "string": "camp day 2015",
                "keywordGroups": [
                    {
                        "id": 86,
                        "name": "autre"
                    }
                ]
            },
            {
                "id": 10990,
                "string": "exemple blog entreprise",
                "keywordGroups": [
                    {
                        "id": 83,
                        "name": "blog"
                    }
                ]
            },
            {
                "id": 10981,
                "string": "logiciel site map",
                "keywordGroups": [
                    {
                        "id": 84,
                        "name": "logiciel"
                    }
                ]
            },
            {
                "id": 10986,
                "string": "logiciel sitemap",
                "keywordGroups": [
                    {
                        "id": 84,
                        "name": "logiciel"
                    }
                ]
            },
            {
                "id": 10982,
                "string": "logiciel sitemap gratuit",
                "keywordGroups": [
                    {
                        "id": 84,
                        "name": "logiciel"
                    }
                ]
            },
            {
                "id": 10988,
                "string": "mobile ready google",
                "keywordGroups": [
                    {
                        "id": 86,
                        "name": "autre"
                    }
                ]
            },
            {
                "id": 10977,
                "string": "score de visibilité",
                "keywordGroups": [
                    {
                        "id": 86,
                        "name": "autre"
                    }
                ]
            },
            {
                "id": 10971,
                "string": "seeurank mac",
                "keywordGroups": [
                    {
                        "id": 85,
                        "name": "seeurank"
                    }
                ]
            },
            {
                "id": 10987,
                "string": "seo 2014",
                "keywordGroups": [
                    {
                        "id": 82,
                        "name": "seo"
                    }
                ]
            },
            {
                "id": 10968,
                "string": "seo 2015",
                "keywordGroups": [
                    {
                        "id": 82,
                        "name": "seo"
                    }
                ]
            },
            {
                "id": 10978,
                "string": "seo award",
                "keywordGroups": [
                    {
                        "id": 82,
                        "name": "seo"
                    }
                ]
            },
            {
                "id": 10974,
                "string": "seo awards",
                "keywordGroups": [
                    {
                        "id": 82,
                        "name": "seo"
                    }
                ]
            },
            {
                "id": 10973,
                "string": "seo awards 2014",
                "keywordGroups": [
                    {
                        "id": 82,
                        "name": "seo"
                    }
                ]
            },
            {
                "id": 10972,
                "string": "seo awards 2015",
                "keywordGroups": [
                    {
                        "id": 82,
                        "name": "seo"
                    }
                ]
            },
            {
                "id": 10980,
                "string": "seo conferences 2015",
                "keywordGroups": [
                    {
                        "id": 82,
                        "name": "seo"
                    }
                ]
            },
            {
                "id": 10984,
                "string": "seo en 2015",
                "keywordGroups": [
                    {
                        "id": 82,
                        "name": "seo"
                    }
                ]
            },
            {
                "id": 10983,
                "string": "seo for 2015",
                "keywordGroups": [
                    {
                        "id": 82,
                        "name": "seo"
                    }
                ]
            },
            {
                "id": 10969,
                "string": "webtools yooda",
                "keywordGroups": [
                    {
                        "id": 86,
                        "name": "autre"
                    }
                ]
            },
            {
                "id": 10966,
                "string": "yooda map",
                "keywordGroups": [
                    {
                        "id": 86,
                        "name": "autre"
                    }
                ]
            },
            {
                "id": 10976,
                "string": "yooda sitemap",
                "keywordGroups": [
                    {
                        "id": 86,
                        "name": "autre"
                    }
                ]
            },
            {
                "id": 10965,
                "string": "yooda submit",
                "keywordGroups": [
                    {
                        "id": 86,
                        "name": "autre"
                    }
                ]
            },
            {
                "id": 10979,
                "string": "yooda webtools",
                "keywordGroups": [
                    {
                        "id": 86,
                        "name": "autre"
                    }
                ]
            },
            {
                "id": 10970,
                "string": "yoodamap",
                "keywordGroups": [
                    {
                        "id": 86,
                        "name": "autre"
                    }
                ]
            },
            {
                "id": 10967,
                "string": "yoodx",
                "keywordGroups": [
                    {
                        "id": 86,
                        "name": "autre"
                    }
                ]
            }
        ]
    },

    projectSegments:{
        content: [
            {
                "id": 86,
                "name": "autre",
                "keywords": [
                    {
                        "id": 10965,
                        "string": "yooda submit"
                    },
                    {
                        "id": 10966,
                        "string": "yooda map"
                    },
                    {
                        "id": 10967,
                        "string": "yoodx"
                    },
                    {
                        "id": 10969,
                        "string": "webtools yooda"
                    },
                    {
                        "id": 10970,
                        "string": "yoodamap"
                    },
                    {
                        "id": 10975,
                        "string": "buzzea"
                    },
                    {
                        "id": 10976,
                        "string": "yooda sitemap"
                    },
                    {
                        "id": 10977,
                        "string": "score de visibilité"
                    },
                    {
                        "id": 10979,
                        "string": "yooda webtools"
                    },
                    {
                        "id": 10988,
                        "string": "mobile ready google"
                    },
                    {
                        "id": 10989,
                        "string": "camp day 2015"
                    }
                ]
            },
            {
                "id": 83,
                "name": "blog",
                "keywords": [
                    {
                        "id": 10990,
                        "string": "exemple blog entreprise"
                    },
                    {
                        "id": 10991,
                        "string": "blog entreprise exemple"
                    }
                ]
            },
            {
                "id": 84,
                "name": "logiciel",
                "keywords": [
                    {
                        "id": 10981,
                        "string": "logiciel site map"
                    },
                    {
                        "id": 10982,
                        "string": "logiciel sitemap gratuit"
                    },
                    {
                        "id": 10986,
                        "string": "logiciel sitemap"
                    }
                ]
            },
            {
                "id": 85,
                "name": "seeurank",
                "keywords": [
                    {
                        "id": 10971,
                        "string": "seeurank mac"
                    }
                ]
            },
            {
                "id": 85,
                "name": "segment vide",
                "keywords": []
            },
            {
                "id": 82,
                "name": "seo",
                "keywords": [
                    {
                        "id": 10968,
                        "string": "seo 2015"
                    },
                    {
                        "id": 10972,
                        "string": "seo awards 2015"
                    },
                    {
                        "id": 10973,
                        "string": "seo awards 2014"
                    },
                    {
                        "id": 10974,
                        "string": "seo awards"
                    },
                    {
                        "id": 10978,
                        "string": "seo award"
                    },
                    {
                        "id": 10980,
                        "string": "seo conferences 2015"
                    },
                    {
                        "id": 10983,
                        "string": "seo for 2015"
                    },
                    {
                        "id": 10984,
                        "string": "seo en 2015"
                    },
                    {
                        "id": 10985,
                        "string": "2015 seo"
                    },
                    {
                        "id": 10987,
                        "string": "seo 2014"
                    }
                ]
            }
        ]

    },

    projectList: {
        content:[
            {
                "id": 1,
                "name": "insight.yooda.com",
                "url": "https://insight.yooda.com",
                "thumbnail": thumbnail.insight,
                "computeStatus": "waiting",
                "subDomainAnalysis": true,
                "analyticsViewConfiguration": {
                    "id": 94595859,
                    "name": "blog.yooda.com"
                }
            },
            {
                "id": 1,
                "name": "seeurank.yooda.com",
                "url": "https://seeurank.yooda.com",
                "thumbnail": thumbnail.seeurank,
                "computeStatus": "waiting",
                "subDomainAnalysis": true,
                "analyticsViewConfiguration": {
                    "id": 94595859,
                    "name": "blog.yooda.com"
                }
            },
            {
                "id": 1,
                "name": "yooda.com",
                "url": "https://yooda.com",
                "thumbnail": thumbnail.yooda,
                "computeStatus": "waiting",
                "subDomainAnalysis": true,
                "analyticsViewConfiguration": {
                    "id": 94595859,
                    "name": "blog.yooda.com"
                }
            },
            {
                "id": 1,
                "name": "blog.yooda.com",
                "url": "https://blog.yooda.com",
                "thumbnail": thumbnail.yooda,
                "computeStatus": "waiting",
                "subDomainAnalysis": true,
                "analyticsViewConfiguration": {
                    "id": 94595859,
                    "name": "blog.yooda.com"
                }
            },
        ]
    },

    projectsSummary:{
        content:[
            {
                "name": "projet 01",
                "distinctKeywordCount": "315",
                "distinctSearchGeolocationCount": "2",
                "distinctRivalCount": "4",
                "requestCount": "630"
            },
            {
                "name": "projet 02",
                "distinctKeywordCount": "112",
                "distinctSearchGeolocationCount": "3",
                "distinctRivalCount": "5",
                "requestCount": "336"
            },
        ]
    },

    projectInfo:{
        content:{
            "id": 1,
            "name": "https://blog.yooda.com",
            "url": "https://blog.yooda.com",
            "thumbnail": null,
            "computeStatus": "waiting",
            "subDomainAnalysis": true,
            "analyticsViewConfiguration": {
                "id": 94595859,
                "name": "blog.yooda.com"
            },
            "searchGeolocations": [
                {
                    "id": 1,
                    "canonicalName": "France",
                    "latitude": 46.5,
                    "longitude": 61.5,
                    "isDefault": true
                },
                {
                    "id": 1065,
                    "canonicalName": "Marseille,Provence-Alpes-Cote d'Azur,France",
                    "latitude": 43.2961743,
                    "longitude": 5.3699525,
                    "isDefault": false
                },
                {
                    "id": 1155,
                    "canonicalName": "Montpellier,Occitanie,France",
                    "latitude": 43.6112422,
                    "longitude": 3.8767337,
                    "isDefault": false
                },
                {
                    "id": 1715,
                    "canonicalName": "Toulouse,Occitanie,France",
                    "latitude": 43.6044622,
                    "longitude": 1.4442469,
                    "isDefault": false
                }
            ],
            "keywords": [
                {
                    "id": 10985,
                    "string": "2015 seo"
                },
                {
                    "id": 10991,
                    "string": "blog entreprise exemple"
                },
                {
                    "id": 10975,
                    "string": "buzzea"
                },
                {
                    "id": 10989,
                    "string": "camp day 2015"
                },
                {
                    "id": 10990,
                    "string": "exemple blog entreprise"
                },
                {
                    "id": 10981,
                    "string": "logiciel site map"
                },
                {
                    "id": 10986,
                    "string": "logiciel sitemap"
                },
                {
                    "id": 10982,
                    "string": "logiciel sitemap gratuit"
                },
                {
                    "id": 10988,
                    "string": "mobile ready google"
                },
                {
                    "id": 10977,
                    "string": "score de visibilité"
                },
                {
                    "id": 10971,
                    "string": "seeurank mac"
                },
                {
                    "id": 10987,
                    "string": "seo 2014"
                },
                {
                    "id": 10968,
                    "string": "seo 2015"
                },
                {
                    "id": 10978,
                    "string": "seo award"
                },
                {
                    "id": 10974,
                    "string": "seo awards"
                },
                {
                    "id": 10973,
                    "string": "seo awards 2014"
                },
                {
                    "id": 10972,
                    "string": "seo awards 2015"
                },
                {
                    "id": 10980,
                    "string": "seo conferences 2015"
                },
                {
                    "id": 10984,
                    "string": "seo en 2015"
                },
                {
                    "id": 10983,
                    "string": "seo for 2015"
                },
                {
                    "id": 10969,
                    "string": "webtools yooda"
                },
                {
                    "id": 10966,
                    "string": "yooda map"
                },
                {
                    "id": 10976,
                    "string": "yooda sitemap"
                },
                {
                    "id": 10965,
                    "string": "yooda submit"
                },
                {
                    "id": 10979,
                    "string": "yooda webtools"
                },
                {
                    "id": 10970,
                    "string": "yoodamap"
                },
                {
                    "id": 10967,
                    "string": "yoodx"
                }
            ],
            "rivals": [
                {
                    "id": 104,
                    "url": "http://www.itligentia.com"
                },
                {
                    "id": 106,
                    "url": "http://www.yeca.pro"
                },
                {
                    "id": 105,
                    "url": "https://madrileno.fr"
                }
            ],
            "keywordGroups": [
                {
                    "id": 86,
                    "name": "autre"
                },
                {
                    "id": 83,
                    "name": "blog"
                },
                {
                    "id": 84,
                    "name": "logiciel"
                },
                {
                    "id": 85,
                    "name": "seeurank"
                },
                {
                    "id": 82,
                    "name": "seo"
                }
            ],
            "pageGroups": [],
            "subscriptionMeta": {
                "analyticsToken": "1//03modeQ0BovYLCgYIARAAGAMSNwF-L9IrUKgRyqaCuHDQFtcY6vYu9qCnY3FnYljXWL3GKSrDWm9MjwFLvJ03SXw98Po7YZsWJ58"
            },
            "reports": [
                {
                    "id": 1,
                    "createdAt": "2020-02-15T00:00:00+00:00",
                    "complete": true
                },
                {
                    "id": 2,
                    "createdAt": "2020-02-16T00:00:00+00:00",
                    "complete": true
                },
                {
                    "id": 3,
                    "createdAt": "2020-02-17T00:00:00+00:00",
                    "complete": false
                }
            ],
            reportingModel:{
                name:'default',
                config:[
                    {"x":0,"y":0,"w":12,"h":13,"i":"trafficEvolutionConversion"},
                    {"x":0,"y":0,"w":4,"h":13,"i":"trafficAcquisitionChanel"},
                    {"x":4,"y":0,"w":4,"h":13,"i":"competitionTopCompetitor"},
                    {"x":8,"y":0,"w":4,"h":13,"i":"marketSegmentEvolution"},
                    {"x":0,"y":0,"w":4,"h":13,"i":"marketSegmentProgressionPotential"},
                    {"x":4,"y":0,"w":4,"h":13,"i":"advertisingPerformance"},
                    {"x":8,"y":0,"w":4,"h":13,"i":"seoVisibilityPresenceEvolution"},
                    {"x":0,"y":0,"w":4,"h":13,"i":"localVisibilityByLocation"},
                    {"x":4,"y":0,"w":4,"h":13,"i":"websitePagesBestPages"},
                    {"x":8,"y":0,"w":4,"h":13,"i":"marketSegmentSize"},
                    {"x":0,"y":0,"w":3,"h":5,"i":"trafficQualityTotalTurnover"},
                    {"x":3,"y":0,"w":3,"h":5,"i":"trafficQualityTurnoverByVisitor"},
                    {"x":6,"y":0,"w":3,"h":5,"i":"trafficQualityConversion"},
                    {"x":0,"y":0,"w":12,"h":17,"i":"trafficSource"},
                    {"x":0,"y":0,"w":12,"h":13,"i":"trafficQuality"},
                    {"x":0,"y":0,"w":12,"h":13,"i":"trafficIncomingWebsite"},
                    {"x":0,"y":0,"w":4,"h":13,"i":"marketSegmentSize2"},
                    {"x":4,"y":0,"w":8,"h":13,"i":"marketSegmentCompetitorMarketCompare"},
                    {"x":0,"y":0,"w":6,"h":13,"i":"marketSegmentSegmentSize"},
                    {"x":6,"y":0,"w":6,"h":13,"i":"marketSegmentSegmentPerformance"},
                    {"x":0,"y":0,"w":12,"h":17,"i":"marketSegmentVisibility"},
                    {"x":0,"y":0,"w":12,"h":17,"i":"marketSegmentBestProgressionPotential"},
                    {"x":0,"y":0,"w":12,"h":20,"i":"progressionAxisGoogleBlockTypeFrequency"},
                    {"x":0,"y":0,"w":12,"h":15,"i":"progressionAxisImprovementWay"},
                    {"x":0,"y":0,"w":12,"h":15,"i":"progressionAxisGooglePositionList"},
                    {"x":0,"y":0,"w":12,"h":13,"i":"advertisingIncomingTraffic"},
                    {"x":0,"y":0,"w":3,"h":10,"i":"advertisingConversion"},
                    {"x":3,"y":0,"w":3,"h":10,"i":"advertisingTurnover"},
                    {"x":6,"y":0,"w":3,"h":10,"i":"advertisingAdwordsTopCompetitor"},
                    {"x":9,"y":0,"w":3,"h":10,"i":"advertisingShoppingTopCompetitor"},
                    {"x":0,"y":0,"w":6,"h":10,"i":"advertisingAdwordsPerformance"},
                    {"x":6,"y":0,"w":6,"h":10,"i":"advertisingShoppingPerformance"},
                    {"x":0,"y":0,"w":12,"h":20,"i":"advertisingAdwordsAdvertisers"},
                    {"x":0,"y":0,"w":12,"h":20,"i":"advertisingAdwordsCompetitionKeywords"},
                    {"x":0,"y":0,"w":12,"h":20,"i":"advertisingShoppingAdvertisers"},
                    {"x":0,"y":0,"w":12,"h":20,"i":"advertisingShoppingCompetitionKeywords"},
                    {"x":0,"y":0,"w":12,"h":17,"i":"seoVisibilityIncomingTraffic"},
                    {"x":0,"y":0,"w":4,"h":8,"i":"seoVisibilityConversion"},
                    {"x":4,"y":0,"w":4,"h":8,"i":"seoVisibilitySeoTurnover"},
                    {"x":8,"y":0,"w":4,"h":8,"i":"seoVisibilityAveragePosition"},
                    {"x":0,"y":0,"w":6,"h":17,"i":"seoVisibilityTopPositionCount"},
                    {"x":6,"y":0,"w":6,"h":17,"i":"seoVisibilityPositionRepartition"},
                    {"x":0,"y":0,"w":12,"h":20,"i":"seoVisibilityNaturalPositionList"},
                    {"x":0,"y":0,"w":12,"h":20,"i":"seoVisibilityCompareKeywordsPositions"},
                    {"x":0,"y":0,"w":12,"h":17,"i":"localVisibilityCompetitor"},
                    {"x":0,"y":0,"w":12,"h":20,"i":"localVisibilityKeywordsPositions"},
                    {"x":0,"y":0,"w":12,"h":17,"i":"localVisibilityMyBusinessKeywordsPositions"},
                    {"x":0,"y":0,"w":12,"h":17,"i":"localVisibilityLocalAdvertisingPresence"},
                    {"x":0,"y":0,"w":4,"h":13,"i":"competitionTopCompetitor2"},
                    {"x":4,"y":0,"w":4,"h":13,"i":"competitionBestProgression"},
                    {"x":8,"y":0,"w":4,"h":13,"i":"competitionNewCompetitor"},
                    {"x":0,"y":0,"w":6,"h":17,"i":"competitionVisibilityEvolution"},
                    {"x":6,"y":0,"w":6,"h":17,"i":"competitionCompetitorCompare"},
                    {"x":0,"y":0,"w":12,"h":17,"i":"competitionMarketLeader"},
                    {"x":0,"y":0,"w":12,"h":13,"i":"websitePagesPositionedCountEvolution"},
                    {"x":0,"y":0,"w":12,"h":17,"i":"websitePagesBestPositionedPages"}
                ]
            }
        }
    },

    projectCompetitors:{
        content:[
            {
                "id": 104,
                "url": "http://www.itligentia.com"
            },
            {
                "id": 106,
                "url": "http://www.yeca.pro"
            },
            {
                "id": 105,
                "url": "https://madrileno.fr"
            }
        ]
    },

    searchEngines:{
        content:[
            {
                "id": 1,
                "name": "France",
                "url": "https://www.google.fr/",
                "countryCode":"FR"
            },
            {
                "id": 2,
                "name": "Belgique",
                "url": "https://www.google.fr/",
                "countryCode":"BE"
            }
        ]
    },

    projectLocation:{
        "content": [
            {
                "id": 1,
                "name": "googlefr",
                "url": "https://www.google.fr/",
                "searchGeolocations": [
                    {
                        "id": 1,
                        "name": "France",
                        "canonicalName": "France",
                        "targetType": "country"
                    },
                    {
                        "id": 2,
                        "name": "Toulouse",
                        "canonicalName": "Toulouse,Occitanie,France",
                        "targetType": "city"
                    },
                    {
                        "id": 3,
                        "name": "Marseille",
                        "canonicalName": "Marseille,Provence-Alpes-Cote d'Azur,France",
                        "targetType": "city"
                    },
                    {
                        "id": 4,
                        "name": "Montpellier",
                        "canonicalName": "Montpellier,Occitanie,France",
                        "targetType": "city"
                    },
                ]
            }
        ]
    },

    searchGeoloc1:{
        content:[
            {
                "id": 1,
                "criteriaId": 2250,
                "countryCode": "fr",
                "name": "France",
                "canonicalName": "France",
                "parentId": null,
                "targetType": "country",
                "status": "active"
            },
            {
                "id": 2,
                "criteriaId": 9049254,
                "countryCode": "fr",
                "name": "Montpellier",
                "canonicalName": "Montpellier,Occitanie,France",
                "parentId": 9068898,
                "targetType": "city",
                "status": "active"
            },
            {
                "id": 3,
                "criteriaId": 9049255,
                "countryCode": "fr",
                "name": "Marseille",
                "canonicalName": "Marseille,Provence-Alpes-Cote-d'Azur,France",
                "parentId": 20321,
                "targetType": "city",
                "status": "active"
            },
            {
                "id": 4,
                "criteriaId": 1005946,
                "countryCode": "fr",
                "name": "Lyon",
                "canonicalName": "Lyon,Auvergne-Rhon-Alpes,France",
                "parentId": 20321,
                "targetType": "city",
                "status": "active"
            },
            {
                "id": 5,
                "criteriaId": 9049256,
                "countryCode": "fr",
                "name": "Toulouse",
                "canonicalName": "Toulouse,Occitanie,France",
                "parentId": 20321,
                "targetType": "city",
                "status": "active"
            },
            {
                "id": 6,
                "criteriaId": 9049257,
                "countryCode": "fr",
                "name": "Bordeaux",
                "canonicalName": "Bordeaux,Nouvelle-Aquitaine,France",
                "parentId": 20316,
                "targetType": "city",
                "status": "active"
            },
        ]
    },
    searchGeoloc2:{
        content:[
            {
                "id": 7,
                "criteriaId": 2250,
                "countryCode": "be",
                "name": "Belgique",
                "canonicalName": "Belgique",
                "parentId": null,
                "targetType": "country",
                "status": "active"
            },
            {
                "id": 8,
                "criteriaId": 9049254,
                "countryCode": "be",
                "name": "Bruxelles",
                "canonicalName": "Bruxelles,Bruxelles-Capitale,Belgique",
                "parentId": 9068898,
                "targetType": "city",
                "status": "active"
            },
            {
                "id": 9,
                "criteriaId": 9049255,
                "countryCode": "be",
                "name": "Bruges",
                "canonicalName": "Bruges,Flandre-Occidentale,Belgique",
                "parentId": 20321,
                "targetType": "city",
                "status": "active"
            },
            {
                "id": 10,
                "criteriaId": 1005946,
                "countryCode": "be",
                "name": "Anvers",
                "canonicalName": "Anvers,Province d'Anvers,Belgique",
                "parentId": 20321,
                "targetType": "city",
                "status": "active"
            },
            {
                "id": 11,
                "criteriaId": 9049256,
                "countryCode": "be",
                "name": "Namur",
                "canonicalName": "Namur,Province de Namur,Belgique",
                "parentId": 20321,
                "targetType": "city",
                "status": "active"
            },
            {
                "id": 12,
                "criteriaId": 9049257,
                "countryCode": "be",
                "name": "Louvain",
                "canonicalName": "Louvain, Province du Brabant flamand,Belgique",
                "parentId": 20316,
                "targetType": "city",
                "status": "active"
            },
        ]
    },

    language1:{
        content:[
            {
                id:1,
                languageCode:'fr',
                name:'Français'
            }
        ]
    },
    language2:{
        content:[
            {
                id:2,
                languageCode:'be',
                name:'Français'
            },
            {
                id:3,
                languageCode:'be',
                name:'Flamand'
            }
        ]
    },

    subscriptionMeta:{
        content:{
            "externalSubscriptionId": 3,
            "stackCreated":true,
            "analyticsToken": "1//03modeQ0BovYLCgYIARAAGAMSNwF-L9IrUKgRyqaCuHDQFtcY6vYu9qCnY3FnYljXWL3GKSrDWm9MjwFLvJ03SXw98Po7YZsWJ58"
        }
    },

    createProject: {
        content: {
            dataItem:{
                projectId: 1
            }
        }
    },

    createKeyword: {
        content: {
            dataItem:{
                id: Math.floor(Math.random() * (99999 - 10) ) + 10
            }
        }
    },

    analyticsViewConfiguration: {
        content: {
            dataItem:{
                id: Math.floor(Math.random() * (99999 - 10) ) + 10
            }
        }
    },

    deleteKeywords: {
        content: true
    },
    suggestedKeywords: {
        content: [
            {
                "id": 6104257,
                "searchExpression": "yooda"
            },
            {
                "id": 130965642,
                "searchExpression": "yooda insight"
            },
            {
                "id": 135273719,
                "searchExpression": "insight yooda"
            },
            {
                "id": 978486791,
                "searchExpression": "yooda insights"
            },
            {
                "id": 725742519,
                "searchExpression": "yoda insight"
            },
            {
                "id": 521762307,
                "searchExpression": "yoodara"
            },
            {
                "id": 6162822,
                "searchExpression": "seeurank"
            },
            {
                "id": 208084332,
                "searchExpression": "aldeis"
            },
            {
                "id": 725742523,
                "searchExpression": "insight yoda"
            },
            {
                "id": 719680965,
                "searchExpression": "yooda seo"
            },
            {
                "id": 132663424,
                "searchExpression": "yooda technology"
            },
            {
                "id": 6162820,
                "searchExpression": "yooda seeurank"
            },
            {
                "id": 6162819,
                "searchExpression": "yooda submit"
            },
            {
                "id": 75498233,
                "searchExpression": "webtools yooda"
            },
            {
                "id": 135437669,
                "searchExpression": "yooda manager"
            },
            {
                "id": 342849495,
                "searchExpression": "spammy"
            },
            {
                "id": 6162824,
                "searchExpression": "yooda sitemap"
            },
            {
                "id": 47553832,
                "searchExpression": "yoovi"
            }
        ]
    },


}


export default {
    computedReportingList : {
        content:[
            {date:"2022-01-01",project:'yooda.com', frequency:'year', projectId:1},
            {date:"2022-01-01",project:'seeurank.yooda.com', frequency:'year', projectId:1},
            {date:"2022-01-01",project:'blog.yooda.com', frequency:'year', projectId:1},
            {date:"2022-04-18",project:'decathlon.com', frequency:'week', projectId:1},
            {date:"2022-04-18",project:'intersport.com', frequency:'week', projectId:1},
            {date:"2022-04-04",project:'kenzai.fr', frequency:'month', projectId:1},
        ]
    },
    scheduledReportingList : {
        content:[
            {date:"2022-01-01",project:'yooda.com', frequency:'year'},
            {date:"2022-01-01",project:'seeurank.yooda.com', frequency:'year'},
            {date:"2022-01-01",project:'blog.yooda.com', frequency:'year'},
            {date:"2022-04-18",project:'decathlon.com', frequency:'week'},
            {date:"2022-04-18",project:'intersport.com', frequency:'week'},
            {date:"2022-04-04",project:'kenzai.fr', frequency:'month'},
        ]
    },

}

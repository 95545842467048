
export default {
    websitePagesPositionedCountEvolution : {
        content:[
            {
                date: "2020-01-01",
                expandedDistinctPageCounts: [
                    {positionType: "natural",distinctPageCount: 401},
                    {positionType: "adwords",distinctPageCount: 22},
                    {positionType: "map",distinctPageCount: 2},
                    {positionType: "snippet",distinctPageCount: 3},
                    {positionType: "shopping",distinctPageCount: 3},
                    {positionType: "actuality",distinctPageCount: 1}
                ],
                cumulatedDistinctPageCount: 20
            },
            {
                date: "2020-02-01",
                expandedDistinctPageCounts: [
                    {positionType: "natural",distinctPageCount: 18},
                    {positionType: "video",distinctPageCount: 1},
                    {positionType: "shopping",distinctPageCount: 3},
                    {positionType: "actuality",distinctPageCount: 2}
                ],
                cumulatedDistinctPageCount: 24
            },
            {
                date: "2020-03-01",
                expandedDistinctPageCounts: [
                    {positionType: "natural",distinctPageCount: 20},
                    {positionType: "video",distinctPageCount: 2},
                    {positionType: "shopping",distinctPageCount: 3},
                    {positionType: "actuality",distinctPageCount: 1}
                ],
                cumulatedDistinctPageCount: 26
            },
            {
                date: "2020-03-02",
                expandedDistinctPageCounts: [
                    {positionType: "natural",distinctPageCount: 19},
                    {positionType: "video",distinctPageCount: 2},
                    {positionType: "shopping",distinctPageCount: 3},
                    {positionType: "actuality",distinctPageCount: 1}
                ],
                cumulatedDistinctPageCount: 25
            },
            {
                date: "2020-03-09",
                expandedDistinctPageCounts: [
                    {positionType: "natural",distinctPageCount: 25},
                    {positionType: "video",distinctPageCount: 2},
                    {positionType: "shopping",distinctPageCount: 4},
                    {positionType: "actuality",distinctPageCount: 2}
                ],
                cumulatedDistinctPageCount: 33
            },
            {
                date: "2020-03-10",
                expandedDistinctPageCounts: [
                    {positionType: "natural",distinctPageCount: 28},
                    {positionType: "video",distinctPageCount: 2},
                    {positionType: "shopping",distinctPageCount: 3},
                    {positionType: "actuality",distinctPageCount: 1}
                ],
                cumulatedDistinctPageCount: 34
            },
            {
                date: "2020-03-11",
                expandedDistinctPageCounts: [
                    {positionType: "natural",distinctPageCount: 32},
                    {positionType: "video",distinctPageCount: 2},
                    {positionType: "shopping",distinctPageCount: 2},
                    {positionType: "actuality",distinctPageCount: 2}
                ],
                cumulatedDistinctPageCount: 38
            },
            {
                date: "2020-03-12",
                expandedDistinctPageCounts: [
                    {positionType: "natural",distinctPageCount: 33},
                    {positionType: "video",distinctPageCount: 1},
                    {positionType: "shopping",distinctPageCount: 3},
                    {positionType: "actuality",distinctPageCount: 0}
                ],
                cumulatedDistinctPageCount: 37
            },
            {
                date: "2020-03-13",
                expandedDistinctPageCounts: [
                    {positionType: "natural",distinctPageCount: 35},
                    {positionType: "video",distinctPageCount: 2},
                    {positionType: "shopping",distinctPageCount: 3},
                    {positionType: "actuality",distinctPageCount: 2}
                ],
                cumulatedDistinctPageCount: 42
            },
            {
                date: "2020-03-14",
                expandedDistinctPageCounts: [
                    {positionType: "natural",distinctPageCount: 40},
                    {positionType: "video",distinctPageCount: 2},
                    {positionType: "shopping",distinctPageCount: 4},
                    {positionType: "actuality",distinctPageCount: 3}
                ],
                cumulatedDistinctPageCount: 49
            },
            {
                date: "2020-03-15",
                expandedDistinctPageCounts: [
                    {positionType: "natural",distinctPageCount: 401},
                    {positionType: "adwords",distinctPageCount: 22},
                    {positionType: "map",distinctPageCount: 2},
                    {positionType: "snippet",distinctPageCount: 3},
                    {positionType: "shopping",distinctPageCount: 3},
                    {positionType: "actuality",distinctPageCount: 1},
                    {positionType: "video",distinctPageCount: 1},
                    {positionType: "image",distinctPageCount: 1},
                ],
                cumulatedDistinctPageCount: 46
            },
        ]

    },

    websitePagesBestPositionedPages: {
        context:{ inputs:{ dateRangeType:1, isReporting:false } },
        content:[
            {
                url: "/velo-electrique",
                pageId: 1,
                distinctPositionCount: 12,
                traffic: 3779,
                turnover: 493,
                conversion: 4
            },
            {
                url: "/velo-homme",
                pageId: 1,
                distinctPositionCount: 6,
                traffic: 2365,
                turnover: 587,
                conversion: 7
            },
            {
                url: "/vtt",
                pageId: 1,
                distinctKeywordCount: 8,
                traffic: 3887,
                turnover: 1287,
                conversion: 11
            },
        ]

    },
    websitePagesBestPositionedPagePositions: {
        content: [
            {
                string: "meilleur vélo électrique",
                rank: 1,
                visibility: 150,
                searchCount: 10,
                rankEvolution: 2,
                positionType: "natural"
            },
            {
                string: "vélo électrique d'occasion",
                rank: 5,
                visibility: 2700,
                searchCount: 30,
                rankEvolution: -2,
                positionType: "adwords"
            }
        ]
    }
}


export default {

    localVisibilityCompetitor : {
        content: [
            {
                "websiteId": 1,
                "domainName": "bikester.fr",
                "competitorType": "watched",
                "cumulatedVisibilities": [
                    {
                        "canonicalName": "Montpellier",
                        "cumulatedVisibility": 1250
                    },
                    {
                        "canonicalName": "Toulouse",
                        "cumulatedVisibility": 830
                    },
                    {
                        "canonicalName": "Marseille",
                        "cumulatedVisibility": 220
                    },
                ]
            },
            {
                "websiteId": 2,
                "domainName": "cyclable.com",
                "competitorType": "watched",
                "cumulatedVisibilities": [
                    {
                        "canonicalName": "Montpellier",
                        "cumulatedVisibility": 658
                    },
                    {
                        "canonicalName": "Toulouse",
                        "cumulatedVisibility": 368
                    },
                    {
                        "canonicalName": "Marseille",
                        "cumulatedVisibility": 1100
                    },
                ]
            },
            {
                "websiteId": 3,
                "domainName": "intersport.fr",
                "competitorType": "watched",
                "cumulatedVisibilities": [
                    {
                        "canonicalName": "Montpellier",
                        "cumulatedVisibility": 840
                    },
                    {
                        "canonicalName": "Toulouse",
                        "cumulatedVisibility": 420
                    },
                    {
                        "canonicalName": "Marseille",
                        "cumulatedVisibility": 980
                    },
                ]
            },
            {
                "websiteId": 4,
                "domainName": "culturevelo.com",
                "competitorType": "project",
                "cumulatedVisibilities": [
                    {
                        "canonicalName": "Montpellier",
                        "cumulatedVisibility": 1520
                    },
                    {
                        "canonicalName": "Toulouse",
                        "cumulatedVisibility": 1200
                    },
                    {
                        "canonicalName": "Marseille",
                        "cumulatedVisibility": 350
                    },
                ]
            }
        ]

    },
    averageNote : {
        content:{
            value:3.8,
            count:128
        }

    },

    localVisibilityKeywordsPositions : {
        context:{
            inputs: {currentReport: {searchGeolocations: [{name:'Montpellier'}, {name:'Lyon'}, {name:'Paris'}]}},
        },
        content:[
            {
                "string": "meilleur vélo électrique",
                "city1": {
                    "rank": 1,
                    "rankEvolution": 1
                },
                "city2": {
                    "rank": 5,
                    "rankEvolution": 0
                },
                "city3": {
                    "rank": 6,
                    "rankEvolution": 3
                }
            },
            {
                "string": "vélo d'occasion",
                "city1": {
                    "rank": 3,
                    "rankEvolution": -1
                },
                "city2": {
                    "rank": 7,
                    "rankEvolution": -2
                },
                "city3": {
                    "rank": 4,
                    "rankEvolution": 1
                }
            },
            {
                "string": "vélo homme",
                "city1": {
                    "rank": 2,
                    "rankEvolution": 1
                },
                "city2": {
                    "rank": 4,
                    "rankEvolution": 1
                },
                "city3": {
                    "rank": 7,
                    "rankEvolution": 3
                }
            },
            {
                "string": "achat vélo route",
                "city1": {
                    "rank": 3,
                    "rankEvolution": -2
                },
                "city2": {
                    "rank": 2,
                    "rankEvolution": -1
                },
                "city3": {
                    "rank": 9,
                    "rankEvolution": 2
                }
            }
        ]
    },

    localVisibilityMyBusinessKeywordsPositions:{
        content:[
            {
                "string": "meilleur vélo électrique",
                "name": "Montpellier",
                "domainName": "culturevelo.com",
                "rank": 1
            },
            {
                "string": "achat vélo route",
                "name": "La-Celle-Sous-Gouzon",
                "domainName": "bikester.fr",
                "rank": 3
            },
            {
                "string": "vélo électrique d'occasion",
                "name": "Valreas",
                "domainName": "pureelectric.fr",
                "rank": 8
            }
        ]

    },

    localVisibilityLocalAdvertisingPresence:{
        content:[
            {
                "string": "réparateur vélo",
                "name": "Montpellier",
                "domainName": "bikester.fr",
                "adwords": {
                    "rank": 14,
                    "display": "top"
                }
            },
            {
                "string": "vélo femme",
                "name": "La-Celle-Sous-Gouzon",
                "domainName": "cyclable.com",
                "adwords": {
                    "rank": 3,
                    "display": "bottom"
                }
            },
            {
                "string": "magasin vélo",
                "name": "Valreas",
                "domainName": "intersport.fr",
                "adwords": {
                    "rank": 12,
                    "display": "bottom"
                },
                "shopping": {
                    "rank": 1
                }
            }
        ]
    }

}

<template>
    <div :style="{height:this.height, width:this.width}">
        <div class="con-vs-loading vs-loading-background-undefined vs-loading-color-undefined">
            <div class="vs-loading default" style="transform: scale(0.6);">
                <div class="effect-1 effects"></div>
                <div class="effect-2 effects"></div>
                <div class="effect-3 effects"></div>
                <img src="">
            </div>
        </div>
    </div>

</template>


<script>

export default {
    name: 'ComponentLoader',
    props: {
        height: {type: String, default:'100%'},
        width: {type: String, default:'100%'}
    },
    data() {
        return {}
    },
    computed: {

    },
    mounted() {

    }
}
</script>

<style lang="scss" >



</style>

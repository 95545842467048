
export default {
    "fr": {
        "common_allCountry_label_france": "Toute la France",
        "common_allCountry_label_belgium": "Toute la Belgique",
        "common_allCountry_label_switzerland": "Toute la Suisse",
        "common_allCountry_label_italy": "Toute l'Italie",
        "common_allCountry_label_germany": "Toute l'Allemagne",
        "common_allCountry_label_spain": "Toute l'Espagne",
        "common_allCountry_label_netherlands": "Tout le Pays-Bas",
        "common_allCountry_label_united kingdom": "Toute l'Angleterre",
        "common_allCountry_label_united states": "Tous les Etats Unis",
        "common_allCountry_label_canada": "Tout le Canada",
        "common_allCountry_label_brazil": "Tout le Brésil",
        "common_allCountry_label_mexico": "Tout le Mexique",

        "common_language_label_fr": "Français",
        "common_language_label_nl": "Néerlandais",
        "common_language_label_de": "Allemand",
        "common_language_label_it": "Italien",
        "common_language_label_en": "Anglais",
        "common_language_label_es": "Espagnol",
        "common_language_label_ca": "Catalan",
        "common_language_label_pt": "Portugais",

        "common_countryName_for_searchEngine_google.fr": "France",
        "common_countryName_for_searchEngine_google.be": "Belgique",
        "common_countryName_for_searchEngine_google.ch": "Suisse",
        "common_countryName_for_searchEngine_google.it": "Italie",
        "common_countryName_for_searchEngine_google.de": "Allemagne",
        "common_countryName_for_searchEngine_google.es": "Espagne",
        "common_countryName_for_searchEngine_google.nl": "Pays-Bas",
        "common_countryName_for_searchEngine_google.co.uk": "Angleterre",
        "common_countryName_for_searchEngine_google.com": "Etats-Unis",
        "common_countryName_for_searchEngine_google.ca": "Canada",
        "common_countryName_for_searchEngine_google.com.br": "Brésil",
        "common_countryName_for_searchEngine_google.com.mx": "Mexique",

        "common_country_label": "Pays",
        "common_language_label": "Langue",
        "common_location_label": "Ville",

        "common_changeSaved": "Modification enregistrée !",

        "common_scheduled_period_1": "Journalier",
        "common_scheduled_period_7": "Hebdomadaire",
        "common_scheduled_period_30": "Mensuel",
        "common_scheduled_period_365": "Annuel",

        "common_periodPicker_kpiLabel_1": "hier",
        "common_periodPicker_kpiLabel_7": "cette semaine",
        "common_periodPicker_kpiLabel_30": "ce mois-ci",
        "common_periodPicker_kpiLabel_365": "cette année",

        "common_agGridTable_selectAll": "Sélectionner tout",
        "common_agGridTable_selectAllSearchResults": "Sélectionner tous les résultats de recherche",
        "common_agGridTable_searchOoo": "Rechercher...",
        "common_agGridTable_blanks": "Vide",
        "common_agGridTable_noMatches": "Pas de résultat.",
        "common_agGridTable_filterOoo": "Filtrer...",
        "common_agGridTable_equals": "Egal",
        "common_agGridTable_notEqual": "Différent",
        "common_agGridTable_lessThan": "Inférieur à",
        "common_agGridTable_greaterThan": "Supérieur à",
        "common_agGridTable_lessThanOrEqual": "Inférieur ou égal",
        "common_agGridTable_greaterThanOrEqual": "Supérieur ou égal",
        "common_agGridTable_inRange": "Compris ",
        "common_agGridTable_inRangeStart": "A",
        "common_agGridTable_inRangeEnd": "De",
        "common_agGridTable_contains": "Contient",
        "common_agGridTable_notContains": "Ne contient pas",
        "common_agGridTable_startsWith": "Commence par",
        "common_agGridTable_endsWith": "Fini par",
        "common_agGridTable_dateFormatOoo": "dd/mm/Yyyy",
        "common_agGridTable_andCondition": "ET",
        "common_agGridTable_orCondition": "OU",
        "common_agGridTable_initComponentData": "Appliquer",
        "common_agGridTable_applyFilter": "Appliquer",
        "common_agGridTable_resetFilter": "Reset",
        "common_agGridTable_clearFilter": "Vider",
        "common_agGridTable_cancelFilter": "Annuler",
        "common_agGridTable_columns": "Colonne",
        "common_agGridTable_filters": "Filtres",
        "common_agGridTable_noRowsToShow":"Aucune ligne à afficher",

        "common_reporting_template_projectNotFound_title": "La page demandée n'existe pas !",
        "common_reporting_template_projectNotFound_text": "Le projet n'à pas été trouvé",
        "common_reporting_template_noReporting_title": "Aucun rapport pour l'instant.",
        "common_reporting_template_noReporting_text": "Votre premier rapport sera créé le [*DATE*].",

        "common_google_block_label_natural": "Naturel",
        "common_google_block_label_map": "Google Business Profile",
        "common_google_block_label_adwords": "Google Ads",
        "common_google_block_label_image": "Images",
        "common_google_block_label_shopping": "Shopping",
        "common_google_block_label_snippet": "Position 0",
        "common_google_block_label_zero": "Position 0",
        "common_google_block_label_video": "Video",
        "common_google_block_label_actuality": "Actualité",

        "common_label_ad": "Annonce",
        "common_accept_label": "Accepter",
        "common_cancel_label": "Annuler",
        "common_keyword_imported": "Vos mots-clés ont été importés",


        "common_tooltip_date_comparison": "du [*STARTDATE*] au [*ENDDATE*]",
        "common_tooltip_compared_to": "vs",

        "common_not_owned_project_text": "<h5 class='text-h5 error--text font-weight-medium mb-2'>Problème d'authentification</h5><p>Attention il semblerait que vous tentez d'accéder à un abonnement qui ne vous appartient pas.</p><p>Veuillez vérifier l'URL que vous avez saisie ou contacter le <a href='mailto:support@yooda.com'>support technique</a> si le problème persiste.</p>",
        "common_project_loading_error_text": "<h5 class='text-h5 error--text font-weight-medium mb-2'>Erreur lors de la récupération de vos données</h5><p>Veuillez ré-essayer dans quelques minutes ou contacter le <a href='https://support.yooda.com/hc/fr/requests/new' target='_blank'>support technique</a> si le problème persiste.</p>",
        "common_project_scaling_report_title": "Veuillez patienter quelques secondes",
        "common_project_scaling_report_subtitle": "Nous ajoutons de la puissance à votre compte, pour calculer rapidement votre projet.",
        "common_project_scaling_report_text": "<p class='mb-2'><strong>Le saviez-vous ?</strong></p><p class='my-0'>Votre compte possède deux modes de fonctionnement.</p><p class='my-0'>Le \"<strong>mode lecture</strong>\", dimensionné pour la consultation des données.</p><p class='my-0'>Et le \"<strong>mode calcul</strong>\", plus puissant, pour traiter les informations recueillies et calculer tous les indicateurs.</p><p class='my-0'>En modulant ainsi les ressources informatiques de votre compte selon le besoin du moment, nous réduisons les coûts et le prix de votre abonnement.</p><p class='my-0'>Nous évitons aussi un gaspillage d'énergie et les émissions de CO² inutiles.</p>",
        "common_project_creation_waiting_text": "<h5 class='text-h5 success--text font-weight-medium mb-2'>Nous collectons les données pour votre premier rapport</h5><p>Votre projet a été créé et nous collectons les informations pour votre premier rapport.</p><strong class='grey--text text--darken-3'>Pour patienter, vous pouvez :</strong><ul class='mt-4' style='list-style: disc'><li>Découvrir les indicateurs présentés à l'aide de données factices</li><li>Paramétrer vos <a href='segments-configuration'>segments de marchés</a></li><li>Compléter vos autres paramètres de projet (concurrents, connexion Analytics...)</li></ul>",
        "common_errors_report_dataTitle": "Erreur lors de la récupération de vos données",
        "common_errors_report_dataText": "<span>Erreur de chargement de certains indicateurs  - Merci de contacter notre <a href='https://support.yooda.com/hc/fr/requests/new' target='_blank'>Support technique</a></span>",

        "common_report_date_title" : "données du",

        "common_subscription_plan_name_yooda-one-plan-ebusiness" : "Plan Starter",
        "common_subscription_plan_name_yooda-one-plan-ecommerce" : "Plan Pro",
        "common_subscription_plan_name_yooda-one-plan-agency" : "Plan Entreprise",
        "common_subscription_plan_name_yooda-one-plan-noiise" : "Plan Noiise",

        "common_rank_position" : "Position",
        "common_rank_notPositioned" : "Non positionné",
        "common_rank_visibility" : "Visibilité",

        "common_rank_positionLabel_1" : "er",
        "common_rank_positionLabel_2" : "ème",
        "common_rank_positionLabel_3" : "ème",
        "common_rank_positionLabel_4" : "ème",
        "common_rank_placesWon" : "places gagnées",
        "common_rank_performance" : "performance",
        "common_rank_pages" : "pages",
        "common_rank_including" : "dont",

        "common_master_yooda" : "Maitre Yooda",

    },
    "en": {
        "common_allCountry_label_france": "All of France",
        "common_allCountry_label_belgium": "All Belgium",
        "common_allCountry_label_switzerland": "All Switzerland",
        "common_allCountry_label_italy": "All Italy",
        "common_allCountry_label_germany": "All of Germany",
        "common_allCountry_label_spain": "All Spain",
        "common_allCountry_label_netherlands": "All of the Netherlands",
        "common_allCountry_label_united kingdom": "All of England",
        "common_allCountry_label_united states": "All of Unites States",
        "common_allCountry_label_canada": "All of Canada",
        "common_allCountry_label_brazil": "All of Brazil",
        "common_allCountry_label_mexico": "All of Mexico",

        "common_language_label_fr": "French",
        "common_language_label_nl": "Dutch",
        "common_language_label_de": "German",
        "common_language_label_it": "Italian",
        "common_language_label_en": "English",
        "common_language_label_es": "Spanish",
        "common_language_label_ca": "Catalan",
        "common_language_label_pt": "Portuguese",

        "common_countryName_for_searchEngine_google.fr": "France",
        "common_countryName_for_searchEngine_google.be": "Belgium",
        "common_countryName_for_searchEngine_google.ch": "Switzerland",
        "common_countryName_for_searchEngine_google.it": "Italy",
        "common_countryName_for_searchEngine_google.de": "Germany",
        "common_countryName_for_searchEngine_google.es": "Spain",
        "common_countryName_for_searchEngine_google.nl": "Netherlands",
        "common_countryName_for_searchEngine_google.co.uk": "England",
        "common_countryName_for_searchEngine_google.com": "United States",
        "common_countryName_for_searchEngine_google.ca": "Canada",
        "common_countryName_for_searchEngine_google.com.br": "Brazil",
        "common_countryName_for_searchEngine_google.com.mx": "Mexico",


        "common_country_label": "Country",
        "common_language_label": "Language",
        "common_location_label": "City",

        "common_changeSaved": "Change saved!",

        "common_scheduled_period_1": "Daily",
        "common_scheduled_period_7": "Weekly",
        "common_scheduled_period_30": "Monthly",
        "common_scheduled_period_365": "Annual",

        "common_periodPicker_kpiLabel_1": "yesterday",
        "common_periodPicker_kpiLabel_7": "this week",
        "common_periodPicker_kpiLabel_30": "this month",
        "common_periodPicker_kpiLabel_365": "this year",

        "common_agGridTable_selectAll": "select all",
        "common_agGridTable_selectAllSearchResults": "Select all search results",
        "common_agGridTable_searchOoo": "Search...",
        "common_agGridTable_blanks": "Blanks",
        "common_agGridTable_noMatches": "No results.",
        "common_agGridTable_filterOoo": "Filter...",
        "common_agGridTable_equals": "Equals",
        "common_agGridTable_notEqual": "Different",
        "common_agGridTable_lessThan": "Less than",
        "common_agGridTable_greaterThan": "Greater than",
        "common_agGridTable_lessThanOrEqual": "Less than or equal to",
        "common_agGridTable_greaterThanOrEqual": "Greater than or equal to",
        "common_agGridTable_inRange": "Included",
        "common_agGridTable_inRangeStart": "A",
        "common_agGridTable_inRangeEnd": "From",
        "common_agGridTable_contains": "Contains",
        "common_agGridTable_notContains": "Does not contain",
        "common_agGridTable_startsWith": "Starts with",
        "common_agGridTable_endsWith": "Ends with",
        "common_agGridTable_dateFormatOoo": "dd/mm/Yyyy",
        "common_agGridTable_andCondition": "AND",
        "common_agGridTable_orCondition": "OR",
        "common_agGridTable_initComponentData": "Apply",
        "common_agGridTable_applyFilter": "Apply",
        "common_agGridTable_resetFilter": "Reset",
        "common_agGridTable_clearFilter": "Empty",
        "common_agGridTable_cancelFilter": "Cancel",
        "common_agGridTable_columns": "Columns",
        "common_agGridTable_filters": "Filters",
        "common_agGridTable_noRowsToShow": "No rows to show",

        "common_reporting_template_projectNotFound_title": "The requested page does not exist!",
        "common_reporting_template_projectNotFound_text": "The project was not found",
        "common_reporting_template_noReporting_title": "No report yet.",
        "common_reporting_template_noReporting_text": "Your first report will be created on [*DATE*].",

        "common_google_block_label_natural": "Natural",
        "common_google_block_label_map": "Google Business Profile",
        "common_google_block_label_adwords": "Google Ads",
        "common_google_block_label_image": "Images",
        "common_google_block_label_shopping": "Shopping",
        "common_google_block_label_snippet": "Position 0",
        "common_google_block_label_zero": "Position 0",
        "common_google_block_label_video": "Video",
        "common_google_block_label_actuality": "News",

        "common_label_ad": "Ad",
        "common_accept_label": "Accept",
        "common_cancel_label": "Cancel",
        "common_keyword_imported": "Keywords successfully imported",

        "common_tooltip_date_comparison": "from [*STARTDATE*] to [*ENDDATE*]",
        "common_tooltip_compared_to": "vs",

        "common_not_owned_project_text": "<h5 class='text-h5 error--text font-weight-medium mb-2'>Authentication problem</h5><p>Warning it looks like you are trying to access a subscription that does not belong to you.</p><p>Please check the URL you entered or contact <a href='mailto:support@yooda.com'>technical support</a> if the problem persists.</p>",
        "common_project_loading_error_text":"<h5 class='text-h5 error--text font-weight-medium mb-2'>Error while retrieving your data</h5><p>Please try again in a few minutes or contact the <a href='https://support.yooda.com/hc/fr/requests/new' target='_blank'>technical support</a> if the problem persists.</p>",
        "common_project_scaling_report_title": "Please wait a few seconds",
        "common_project_scaling_report_subtitle": "We add power to your account, to quickly calculate your project.",
        "common_project_scaling_report_text":"<p class='mb-2'><strong>Did you know?</strong></p><p class='my-0'>Your account has two modes of operation.</p><p class='my-0'>The \"<strong>reading mode</strong>\", sized for viewing data.</p><p class='my-0'>And the \"<strong>calculating mode</strong>\", which is more powerful, for processing the information collected and calculating all the indicators. </p><p class='my-0'>By modulating your account's computing resources in this way according to the need of the moment, we reduce costs and the price of your subscription.</p><p class='my-0'>We also avoid unnecessary energy waste and CO² emissions.</p>",
        "common_project_creation_waiting_text":"<h5 class='text-h5 success--text font-weight-medium mb-2'>We are collecting the data for your first report</h5><p>Your project has been created and we are collecting the information for your first report. </p><strong class='grey--text text--darken-3'>To wait, you can:</strong><ul class='mt-4' style='list-style: disc'><li>Discover the indicators presented with dummy data</li><li>Set up your <a href='segments-configuration'>market segments</a></li><li>Complete your other project settings (competitors, Analytics connection...)</li></ul>.",
        "common_errors_report_dataTitle": "Error while retrieving your data",
        "common_errors_report_dataText":"<span>Error loading some indicators - Please contact our <a href='https://support.yooda.com/hc/fr/requests/new' target='_blank'>Technical Support</a></span>",

        "common_report_date_title": "data from",

        "common_subscription_plan_name_yooda-one-plan-ebusiness" : "Starter Plan",
        "common_subscription_plan_name_yooda-one-plan-ecommerce" : "Pro Plan",
        "common_subscription_plan_name_yooda-one-plan-agency" : "Enterprise Plan",
        "common_subscription_plan_name_yooda-one-plan-noiise" : "Noiise Plan",

        "common_rank_position" : "Position",
        "common_rank_notPositioned" : "Not positioned",
        "common_rank_visibility" : "Visibility",

        "common_rank_positionLabel_1" : "st",
        "common_rank_positionLabel_2" : "nd",
        "common_rank_positionLabel_3" : "rd",
        "common_rank_positionLabel_4" : "th",
        "common_rank_placesWon" : "places won",
        "common_rank_performance" : "performance",
        "common_rank_pages" : "pages",
        "common_rank_including" : "including",

        "common_master_yooda" : "Master Yooda",

    },
}

export default {
    "fr": {
        "component_progressionAxisTopActionPriorities_title": "Vos TOP priorités d'action",
        "component_progressionAxisTopActionPriorities_subtitle": "Types de résultats Google et segments de marché à optimiser en priorité pour avoir le maximum d'impact sur votre trafic mensuel.",
        "component_progressionAxisTopActionPriorities_resultTypePriority": "Type de résultats à prioriser",
        "component_progressionAxisTopActionPriorities_resultTypeLabel": "Résultats [*TYPE*]",
        "component_progressionAxisTopActionPriorities_segmentPriority": "Segment de marché à prioriser",
        "component_progressionAxisTopActionPriorities_segmentLabel": "Segment ",
        "component_progressionAxisTopActionPriorities_noSegment": "Pas de segments de marché",
        "component_progressionAxisTopActionPriorities_addSegment": "Créer",
        "component_progressionAxisTopActionPriorities_potentialVisitor": "visiteurs potentiels",
        "component_progressionAxisTopActionPriorities_keywordWithResultType": " de vos mots-clés affichent des résultats [*TYPE*]",
        "component_progressionAxisTopActionPriorities_keywordResultTypePresence": "Vous êtes positionné sur <strong>[*PERCENT*]%</strong> de ces mots-clés en ",
        "component_progressionAxisTopActionPriorities_keywordWithSegment": " de vos mots-clés sont dans ce segment",
        "component_progressionAxisTopActionPriorities_keywordSegmentPresence": "Vous êtes positionné sur <strong>[*PERCENT*]%</strong> de ces mots-clés",
        "component_progressionAxisTopActionPriorities_keywordToOptimizeCount": "[*COUNT*] mots-clés à optimiser",


        "component_progressionAxisGoogleBlockTypeFrequency_title": "Fréquence d'apparition des types de résultats Google",
        "component_progressionAxisGoogleBlockTypeFrequency_subtitle": "Pourcentage des pages Google présentant un type de résultats",
        "component_progressionAxisGoogleBlockTypeFrequency_informationTooltip_title": "Fréquence d'apparition des blocs de Google",
        "component_progressionAxisGoogleBlockTypeFrequency_informationTooltip_text": "<p>À gauche, identifiez le pourcentage de mots-clés du projet affichant un type de résultat spécifique dans Google tel que le naturel, Google Ads ou Google Business Profile... </p><p><br><strong>Exemple - 35 % Google Ads</strong> : Le bloc Google Ads apparaît pour 35% des mots-clés.</p><p><br>À droite, identifiez la performance de visibilité de votre site pour chacun des types de résultats Google</p><p><br><strong>Exemple - 100% </strong>: Le site est en 1ère position sur tous les mots-clés affichant ce type de résultats.</p>",
        "component_progressionAxisGoogleBlockPerformance_title": "Ma performance par type de résultats",


        "component_progressionAxisImprovementWay_title": "Types de résultats à prioriser",
        "component_progressionAxisImprovementWay_subtitle": "Gain potentiel de trafic pour une optimisation pour les différents type de résultat Google sur vos mots-clés.",
        "component_progressionAxisImprovementWay_informationTooltip_title": "<p>Types de résultats à prioriser</p>",
        "component_progressionAxisImprovementWay_informationTooltip_text": "<p>Permet de cibler par type de résultats Google (SEO, Google Ads, Google Business Profile, ...) les mots-clés sur lesquels il y a un potentiel de trafic.</p>",
        "component_progressionAxisImprovementWay_label": "visites",
        "component_progressionAxisImprovementWay_table_header_channel": "Type de résultat",
        "component_progressionAxisImprovementWay_table_header_performance": "Ma performance",
        "component_progressionAxisImprovementWay_table_header_potentialGain": "Gain potentiel de trafic",
        "component_progressionAxisImprovementWay_table_header_resultType": "Mots-clés avec ce type de résultat",
        "component_progressionAxisImprovementWay_table_header_potentialKeywords": "Mots-clés à optimiser",


        "component_progressionAxisGooglePositionList_title": "Liste des positions sur Google",
        "component_progressionAxisGooglePositionList_subtitle": "Détail des positions de mon site sur les différents types de résultat Google",
        "component_progressionAxisGooglePositionList_informationTooltip_title": "Liste des positions sur Google",
        "component_progressionAxisGooglePositionList_informationTooltip_text": "<p>Permet de connaître la position de votre site ou d'un concurrent suivi, sur les mots-clés du projet pour chaque type de résultat Google (SEO, Google Ads, Google Business Profile, ...).</p>",
        "component_progressionAxisGooglePositionList_table_header_keyword": "Mot-clé",
        "component_progressionAxisGooglePositionList_table_header_seo": "SEO",
        "component_progressionAxisGooglePositionList_table_header_map": "Google Business Profile",
        "component_progressionAxisGooglePositionList_table_header_zero": "Position 0",
        "component_progressionAxisGooglePositionList_table_header_image": "Image",
        "component_progressionAxisGooglePositionList_table_header_video": "Vidéo",
        "component_progressionAxisGooglePositionList_table_header_actuality": "Actualité",
        "component_progressionAxisGooglePositionList_table_header_adwords": "Google Ads",
        "component_progressionAxisGooglePositionList_table_header_shopping": "Shopping",


        "component_progressionAxisImprovementPotentialKeywords_title": "Potentiels de progression sur le canal :",
        "component_progressionAxisImprovementPotentialKeywords_subtitle": "Indique les mots-clés à optimiser en priorité en pour gagner un maximum de trafic grâce aux résultats",
        "component_progressionAxisImprovementPotentialKeywords_table_header_keyword": "Mot-clé",
        "component_progressionAxisImprovementPotentialKeywords_table_header_position": "Ma position",
        "component_progressionAxisImprovementPotentialKeywords_table_header_searchCount": "Nombre de recherches total",
        "component_progressionAxisImprovementPotentialKeywords_table_header_potentialGain": "Gain de trafic potentiel",


        "component_progressionAxisKeywordsToOptimize_title": "Potentiels de progression sur le marché :",
        "component_progressionAxisKeywordsToOptimize_subtitle": "Indique les mots-clés à optimiser en priorité pour gagner un maximum de trafic sur votre segment de marché",
        "component_progressionAxisKeywordsToOptimize_table_header_keyword": "Mot-clé",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialSeoTraffic": "Trafic potentiel SEO",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialAdwordsTraffic": "Trafic potentiel Google Ads",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialVideoTraffic": "Trafic potentiel Vidéo",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialGoogleBusinessTraffic": "Trafic potentiel Google Business Profile",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialZeroTraffic": "Trafic potentiel Position Zéro",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialShoppingTraffic": "Trafic potentiel Shopping",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialActualityTraffic": "Trafic potentiel Actualité",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialImageTraffic": "Trafic potentiel Image",
        "component_progressionAxisKeywordsToOptimize_table_header_searchCount": "Nombre de recherches total",
        "component_progressionAxisKeywordsToOptimize_table_header_totalPotentialTraffic": "Gain de trafic potentiel total",

    },
    "en": {
        "component_progressionAxisTopActionPriorities_title": "Your top priorities for action",
        "component_progressionAxisTopActionPriorities_subtitle": "Types of Google results and market segments to prioritize for maximum impact on your monthly traffic.",
        "component_progressionAxisTopActionPriorities_resultTypePriority": "Type of results to prioritize",
        "component_progressionAxisTopActionPriorities_resultTypeLabel": "[*TYPE*] results",
        "component_progressionAxisTopActionPriorities_segmentPriority": "Market segment to prioritize",
        "component_progressionAxisTopActionPriorities_segmentLabel": "Segment ",
        "component_progressionAxisTopActionPriorities_noSegment": "No market segments",
        "component_progressionAxisTopActionPriorities_addSegment": "Create",
        "component_progressionAxisTopActionPriorities_potentialVisitor": "potential visitors",
        "component_progressionAxisTopActionPriorities_keywordWithResultType": " of your keywords display [*TYPE*] results",
        "component_progressionAxisTopActionPriorities_keywordResultTypePresence": "You are positioned on <strong>[*PERCENT*]%</strong> of these keywords in ",
        "component_progressionAxisTopActionPriorities_keywordWithSegment": " of your keywords are in this segment",
        "component_progressionAxisTopActionPriorities_keywordSegmentPresence": "You are positioned on <strong>[*PERCENT*]%</strong> of these keywords",
        "component_progressionAxisTopActionPriorities_keywordToOptimizeCount": "[*COUNT*] keywords to optimize",


        "component_progressionAxisGoogleBlockTypeFrequency_title": "Frequency of appearance of Google result types",
        "component_progressionAxisGoogleBlockTypeFrequency_subtitle": "Percentage of Google pages with a result type",
        "component_progressionAxisGoogleBlockTypeFrequency_informationTooltip_title": "Frequency of appearance of Google blocks",
        "component_progressionAxisGoogleBlockTypeFrequency_informationTooltip_text": "<p>On the left, identify the percentage of project keywords displaying a specific type of result in Google such as natural, Google Ads or Google Business Profile... </p><p><br><strong>Example - 35% Google Ads</strong> : The Google Ads block appears for 35% of the keywords.</p><p><br>On the right, identify your site's visibility performance for each of the Google result types</p><p><br><strong>Example - 100%. </strong>: The site is in 1st position on all keywords displaying this type of results.</p>",
        "component_progressionAxisGoogleBlockPerformance_title": "My performance by type of results",


        "component_progressionAxisImprovementWay_title": "Types of results to prioritize",
        "component_progressionAxisImprovementWay_subtitle": "Potential gain of traffic for an optimization for the different types of Google results on your keywords.",
        "component_progressionAxisImprovementWay_informationTooltip_title":"<p>Types of results to prioritize</p>",
        "component_progressionAxisImprovementWay_informationTooltip_text":"<p>Allows you to target by type of Google results (SEO, Google Ads, Google Business Profile, ...) the keywords on which there is traffic potential.</p>",
        "component_progressionAxisImprovementWay_label": "visits",
        "component_progressionAxisImprovementWay_table_header_channel": "result type",
        "component_progressionAxisImprovementWay_table_header_performance": "My performance",
        "component_progressionAxisImprovementWay_table_header_potentialGain": "Potential traffic gain",
        "component_progressionAxisImprovementWay_table_header_resultType": "Keywords with this result type",
        "component_progressionAxisImprovementWay_table_header_potentialKeywords": "Keywords to optimize",


        "component_progressionAxisGooglePositionList_title": "List of positions on Google",
        "component_progressionAxisGooglePositionList_subtitle": "Detail of the positions of my site on the different types of Google results",
        "component_progressionAxisGooglePositionList_informationTooltip_title": "List of positions on Google",
        "component_progressionAxisGooglePositionList_informationTooltip_text":"<p>Allows you to know the position of your site or a tracked competitor, on the project keywords for each type of Google result (SEO, Google Ads, Google Business Profile, ...).</p>",
        "component_progressionAxisGooglePositionList_table_header_keyword": "Keyword",
        "component_progressionAxisGooglePositionList_table_header_seo": "SEO",
        "component_progressionAxisGooglePositionList_table_header_map": "Google Business Profile",
        "component_progressionAxisGooglePositionList_table_header_zero": "Position 0",
        "component_progressionAxisGooglePositionList_table_header_image": "Image",
        "component_progressionAxisGooglePositionList_table_header_video": "Video",
        "component_progressionAxisGooglePositionList_table_header_actuality": "News",
        "component_progressionAxisGooglePositionList_table_header_adwords": "Google Ads",
        "component_progressionAxisGooglePositionList_table_header_shopping": "Shopping",


        "component_progressionAxisImprovementPotentialKeywords_title": "Potential for progress on the channel:",
        "component_progressionAxisImprovementPotentialKeywords_subtitle": "Indicates the keywords to be optimized in priority in order to gain maximum traffic from the results",
        "component_progressionAxisImprovementPotentialKeywords_table_header_keyword": "Keyword",
        "component_progressionAxisImprovementPotentialKeywords_table_header_position": "My position",
        "component_progressionAxisImprovementPotentialKeywords_table_header_searchCount": "Total number of searches",
        "component_progressionAxisImprovementPotentialKeywords_table_header_potentialGain": "Potential traffic gain",


        "component_progressionAxisKeywordsToOptimize_title": "Market growth potential:",
        "component_progressionAxisKeywordsToOptimize_subtitle": "Indicates which keywords to prioritize to gain maximum traffic to your market segment.",
        "component_progressionAxisKeywordsToOptimize_table_header_keyword": "Keyword",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialSeoTraffic": "Potential SEO traffic",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialAdwordsTraffic": "Google Ads potential traffic",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialVideoTraffic": "Potential Video Traffic",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialGoogleBusinessTraffic": "Potential Traffic Google Business Profile",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialZeroTraffic": "Potential Traffic Zero Position",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialShoppingTraffic": "Potential Shopping Traffic",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialActualityTraffic": "Potential News Traffic",
        "component_progressionAxisKeywordsToOptimize_table_header_potentialImageTraffic": "Potential Image Traffic",
        "component_progressionAxisKeywordsToOptimize_table_header_searchCount": "Total number of searches",
        "component_progressionAxisKeywordsToOptimize_table_header_totalPotentialTraffic": "Total potential traffic gain",
    },
}


export default {
    "fr": {
        "component_onboardingFirstProject_title": "Prêt à améliorer vos perfs ?",
        "component_onboardingFirstProject_text_trial": "Vos 8 jours d'essai débutent aujourd'hui !",
        "component_onboardingFirstProject_text_todo": "Ce que vous devez-faire maintenant :",
        "component_onboardingFirstProject_text_todo_1": "Paramétrez un projet pour votre site web.",
        "component_onboardingFirstProject_text_todo_2": "Laissez Yooda One récupérer une tonne d'informations utiles.",
        "component_onboardingFirstProject_text_todo_3": "Identifiez les marchés et les canaux d'acquisition à optimiser en priorité",
        "component_onboardingFirstProject_btn_create": "Paramétrer mon analyse",

        "component_onboardingWaitingCompute_title": "Nous construisons vos données",
        "component_onboardingWaitingCompute_compute_text": "La collecte et les calculs prendront",
        "component_onboardingWaitingCompute_compute_duration": "moins de 5 minutes",
        "component_onboardingWaitingCompute_waiting_title": "Que faire en attendant ?",
        "component_onboardingWaitingCompute_waiting_text": "Pour patienter pendant que Yooda One rassemble les informations dont vous aurez besoin, nous vous proposons de :",
        "component_onboardingWaitingCompute_text_todo_1": "Découvrir Yooda One en vidéo",
        "component_onboardingWaitingCompute_btn_todo_1": "voir vidéo",
        "component_onboardingWaitingCompute_text_todo_2": "Consulter un projet d'exemple",
        "component_onboardingWaitingCompute_btn_todo_2": "voir exemple",
        "component_onboardingWaitingCompute_text_todo_3": "Avancer sur votre projet en regroupant vos mots-clés en Segments de marché",
        "component_onboardingWaitingCompute_btn_todo_3": "créer segments",
    },
    "en": {
        "component_onboardingFirstProject_title": "Ready to improve your perfs?",
        "component_onboardingFirstProject_text_trial": "Your 8-day trial starts today!",
        "component_onboardingFirstProject_text_todo": "What you should do now:",
        "component_onboardingFirstProject_text_todo_1": "Set up a project for your website.",
        "component_onboardingFirstProject_text_todo_2": "Let Yooda One retrieve a ton of useful information.",
        "component_onboardingFirstProject_text_todo_3": "Identify which markets and acquisition channels to optimize first.",
        "component_onboardingFirstProject_btn_create": "Set up my analysis",

        "component_onboardingWaitingCompute_title": "We build your data",
        "component_onboardingWaitingCompute_compute_text": "The collection and computation will take",
        "component_onboardingWaitingCompute_compute_duration": "less than 5 minutes",
        "component_onboardingWaitingCompute_waiting_title": "What to do in the meantime?",
        "component_onboardingWaitingCompute_waiting_text": "To wait while Yooda One gathers the information you'll need, we suggest you:",
        "component_onboardingWaitingCompute_text_todo_1": "Discover Yooda One in video",
        "component_onboardingWaitingCompute_btn_todo_1": "watch video",
        "component_onboardingWaitingCompute_text_todo_2": "view a sample project",
        "component_onboardingWaitingCompute_btn_todo_2": "view example",
        "component_onboardingWaitingCompute_text_todo_3": "Advance your project by grouping your keywords into market segments",
        "component_onboardingWaitingCompute_btn_todo_3": "create segments",
    },
}


const getDefaultState = () => {
    return {

        // subscriptionMeta:{  isLoaded:false, error:null,data:null },
        // projectsSummary: { isLoaded:false, error:null,  data: null },
        // projectInfo: {
        //     isLoaded:false,
        //     error:null,
        //     data: {
        //         url: '',
        //         analyticsViewConfiguration:null,
        //         defaultSearchGeolocation:null,
        //         keywords:[],
        //         rivals:[],
        //         projectLoaded:false,
        //         keywordGroups:[],
        //         searchGeolocations:[],
        //         reports:[],
        //         subscriptionMeta:null,
        //         reportingModel:null,
        //         thumbnail:null,
        //         errors:null
        //     }
        // },
        //
        //
        // globalFilterFrequency: '1',
        // globalFilterLocation: 1,

    }
}

export default {getDefaultState}

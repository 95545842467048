
export default {
    marketSegmentTopCompetitorData:{
        content:[
            {
                performances:[
                    {performance:100,keywordGroupName:"Vélos électriques"},
                    {performance:67.2,keywordGroupName:"VTT"},
                    {performance:42,keywordGroupName:"Vélos ville"},
                    {performance:25,keywordGroupName:"Vélos route"},
                ],
                websiteId:2,
                domainName:"culturevelo.com",
                competitorType:"watched"
            },
            {
                performances:[
                    {performance:70,keywordGroupName:"Vélos électriques"},
                    {performance:42,keywordGroupName:"VTT"},
                    {performance:65,keywordGroupName:"Vélos ville"},
                    {performance:38,keywordGroupName:"Vélos route"},
                ],
                websiteId:3,
                domainName:"cyclable.com",
                competitorType:"watched"
            }
        ]
    },

    marketSegmentSegmentSize:{
        content:[
            {keywordGroupName:'Vélos électriques', cumulatedSearchCount:104850},
            {keywordGroupName:'VTT', cumulatedSearchCount:81450},
            {keywordGroupName:'Vélos ville', cumulatedSearchCount:75450},
            {keywordGroupName:'Vélos route', cumulatedSearchCount:62937},
        ]

    },
    marketSegmentSegmentPerformance:{
        content:[
            {keywordGroupName:'Vélos électriques', performance:26, maximumCumulatedVisibility: 104850, websiteCumulatedVisibility:9814},
            {keywordGroupName:'VTT', performance:35, maximumCumulatedVisibility: 81450, websiteCumulatedVisibility:10263},
            {keywordGroupName:'Vélos ville', performance:7, maximumCumulatedVisibility: 75450, websiteCumulatedVisibility:1901},
            {keywordGroupName:'Vélos route', performance:11, maximumCumulatedVisibility: 62937, websiteCumulatedVisibility:2492},
        ]

    },


    marketSegmentVisibility:{
        content:[

            {
                keywordGroupName: "Vélos électriques",
                cumulatedVisibilities: [
                    {date: "2020-01-01",cumulatedVisibility: 730},
                    {date: "2020-02-01",cumulatedVisibility: 910},
                    {date: "2020-03-01",cumulatedVisibility: 1258},
                    {date: "2020-03-02",cumulatedVisibility: 1110},
                    {date: "2020-03-09",cumulatedVisibility: 1169},
                    {date: "2020-03-10",cumulatedVisibility: 1146},
                    {date: "2020-03-11",cumulatedVisibility: 1124},
                    {date: "2020-03-12",cumulatedVisibility: 1158},
                    {date: "2020-03-13",cumulatedVisibility: 1331},
                    {date: "2020-03-14",cumulatedVisibility: 1256},
                    {date: "2020-03-15",cumulatedVisibility: 1595},
                ]
            },
            {
                keywordGroupName: "VTT",
                cumulatedVisibilities: [
                    {date: "2020-01-01",cumulatedVisibility: 520},
                    {date: "2020-02-01",cumulatedVisibility: 750},
                    {date: "2020-03-01",cumulatedVisibility: 1020},
                    {date: "2020-03-02",cumulatedVisibility: 1200},
                    {date: "2020-03-09",cumulatedVisibility: 1400},
                    {date: "2020-03-10",cumulatedVisibility: 1972},
                    {date: "2020-03-11",cumulatedVisibility: 1934},
                    {date: "2020-03-12",cumulatedVisibility: 2197},
                    {date: "2020-03-13",cumulatedVisibility: 1998},
                    {date: "2020-03-14",cumulatedVisibility: 1867},
                    {date: "2020-03-15",cumulatedVisibility: 2259},
                ]
            },
            {
                keywordGroupName: "Vélos ville",
                cumulatedVisibilities: [
                    {date: "2020-01-01",cumulatedVisibility: 170},
                    {date: "2020-02-01",cumulatedVisibility: 188},
                    {date: "2020-03-01",cumulatedVisibility: 274},
                    {date: "2020-03-02",cumulatedVisibility: 318},
                    {date: "2020-03-09",cumulatedVisibility: 289},
                    {date: "2020-03-10",cumulatedVisibility: 273},
                    {date: "2020-03-11",cumulatedVisibility: 303},
                    {date: "2020-03-12",cumulatedVisibility: 316},
                    {date: "2020-03-13",cumulatedVisibility: 363},
                    {date: "2020-03-14",cumulatedVisibility: 478},
                    {date: "2020-03-15",cumulatedVisibility: 402},
                ]
            },
            {
                keywordGroupName: "Vélos route",
                cumulatedVisibilities: [
                    {date: "2020-01-01",cumulatedVisibility: 336},
                    {date: "2020-02-01",cumulatedVisibility: 420},
                    {date: "2020-03-01",cumulatedVisibility: 656},
                    {date: "2020-03-02",cumulatedVisibility: 661},
                    {date: "2020-03-09",cumulatedVisibility: 727},
                    {date: "2020-03-10",cumulatedVisibility: 734},
                    {date: "2020-03-11",cumulatedVisibility: 749},
                    {date: "2020-03-12",cumulatedVisibility: 700},
                    {date: "2020-03-13",cumulatedVisibility: 729},
                    {date: "2020-03-14",cumulatedVisibility: 722},
                    {date: "2020-03-15",cumulatedVisibility: 657},
                ]
            },
        ]

    },


    marketSegmentBestProgressionPotentialKeywords:{
        content: [
            {
                "string": "meilleur vélo électrique",
                "naturalPotentialTrafficGain": 50,
                "adwordsPotentialTrafficGain": 2400,
                "videoPotentialTrafficGain": null,
                "mapPotentialTrafficGain": 100,
                "zeroPotentialTrafficGain": 0,
                "shoppingPotentialTrafficGain": 10,
                "actualityPotentialTrafficGain": 240,
                "imagePotentialTrafficGain": 5000,
                "searchCount": 99000,
                "cumulatedPotentialTrafficGain": 2560
            },
            {
                "string": "achat vélo route",
                "naturalPotentialTrafficGain": 1000,
                "adwordsPotentialTrafficGain": 9000,
                "videoPotentialTrafficGain": 500,
                "mapPotentialTrafficGain": 3000,
                "zeroPotentialTrafficGain": 0,
                "shoppingPotentialTrafficGain": 6000,
                "actualityPotentialTrafficGain": 240,
                "imagePotentialTrafficGain": null,
                "searchCount": 500000,
                "cumulatedPotentialTrafficGain": 19000
            }
        ]
    },



    marketSegmentBestProgressionPotential:{
        content:[
            {keywordGroupName: 'Vélos électriques', performance:26,potentialTrafficGain:27932,potentialProgressionKeywordCount:13, keywordGroupId:1},
            {keywordGroupName: 'VTT', performance:35,potentialTrafficGain:19059,potentialProgressionKeywordCount:9, keywordGroupId:1},
            {keywordGroupName: 'Vélos ville', performance:7,potentialTrafficGain:25261,potentialProgressionKeywordCount:14, keywordGroupId:1},
            {keywordGroupName: 'Vélos route', performance:11,potentialTrafficGain:20165,potentialProgressionKeywordCount:11, keywordGroupId:1},
        ]

    },

    marketSegmentCompetitorCompare:{
        content:[
            {
                competitorType: "project",
                domainName: "culturevelo.com",
                websiteId:1274,
                performances:[
                    {keywordGroupName: "Vélos électriques", performance:26},
                    {keywordGroupName: "VTT", performance:35},
                    {keywordGroupName: "Vélos ville", performance:7},
                    {keywordGroupName: "Vélos route", performance:11},
                ]
            },
            {
                competitorType: "watched",
                domainName: "intersport.fr",
                websiteId:336,
                performances:[
                    {keywordGroupName: "Vélos électriques", performance:15},
                    {keywordGroupName: "VTT", performance:45},
                    {keywordGroupName: "Vélos ville", performance:18},
                    {keywordGroupName: "Vélos route", performance:11},
                ]
            },
        ]
    },

    marketSegmentTopCompetitors:{
        content:[
            "culturevelo.com","cyclable.com","intersport.fr","bikester.fr","pureelectric.fr"
        ]
    },





}

export const displayErrorMixin = {
    methods:{
        displayForbidenError(response){
            if(response.status === 403){
                if(response.data.context.errors){
                    this.$vs.notify({
                        time:7000,
                        title:'ERREUR',
                        text:this.$t(`errors_${response.data.context.errors[0].type}`),
                        position:'top-right',
                        color:'danger'
                    })
                }
            }

        },
    }
}

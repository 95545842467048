/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store.js'
import authService from '@/service/auth/AuthService.js'

const authMode = {
    AUTHENTICATED_REQUIRED: 'authRequired',
    NOT_AUTHENTICATED_REQUIRED: 'notAuthRequired',
    NONE: 'none'
}

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

        {
            // =============================================================================
            // MAIN LAYOUT ROUTES
            // =============================================================================
            path: '/subscriptions/:subscriptionId/projects/:projectId',
            component: () => import('@/layouts/Main.vue'),
            children: [
                // =============================================================================
                // Theme Routes
                // =============================================================================
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: () => import('@/views/analyse/Dashboard.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Tableau de bord - Evolutions'
                    }
                },
                {
                    path: 'dashboard-recommendations',
                    name: 'dashboard_recommendations',
                    component: () => import('@/views/analyse/DashboardRecommendations.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Tableau de bord - Recommandations'
                    }
                },
                {
                    path: 'traffic',
                    name: 'traffic',
                    component: () => import('@/views/analyse/Traffic.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Trafic'
                    }
                },
                {
                    path: 'progression-axis',
                    name: 'progression_axis',
                    component: () => import('@/views/activate/ProgressionAxis.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Mes priorités d\'action'
                    }
                },
                {
                    path: 'seo-visibility',
                    name: 'seo_visibility',
                    component: () => import('@/views/analyse/SeoVisibility.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Visibilité SEO'
                    }
                },
                {
                    path: 'local-visibility',
                    name: 'local_visibility',
                    component: () => import('@/views/analyse/LocalVisibility.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Visibilité locale'
                    }
                },
                {
                    path: 'advertising',
                    name: 'advertising',
                    component: () => import('@/views/analyse/Advertising.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Visibilité SEA'
                    }
                },
                {
                    path: 'market-segment',
                    name: 'market_segment',
                    component: () => import('@/views/analyse/MarketSegment.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Segments de marché'
                    }
                },
                {
                    path: 'competitor',
                    name: 'competitor',
                    component: () => import('@/views/analyse/Competition.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Concurrence'
                    }
                },
                {
                    path: 'website-pages',
                    name: 'website_pages',
                    component: () => import('@/views/analyse/WebsitePages.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Pages du site'
                    }
                },
                {
                    path: 'linking',
                    name: 'linking',
                    component: () => import('@/views/analyse/Linking.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Linking'
                    }
                },
                {
                    path: 'keywords-configuration',
                    name: 'keywords_configuration',
                    component: () => import('@/views/settings/KeywordsConfiguration.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Mes mots-clés'
                    }
                },
                {
                    path: 'segments-configuration',
                    name: 'segments_configuration',
                    component: () => import('@/views/settings/MarketSegmentsConfiguration.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Mes segments'
                    }
                },
                {
                    path: 'competitors-configuration',
                    name: 'competitors_configuration',
                    component: () => import('@/views/settings/CompetitorsConfiguration.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Concurrents à suivre'
                    }
                },
                {
                    path: 'analytics-project-configuration',
                    name: 'analytics_project_configuration',
                    component: () => import('@/views/settings/AnalyticsProjectManagement.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Analytics'
                    }
                },
                {
                    path: 'reporting',
                    name: 'reporting',
                    component: () => import('@/views/activate/ReportingConfiguration.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Partager un rapport'
                    }
                },
                {
                    path: 'export',
                    name: 'export',
                    component: () => import('@/views/activate/ExportConfiguration.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Exporter les données'
                    }
                },

            ],
        },
        {
            path: '/reporting/:projectId',
            name: 'display_reporting',
            component: () => import('@/views/DisplayReporting.vue'),
            meta: {
                authMode: authMode.NONE,
                title:'Reporting'
            }
        },

        {
            path: '',
            component: () => import('@/layouts/FullPage.vue'),
            children: [
                {
                    path: 'error-404',
                    name: 'page-error-404',
                    component: () => import('@/views/pages/Error404.vue'),
                    meta: {
                        authMode: authMode.NONE,
                        title:'404'
                    }
                },
                {
                    path: '/',
                    name: 'home',
                    component: () => import('@/views/pages/Home.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Home'
                    }
                },
                {
                    path: '/maintenance',
                    name: 'maintenance',
                    component: () => import('@/views/pages/Maintenance.vue'),
                    meta: {
                        authMode: authMode.NONE,
                        title:'Maintenance'
                    }
                },
                {
                    path: '/subscriptions/:subscriptionId/first-project',
                    name: 'first_project',
                    component: () => import('@/views/home/FirstProject.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Paramétrer mon analyse'
                    }
                },
            ]
        },


        {
            path: '/subscriptions/:subscriptionId',
            component: () => import('@/layouts/Main.vue'),
            children: [
                {
                    path: 'projects',
                    name: 'projects',
                    component: () => import('@/views/MyProjects.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Mes projets'
                    }
                },
                {
                    path: 'reporting-planning',
                    name: 'reporting_planning',
                    component: () => import('@/views/reporting/ReportingPlanning'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Mes rapports'
                    }
                },
                {
                    path: 'subscription-info',
                    name: 'subscription_info',
                    component: () => import('@/views/settings/SubscriptionInfo'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Mon compte'
                    }
                },
                {
                    path: 'report-template',
                    name: 'report_template',
                    component: () => import('@/views/reporting/ReportTemplate.vue'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Modèles de rapports'
                    }
                },
                {
                    path: 'external-sources-management',
                    name: 'external_sources_management',
                    component: () => import('@/views/settings/ExternalSourcesManagement'),
                    meta: {
                        authMode: authMode.AUTHENTICATED_REQUIRED,
                        title:'Sources externes'
                    }
                },
            ]
        },

        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/error-404'
        }
    ],
})

router.afterEach((to) => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none';
    }

    setTimeout(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, 600)

    Vue.nextTick(() => {
        if(!to.meta || ! to.meta.title){
            document.title = to.name;
        }
        else{
            document.title = to.meta.title;
        }

    });
})


router.beforeEach((to, from, next) => {

    Vue.$log.info('beforeEach', {
        'from.url': from.path,
        'to.url': to.path,
        'to.name': to.name,
        'authMode': to.meta.authMode,
        'authService.isAuthenticated': authService.isAuthenticated(),
        'clientStorageService.connected': authService.getConnected(),
        'clientStorageService.email': authService.getEmail(),
        'customer': store.state.customersManager.customer,
        'productFamily': store.state.customersManager.productFamily
    })


    switch (to.meta.authMode) {
        case authMode.AUTHENTICATED_REQUIRED:
            if (authService.isAuthenticated()) {
                Vue.$log.debug('beforeEach', 'need auth and authenticated')
                if (store.state.customersManager.customer === null) {
                    Vue.$log.debug('beforeEach', 'customer (store) is null')
                    authService
                        .retrieveSession()
                        .then(() => {
                            Vue.$log.debug('beforeEach', 'email found on client storage side', 'success')
                            if (to.name !== 'home') {
                                next()
                            } else {
                                if(to.query["first-project"]){
                                    router.push({ name: 'first_project', params: { subscriptionId: store.getters['customersManager/getActiveSubscription']().id.toString() } })
                                }
                                else{
                                    router.push({ name: 'projects', params: { subscriptionId: store.getters['customersManager/getActiveSubscription']().id.toString() } })
                                }
                            }
                        })
                        .catch((error) => {
                            Vue.$log.error('beforeEach', 'email found on client storage side', error)
                        })
                } else {
                    Vue.$log.debug('beforeEach', 'customer (store) is not null')
                    if (to.name !== 'home') {
                        next()
                    } else {
                        router.push({
                            name: 'projects',
                            params: {
                                subscriptionId: store.getters['customersManager/getActiveSubscription']().id.toString()
                            }
                        })
                    }
                }
            } else {
                Vue.$log.debug('beforeEach', 'need auth and not authenticated')

                authService.redirectToExternalLogoutPage(window.location.href)
            }
            break;
        case authMode.NOT_AUTHENTICATED_REQUIRED:
            if (!authService.isAuthenticated()) {
                Vue.$log.debug('beforeEach', 'need no auth and not authenticated')
                next()
            } else {
                Vue.$log.debug('beforeEach', 'need no auth and authenticated')
                router.push({ name: 'home' })
            }
            break;
        default:
            Vue.$log.debug('beforeEach', 'default')
            next()
    }
})

export default router


export default {
    "fr": {

        "component_headerNavBar_trialing_remaining": "Votre essai expire dans <strong>[*DAYS-COUNT*] jours</strong>",
        "component_headerNavBar_trialing_turnPremium_btn": "passer en version payante",
        "component_headerNavBar_goToHome" : "Retour à mes domaines",
        "component_profileDropDown_title" : "Mon abonnement",
        "component_profileDropDown_account" : "Compte",
        "component_profileDropDown_logout" : "Déconnexion",

        "navigationMenu_category_title_projects": "",
        "navigationMenu_link_title_projects": "Mes domaines ",

        "navigationMenu_category_title_reporting": "REPORTING",
        "navigationMenu_link_title_reporting_planning": "Mes rapports",
        "navigationMenu_link_title_report_template": "Modèles de rapports",

        "navigationMenu_category_title_apps": "ANALYSER",
        "navigationMenu_link_title_dashboard": "Tableau de bord",
        "navigationMenu_link_title_dashboard_recommendations": "Tableau de bord",
        "navigationMenu_link_title_seo_visibility": "Visibilité SEO",
        "navigationMenu_link_title_advertising": "Visibilité SEA",
        "navigationMenu_link_title_market_segment": "Segments de marché",
        "navigationMenu_link_title_competitor": "Concurrence",
        "navigationMenu_link_title_traffic": "Trafic",
        "navigationMenu_link_title_local_visibility": "Visibilité locale",
        "navigationMenu_link_title_website_pages": "Pages du site",
        "navigationMenu_link_title_linking": "Linking",

        "navigationMenu_category_title_settings": "PARAMETRER",
        "navigationMenu_link_title_subscription_info": "Mon compte",
        "navigationMenu_link_title_external_sources_management": "Sources externes",


        "navigationMenu_category_title_action": "ACTIONNER",
        "navigationMenu_link_title_reporting": "Partager un rapport",
        "navigationMenu_link_title_export": "Exporter les données",
        "navigationMenu_link_title_progression_axis": "Mes priorités d'action",

        "navigationMenu_link_title_keywords_configuration": "Mes mots-clés",
        "navigationMenu_link_title_segments_configuration": "Mes segments",
        "navigationMenu_link_title_competitors_configuration": "Concurrents à suivre",
        "navigationMenu_link_title_analytics_project_configuration": "Analytics",


        "page_link_name_dashboard": "Tableau de bord",
        "page_link_name_dashboard_recommendations": "Tableau de bord",
        "page_link_name_dashboard_switch_evolution": "EVOLUTIONS",
        "page_link_name_dashboard_switch_recommendations": "RECOMMANDATIONS",

        "navigationMenu_sleekPlan_suggest": "Suggestion",
        "navigationMenu_sleekPlan_news": "Nouveautés",

    },
    "en": {

        "component_headerNavBar_trialing_remaining": "Your trial expires in <strong>[*DAYS-COUNT*] days</strong>",
        "component_headerNavBar_trialing_turnPremium_btn": "upgrade to paid version",
        "component_headerNavBar_goToHome": "return to my domains",
        "component_profileDropDown_title" : "My subscription",
        "component_profileDropDown_account" : "Account",
        "component_profileDropDown_logout" : "Logout",

        "navigationMenu_category_title_projects": "",
        "navigationMenu_link_title_projects": "My domains",

        "navigationMenu_category_title_reporting": "REPORTING",
        "navigationMenu_link_title_reporting_planning": "My reports",
        "navigationMenu_link_title_report_template": "Report templates",

        "navigationMenu_category_title_apps": "ANALYZE",
        "navigationMenu_link_title_dashboard": "Dashboard",
        "navigationMenu_link_title_dashboard_recommendations": "Dashboard",
        "navigationMenu_link_title_seo_visibility": "SEO visibility",
        "navigationMenu_link_title_advertising": "SEA visibility",
        "navigationMenu_link_title_market_segment": "Market Segments",
        "navigationMenu_link_title_competitor": "Competition",
        "navigationMenu_link_title_traffic": "Traffic",
        "navigationMenu_link_title_local_visibility": "Local visibility",
        "navigationMenu_link_title_website_pages": "Website pages",
        "navigationMenu_link_title_linking": "Linking",

        "navigationMenu_category_title_settings": "SETTINGS",
        "navigationMenu_link_title_subscription_info": "My account",
        "navigationMenu_link_title_external_sources_management": "External sources",


        "navigationMenu_category_title_action": "ACTION",
        "navigationMenu_link_title_reporting": "Share a report",
        "navigationMenu_link_title_export": "Export data",
        "navigationMenu_link_title_progression_axis": "My action priorities",

        "navigationMenu_link_title_keywords_configuration": "My keywords",
        "navigationMenu_link_title_segments_configuration": "My segments",
        "navigationMenu_link_title_competitors_configuration": "Competitors to follow",
        "navigationMenu_link_title_analytics_project_configuration": "Analytics",


        "page_link_name_dashboard": "Dashboard",
        "page_link_name_dashboard_recommendations": "Dashboard",
        "page_link_name_dashboard_switch_evolution": "EVOLUTIONS",
        "page_link_name_dashboard_switch_recommendations": "RECOMMENDATIONS",

        "navigationMenu_sleekPlan_suggest": "Suggestions",
        "navigationMenu_sleekPlan_news": "News",
    },
}


export default {

    linkingPopularityByWebsite : {
        content:{
            project:33,
          competitors:48
        }
    },
    linkingWebsiteAuthority : {
        content:{
            project:53,
          competitors:31
        }
    },
    linkingLinkCount : {
        content: {
            sites: {
                project: 1497,
                competitors: 125
            },
            backlinks: {
                project: 26848,
                competitors: 481
            }
        }
    },
    linkingCompetitorsLinking : {
        content:[
            {
                "seoRank":3,
                "authority": 82,
                "popularity": 73,
                "thematics": {
                    "main": {
                        "topic": "Society/Folklore",
                        "authority": 51,
                        "topLevelTopic": "Society"
                    },
                    "others": [
                        {
                            "topic": "News/Newspapers",
                            "authority": 48,
                            "topLevelTopic": "News"
                        },
                        {
                            "topic": "Science/Social Sciences",
                            "authority": 46,
                            "topLevelTopic": "Science"
                        }
                    ]
                },
                "refDomainCount": 110641,
                "domainName": "lemonde.fr"
            },
            {
                "seoRank":2,
                "authority": 72,
                "popularity": 64,
                "thematics": {
                    "main": {
                        "topic": "Arts/Music",
                        "authority": 60,
                        "topLevelTopic": "Arts"
                    },
                    "others": [
                        {
                            "topic": "Science/Social Sciences",
                            "authority": 56,
                            "topLevelTopic": "Science"
                        },
                        {
                            "topic": "Arts/Literature",
                            "authority": 53,
                            "topLevelTopic": "Arts"
                        }
                    ]
                },
                "refDomainCount": 110957,
                "domainName": "amazon.fr"
            },
            {
                "seoRank":1,
                "authority": 48,
                "popularity": 55,
                "thematics": {
                    "main": {
                        "topic": "Business/Financial Services",
                        "authority": 51,
                        "topLevelTopic": "Business"
                    },
                    "others": [
                        {
                            "topic": "Recreation/Travel",
                            "authority": 46,
                            "topLevelTopic": "Recreation"
                        },
                        {
                            "topic": "Computers/Internet/Web Design and Development",
                            "authority": 45,
                            "topLevelTopic": "Computers"
                        }
                    ]
                },
                "refDomainCount": 8130,
                "domainName": "fr.trustpilot.com"
            }
        ]
    },
    competitorsLinkingReferralDomain : {
        content:[
            { domainName:'pearl.fr' },
            { domainName:'rueducommerce.fr' },
            { domainName:'ubaldi.com' },
            { domainName:'cherchons.com' },
            { domainName:'electrodepot.fr' },
            { domainName:'lionshome.fr' },
            { domainName:'achatmoinscher.com' },
            { domainName:'bebeboutik.fr' },
            { domainName:'123comparer.fr' },
            { domainName:'m.boulanger.com' },
            { domainName:'idealo.fr' },
            { domainName:'bons-plans.easycash.fr' },
            { domainName:'darty.com' },
            { domainName:'king-jouet.com' },
            { domainName:'touslesprix.com' },
            { domainName:'ldlc.pro' },
            { domainName:'ldlc.com' },
            { domainName:'manomano.fr' },
            { domainName:'prix.net' },
            { domainName:'asgoodasnew.fr' },
            { domainName:'ledenicheur.fr' },
            { domainName:'amazon.com.be' },
            { domainName:'samsung.com' },
            { domainName:'fr.tvc-mall.com' },
            { domainName:'fr.aliexpress.com' },
            { domainName:'amazon.fr' },
            { domainName:'fnac.com' },
            { domainName:'m.darty.com' },
            { domainName:'selection-sejours.cdiscount.com' },
            { domainName:'roulettes-portes-de-douches.com' },
            { domainName:'cubicfun.com' },
            { domainName:'tropicar-guadeloupe.fr' },
            { domainName:'cdiscount.fr.uptodown.com' },
            { domainName:'epouvup.com' },
            { domainName:'moulage-bebe.com' },
            { domainName:'aukru.com' },
            { domainName:'feeric-lights.com' },
            { domainName:'support-telephone-lit.com' },
            { domainName:'egouttoir-vaisselle.fr' },
            { domainName:'coquefactory.ch' },
            { domainName:'bopb-boutique.com' },
            { domainName:'switch-shield.com' },
            { domainName:'carte-mere-775-ddr3.cartesmeres.com' },
            { domainName:'planetcofy.com' },
            { domainName:'leptitlinge.wixsite.com' },
            { domainName:'locations-saisonnieres.cdiscount.com' },
            { domainName:'nutella.galerie-creation.com' },
            { domainName:'formfoad.fr' },
            { domainName:'zolsaanconfections.com' },
            { domainName:'nettoyer-lave-vaisselle.com' },
            { domainName:'divineeagle.com' },
            { domainName:'pasjel.fr' },
            { domainName:'vgate.fr.aliexpress.com' },
            { domainName:'accrocherunetoile.blogspot.com' },
            { domainName:'chaussure-mostro.blogspot.com' },
            { domainName:'clefde7.free.fr' },
            { domainName:'fabyblog78.canalblog.com' },
            { domainName:'floricienta.eklablog.net' },
            { domainName:'hardrockmakeup.over-blog.fr' },
            { domainName:'lamode-des-4saisons.wifeo.com' },
            { domainName:'monsejourenallemagne.over-blog.com' },
            { domainName:'nania-14.blogspot.com' },

        ]
    },
    linkingMyThematicAuthority : {
        content:[
            {
                "topic": "Shopping/Food",
                "authority": 32,
                "refDomain": 39
            },
            {
                "topic": "Recreation/Food",
                "authority": 27,
                "refDomain": 177
            },
            {
                "topic": "Home/Cooking",
                "authority": 21,
                "refDomain": 128
            },
            {
                "topic": "Business/Consumer Goods and Services",
                "authority": 21,
                "refDomain": 49
            },
            {
                "topic": "Business",
                "authority": 20,
                "refDomain": 323
            }

        ]
    },
    linkingMyThematicAutorityReferralDomains : {
        content:[
            {
                "popularity": 66,
                "authority": 83,
                "title": "Choisissez une offre Fibre ou ADSL sans engagement - Free",
                "thematics": {
                    "main": {
                        "topic": "Computers/Internet",
                        "authority": 68,
                        "topLevelTopic": "Computers"
                    },
                    "others": [
                        {
                            "topic": "Computers/Internet/Web Design and Development",
                            "authority": 60,
                            "topLevelTopic": "Computers"
                        },
                        {
                            "topic": "Science/Social Sciences",
                            "authority": 53,
                            "topLevelTopic": "Science"
                        }
                    ]
                },
                "domainName": "free.fr",
                "linkCount": 29
            },

        ]
    },
    linkingAuthorityCompare : {
        "context": {
            "inputs": {
                "project": {
                    "externalId": 1002,
                    "computeSubDomains": false,
                    "domainName": "saveur-biere.com"
                },
                "currentLinkingReport": {
                    "id": 2,
                    "fetchedAt": "2024-03-25T00:00:00+00:00"
                },
                "website": {
                    "id": 2307
                }
            },
            "computed": {
                "competitors": [
                    "facebook.com",
                    "lemonde.fr",
                    "m.facebook.com",
                    "amazon.fr",
                    "pagesjaunes.fr",
                    "lavoixdunord.fr",
                    "fr.shopping.rakuten.com",
                    "fr.trustpilot.com",
                    "natureetdecouvertes.com",
                    "beertime.fr",
                    "destination-nancy.com",
                    "latelierdesbieres.fr",
                    "vandb.fr",
                    "rolling-beers.fr",
                    "lavignery.fr",
                    "cdiscount.com",
                    "bieresetsaveurs.com",
                    "brouwland.com",
                    "salondubrasseur.com",
                    "foie-gras-sarlat.com",
                    "perfectdraft.com",
                    "lartdelabiere.fr",
                    "les-saveurs-du-colombier.com",
                    "produitsregionaux-maraispoitevin.com",
                    "bieresetsaveurs.net",
                    "autobrasseur.fr",
                    "biere-actu.fr",
                    "unepetitemousse.fr",
                    "adopteunbrasseur.fr",
                    "fairedelabiere.fr",
                    "bieres-et-brasseries.fr",
                    "touteslesbox.fr",
                    "monkitabiere.fr",
                    "calais-vins.com",
                    "microbrasseur.com",
                    "charlemagne-boissons.com",
                    "mille-et-une-bieres.fr",
                    "lecomptoirdubrasseur.fr",
                    "la-cave-du-moulin.com",
                    "lacavedantoine.com",
                    "boutiquemira.fr",
                    "editions-bastberg.fr",
                    "my-food-online.com",
                    "biere-discount.com",
                    "cave-dit-vin-draguignan.fr",
                    "toutenpression.fr",
                    "help.saveur-biere.com",
                    "cavedu28.com",
                    "saveurs-melees.com"
                ]
            },
            "itemsDisplayedStart": 1,
            "itemsCount": null,
            "itemsDisplayedCount": 3,
            "itemsDisplayedEnd": 3,
            "itemsDisplayedRange": "1-3"
        },
        "content": {
            "projectDomainName": "saveur-biere.com",
            "competitorDomainName": "lemonde.fr",
            "topicals": [
                {
                    "topLevelTopic": "Shopping",
                    "projectAuthority": 31,
                    "competitorAuthority": 28
                },
                {
                    "topLevelTopic": "Recreation",
                    "projectAuthority": 28,
                    "competitorAuthority": 40
                },
                {
                    "topLevelTopic": "Business",
                    "projectAuthority": 22,
                    "competitorAuthority": 40
                },
                {
                    "topLevelTopic": "Home",
                    "projectAuthority": 21,
                    "competitorAuthority": 39
                },
                {
                    "topLevelTopic": "Regional",
                    "projectAuthority": 20,
                    "competitorAuthority": 40
                },
                {
                    "topLevelTopic": "Computers",
                    "projectAuthority": 20,
                    "competitorAuthority": 35
                },
                {
                    "topLevelTopic": "Society",
                    "projectAuthority": 19,
                    "competitorAuthority": 51
                },
                {
                    "topLevelTopic": "Arts",
                    "projectAuthority": 19,
                    "competitorAuthority": 43
                },
                {
                    "topLevelTopic": "Sports",
                    "projectAuthority": 18,
                    "competitorAuthority": 31
                },
                {
                    "topLevelTopic": "News",
                    "projectAuthority": 15,
                    "competitorAuthority": 48
                },
                {
                    "topLevelTopic": "Reference",
                    "projectAuthority": 11,
                    "competitorAuthority": 38
                },
                {
                    "topLevelTopic": "Games",
                    "projectAuthority": 10,
                    "competitorAuthority": 28
                },
                {
                    "topLevelTopic": "Science",
                    "projectAuthority": 8,
                    "competitorAuthority": 44
                },
                {
                    "topLevelTopic": "Health",
                    "projectAuthority": 8,
                    "competitorAuthority": 32
                },
                {
                    "topLevelTopic": "Adult",
                    "projectAuthority": 5,
                    "competitorAuthority": 31
                },
                {
                    "topLevelTopic": "World",
                    "projectAuthority": 2,
                    "competitorAuthority": 25
                }
            ]
        }
    },

    linkingBestBacklinks:{
        "content": [
            {
                "id": 13033,
                "popularity": 36,
                "internalLinkCount": 29,
                "externalLinkCount": 42,
                "anchorText": "saveur-biere.com/fr/",
                "sourceTitle": "Buy Beer \u2013 Trooper",
                "targetTitle": "",
                "sourceUrl": "https://www.ironmaidenbeer.com/buy-beer/",
                "targetUrl": "https://www.saveur-biere.com/fr/search-result/trooper",
                "thematicDatas": [
                    {
                        "id": 1616296,
                        "authority": 10,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 60,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Food"
                        }
                    },
                    {
                        "id": 1616297,
                        "authority": 8,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 33,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Music"
                        }
                    },
                    {
                        "id": 1616298,
                        "authority": 8,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 2,
                            "topLevelTopic": "Regional",
                            "topic": "Regional/Europe"
                        }
                    }
                ],
                "transmittedPopularity": 34
            },
            {
                "id": 13034,
                "popularity": 35,
                "internalLinkCount": 58,
                "externalLinkCount": 48,
                "anchorText": "",
                "sourceTitle": "Einst\u00f6k Beer | Conquer your Viking thirst with Iceland\u2019s #1 craft beer.",
                "targetTitle": "",
                "sourceUrl": "https://einstokbeer.com/",
                "targetUrl": "https://www.saveur-biere.com/en",
                "thematicDatas": [
                    {
                        "id": 1616299,
                        "authority": 18,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 60,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Food"
                        }
                    },
                    {
                        "id": 1616300,
                        "authority": 15,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 8,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Travel"
                        }
                    },
                    {
                        "id": 1616301,
                        "authority": 12,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 100,
                            "topLevelTopic": "Health",
                            "topic": "Health/Conditions and Diseases"
                        }
                    }
                ],
                "transmittedPopularity": 32
            },
            {
                "id": 13035,
                "popularity": 34,
                "internalLinkCount": 327,
                "externalLinkCount": 30,
                "anchorText": "https://www.saveur-biere.com/fr/",
                "sourceTitle": "Tireuse \u00e0 bi\u00e8re PerfectDraft | Philips",
                "targetTitle": "",
                "sourceUrl": "https://www.philips.fr/c-e/ho/perfectdraft.html",
                "targetUrl": "https://www.saveur-biere.com/fr",
                "thematicDatas": [
                    {
                        "id": 1616302,
                        "authority": 35,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 30,
                            "topLevelTopic": "Business",
                            "topic": "Business/Consumer Goods and Services"
                        }
                    },
                    {
                        "id": 1616303,
                        "authority": 17,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 6,
                            "topLevelTopic": "Business",
                            "topic": "Business"
                        }
                    },
                    {
                        "id": 1616304,
                        "authority": 17,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 285,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Architecture"
                        }
                    }
                ],
                "transmittedPopularity": 31
            },
            {
                "id": 13036,
                "popularity": 32,
                "internalLinkCount": 14,
                "externalLinkCount": 210,
                "anchorText": "",
                "sourceTitle": "Leffe 0.0 | Leffe France",
                "targetTitle": "",
                "sourceUrl": "https://leffe.fr/leffe0.0?r=1",
                "targetUrl": "https://www.saveur-biere.com/fr/bouteilles/33206-leffe-blonde-0-0.html",
                "thematicDatas": [
                    {
                        "id": 1616305,
                        "authority": 11,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 28,
                            "topLevelTopic": "Computers",
                            "topic": "Computers/Internet/Searching"
                        }
                    },
                    {
                        "id": 1616306,
                        "authority": 10,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 2,
                            "topLevelTopic": "Regional",
                            "topic": "Regional/Europe"
                        }
                    },
                    {
                        "id": 1616307,
                        "authority": 9,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 33,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Music"
                        }
                    }
                ],
                "transmittedPopularity": 29
            },
            {
                "id": 13039,
                "popularity": 31,
                "internalLinkCount": 231,
                "externalLinkCount": 83,
                "anchorText": "une tireuse \u00e0 bi\u00e8re",
                "sourceTitle": "150+ id\u00e9es cadeaux incroyables \u00e0 offrir en 2024",
                "targetTitle": "",
                "sourceUrl": "https://touteslesbox.fr/idee-cadeau-guide/",
                "targetUrl": "https://www.saveur-biere.com/fr/?utm_campaign=affilae&utm_source=touteslesbox&utm_medium=affiliation",
                "thematicDatas": [
                    {
                        "id": 1616314,
                        "authority": 9,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 106,
                            "topLevelTopic": "Home",
                            "topic": "Home/Cooking"
                        }
                    },
                    {
                        "id": 1616315,
                        "authority": 8,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 336,
                            "topLevelTopic": "Sports",
                            "topic": "Sports/Cricket"
                        }
                    },
                    {
                        "id": 1616316,
                        "authority": 8,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 64,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Pets"
                        }
                    }
                ],
                "transmittedPopularity": 28
            },
            {
                "id": 13038,
                "popularity": 31,
                "internalLinkCount": 59,
                "externalLinkCount": 30,
                "anchorText": "d\u00e9couvrir saveur bi\u00e8re (a partir de 21,67 \u20ac)",
                "sourceTitle": "Box vin : Les 20 meilleures en 2024",
                "targetTitle": "",
                "sourceUrl": "https://www.eccevino.com/magazine/top-10-des-meilleures-box-de-vin-biere-et-alcool/",
                "targetUrl": "https://www.saveur-biere.com/fr/?utm_campaign=affilae&utm_source=Eccevino&utm_medium=affiliation",
                "thematicDatas": [
                    {
                        "id": 1616311,
                        "authority": 20,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 6,
                            "topLevelTopic": "Business",
                            "topic": "Business"
                        }
                    },
                    {
                        "id": 1616312,
                        "authority": 19,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 106,
                            "topLevelTopic": "Home",
                            "topic": "Home/Cooking"
                        }
                    },
                    {
                        "id": 1616313,
                        "authority": 19,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 3,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation"
                        }
                    }
                ],
                "transmittedPopularity": 29
            },
            {
                "id": 13037,
                "popularity": 31,
                "internalLinkCount": 33,
                "externalLinkCount": 11,
                "anchorText": "www.saveur-bi\u00e8re.com",
                "sourceTitle": "Trouver nos produits - Brasserie du Mont-Blanc",
                "targetTitle": "",
                "sourceUrl": "https://www.brasserie-montblanc.com/trouver-bieres/",
                "targetUrl": "http://www.saveur-biere.com/fr/recherche-brasserie-du-mont-blanc",
                "thematicDatas": [
                    {
                        "id": 1616308,
                        "authority": 41,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 60,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Food"
                        }
                    },
                    {
                        "id": 1616309,
                        "authority": 26,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 1,
                            "topLevelTopic": "Sports",
                            "topic": "Sports/Running"
                        }
                    },
                    {
                        "id": 1616310,
                        "authority": 16,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 275,
                            "topLevelTopic": "Business",
                            "topic": "Business/Hospitality"
                        }
                    }
                ],
                "transmittedPopularity": 29
            },
            {
                "id": 13040,
                "popularity": 29,
                "internalLinkCount": 4,
                "externalLinkCount": 5,
                "anchorText": "saveur bi\u00e8re",
                "sourceTitle": "Excellence Bi\u00e8re : La passion de la bi\u00e8re",
                "targetTitle": "",
                "sourceUrl": "http://www.excellence-biere.com/",
                "targetUrl": "https://www.saveur-biere.com/fr",
                "thematicDatas": [
                    {
                        "id": 1616317,
                        "authority": 42,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 148,
                            "topLevelTopic": "Shopping",
                            "topic": "Shopping/Food"
                        }
                    }
                ],
                "transmittedPopularity": 28
            },
            {
                "id": 13041,
                "popularity": 29,
                "internalLinkCount": 1,
                "externalLinkCount": 3,
                "anchorText": "d\u00e9couvrir saveur bi\u00e8re",
                "sourceTitle": "Au revoir et merci",
                "targetTitle": "",
                "sourceUrl": "https://www.interdrinks.fr/",
                "targetUrl": "https://www.saveur-biere.com/fr",
                "thematicDatas": [
                    {
                        "id": 1616318,
                        "authority": 11,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 60,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Food"
                        }
                    },
                    {
                        "id": 1616319,
                        "authority": 5,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 6,
                            "topLevelTopic": "Business",
                            "topic": "Business"
                        }
                    },
                    {
                        "id": 1616320,
                        "authority": 4,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 23,
                            "topLevelTopic": "Reference",
                            "topic": "Reference/Education"
                        }
                    }
                ],
                "transmittedPopularity": 28
            },
            {
                "id": 13042,
                "popularity": 29,
                "internalLinkCount": 87,
                "externalLinkCount": 19,
                "anchorText": "",
                "sourceTitle": "Agence de communication - SUPER : Conseils & Cr\u00e9ation",
                "targetTitle": "",
                "sourceUrl": "https://www.agence-super.fr/",
                "targetUrl": "https://www.saveur-biere.com/fr",
                "thematicDatas": [
                    {
                        "id": 1616321,
                        "authority": 9,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 6,
                            "topLevelTopic": "Business",
                            "topic": "Business"
                        }
                    },
                    {
                        "id": 1616322,
                        "authority": 7,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 88,
                            "topLevelTopic": "Business",
                            "topic": "Business/Real Estate"
                        }
                    },
                    {
                        "id": 1616323,
                        "authority": 4,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 2,
                            "topLevelTopic": "Regional",
                            "topic": "Regional/Europe"
                        }
                    }
                ],
                "transmittedPopularity": 26
            },
            {
                "id": 13043,
                "popularity": 29,
                "internalLinkCount": 117,
                "externalLinkCount": 1,
                "anchorText": "5\u20ac de bi\u00e8res offerts",
                "sourceTitle": "Label Mousse. D\u00e9couvrez les brasseries de France !",
                "targetTitle": "",
                "sourceUrl": "https://www.labelmousse.fr/",
                "targetUrl": "https://www.saveur-biere.com/fr/give/labelmousse/1RZDJA6LU1",
                "thematicDatas": [
                    {
                        "id": 1616324,
                        "authority": 2,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 82,
                            "topLevelTopic": "Society",
                            "topic": "Society/Organizations"
                        }
                    },
                    {
                        "id": 1616325,
                        "authority": 2,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 23,
                            "topLevelTopic": "Reference",
                            "topic": "Reference/Education"
                        }
                    }
                ],
                "transmittedPopularity": 26
            },
            {
                "id": 13047,
                "popularity": 28,
                "internalLinkCount": 576,
                "externalLinkCount": 908,
                "anchorText": "saveur biere",
                "sourceTitle": "Contact - Brasserie Sainte Cru",
                "targetTitle": "",
                "sourceUrl": "https://sainte-cru.com/contact/",
                "targetUrl": "https://www.saveur-biere.com/fr/search-result/sainte cru",
                "thematicDatas": [
                    {
                        "id": 1616333,
                        "authority": 7,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 8,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Travel"
                        }
                    },
                    {
                        "id": 1616334,
                        "authority": 5,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 45,
                            "topLevelTopic": "Business",
                            "topic": "Business/Publishing and Printing"
                        }
                    },
                    {
                        "id": 1616335,
                        "authority": 3,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 60,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Food"
                        }
                    }
                ],
                "transmittedPopularity": 24
            },
        ]
    },

    linkingWebsiteBacklinks:{
        "content": [
            {
                "id": 19232,
                "popularity": 34,
                "authority": 20,
                "linkCount": 317,
                "title": "Deedee \u2013 Un blog f\u00e9minin et parisien",
                "thematicDatas": [
                    {
                        "id": 1617711,
                        "authority": 19,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 19,
                            "topLevelTopic": "Business",
                            "topic": "Business/Construction and Maintenance"
                        }
                    },
                    {
                        "id": 1617712,
                        "authority": 17,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 31,
                            "topLevelTopic": "Society",
                            "topic": "Society/People"
                        }
                    },
                    {
                        "id": 1617713,
                        "authority": 16,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 6,
                            "topLevelTopic": "Business",
                            "topic": "Business"
                        }
                    }
                ],
                "domainName": "deedeeparis.com"
            },
            {
                "id": 19238,
                "popularity": 32,
                "authority": 30,
                "linkCount": 221,
                "title": "Modeemi ry",
                "thematicDatas": [
                    {
                        "id": 1617725,
                        "authority": 30,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 92,
                            "topLevelTopic": "Computers",
                            "topic": "Computers/Organizations"
                        }
                    },
                    {
                        "id": 1617726,
                        "authority": 19,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 443,
                            "topLevelTopic": "Computers",
                            "topic": "Computers/Programming/Games"
                        }
                    },
                    {
                        "id": 1617727,
                        "authority": 18,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 101,
                            "topLevelTopic": "Computers",
                            "topic": "Computers/Hardware"
                        }
                    }
                ],
                "domainName": "modeemi.fi"
            },
            {
                "id": 19303,
                "popularity": 41,
                "authority": 19,
                "linkCount": 214,
                "title": "Trustroots: travellers hospitality community",
                "thematicDatas": [
                    {
                        "id": 1617884,
                        "authority": 19,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 2,
                            "topLevelTopic": "Regional",
                            "topic": "Regional/Europe"
                        }
                    },
                    {
                        "id": 1617885,
                        "authority": 15,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 72,
                            "topLevelTopic": "Society",
                            "topic": "Society/Activism"
                        }
                    },
                    {
                        "id": 1617886,
                        "authority": 14,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 8,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Travel"
                        }
                    }
                ],
                "domainName": "trustroots.org"
            },
            {
                "id": 19412,
                "popularity": 35,
                "authority": 43,
                "linkCount": 171,
                "title": "Bi\u00e8res Belges et du monde - 500 bi\u00e8res \u00e0 d\u00e9couvrir",
                "thematicDatas": [
                    {
                        "id": 1618144,
                        "authority": 43,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 60,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Food"
                        }
                    },
                    {
                        "id": 1618145,
                        "authority": 15,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 152,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Collecting"
                        }
                    }
                ],
                "domainName": "bierebel.com"
            },
            {
                "id": 19475,
                "popularity": 19,
                "authority": 30,
                "linkCount": 92,
                "title": "Excellence Bi\u00e8re : La passion de la bi\u00e8re",
                "thematicDatas": [
                    {
                        "id": 1618243,
                        "authority": 30,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 148,
                            "topLevelTopic": "Shopping",
                            "topic": "Shopping/Food"
                        }
                    }
                ],
                "domainName": "excellence-biere.com"
            },
            {
                "id": 19246,
                "popularity": 36,
                "authority": 29,
                "linkCount": 89,
                "title": "SHMUP - NANOFORCE : Tactical Surgeon Fighter enfin disponible ! - Archives et mus\u00e9e du Shoot them Up",
                "thematicDatas": [
                    {
                        "id": 1617747,
                        "authority": 27,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 93,
                            "topLevelTopic": "Games",
                            "topic": "Games/Video Games/News and Reviews"
                        }
                    },
                    {
                        "id": 1617748,
                        "authority": 26,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 174,
                            "topLevelTopic": "Games",
                            "topic": "Games/Video Games"
                        }
                    },
                    {
                        "id": 1617749,
                        "authority": 18,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 69,
                            "topLevelTopic": "Science",
                            "topic": "Science/Environment"
                        }
                    }
                ],
                "domainName": "shmup.com"
            },
            {
                "id": 19533,
                "popularity": 25,
                "authority": 20,
                "linkCount": 67,
                "title": "Alfabbs.fi - Index page",
                "thematicDatas": [
                    {
                        "id": 1618388,
                        "authority": 20,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 40,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Autos"
                        }
                    }
                ],
                "domainName": "alfabbs.fi"
            },
            {
                "id": 19488,
                "popularity": 21,
                "authority": 25,
                "linkCount": 54,
                "title": "Taulard",
                "thematicDatas": [
                    {
                        "id": 1618267,
                        "authority": 25,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 56,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Humor"
                        }
                    },
                    {
                        "id": 1618268,
                        "authority": 14,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 178,
                            "topLevelTopic": "Regional",
                            "topic": "Regional/Asia"
                        }
                    }
                ],
                "domainName": "taulard.net"
            },
            {
                "id": 19177,
                "popularity": 34,
                "authority": 8,
                "linkCount": 49,
                "title": "Reittausblogi",
                "thematicDatas": [
                    {
                        "id": 1617555,
                        "authority": 8,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 54,
                            "topLevelTopic": "Arts",
                            "topic": "Arts"
                        }
                    },
                    {
                        "id": 1617556,
                        "authority": 6,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 8,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Travel"
                        }
                    },
                    {
                        "id": 1617557,
                        "authority": 6,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 2,
                            "topLevelTopic": "Regional",
                            "topic": "Regional/Europe"
                        }
                    }
                ],
                "domainName": "reittausblogi.info"
            },
            {
                "id": 19092,
                "popularity": 53,
                "authority": 26,
                "linkCount": 45,
                "title": "Topito : top listes et classements - humour, insolite, utile, et d\u00e9couverte !",
                "thematicDatas": [
                    {
                        "id": 1617321,
                        "authority": 23,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 3,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation"
                        }
                    },
                    {
                        "id": 1617322,
                        "authority": 21,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 256,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Bodyart"
                        }
                    },
                    {
                        "id": 1617323,
                        "authority": 20,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 285,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Architecture"
                        }
                    }
                ],
                "domainName": "topito.com"
            },
            {
                "id": 19436,
                "popularity": 32,
                "authority": 38,
                "linkCount": 39,
                "title": "Test, Avis, Chroniques, et Recettes de burgers - MyBurger.fr",
                "thematicDatas": [
                    {
                        "id": 1618187,
                        "authority": 38,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 106,
                            "topLevelTopic": "Home",
                            "topic": "Home/Cooking"
                        }
                    }
                ],
                "domainName": "myburger.fr"
            },
            {
                "id": 19460,
                "popularity": 27,
                "authority": 33,
                "linkCount": 38,
                "title": "Voxan Club de France \u2013 Bienvenue sur le site des amateurs de moto Voxan.",
                "thematicDatas": [
                    {
                        "id": 1618219,
                        "authority": 33,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 86,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Motorcycles"
                        }
                    }
                ],
                "domainName": "voxanclubdefrance.com"
            },
            {
                "id": 19201,
                "popularity": 27,
                "authority": 15,
                "linkCount": 35,
                "title": "",
                "thematicDatas": [
                    {
                        "id": 1617621,
                        "authority": 14,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 324,
                            "topLevelTopic": "Sports",
                            "topic": "Sports/Software"
                        }
                    },
                    {
                        "id": 1617622,
                        "authority": 13,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 28,
                            "topLevelTopic": "Computers",
                            "topic": "Computers/Internet/Searching"
                        }
                    },
                    {
                        "id": 1617623,
                        "authority": 12,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 331,
                            "topLevelTopic": "Home",
                            "topic": "Home/Do-It-Yourself"
                        }
                    }
                ],
                "domainName": "anti-crise.fr"
            },
            {
                "id": 19558,
                "popularity": 30,
                "authority": 18,
                "linkCount": 35,
                "title": "Virage Radio - La Rock Station",
                "thematicDatas": [
                    {
                        "id": 1618456,
                        "authority": 18,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 298,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Radio"
                        }
                    },
                    {
                        "id": 1618457,
                        "authority": 13,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 33,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Music"
                        }
                    }
                ],
                "domainName": "virageradio.com"
            },
            {
                "id": 19362,
                "popularity": 38,
                "authority": 44,
                "linkCount": 34,
                "title": "Welkom bij Hobbybrouwen.nl - Hobbybrouwen.nl",
                "thematicDatas": [
                    {
                        "id": 1618018,
                        "authority": 43,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 60,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Food"
                        }
                    },
                    {
                        "id": 1618019,
                        "authority": 30,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 235,
                            "topLevelTopic": "Reference",
                            "topic": "Reference/Dictionaries"
                        }
                    },
                    {
                        "id": 1618020,
                        "authority": 17,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 106,
                            "topLevelTopic": "Home",
                            "topic": "Home/Cooking"
                        }
                    }
                ],
                "domainName": "hobbybrouwen.nl"
            },
            {
                "id": 19463,
                "popularity": 26,
                "authority": 32,
                "linkCount": 33,
                "title": "Bob Morane - Page d\u2019accueil",
                "thematicDatas": [
                    {
                        "id": 1618224,
                        "authority": 32,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 22,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Comics"
                        }
                    }
                ],
                "domainName": "aproposdebobmorane.net"
            },
            {
                "id": 19033,
                "popularity": 49,
                "authority": 25,
                "linkCount": 32,
                "title": "",
                "thematicDatas": [
                    {
                        "id": 1617148,
                        "authority": 21,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 45,
                            "topLevelTopic": "Business",
                            "topic": "Business/Publishing and Printing"
                        }
                    },
                    {
                        "id": 1617149,
                        "authority": 20,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 6,
                            "topLevelTopic": "Business",
                            "topic": "Business"
                        }
                    },
                    {
                        "id": 1617150,
                        "authority": 20,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 10,
                            "topLevelTopic": "Computers",
                            "topic": "Computers/Internet/On the Web"
                        }
                    }
                ],
                "domainName": "gqmagazine.fr"
            },
            {
                "id": 19423,
                "popularity": 31,
                "authority": 40,
                "linkCount": 31,
                "title": "Recettes de Rhums Arrang\u00e9s & Punchs - Rhum Arrange.fr",
                "thematicDatas": [
                    {
                        "id": 1618164,
                        "authority": 40,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 106,
                            "topLevelTopic": "Home",
                            "topic": "Home/Cooking"
                        }
                    }
                ],
                "domainName": "rhum-arrange.fr"
            },
            {
                "id": 19097,
                "popularity": 38,
                "authority": 15,
                "linkCount": 31,
                "title": "Wheeling It: Tales From a Nomadic Life \u2013 On the Road Since 2010, Traveling Across USA & Europe With Pets",
                "thematicDatas": [
                    {
                        "id": 1617336,
                        "authority": 15,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 9,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Outdoors"
                        }
                    },
                    {
                        "id": 1617337,
                        "authority": 11,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 150,
                            "topLevelTopic": "Shopping",
                            "topic": "Shopping/Vehicles"
                        }
                    },
                    {
                        "id": 1617338,
                        "authority": 7,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 217,
                            "topLevelTopic": "Shopping",
                            "topic": "Shopping/Visual Arts"
                        }
                    }
                ],
                "domainName": "wheelingit.us"
            },
            {
                "id": 19073,
                "popularity": 29,
                "authority": 6,
                "linkCount": 27,
                "title": "Quaff Magazine \u2013 Blog Cocktails & Bi\u00e8res \u2013 Recettes cocktails, tests bi\u00e8res, articles, bars & spiritueux",
                "thematicDatas": [
                    {
                        "id": 1617267,
                        "authority": 6,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 23,
                            "topLevelTopic": "Reference",
                            "topic": "Reference/Education"
                        }
                    },
                    {
                        "id": 1617268,
                        "authority": 5,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 111,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Movies"
                        }
                    },
                    {
                        "id": 1617269,
                        "authority": 5,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 160,
                            "topLevelTopic": "Shopping",
                            "topic": "Shopping/Clothing"
                        }
                    }
                ],
                "domainName": "quaff-magazine.com"
            },
            {
                "id": 19288,
                "popularity": 24,
                "authority": 12,
                "linkCount": 26,
                "title": "Bienvenue sur le site de l'ASCREB - ASCREB",
                "thematicDatas": [
                    {
                        "id": 1617857,
                        "authority": 12,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 129,
                            "topLevelTopic": "Games",
                            "topic": "Games/Roleplaying"
                        }
                    },
                    {
                        "id": 1617858,
                        "authority": 11,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 2,
                            "topLevelTopic": "Regional",
                            "topic": "Regional/Europe"
                        }
                    },
                    {
                        "id": 1617859,
                        "authority": 5,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 220,
                            "topLevelTopic": "Business",
                            "topic": "Business/Biotechnology and Pharmaceuticals"
                        }
                    }
                ],
                "domainName": "ascreb.org"
            },
            {
                "id": 19273,
                "popularity": 32,
                "authority": 40,
                "linkCount": 26,
                "title": "Liste de mariage MilleMercisMariage \u2013 R\u00e9ussir son mariage",
                "thematicDatas": [
                    {
                        "id": 1617818,
                        "authority": 40,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 6,
                            "topLevelTopic": "Business",
                            "topic": "Business"
                        }
                    }
                ],
                "domainName": "millemercismariage.com"
            },
            {
                "id": 18975,
                "popularity": 43,
                "authority": 36,
                "linkCount": 26,
                "title": "Classement des meilleurs sites Internet - Meilleur du Web",
                "thematicDatas": [
                    {
                        "id": 1616980,
                        "authority": 28,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 63,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Performing Arts"
                        }
                    },
                    {
                        "id": 1616981,
                        "authority": 28,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 6,
                            "topLevelTopic": "Business",
                            "topic": "Business"
                        }
                    },
                    {
                        "id": 1616982,
                        "authority": 26,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 23,
                            "topLevelTopic": "Reference",
                            "topic": "Reference/Education"
                        }
                    }
                ],
                "domainName": "meilleurduweb.com"
            },
            {
                "id": 19144,
                "popularity": 49,
                "authority": 19,
                "linkCount": 25,
                "title": "Free Collaborative Comparison engine : Create Comparison tables & Compare everything on SocialCompare",
                "thematicDatas": [
                    {
                        "id": 1617464,
                        "authority": 18,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 41,
                            "topLevelTopic": "Science",
                            "topic": "Science/Social Sciences"
                        }
                    },
                    {
                        "id": 1617465,
                        "authority": 17,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 282,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Online Writing"
                        }
                    },
                    {
                        "id": 1617466,
                        "authority": 16,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 39,
                            "topLevelTopic": "Computers",
                            "topic": "Computers/Internet/Web Design and Development"
                        }
                    }
                ],
                "domainName": "socialcompare.com"
            },
            {
                "id": 19308,
                "popularity": 34,
                "authority": 17,
                "linkCount": 23,
                "title": "Verygoodlord \u00ae | Le blog Mode Homme",
                "thematicDatas": [
                    {
                        "id": 1617896,
                        "authority": 16,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 231,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Design"
                        }
                    },
                    {
                        "id": 1617897,
                        "authority": 15,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 321,
                            "topLevelTopic": "Shopping",
                            "topic": "Shopping/Jewelry"
                        }
                    },
                    {
                        "id": 1617898,
                        "authority": 14,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 10,
                            "topLevelTopic": "Computers",
                            "topic": "Computers/Internet/On the Web"
                        }
                    }
                ],
                "domainName": "verygoodlord.com"
            },
            {
                "id": 19157,
                "popularity": 38,
                "authority": 27,
                "linkCount": 23,
                "title": "7th February 2024",
                "thematicDatas": [
                    {
                        "id": 1617501,
                        "authority": 27,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 60,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Food"
                        }
                    },
                    {
                        "id": 1617502,
                        "authority": 20,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 155,
                            "topLevelTopic": "Science",
                            "topic": "Science/News and Media"
                        }
                    },
                    {
                        "id": 1617503,
                        "authority": 18,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 307,
                            "topLevelTopic": "Computers",
                            "topic": "Computers/Data Formats"
                        }
                    }
                ],
                "domainName": "zythophile.co.uk"
            },
            {
                "id": 18956,
                "popularity": 48,
                "authority": 33,
                "linkCount": 23,
                "title": "digidip",
                "thematicDatas": [
                    {
                        "id": 1616923,
                        "authority": 31,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 111,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Movies"
                        }
                    },
                    {
                        "id": 1616924,
                        "authority": 27,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 171,
                            "topLevelTopic": "News",
                            "topic": "News/Magazines and E-zines"
                        }
                    },
                    {
                        "id": 1616925,
                        "authority": 25,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 197,
                            "topLevelTopic": "Computers",
                            "topic": "Computers/News and Media"
                        }
                    }
                ],
                "domainName": "digidip.net"
            },
        ]
    },

    linkingCompetitorsLinkingReferralDomains:{
        content:[
            {
                "popularity": 66,
                "authority": 83,
                "title": "Choisissez une offre Fibre ou ADSL sans engagement - Free",
                "thematics": {
                    "main": {
                        "topic": "Computers/Internet",
                        "authority": 68,
                        "topLevelTopic": "Computers"
                    },
                    "others": [
                        {
                            "topic": "Computers/Internet/Web Design and Development",
                            "authority": 60,
                            "topLevelTopic": "Computers"
                        },
                        {
                            "topic": "Science/Social Sciences",
                            "authority": 53,
                            "topLevelTopic": "Science"
                        }
                    ]
                },
                "domainName": "free.fr",
                "linkCount": 29
            },
        ]
    },


    linkingAnchorBacklinks:{
        "content": [
            {
                "id": 9136,
                "popularity": 9,
                "authority": 5,
                "linkCount": 375,
                "refDomainCount": 177,
                "text": "saveur biere com",
                "thematicDatas": [
                    {
                        "id": 1616604,
                        "authority": 4,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 371,
                            "topLevelTopic": "Reference",
                            "topic": "Reference/Ask an Expert"
                        }
                    },
                    {
                        "id": 1616605,
                        "authority": 4,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 60,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Food"
                        }
                    },
                    {
                        "id": 1616606,
                        "authority": 4,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 243,
                            "topLevelTopic": "News",
                            "topic": "News/Journalism"
                        }
                    }
                ]
            },
            {
                "id": 9137,
                "popularity": 29,
                "authority": 22,
                "linkCount": 1769,
                "refDomainCount": 176,
                "text": "",
                "thematicDatas": [
                    {
                        "id": 1616607,
                        "authority": 22,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 30,
                            "topLevelTopic": "Business",
                            "topic": "Business/Consumer Goods and Services"
                        }
                    },
                    {
                        "id": 1616608,
                        "authority": 19,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 60,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Food"
                        }
                    },
                    {
                        "id": 1616609,
                        "authority": 14,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 10,
                            "topLevelTopic": "Computers",
                            "topic": "Computers/Internet/On the Web"
                        }
                    }
                ]
            },
            {
                "id": 9138,
                "popularity": 23,
                "authority": 31,
                "linkCount": 4075,
                "refDomainCount": 171,
                "text": "saveur bi\u00e8re",
                "thematicDatas": [
                    {
                        "id": 1616610,
                        "authority": 31,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 148,
                            "topLevelTopic": "Shopping",
                            "topic": "Shopping/Food"
                        }
                    },
                    {
                        "id": 1616611,
                        "authority": 26,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 60,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Food"
                        }
                    },
                    {
                        "id": 1616612,
                        "authority": 20,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 10,
                            "topLevelTopic": "Computers",
                            "topic": "Computers/Internet/On the Web"
                        }
                    }
                ]
            },
            {
                "id": 9139,
                "popularity": 7,
                "authority": 3,
                "linkCount": 395,
                "refDomainCount": 99,
                "text": "750617 bieresdumonde fr",
                "thematicDatas": [
                    {
                        "id": 1616613,
                        "authority": 2,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 19,
                            "topLevelTopic": "Business",
                            "topic": "Business/Construction and Maintenance"
                        }
                    },
                    {
                        "id": 1616614,
                        "authority": 2,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 82,
                            "topLevelTopic": "Society",
                            "topic": "Society/Organizations"
                        }
                    },
                    {
                        "id": 1616615,
                        "authority": 2,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 219,
                            "topLevelTopic": "Health",
                            "topic": "Health/Fitness"
                        }
                    }
                ]
            },
            {
                "id": 9140,
                "popularity": 7,
                "authority": 3,
                "linkCount": 374,
                "refDomainCount": 98,
                "text": "88189 saveur biere com",
                "thematicDatas": [
                    {
                        "id": 1616616,
                        "authority": 2,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 19,
                            "topLevelTopic": "Business",
                            "topic": "Business/Construction and Maintenance"
                        }
                    },
                    {
                        "id": 1616617,
                        "authority": 2,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 82,
                            "topLevelTopic": "Society",
                            "topic": "Society/Organizations"
                        }
                    },
                    {
                        "id": 1616618,
                        "authority": 2,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 219,
                            "topLevelTopic": "Health",
                            "topic": "Health/Fitness"
                        }
                    }
                ]
            },
            {
                "id": 9141,
                "popularity": 10,
                "authority": 19,
                "linkCount": 325,
                "refDomainCount": 65,
                "text": "saveur biere",
                "thematicDatas": [
                    {
                        "id": 1616619,
                        "authority": 19,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 22,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Comics"
                        }
                    },
                    {
                        "id": 1616620,
                        "authority": 11,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 106,
                            "topLevelTopic": "Home",
                            "topic": "Home/Cooking"
                        }
                    }
                ]
            },
            {
                "id": 9142,
                "popularity": 21,
                "authority": 19,
                "linkCount": 2712,
                "refDomainCount": 53,
                "text": "www saveur biere com",
                "thematicDatas": [
                    {
                        "id": 1616621,
                        "authority": 18,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 2,
                            "topLevelTopic": "Regional",
                            "topic": "Regional/Europe"
                        }
                    },
                    {
                        "id": 1616622,
                        "authority": 17,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 83,
                            "topLevelTopic": "Society",
                            "topic": "Society/Government"
                        }
                    },
                    {
                        "id": 1616623,
                        "authority": 15,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 6,
                            "topLevelTopic": "Business",
                            "topic": "Business"
                        }
                    }
                ]
            },
            {
                "id": 9143,
                "popularity": 10,
                "authority": 13,
                "linkCount": 90,
                "refDomainCount": 27,
                "text": "http www saveur biere com",
                "thematicDatas": [
                    {
                        "id": 1616624,
                        "authority": 12,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 60,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Food"
                        }
                    },
                    {
                        "id": 1616625,
                        "authority": 12,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 197,
                            "topLevelTopic": "Computers",
                            "topic": "Computers/News and Media"
                        }
                    },
                    {
                        "id": 1616626,
                        "authority": 10,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 20,
                            "topLevelTopic": "Reference",
                            "topic": "Reference/Museums"
                        }
                    }
                ]
            },
            {
                "id": 9144,
                "popularity": 14,
                "authority": 19,
                "linkCount": 34,
                "refDomainCount": 21,
                "text": "beery christmas",
                "thematicDatas": [
                    {
                        "id": 1616627,
                        "authority": 18,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 2,
                            "topLevelTopic": "Regional",
                            "topic": "Regional/Europe"
                        }
                    },
                    {
                        "id": 1616628,
                        "authority": 17,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 83,
                            "topLevelTopic": "Society",
                            "topic": "Society/Government"
                        }
                    },
                    {
                        "id": 1616629,
                        "authority": 15,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 6,
                            "topLevelTopic": "Business",
                            "topic": "Business"
                        }
                    }
                ]
            },
            {
                "id": 9145,
                "popularity": 14,
                "authority": 20,
                "linkCount": 106,
                "refDomainCount": 20,
                "text": "https www saveur biere com fr",
                "thematicDatas": [
                    {
                        "id": 1616630,
                        "authority": 20,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 30,
                            "topLevelTopic": "Business",
                            "topic": "Business/Consumer Goods and Services"
                        }
                    },
                    {
                        "id": 1616631,
                        "authority": 14,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 28,
                            "topLevelTopic": "Computers",
                            "topic": "Computers/Internet/Searching"
                        }
                    },
                    {
                        "id": 1616632,
                        "authority": 9,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 285,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Architecture"
                        }
                    }
                ]
            },
            {
                "id": 9146,
                "popularity": 5,
                "authority": 5,
                "linkCount": 142,
                "refDomainCount": 18,
                "text": "ici",
                "thematicDatas": [
                    {
                        "id": 1616633,
                        "authority": 5,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 31,
                            "topLevelTopic": "Society",
                            "topic": "Society/People"
                        }
                    },
                    {
                        "id": 1616634,
                        "authority": 4,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 8,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Travel"
                        }
                    },
                    {
                        "id": 1616635,
                        "authority": 4,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 113,
                            "topLevelTopic": "Reference",
                            "topic": "Reference/Maps"
                        }
                    }
                ]
            },
            {
                "id": 9147,
                "popularity": 7,
                "authority": 9,
                "linkCount": 21,
                "refDomainCount": 15,
                "text": "saveur bi\u00e8re com",
                "thematicDatas": [
                    {
                        "id": 1616636,
                        "authority": 9,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 111,
                            "topLevelTopic": "Arts",
                            "topic": "Arts/Movies"
                        }
                    },
                    {
                        "id": 1616637,
                        "authority": 6,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 106,
                            "topLevelTopic": "Home",
                            "topic": "Home/Cooking"
                        }
                    },
                    {
                        "id": 1616638,
                        "authority": 6,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 31,
                            "topLevelTopic": "Society",
                            "topic": "Society/People"
                        }
                    }
                ]
            },
            {
                "id": 9148,
                "popularity": 7,
                "authority": 9,
                "linkCount": 79,
                "refDomainCount": 12,
                "text": "https www saveur biere com",
                "thematicDatas": [
                    {
                        "id": 1616639,
                        "authority": 9,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 46,
                            "topLevelTopic": "Home",
                            "topic": "Home/Consumer Information"
                        }
                    },
                    {
                        "id": 1616640,
                        "authority": 7,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 60,
                            "topLevelTopic": "Recreation",
                            "topic": "Recreation/Food"
                        }
                    }
                ]
            },
            {
                "id": 9149,
                "popularity": 0,
                "authority": 0,
                "linkCount": 13,
                "refDomainCount": 12,
                "text": "find over 1300 different beers country mauritius saveur bi\u00e8re",
                "thematicDatas": []
            },
            {
                "id": 9150,
                "popularity": 8,
                "authority": 4,
                "linkCount": 14,
                "refDomainCount": 10,
                "text": "bi\u00e8re",
                "thematicDatas": [
                    {
                        "id": 1616641,
                        "authority": 4,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 23,
                            "topLevelTopic": "Reference",
                            "topic": "Reference/Education"
                        }
                    },
                    {
                        "id": 1616642,
                        "authority": 3,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 21,
                            "topLevelTopic": "Home",
                            "topic": "Home/Family"
                        }
                    },
                    {
                        "id": 1616643,
                        "authority": 3,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 19,
                            "topLevelTopic": "Business",
                            "topic": "Business/Construction and Maintenance"
                        }
                    }
                ]
            },
            {
                "id": 9151,
                "popularity": 0,
                "authority": 0,
                "linkCount": 10,
                "refDomainCount": 10,
                "text": "lapin kulta buy finnish beer online saveur bi\u00e8re",
                "thematicDatas": []
            },
            {
                "id": 9152,
                "popularity": 0,
                "authority": 0,
                "linkCount": 10,
                "refDomainCount": 10,
                "text": "stella artois pils lager buy belgian beer online saveur bi\u00e8re",
                "thematicDatas": []
            },
            {
                "id": 9153,
                "popularity": 0,
                "authority": 0,
                "linkCount": 9,
                "refDomainCount": 9,
                "text": "nogne \u00f8 100 beer from norway in 16 90 fl oz saveur bi\u00e8re",
                "thematicDatas": []
            },
            {
                "id": 9154,
                "popularity": 3,
                "authority": 1,
                "linkCount": 40,
                "refDomainCount": 8,
                "text": "julien",
                "thematicDatas": [
                    {
                        "id": 1616644,
                        "authority": 1,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 19,
                            "topLevelTopic": "Business",
                            "topic": "Business/Construction and Maintenance"
                        }
                    },
                    {
                        "id": 1616645,
                        "authority": 1,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 160,
                            "topLevelTopic": "Shopping",
                            "topic": "Shopping/Clothing"
                        }
                    },
                    {
                        "id": 1616646,
                        "authority": 1,
                        "refDomainCount": null,
                        "thematic": {
                            "id": 6,
                            "topLevelTopic": "Business",
                            "topic": "Business"
                        }
                    }
                ]
            },
        ]
    },

    linkingReferralBacklinks:{
        "content": [
            {
                "id": 67896,
                "popularity": 25,
                "authority": 1,
                "title": "https://saveur-biere.com/",
                "url": "https://saveur-biere.com/",
                "linkCount": 66,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Computers/Internet",
                            "topLevelTopic": "Computers"
                        }
                    },
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Society/Religion and Spirituality",
                            "topLevelTopic": "Society"
                        }
                    },
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Society/Organizations",
                            "topLevelTopic": "Society"
                        }
                    }
                ]
            },
            {
                "id": 67897,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/",
                "url": "http://saveur-biere.com/fr/",
                "linkCount": 4,
                "positionCount": 39
            },
            {
                "id": 67898,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/lpn/123-brassage",
                "url": "http://saveur-biere.com/fr/lpn/123-brassage",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67899,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/beery-christmas",
                "url": "http://saveur-biere.com/fr/beery-christmas",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67900,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/en",
                "url": "http://saveur-biere.com/en",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67901,
                "popularity": 24,
                "authority": 6,
                "title": "https://www.saveur-biere.com/en/magazine/beer-styles/3/what-is-...",
                "url": "https://www.saveur-biere.com/en/magazine/beer-styles/3/what-is-a-festbier/oktoberfestbier-style-beer/403",
                "linkCount": 47,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 6,
                        "thematic": {
                            "topic": "Recreation/Food",
                            "topLevelTopic": "Recreation"
                        }
                    },
                    {
                        "authority": 3,
                        "thematic": {
                            "topic": "Recreation/Travel",
                            "topLevelTopic": "Recreation"
                        }
                    },
                    {
                        "authority": 2,
                        "thematic": {
                            "topic": "Sports/Running",
                            "topLevelTopic": "Sports"
                        }
                    }
                ]
            },
            {
                "id": 67902,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/box-decouverte/35034-nouvelle-box-...",
                "url": "http://saveur-biere.com/fr/box-decouverte/35034-nouvelle-box-decouverte.html",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67903,
                "popularity": 7,
                "authority": 3,
                "title": "https://www.saveur-biere.com/en/beery-christmas",
                "url": "https://www.saveur-biere.com/en/beery-christmas",
                "linkCount": 51,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 3,
                        "thematic": {
                            "topic": "Recreation/Food",
                            "topLevelTopic": "Recreation"
                        }
                    }
                ]
            },
            {
                "id": 67904,
                "popularity": 6,
                "authority": 7,
                "title": "http://www.saveur-biere.com/fr/coffrets-cadeaux-verre-et-biere/...",
                "url": "http://www.saveur-biere.com/fr/coffrets-cadeaux-verre-et-biere/3650-beery-christmas-le-calendrier-de-l-avent-de-la-biere.html",
                "linkCount": 18,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 7,
                        "thematic": {
                            "topic": "Recreation/Motorcycles",
                            "topLevelTopic": "Recreation"
                        }
                    }
                ]
            },
            {
                "id": 67905,
                "popularity": 21,
                "authority": 8,
                "title": "https://www.saveur-biere.com/fr/abonnement-biere",
                "url": "https://www.saveur-biere.com/fr/abonnement-biere",
                "linkCount": 100,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 7,
                        "thematic": {
                            "topic": "Society/People",
                            "topLevelTopic": "Society"
                        }
                    },
                    {
                        "authority": 7,
                        "thematic": {
                            "topic": "Business/Food and Related Products",
                            "topLevelTopic": "Business"
                        }
                    },
                    {
                        "authority": 6,
                        "thematic": {
                            "topic": "Home/Cooking",
                            "topLevelTopic": "Home"
                        }
                    }
                ]
            },
            {
                "id": 67906,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/3-bottled-beer/country-mauritius",
                "url": "https://www.saveur-biere.com/en/3-bottled-beer/country-mauritius",
                "linkCount": 13,
                "positionCount": 0
            },
            {
                "id": 67907,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/5-tireuse-a-biere",
                "url": "http://saveur-biere.com/fr/5-tireuse-a-biere",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67908,
                "popularity": 19,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/bottled-beer/159-lapin-kulta....",
                "url": "https://www.saveur-biere.com/en/bottled-beer/159-lapin-kulta.html",
                "linkCount": 11,
                "positionCount": 0
            },
            {
                "id": 67909,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/494-beer-kit",
                "url": "http://saveur-biere.com/fr/494-beer-kit",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67910,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/bottled-beer/2206-stella-artois....",
                "url": "https://www.saveur-biere.com/en/bottled-beer/2206-stella-artois.html",
                "linkCount": 10,
                "positionCount": 0
            },
            {
                "id": 67911,
                "popularity": 18,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/bottled-beer/2310-nogne-o-100...",
                "url": "https://www.saveur-biere.com/en/bottled-beer/2310-nogne-o-100.html",
                "linkCount": 9,
                "positionCount": 0
            },
            {
                "id": 67912,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/4-futs-de-biere",
                "url": "http://saveur-biere.com/fr/4-futs-de-biere",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67913,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/magazine/brewing/4/how-to-rehydrate-...",
                "url": "https://www.saveur-biere.com/en/magazine/brewing/4/how-to-rehydrate-dried-yeast-and-keep-it-happy/191",
                "linkCount": 10,
                "positionCount": 0
            },
            {
                "id": 67914,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/bouteilles/28554-lupulus-organicus....",
                "url": "https://www.saveur-biere.com/fr/bouteilles/28554-lupulus-organicus.html",
                "linkCount": 8,
                "positionCount": 0
            },
            {
                "id": 67915,
                "popularity": 20,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/bottled-beer/9068-stone-arrogant-...",
                "url": "https://www.saveur-biere.com/en/bottled-beer/9068-stone-arrogant-bastard-ale-50-cl.html",
                "linkCount": 8,
                "positionCount": 0
            },
            {
                "id": 67916,
                "popularity": 4,
                "authority": 11,
                "title": "http://www.saveur-biere.com/fr/biere-bouteille/818-triple-secret-...",
                "url": "http://www.saveur-biere.com/fr/biere-bouteille/818-triple-secret-des-moines-33-cl.html",
                "linkCount": 15,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 11,
                        "thematic": {
                            "topic": "Computers/Programming/Languages",
                            "topLevelTopic": "Computers"
                        }
                    },
                    {
                        "authority": 7,
                        "thematic": {
                            "topic": "Computers/Software/Operating Systems",
                            "topLevelTopic": "Computers"
                        }
                    }
                ]
            },
            {
                "id": 67917,
                "popularity": 5,
                "authority": 12,
                "title": "http://www.saveur-biere.com/verre-a-biere-saveur-biere-%25-16...",
                "url": "http://www.saveur-biere.com/verre-a-biere-saveur-biere-%25-16-------1.html",
                "linkCount": 13,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 12,
                        "thematic": {
                            "topic": "Computers/Programming/Languages",
                            "topLevelTopic": "Computers"
                        }
                    },
                    {
                        "authority": 8,
                        "thematic": {
                            "topic": "Computers/Software/Operating Systems",
                            "topLevelTopic": "Computers"
                        }
                    }
                ]
            },
            {
                "id": 67918,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/bottled-beer/3534-mikkeller-hoppy-...",
                "url": "https://www.saveur-biere.com/en/bottled-beer/3534-mikkeller-hoppy-lovin-christmas.html",
                "linkCount": 9,
                "positionCount": 0
            },
            {
                "id": 67919,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/hop-for-brewing-beer/8741-cascade-...",
                "url": "https://www.saveur-biere.com/en/hop-for-brewing-beer/8741-cascade-bio-en-cones.html",
                "linkCount": 8,
                "positionCount": 0
            },
            {
                "id": 67920,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/beer-glasses/2155-verre-organic-...",
                "url": "https://www.saveur-biere.com/en/beer-glasses/2155-verre-organic-honey-dew-25cl.html",
                "linkCount": 7,
                "positionCount": 0
            },
            {
                "id": 67921,
                "popularity": 8,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/contact",
                "url": "https://www.saveur-biere.com/fr/contact",
                "linkCount": 30,
                "positionCount": 0
            },
            {
                "id": 67922,
                "popularity": 5,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/beery-christmas?LGWCODE=29486...",
                "url": "https://www.saveur-biere.com/fr/beery-christmas?LGWCODE=29486;6416;21&",
                "linkCount": 23,
                "positionCount": 0
            },
            {
                "id": 67923,
                "popularity": 4,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/beer-kit/7117-beer-kit-je-brasse-...",
                "url": "https://www.saveur-biere.com/fr/beer-kit/7117-beer-kit-je-brasse-une-pils-blonde-.html",
                "linkCount": 18,
                "positionCount": 0
            },
            {
                "id": 67924,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/shavingforsavings",
                "url": "https://www.saveur-biere.com/fr/shavingforsavings",
                "linkCount": 15,
                "positionCount": 2
            },
            {
                "id": 67925,
                "popularity": 2,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/4-kegs",
                "url": "https://www.saveur-biere.com/en/4-kegs",
                "linkCount": 10,
                "positionCount": 0
            },
            {
                "id": 67926,
                "popularity": 2,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/660-nos-bieres-trappistes",
                "url": "https://www.saveur-biere.com/fr/660-nos-bieres-trappistes",
                "linkCount": 10,
                "positionCount": 0
            },
            {
                "id": 67927,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/bottled-beer/7495-phoenix-beer....",
                "url": "https://www.saveur-biere.com/en/bottled-beer/7495-phoenix-beer.html",
                "linkCount": 7,
                "positionCount": 0
            },
            {
                "id": 67928,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/572-bons-plans",
                "url": "http://saveur-biere.com/fr/572-bons-plans",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67929,
                "popularity": 10,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/bottled-beer/32362-schorschbrau-...",
                "url": "https://www.saveur-biere.com/en/bottled-beer/32362-schorschbrau-schorschbock-57.html",
                "linkCount": 7,
                "positionCount": 0
            },
            {
                "id": 67930,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/magazine/brewing/4/give-your-...",
                "url": "https://www.saveur-biere.com/en/magazine/brewing/4/give-your-beer-an-even-bigger-hops-flavour-with-dry-hopping/35",
                "linkCount": 7,
                "positionCount": 0
            },
            {
                "id": 67931,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/3-bouteilles",
                "url": "http://saveur-biere.com/fr/3-bouteilles",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67932,
                "popularity": 6,
                "authority": 14,
                "title": "http://www.saveur-biere.com/index.php",
                "url": "http://www.saveur-biere.com/index.php",
                "linkCount": 12,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 14,
                        "thematic": {
                            "topic": "Computers/Internet/On the Web",
                            "topLevelTopic": "Computers"
                        }
                    },
                    {
                        "authority": 5,
                        "thematic": {
                            "topic": "Society/Politics",
                            "topLevelTopic": "Society"
                        }
                    }
                ]
            },
            {
                "id": 67933,
                "popularity": 5,
                "authority": 0,
                "title": "http://www.saveur-biere.com/en/3-bottled-beer",
                "url": "http://www.saveur-biere.com/en/3-bottled-beer",
                "linkCount": 3,
                "positionCount": 0
            },
            {
                "id": 67934,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/bottled-beer/1304-leffe-blonde....",
                "url": "https://www.saveur-biere.com/en/bottled-beer/1304-leffe-blonde.html",
                "linkCount": 6,
                "positionCount": 0
            },
            {
                "id": 67935,
                "popularity": 7,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/295_brasserie-corsendonk",
                "url": "https://www.saveur-biere.com/en/295_brasserie-corsendonk",
                "linkCount": 6,
                "positionCount": 0
            },
            {
                "id": 67936,
                "popularity": 10,
                "authority": 14,
                "title": "https://www.saveur-biere.com/fr/magazine/a-table/6",
                "url": "https://www.saveur-biere.com/fr/magazine/a-table/6",
                "linkCount": 6,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 14,
                        "thematic": {
                            "topic": "Home/Cooking",
                            "topLevelTopic": "Home"
                        }
                    },
                    {
                        "authority": 7,
                        "thematic": {
                            "topic": "Business/Telecommunications",
                            "topLevelTopic": "Business"
                        }
                    },
                    {
                        "authority": 7,
                        "thematic": {
                            "topic": "Recreation/Travel",
                            "topLevelTopic": "Recreation"
                        }
                    }
                ]
            },
            {
                "id": 67937,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/contact",
                "url": "https://www.saveur-biere.com/en/contact",
                "linkCount": 5,
                "positionCount": 0
            },
            {
                "id": 67938,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/bottled-beer/115-franziskaner-...",
                "url": "https://www.saveur-biere.com/en/bottled-beer/115-franziskaner-weissbier.html",
                "linkCount": 5,
                "positionCount": 0
            },
            {
                "id": 67939,
                "popularity": 17,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/bouteilles/33185-bud-25cl.html",
                "url": "https://www.saveur-biere.com/fr/bouteilles/33185-bud-25cl.html",
                "linkCount": 5,
                "positionCount": 0
            },
            {
                "id": 67940,
                "popularity": 18,
                "authority": 5,
                "title": "https://www.saveur-biere.com/fr/assortiments/29928-bieres-du-...",
                "url": "https://www.saveur-biere.com/fr/assortiments/29928-bieres-du-monde.html",
                "linkCount": 5,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 5,
                        "thematic": {
                            "topic": "Computers/Internet/Searching",
                            "topLevelTopic": "Computers"
                        }
                    },
                    {
                        "authority": 3,
                        "thematic": {
                            "topic": "Recreation/Travel",
                            "topLevelTopic": "Recreation"
                        }
                    },
                    {
                        "authority": 3,
                        "thematic": {
                            "topic": "Society/Genealogy",
                            "topLevelTopic": "Society"
                        }
                    }
                ]
            },
            {
                "id": 67941,
                "popularity": 17,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/magazine/je-brasse/4/comment-...",
                "url": "https://www.saveur-biere.com/fr/magazine/je-brasse/4/comment-choisir-sa-levure-quelles-sont-ses-caracteristiques-importantes/287",
                "linkCount": 5,
                "positionCount": 0
            },
            {
                "id": 67942,
                "popularity": 1,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/biere-bouteille/87-chimay-bleue....",
                "url": "https://www.saveur-biere.com/fr/biere-bouteille/87-chimay-bleue.html",
                "linkCount": 13,
                "positionCount": 0
            },
            {
                "id": 67943,
                "popularity": 18,
                "authority": 2,
                "title": "http://www.saveur-biere.com/barbar-miel-p-78.html",
                "url": "http://www.saveur-biere.com/barbar-miel-p-78.html",
                "linkCount": 12,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 2,
                        "thematic": {
                            "topic": "Society/Politics",
                            "topLevelTopic": "Society"
                        }
                    },
                    {
                        "authority": 2,
                        "thematic": {
                            "topic": "Home/Cooking",
                            "topLevelTopic": "Home"
                        }
                    },
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Society/People",
                            "topLevelTopic": "Society"
                        }
                    }
                ]
            },
            {
                "id": 67944,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/89-saveur-biere-le-drive.html",
                "url": "http://saveur-biere.com/fr/89-saveur-biere-le-drive.html",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67945,
                "popularity": 17,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/mr-mrs-waouh",
                "url": "https://www.saveur-biere.com/fr/mr-mrs-waouh",
                "linkCount": 10,
                "positionCount": 0
            },
            {
                "id": 67946,
                "popularity": 3,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/bouteilles/134-corona-extra.html",
                "url": "https://www.saveur-biere.com/fr/bouteilles/134-corona-extra.html",
                "linkCount": 9,
                "positionCount": 0
            },
            {
                "id": 67947,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/beer-kit/7117--beer-kit-je-brasse-...",
                "url": "https://www.saveur-biere.com/fr/beer-kit/7117--beer-kit-je-brasse-une-pils-blonde-.html",
                "linkCount": 8,
                "positionCount": 0
            },
            {
                "id": 67948,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/magazine",
                "url": "http://saveur-biere.com/fr/magazine",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67949,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/748-biere-d-abbaye",
                "url": "https://www.saveur-biere.com/fr/748-biere-d-abbaye",
                "linkCount": 6,
                "positionCount": 0
            },
            {
                "id": 67950,
                "popularity": 2,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/611_brewdog-brewery",
                "url": "https://www.saveur-biere.com/fr/611_brewdog-brewery",
                "linkCount": 6,
                "positionCount": 0
            },
            {
                "id": 67951,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/70-assortiments",
                "url": "http://saveur-biere.com/fr/70-assortiments",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67952,
                "popularity": 6,
                "authority": 2,
                "title": "https://www.saveur-biere.com/fr/kits-de-brassage/3957-kit-de-...",
                "url": "https://www.saveur-biere.com/fr/kits-de-brassage/3957-kit-de-brassage-basic.html",
                "linkCount": 6,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 2,
                        "thematic": {
                            "topic": "Health/Senior Health",
                            "topLevelTopic": "Health"
                        }
                    },
                    {
                        "authority": 2,
                        "thematic": {
                            "topic": "Health/Fitness",
                            "topLevelTopic": "Health"
                        }
                    },
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Home/Cooking",
                            "topLevelTopic": "Home"
                        }
                    }
                ]
            },
            {
                "id": 67953,
                "popularity": 2,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/60_abbaye-de-chimay",
                "url": "https://www.saveur-biere.com/fr/60_abbaye-de-chimay",
                "linkCount": 5,
                "positionCount": 0
            },
            {
                "id": 67954,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/1119_deschutes-brewery",
                "url": "https://www.saveur-biere.com/en/1119_deschutes-brewery",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 67955,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/bottled-beer/1808-saison-dupont....",
                "url": "https://www.saveur-biere.com/en/bottled-beer/1808-saison-dupont.html",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 67956,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/68-fut-5-litres-beertender",
                "url": "https://www.saveur-biere.com/fr/68-fut-5-litres-beertender",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 67957,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/53_brasserie-bosteels",
                "url": "https://www.saveur-biere.com/fr/53_brasserie-bosteels",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 67958,
                "popularity": 16,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/399-soldes",
                "url": "https://www.saveur-biere.com/fr/399-soldes",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 67959,
                "popularity": 17,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/630-french-days",
                "url": "https://www.saveur-biere.com/fr/630-french-days",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 67960,
                "popularity": 17,
                "authority": 1,
                "title": "https://www.saveur-biere.com/en/beer-subscription-box",
                "url": "https://www.saveur-biere.com/en/beer-subscription-box",
                "linkCount": 266,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Recreation/Travel",
                            "topLevelTopic": "Recreation"
                        }
                    },
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Regional/Europe",
                            "topLevelTopic": "Regional"
                        }
                    }
                ]
            },
            {
                "id": 67961,
                "popularity": 20,
                "authority": 6,
                "title": "https://www.saveur-biere.com/fr/bouteilles/33206-leffe-blonde-...",
                "url": "https://www.saveur-biere.com/fr/bouteilles/33206-leffe-blonde-0-0.html",
                "linkCount": 99,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 6,
                        "thematic": {
                            "topic": "Computers/Internet/Searching",
                            "topLevelTopic": "Computers"
                        }
                    },
                    {
                        "authority": 4,
                        "thematic": {
                            "topic": "Regional/Europe",
                            "topLevelTopic": "Regional"
                        }
                    },
                    {
                        "authority": 4,
                        "thematic": {
                            "topic": "Arts/Music",
                            "topLevelTopic": "Arts"
                        }
                    }
                ]
            },
            {
                "id": 68812,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/christian",
                "url": "https://www.saveur-biere.com/fr/christian",
                "linkCount": 1,
                "positionCount": 0
            },
            {
                "id": 67962,
                "popularity": 0,
                "authority": 0,
                "title": "http://www.saveur-biere.com/legendary-duff-beer-p-1064.html",
                "url": "http://www.saveur-biere.com/legendary-duff-beer-p-1064.html",
                "linkCount": 44,
                "positionCount": 0
            },
            {
                "id": 67963,
                "popularity": 16,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/magazine/la-biere/3/combien-de-...",
                "url": "https://www.saveur-biere.com/fr/magazine/la-biere/3/combien-de-bieres-belges-existe-t-il-environ/183",
                "linkCount": 32,
                "positionCount": 0
            },
            {
                "id": 67964,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/login",
                "url": "http://saveur-biere.com/fr/login",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67965,
                "popularity": 22,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/lpn/123-brassage?ItmID=218006...",
                "url": "https://www.saveur-biere.com/fr/lpn/123-brassage?ItmID=2180060",
                "linkCount": 22,
                "positionCount": 0
            },
            {
                "id": 67966,
                "popularity": 8,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/magazine/la-biere/3/quest-ce-...",
                "url": "https://www.saveur-biere.com/fr/magazine/la-biere/3/quest-ce-quune-biere-dabbaye/169",
                "linkCount": 16,
                "positionCount": 0
            },
            {
                "id": 67967,
                "popularity": 5,
                "authority": 3,
                "title": "http://www.saveur-biere.com/blog/index.php",
                "url": "http://www.saveur-biere.com/blog/index.php",
                "linkCount": 6,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 3,
                        "thematic": {
                            "topic": "Reference/Ask an Expert",
                            "topLevelTopic": "Reference"
                        }
                    },
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Business",
                            "topLevelTopic": "Business"
                        }
                    }
                ]
            },
            {
                "id": 67968,
                "popularity": 17,
                "authority": 0,
                "title": "http://www.saveur-biere.com/liste-video-biere.php",
                "url": "http://www.saveur-biere.com/liste-video-biere.php",
                "linkCount": 5,
                "positionCount": 0
            },
            {
                "id": 67969,
                "popularity": 16,
                "authority": 17,
                "title": "https://www.saveur-biere.com/en/193-privacy-policy.html",
                "url": "https://www.saveur-biere.com/en/193-privacy-policy.html",
                "linkCount": 5,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 17,
                        "thematic": {
                            "topic": "Shopping/Food",
                            "topLevelTopic": "Shopping"
                        }
                    },
                    {
                        "authority": 7,
                        "thematic": {
                            "topic": "Recreation/Food",
                            "topLevelTopic": "Recreation"
                        }
                    }
                ]
            },
            {
                "id": 67970,
                "popularity": 8,
                "authority": 1,
                "title": "https://www.saveur-biere.com/en/bottled-beer/9198-robinsons-iron-...",
                "url": "https://www.saveur-biere.com/en/bottled-beer/9198-robinsons-iron-maiden-red-n-black-porter-robinsons-brewery-iron-maiden-craft-beer.html",
                "linkCount": 5,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Recreation/Travel",
                            "topLevelTopic": "Recreation"
                        }
                    },
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Regional/Europe",
                            "topLevelTopic": "Regional"
                        }
                    },
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Shopping/General Merchandise",
                            "topLevelTopic": "Shopping"
                        }
                    }
                ]
            },
            {
                "id": 67971,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/172-conditions-de-livraison.html",
                "url": "http://saveur-biere.com/fr/172-conditions-de-livraison.html",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67972,
                "popularity": 17,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/630-french-days/o-discount",
                "url": "https://www.saveur-biere.com/fr/630-french-days/o-discount",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 67973,
                "popularity": 16,
                "authority": 0,
                "title": "http://www.saveur-biere.com/fr/3-biere-bouteille/pays-angleterre",
                "url": "http://www.saveur-biere.com/fr/3-biere-bouteille/pays-angleterre",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 67974,
                "popularity": 13,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/bouteilles/90-rochefort-8.html",
                "url": "https://www.saveur-biere.com/fr/bouteilles/90-rochefort-8.html",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 67975,
                "popularity": 13,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/bouteilles/1304-leffe-blonde....",
                "url": "https://www.saveur-biere.com/fr/bouteilles/1304-leffe-blonde.html",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 67976,
                "popularity": 9,
                "authority": 16,
                "title": "https://www.saveur-biere.com/en/194-cookies-policy.html",
                "url": "https://www.saveur-biere.com/en/194-cookies-policy.html",
                "linkCount": 4,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 16,
                        "thematic": {
                            "topic": "Shopping/Food",
                            "topLevelTopic": "Shopping"
                        }
                    },
                    {
                        "authority": 6,
                        "thematic": {
                            "topic": "Recreation/Food",
                            "topLevelTopic": "Recreation"
                        }
                    }
                ]
            },
            {
                "id": 67977,
                "popularity": 0,
                "authority": 0,
                "title": "http://www.saveur-biere.com/fr/kit-a-biere-tout-grain/30025-beer-...",
                "url": "http://www.saveur-biere.com/fr/kit-a-biere-tout-grain/30025-beer-kit-complet-je-brasse-une-blonde.html",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 67978,
                "popularity": 13,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/bouteilles/3761-hoegaarden-wit-...",
                "url": "https://www.saveur-biere.com/fr/bouteilles/3761-hoegaarden-wit-blanche.html",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 67979,
                "popularity": 5,
                "authority": 2,
                "title": "https://www.saveur-biere.com/fr/accessoires-et-cadeaux/34258-...",
                "url": "https://www.saveur-biere.com/fr/accessoires-et-cadeaux/34258-coffret-degustation-biere-craft.html",
                "linkCount": 4,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 2,
                        "thematic": {
                            "topic": "Shopping/Jewelry",
                            "topLevelTopic": "Shopping"
                        }
                    },
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Business/Marketing and Advertising",
                            "topLevelTopic": "Business"
                        }
                    }
                ]
            },
            {
                "id": 67980,
                "popularity": 17,
                "authority": 2,
                "title": "https://www.saveur-biere.com/fr/assortiments/7049-assortiment-...",
                "url": "https://www.saveur-biere.com/fr/assortiments/7049-assortiment-bieres-ipa.html",
                "linkCount": 4,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 2,
                        "thematic": {
                            "topic": "Shopping/Jewelry",
                            "topLevelTopic": "Shopping"
                        }
                    },
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Business/Marketing and Advertising",
                            "topLevelTopic": "Business"
                        }
                    }
                ]
            },
            {
                "id": 67981,
                "popularity": 2,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/verres-a-biere/303-verre-kwak-...",
                "url": "https://www.saveur-biere.com/fr/verres-a-biere/303-verre-kwak-avec-pied-en-bois.html",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 67982,
                "popularity": 5,
                "authority": 3,
                "title": "https://www.saveur-biere.com/fr/biere-bouteille/4912-brewmeister-...",
                "url": "https://www.saveur-biere.com/fr/biere-bouteille/4912-brewmeister-snake-venom.html",
                "linkCount": 4,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 3,
                        "thematic": {
                            "topic": "Shopping/Home and Garden",
                            "topLevelTopic": "Shopping"
                        }
                    },
                    {
                        "authority": 2,
                        "thematic": {
                            "topic": "Business",
                            "topLevelTopic": "Business"
                        }
                    },
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Arts/Performing Arts",
                            "topLevelTopic": "Arts"
                        }
                    }
                ]
            },
            {
                "id": 67983,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/1239_nebraska-brewing-company",
                "url": "https://www.saveur-biere.com/en/1239_nebraska-brewing-company",
                "linkCount": 3,
                "positionCount": 0
            },
            {
                "id": 67984,
                "popularity": 7,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/recherche--non-pasteuris%C3%A9...",
                "url": "https://www.saveur-biere.com/fr/recherche--non-pasteuris%C3%A9e",
                "linkCount": 3,
                "positionCount": 0
            },
            {
                "id": 67985,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/3-bouteilles/pays-belgique",
                "url": "https://www.saveur-biere.com/fr/3-bouteilles/pays-belgique",
                "linkCount": 3,
                "positionCount": 0
            },
            {
                "id": 67986,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/bouteilles/57-gulden-draak.html",
                "url": "https://www.saveur-biere.com/fr/bouteilles/57-gulden-draak.html",
                "linkCount": 3,
                "positionCount": 0
            },
            {
                "id": 67987,
                "popularity": 13,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/774-mega-packs",
                "url": "https://www.saveur-biere.com/fr/774-mega-packs",
                "linkCount": 3,
                "positionCount": 0
            },
            {
                "id": 67988,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/193-politique-de-confidentialite.html",
                "url": "http://saveur-biere.com/fr/193-politique-de-confidentialite.html",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 67989,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/bottled-beer/8683-schneider-tap-...",
                "url": "https://www.saveur-biere.com/en/bottled-beer/8683-schneider-tap-4-meine-festweisse.html",
                "linkCount": 3,
                "positionCount": 0
            },
            {
                "id": 67990,
                "popularity": 14,
                "authority": 3,
                "title": "https://www.saveur-biere.com/fr/bouteilles/231-bud.html",
                "url": "https://www.saveur-biere.com/fr/bouteilles/231-bud.html",
                "linkCount": 3,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 3,
                        "thematic": {
                            "topic": "Arts/Crafts",
                            "topLevelTopic": "Arts"
                        }
                    }
                ]
            },
            {
                "id": 67991,
                "popularity": 13,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/beer-glasses/3805-verre-brewdog-...",
                "url": "https://www.saveur-biere.com/en/beer-glasses/3805-verre-brewdog-punk-ipa-50cl.html",
                "linkCount": 3,
                "positionCount": 0
            },
            {
                "id": 67992,
                "popularity": 13,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/assortiments/35844-pack-duo-coffrets-...",
                "url": "https://www.saveur-biere.com/fr/assortiments/35844-pack-duo-coffrets-decouvertes.html",
                "linkCount": 3,
                "positionCount": 0
            },
            {
                "id": 67993,
                "popularity": 16,
                "authority": 5,
                "title": "https://www.saveur-biere.com/fr/3-bouteilles/abv-8.01_10.01/pays-...",
                "url": "https://www.saveur-biere.com/fr/3-bouteilles/abv-8.01_10.01/pays-belgique",
                "linkCount": 3,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 5,
                        "thematic": {
                            "topic": "Regional/Europe",
                            "topLevelTopic": "Regional"
                        }
                    }
                ]
            },
            {
                "id": 67994,
                "popularity": 13,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/bottled-beer/32361-schorschbraeu-...",
                "url": "https://www.saveur-biere.com/en/bottled-beer/32361-schorschbraeu-schorschbock-43.html",
                "linkCount": 3,
                "positionCount": 0
            },
            {
                "id": 67995,
                "popularity": 20,
                "authority": 0,
                "title": "https://www.saveur-biere.com/?do=basic",
                "url": "https://www.saveur-biere.com/?do=basic",
                "linkCount": 356,
                "positionCount": 0
            },
            {
                "id": 67996,
                "popularity": 8,
                "authority": 3,
                "title": "https://www.saveur-biere.com/fr/biere-bouteille/4584-page-24nogne-...",
                "url": "https://www.saveur-biere.com/fr/biere-bouteille/4584-page-24nogne-rhub-ipa.html",
                "linkCount": 76,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 3,
                        "thematic": {
                            "topic": "Computers/Programming/Languages",
                            "topLevelTopic": "Computers"
                        }
                    },
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Computers/Software/Operating Systems",
                            "topLevelTopic": "Computers"
                        }
                    }
                ]
            },
            {
                "id": 67997,
                "popularity": 2,
                "authority": 0,
                "title": "http://www.saveur-biere.com/tireuse-biere-biermaxx-rouge-p-18...",
                "url": "http://www.saveur-biere.com/tireuse-biere-biermaxx-rouge-p-189.html",
                "linkCount": 64,
                "positionCount": 0
            },
            {
                "id": 67998,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/beery-christmas?utm_campaign=...",
                "url": "https://www.saveur-biere.com/fr/beery-christmas?utm_campaign=affilae&utm_source=monpetithoublon&utm_medium=affiliation",
                "linkCount": 36,
                "positionCount": 0
            },
            {
                "id": 67999,
                "popularity": 0,
                "authority": 0,
                "title": "http://www.saveur-biere.com/fr/coffrets-cadeaux-verre-et-biere/...",
                "url": "http://www.saveur-biere.com/fr/coffrets-cadeaux-verre-et-biere/3650-calendrier-de-l-avent-de-la-biere.html",
                "linkCount": 27,
                "positionCount": 0
            },
            {
                "id": 68000,
                "popularity": 10,
                "authority": 5,
                "title": "https://www.saveur-biere.com/en/1477_brasserie-ginette",
                "url": "https://www.saveur-biere.com/en/1477_brasserie-ginette",
                "linkCount": 23,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 5,
                        "thematic": {
                            "topic": "Regional/Europe",
                            "topLevelTopic": "Regional"
                        }
                    },
                    {
                        "authority": 2,
                        "thematic": {
                            "topic": "News/Newspapers",
                            "topLevelTopic": "News"
                        }
                    },
                    {
                        "authority": 2,
                        "thematic": {
                            "topic": "Business/Construction and Maintenance",
                            "topLevelTopic": "Business"
                        }
                    }
                ]
            },
            {
                "id": 68001,
                "popularity": 4,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/497-kit-de-brassage",
                "url": "https://www.saveur-biere.com/fr/497-kit-de-brassage",
                "linkCount": 17,
                "positionCount": 0
            },
            {
                "id": 68002,
                "popularity": 8,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/contact?utm_source=help.saveur-...",
                "url": "https://www.saveur-biere.com/fr/contact?utm_source=help.saveur-biere.com",
                "linkCount": 17,
                "positionCount": 0
            },
            {
                "id": 68003,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/my-account",
                "url": "http://saveur-biere.com/fr/my-account",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 68004,
                "popularity": 3,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/biere-bouteille/81-orval.html",
                "url": "https://www.saveur-biere.com/fr/biere-bouteille/81-orval.html",
                "linkCount": 11,
                "positionCount": 0
            },
            {
                "id": 68005,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/tireuse-a-biere/633-tireuse-a-...",
                "url": "https://www.saveur-biere.com/fr/tireuse-a-biere/633-tireuse-a-biere-biermaxx-argent.html",
                "linkCount": 9,
                "positionCount": 0
            },
            {
                "id": 68006,
                "popularity": 0,
                "authority": 0,
                "title": "http://www.saveur-biere.com/images/pilsner-urquell-33.jpg",
                "url": "http://www.saveur-biere.com/images/pilsner-urquell-33.jpg",
                "linkCount": 8,
                "positionCount": 0
            },
            {
                "id": 68007,
                "popularity": 0,
                "authority": 0,
                "title": "http://saveur-biere.com/fr/168-qui-sommes-nous.html",
                "url": "http://saveur-biere.com/fr/168-qui-sommes-nous.html",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 68008,
                "popularity": 16,
                "authority": 0,
                "title": "https://lov.saveur-biere.com/?P4FDE7426791B1&redir=https://www....",
                "url": "https://lov.saveur-biere.com/?P4FDE7426791B1&redir=https://www.saveur-biere.com/fr/beery-christmas",
                "linkCount": 8,
                "positionCount": 0
            },
            {
                "id": 68009,
                "popularity": 0,
                "authority": 0,
                "title": "http://www.saveur-biere.com/blog/images/leffe-9.jpg",
                "url": "http://www.saveur-biere.com/blog/images/leffe-9.jpg",
                "linkCount": 7,
                "positionCount": 0
            },
            {
                "id": 68010,
                "popularity": 2,
                "authority": 3,
                "title": "http://www.saveur-biere.com/delirium-tremens-p-85.html",
                "url": "http://www.saveur-biere.com/delirium-tremens-p-85.html",
                "linkCount": 7,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 3,
                        "thematic": {
                            "topic": "Arts/Photography",
                            "topLevelTopic": "Arts"
                        }
                    }
                ]
            },
            {
                "id": 68011,
                "popularity": 10,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/lpn/123-brassage?CatID=114458...",
                "url": "https://www.saveur-biere.com/fr/lpn/123-brassage?CatID=1144588",
                "linkCount": 7,
                "positionCount": 0
            },
            {
                "id": 68012,
                "popularity": 2,
                "authority": 0,
                "title": "http://www.saveur-biere.com/fr/biere-bouteille/2688-jupiler.html",
                "url": "http://www.saveur-biere.com/fr/biere-bouteille/2688-jupiler.html",
                "linkCount": 2,
                "positionCount": 0
            },
            {
                "id": 68013,
                "popularity": 16,
                "authority": 4,
                "title": "http://www.saveur-biere.com/fiche-biere.php?biere=angelus-l",
                "url": "http://www.saveur-biere.com/fiche-biere.php?biere=angelus-l",
                "linkCount": 7,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 4,
                        "thematic": {
                            "topic": "Recreation/Motorcycles",
                            "topLevelTopic": "Recreation"
                        }
                    }
                ]
            },
            {
                "id": 68014,
                "popularity": 4,
                "authority": 6,
                "title": "http://www.saveur-biere.com/guide-achat.php",
                "url": "http://www.saveur-biere.com/guide-achat.php",
                "linkCount": 7,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 6,
                        "thematic": {
                            "topic": "Business/Energy",
                            "topLevelTopic": "Business"
                        }
                    },
                    {
                        "authority": 5,
                        "thematic": {
                            "topic": "Society/Organizations",
                            "topLevelTopic": "Society"
                        }
                    },
                    {
                        "authority": 5,
                        "thematic": {
                            "topic": "Recreation/Pets",
                            "topLevelTopic": "Recreation"
                        }
                    }
                ]
            },
            {
                "id": 68015,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/en/magazine/breweries-and-brewers/...",
                "url": "https://www.saveur-biere.com/en/magazine/breweries-and-brewers/5/evil-twin-vs-mikkeller-why-sibling-rivalry-isn-t-always-a-bad-thing/142",
                "linkCount": 7,
                "positionCount": 0
            },
            {
                "id": 68016,
                "popularity": 0,
                "authority": 0,
                "title": "https://lov.saveur-biere.com/?P4FDE7573AA91113",
                "url": "https://lov.saveur-biere.com/?P4FDE7573AA91113",
                "linkCount": 6,
                "positionCount": 0
            },
            {
                "id": 68017,
                "popularity": 1,
                "authority": 0,
                "title": "http://www.saveur-biere.com/verte-mont-blanc-p-194.html",
                "url": "http://www.saveur-biere.com/verte-mont-blanc-p-194.html",
                "linkCount": 6,
                "positionCount": 0
            },
            {
                "id": 68018,
                "popularity": 0,
                "authority": 0,
                "title": "https://lov.saveur-biere.com/?P4FDE7573AA91113 https://action....",
                "url": "https://lov.saveur-biere.com/?P4FDE7573AA91113 https://action.metaffiliation.com/trk.php?mclic=P48D87573C592331",
                "linkCount": 6,
                "positionCount": 0
            },
            {
                "id": 68019,
                "popularity": 13,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/bouteilles/7706-einstok-icelandic-...",
                "url": "https://www.saveur-biere.com/fr/bouteilles/7706-einstok-icelandic-white-ale.html",
                "linkCount": 6,
                "positionCount": 0
            },
            {
                "id": 68020,
                "popularity": 0,
                "authority": 0,
                "title": "http://www.saveur-biere.com/images/biere-unibroue-quelque-chose-...",
                "url": "http://www.saveur-biere.com/images/biere-unibroue-quelque-chose-75.jpg",
                "linkCount": 5,
                "positionCount": 0
            },
            {
                "id": 68021,
                "popularity": 0,
                "authority": 0,
                "title": "http://www.saveur-biere.com/game-over-p-2060.html",
                "url": "http://www.saveur-biere.com/game-over-p-2060.html",
                "linkCount": 5,
                "positionCount": 0
            },
            {
                "id": 68022,
                "popularity": 7,
                "authority": 0,
                "title": "http://www.saveur-biere.com/blog/",
                "url": "http://www.saveur-biere.com/blog/",
                "linkCount": 5,
                "positionCount": 0
            },
            {
                "id": 68023,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/bouteilles/277-bush-de-noel.html",
                "url": "https://www.saveur-biere.com/fr/bouteilles/277-bush-de-noel.html",
                "linkCount": 5,
                "positionCount": 0
            },
            {
                "id": 68024,
                "popularity": 16,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/tireuse-a-biere/678-perfectdraft-...",
                "url": "https://www.saveur-biere.com/fr/tireuse-a-biere/678-perfectdraft-hd-3620.html",
                "linkCount": 5,
                "positionCount": 0
            },
            {
                "id": 68025,
                "popularity": 0,
                "authority": 0,
                "title": "http://www.saveur-biere.com/biere-brasserie/index.php?tag/biere-...",
                "url": "http://www.saveur-biere.com/biere-brasserie/index.php?tag/biere-anglaise",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 68026,
                "popularity": 5,
                "authority": 2,
                "title": "https://www.saveur-biere.com/fr/bouteilles/2149-st-stefanus.html",
                "url": "https://www.saveur-biere.com/fr/bouteilles/2149-st-stefanus.html",
                "linkCount": 4,
                "positionCount": 0,
                "thematicDatas": [
                    {
                        "authority": 2,
                        "thematic": {
                            "topic": "Shopping/Jewelry",
                            "topLevelTopic": "Shopping"
                        }
                    },
                    {
                        "authority": 1,
                        "thematic": {
                            "topic": "Business/Marketing and Advertising",
                            "topLevelTopic": "Business"
                        }
                    }
                ]
            },
            {
                "id": 68027,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/lpn/123-brassage?CatID=584858...",
                "url": "https://www.saveur-biere.com/fr/lpn/123-brassage?CatID=584858&",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 68028,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/lpn/123-brassage?ID=143011",
                "url": "https://www.saveur-biere.com/fr/lpn/123-brassage?ID=143011",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 68029,
                "popularity": 0,
                "authority": 0,
                "title": "http://www.saveur-biere.com/fr/biere-bouteille/2055-westvleteren-...",
                "url": "http://www.saveur-biere.com/fr/biere-bouteille/2055-westvleteren-abt-12.html",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 68030,
                "popularity": 3,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/bouteilles/1088-bourbon-la-dodo....",
                "url": "https://www.saveur-biere.com/fr/bouteilles/1088-bourbon-la-dodo.html",
                "linkCount": 4,
                "positionCount": 0
            },
            {
                "id": 68031,
                "popularity": 0,
                "authority": 0,
                "title": "https://www.saveur-biere.com/fr/bouteilles/31698-coffret-top-...",
                "url": "https://www.saveur-biere.com/fr/bouteilles/31698-coffret-top-dad.html",
                "linkCount": 4,
                "positionCount": 0
            },
        ]
    }
}

/*=========================================================================================
  File Name: moduleAuth.js
  Description: Auth Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from '@/store/customersManager/customersManagerState.js'
import mutations from '@/store/customersManager/customersManagerMutations.js'
import actions from '@/store/customersManager/customersManagerActions.js'
import getters from '@/store/customersManager/customersManagerGetters.js'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

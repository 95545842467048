
export default {
    competitionVisibilityEvolution: {
        content:[
            {
                domainName: "culturevelo.com",
                competitorType: "project",
                cumulatedVisibilities: [
                    {date: "2020-01-01",cumulatedVisibility: 730},
                    {date: "2020-02-01",cumulatedVisibility: 910},
                    {date: "2020-03-01",cumulatedVisibility: 1258},
                    {date: "2020-03-02",cumulatedVisibility: 1312},
                    {date: "2020-03-09",cumulatedVisibility: 1402},
                    {date: "2020-03-10",cumulatedVisibility: 1450},
                    {date: "2020-03-11",cumulatedVisibility: 1408},
                    {date: "2020-03-12",cumulatedVisibility: 1510},
                    {date: "2020-03-13",cumulatedVisibility: 1620},
                    {date: "2020-03-14",cumulatedVisibility: 1688},
                    {date: "2020-03-15",cumulatedVisibility: 1713},
                ]
            },
            {
                domainName: "cyclable.com",
                competitorType: "watched",
                cumulatedVisibilities: [
                    {date: "2020-01-01",cumulatedVisibility: 520},
                    {date: "2020-02-01",cumulatedVisibility: 750},
                    {date: "2020-03-01",cumulatedVisibility: 1020},
                    {date: "2020-03-02",cumulatedVisibility: 1100},
                    {date: "2020-03-09",cumulatedVisibility: 1180},
                    {date: "2020-03-10",cumulatedVisibility: 1210},
                    {date: "2020-03-11",cumulatedVisibility: 1170},
                    {date: "2020-03-12",cumulatedVisibility: 1200},
                    {date: "2020-03-13",cumulatedVisibility: 1230},
                    {date: "2020-03-14",cumulatedVisibility: 1250},
                    {date: "2020-03-15",cumulatedVisibility: 1180},
                ]
            },
            {
                domainName: "intersport.fr",
                competitorType: "watched",
                cumulatedVisibilities: [
                    {date: "2020-01-01",cumulatedVisibility: 70},
                    {date: "2020-02-01",cumulatedVisibility: 88},
                    {date: "2020-03-01",cumulatedVisibility: 80},
                    {date: "2020-03-02",cumulatedVisibility: 78},
                    {date: "2020-03-09",cumulatedVisibility: 75},
                    {date: "2020-03-10",cumulatedVisibility: 72},
                    {date: "2020-03-11",cumulatedVisibility: 70},
                    {date: "2020-03-12",cumulatedVisibility: 70},
                    {date: "2020-03-13",cumulatedVisibility: 68},
                    {date: "2020-03-14",cumulatedVisibility: 72},
                    {date: "2020-03-15",cumulatedVisibility: 78},
                ]
            },
            {
                domainName: "bikester.fr",
                competitorType: "watched",
                cumulatedVisibilities: [
                    {date: "2020-01-01",cumulatedVisibility: 220},
                    {date: "2020-02-01",cumulatedVisibility: 420},
                    {date: "2020-03-01",cumulatedVisibility: 745},
                    {date: "2020-03-02",cumulatedVisibility: 810},
                    {date: "2020-03-09",cumulatedVisibility: 820},
                    {date: "2020-03-10",cumulatedVisibility: 890},
                    {date: "2020-03-11",cumulatedVisibility: 930},
                    {date: "2020-03-12",cumulatedVisibility: 980},
                    {date: "2020-03-13",cumulatedVisibility: 1120},
                    {date: "2020-03-14",cumulatedVisibility: 1280},
                    {date: "2020-03-15",cumulatedVisibility: 1810},
                ]
            },
        ]
    },

    competitionBestProgression: {
        content:[
            {
                "websiteId": 1,
                "domainName": "pureelectric.fr",
                "rankEvolution": 3
            },
            {
                "websiteId": 2,
                "domainName": "bikester.fr",
                "rankEvolution": 2
            },
            {
                "websiteId": 3,
                "domainName": "culturevelo.com",
                "rankEvolution": 2
            }
        ]

    },
    competitionNewCompetitor: {
        content:[
            {
                "websiteId": 3,
                "domainName": "velovelo.com",
                "competitorType": "other",
                "rank": 35,
                "performance": 23.02
            },
            {
                "websiteId": 4,
                "domainName": "powerbike.com",
                "competitorType": "watched",
                "rank": 42,
                "performance": 16.01
            },
            {
                "websiteId": 5,
                "domainName": "velocity.com",
                "competitorType": "other",
                "rank": 48,
                "performance": 12.33
            },
        ]

    },
    myRank: {
        content:{
            rank:14,
            rankEvolution:-1
        }

    },
    competitorMarketCompare: {
        content:[
            {
                performances: [
                    {performance: 92, keywordGroupName: "Vélos électriques"},
                    {performance: 87, keywordGroupName: "VTT"},
                    {performance: 72, keywordGroupName: "Vélos ville"},
                    {performance: 68, keywordGroupName: "Vélos route"},
                ],
                domainName: "blog.yooda.com",
                competitorType: "project"
            },
            {
                performances: [
                    {performance: 36, keywordGroupName: "Vélos électriques"},
                    {performance: 62, keywordGroupName: "VTT"},
                    {performance: 78, keywordGroupName: "Vélos ville"},
                    {performance: 37, keywordGroupName: "Vélos route"},
                ],
                domainName: "semrush.com",
                competitorType: "watched"
            },
        ]
    },
    competitorCompare: {
        content:[
            {
                domainName: "culturevelo.com",
                competitorType: "project",
                performance: 82
            },
            {
                domainName: "cyclable.com",
                competitorType: "watched",
                performance: 66
            },
            {
                domainName: "intersport.fr",
                competitorType: "watched",
                performance: 60
            },
            {
                domainName: "bikester.fr",
                competitorType: "watched",
                performance: 54
            },
            {
                domainName: "pureelectric.fr",
                competitorType: "watched",
                performance: 39
            },

        ]
    },
    competitionMarketLeader: {
        content:[
            {
                "performance": "7.17",
                "rank": 1,
                "cumulatedVisibilities": [],
                "websiteId": 1,
                "domainName": "culturevelo.com",
                "cumulatedVisibility": 125467,
                "rankEvolution": -1,
                "rankedKeywordCount": 87,
                "rankedPageCount": 7
            },
            {
                "performance": "5.71",
                "rank": 2,
                "cumulatedVisibilities": [],
                "websiteId": 2,
                "domainName": "cyclable.com",
                "cumulatedVisibility": 1457895,
                "rankEvolution": 1,
                "rankedKeywordCount": 145,
                "rankedPageCount": 66
            },
            {
                "performance": "4.3",
                "rank": 3,
                "cumulatedVisibilities": [],
                "websiteId": 3,
                "domainName": "intersport.fr",
                "cumulatedVisibility": 1046784,
                "rankEvolution": -2,
                "rankedKeywordCount": 62,
                "rankedPageCount": "44"
            },
            {
                "performance": "4",
                "rank": 4,
                "cumulatedVisibilities": [],
                "websiteId": 4,
                "domainName": "bikester.fr",
                "cumulatedVisibility": 46784,
                "rankEvolution": -8,
                "rankedKeywordCount": 17,
                "rankedPageCount": 23
            },
        ]

    },
    competitionMarketLeaderVisibilityEvolution: {
        content:[
            {
                "websiteId": 101,
                "domainName": "culturevelo.com",
                "competitorType": "project",
                "cumulatedVisibilities": [
                    {date: "2020-01-01",cumulatedVisibility: 220},
                    {date: "2020-02-01",cumulatedVisibility: 420},
                    {date: "2020-03-01",cumulatedVisibility: 745},
                    {date: "2020-03-02",cumulatedVisibility: 810},
                    {date: "2020-03-09",cumulatedVisibility: 820},
                    {date: "2020-03-10",cumulatedVisibility: 890},
                    {date: "2020-03-11",cumulatedVisibility: 930},
                    {date: "2020-03-12",cumulatedVisibility: 980},
                    {date: "2020-03-13",cumulatedVisibility: 1120},
                    {date: "2020-03-14",cumulatedVisibility: 1280},
                    {date: "2020-03-15",cumulatedVisibility: 1810},
                ]
            },
            {
                "websiteId": 572,
                "domainName": "cyclable.com",
                "competitorType": "other",
                "cumulatedVisibilities": [
                    {date: "2020-01-01",cumulatedVisibility: 1730},
                    {date: "2020-02-01",cumulatedVisibility: 1910},
                    {date: "2020-03-01",cumulatedVisibility: 2258},
                    {date: "2020-03-02",cumulatedVisibility: 2312},
                    {date: "2020-03-09",cumulatedVisibility: 2402},
                    {date: "2020-03-10",cumulatedVisibility: 2450},
                    {date: "2020-03-11",cumulatedVisibility: 2408},
                    {date: "2020-03-12",cumulatedVisibility: 2510},
                    {date: "2020-03-13",cumulatedVisibility: 2620},
                    {date: "2020-03-14",cumulatedVisibility: 2688},
                    {date: "2020-03-15",cumulatedVisibility: 2713},
                ]
            }
        ]

    },
    competitionCompetitorCompare:{
        content:[
            {performance:84,domainName:"intersport.fr"},
            {performance:60,domainName:"bikester.fr"},
            {performance:42,domainName:"webyoda.com"},
            {performance:38,domainName:"pureelectric.fr"},
            {performance:20,domainName:"velovelo.fr"},
            {performance:6,domainName:"powerbike.com"},
        ]
    },
    competitionMarketLeaderKeywords: {
        content: [
            {
                "string": "meilleur vélo électrique",
                "natural": {
                    "rank": 5,
                    "rankEvolution": 0
                },
                "adwords": {
                    "rank": 7,
                    "rankEvolution": -5
                },
                "video": [],
                "map": [],
                "zero": true,
                "shopping": {
                    "rank": 7,
                    "rankEvolution": -1
                },
                "actuality": {
                    "rank": 1,
                    "rankEvolution": 3
                },
                "image": {
                    "rank": 1,
                    "rankEvolution": 5
                }
            },
            {
                "string": "achat vélo route",
                "natural": {
                    "rank": 4,
                    "rankEvolution": -1
                },
                "adwords": {
                    "rank": 6,
                    "rankEvolution": 4
                },
                "video": {
                    "rank": 1,
                    "rankEvolution": 3
                },
                "map": {
                    "rank": 1,
                    "rankEvolution": 5
                },
                "zero": false,
                "shopping": {
                    "rank": 3,
                    "rankEvolution": -1
                },
                "actuality": {
                    "rank": 7,
                    "rankEvolution": -3
                },
                "image": {
                    "rank": 10,
                    "rankEvolution": -5
                }
            }
        ]

    },
    competitionMarketLeaderPages: {
        content: [
            {
                "url": "https://example.com/catalogue",
                "performance": 5.78,
                "rank": 8,
                "pageId": 1,
                "cumulatedVisibility": 12548,
                "rankedKeywordCount": 4
            },
            {
                "url": "https://example.com/velo-lapierre",
                "performance": 48.99,
                "rank": 7,
                "pageId": 2,
                "cumulatedVisibility": 45874,
                "rankedKeywordCount": 44
            }
        ]

    },
    sparkline:
        {
            content :
                [
                    (Math.floor(Math.random() * 10)),
                    (Math.floor(Math.random() * 10)),
                    (Math.floor(Math.random() * 10)),
                    (Math.floor(Math.random() * 10)),
                    (Math.floor(Math.random() * 10)),
                    (Math.floor(Math.random() * 10))
                ]
        }





}

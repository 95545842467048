<template>
    <span
        @mouseover="isHovered = true"
        @mouseout="isHovered = false"
        :class="iconClass"
        :style="iconStyle"
    >

    </span>
</template>

<script>
    export default {
        name: 'YoodaIcon',
        props: {
            name: String,
            color: {
                type: String,
                default: 'inherit'
            },
            hoverColor: {
                type: String,
                default: ''
            },
            size: {
                type: Number,
                default: 18
            },
            background: {
                type: String,
                default: 'none'
            },
        },
        data() {
            return {
                isHovered: false
            }
        },
        computed: {
            iconClass(){
                let background = ''
                if(this.background !== 'none'){
                    background = 'yooda-icon-background-'+this.background
                }
                return 'yooda-icon yoodafont-'+this.name+' '+background
            },
            iconStyle(){
                let color = this.color
                let bgColor = 'inherit'
                if(this.color !== 'inherit'){
                    color = this.getThemeColorByName(this.color)
                }

                if(this.background !== 'none'){
                    bgColor = this.getThemeColorByName(this.color,0.15)
                }

                if(this.isHovered){
                    if(this.hoverColor !== ''){
                        color = this.getThemeColorByName(this.hoverColor)
                    }
                }
                return {
                    fontSize: this.size+'px',
                    color: color,
                    backgroundColor: bgColor,
                }
            },
        },
    }
</script>

<style lang="scss" >

@font-face {
    font-family: "yoodafont";
    src:url("../../assets/fonts/yoodaone.eot");
    src:url("../../assets/fonts/yoodaone.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/yoodaone.woff") format("woff"),
    url("../../assets/fonts/yoodaone.ttf") format("truetype"),
    url("../../assets/fonts/yoodaone.svg#yoodaone") format("svg");
}

.yooda-icon{
    font-family: 'yoodafont' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

}
.yooda-icon-background-rounded{
    border-radius: 50%;
    padding: 6px;
}



.yoodafont-home:before {
    content: "\61";
}
.yoodafont-users:before {
    content: "\62";
}
.yoodafont-tachometer:before {
    content: "\63";
}
.yoodafont-ribbon-b:before {
    content: "\64";
}
.yoodafont-map-marker:before {
    content: "\65";
}
.yoodafont-tags:before {
    content: "\66";
}
.yoodafont-sitemap:before {
    content: "\67";
}
.yoodafont-filter:before {
    content: "\68";
}
.yoodafont-shopping-cart:before {
    content: "\69";
}
.yoodafont-info-circle:before {
    content: "\6a";
}
.yoodafont-hourglass-half:before {
    content: "\6b";
}
.yoodafont-caret-up:before {
    content: "\6c";
}
.yoodafont-caret-down:before {
    content: "\6d";
}
.yoodafont-check:before {
    content: "\6e";
}
.yoodafont-eye:before {
    content: "\6f";
}
.yoodafont-heart:before {
    content: "\70";
}
.yoodafont-file-text-o:before {
    content: "\71";
}
.yoodafont-adwords-bottom:before {
    content: "\72";
}
.yoodafont-adwords-top:before {
    content: "\73";
}
.yoodafont-compass:before {
    content: "\74";
}
.yoodafont-target:before {
    content: "\75";
}
.yoodafont-arrow-up-c:before {
    content: "\76";
}
.yoodafont-arrow-down-c:before {
    content: "\77";
}
.yoodafont-website:before {
    content: "\78";
}
.yoodafont-list:before {
    content: "\7a";
}
.yoodafont-trash-o:before {
    content: "\41";
}
.yoodafont-cloud-upload:before {
    content: "\43";
}
.yoodafont-share-square-o:before {
    content: "\79";
}
.yoodafont-search:before {
    content: "\44";
}
.yoodafont-delete-circle:before {
    content: "\45";
}
.yoodafont-cogs:before {
    content: "\46";
}
.yoodafont-exclamation-circle:before {
    content: "\47";
}
.yoodafont-equal:before {
    content: "\42";
}
.yoodafont-check-ok:before {
    content: "\48";
}
.yoodafont-calendar:before {
    content: "\49";
}
.yoodafont-area-chart:before {
    content: "\4a";
}
.yoodafont-pie-chart:before {
    content: "\4b";
}
.yoodafont-user-secret:before {
    content: "\4c";
}
.yoodafont-android-alarm-clock:before {
    content: "\4d";
}
.yoodafont-expand:before {
    content: "\4e";
}
.yoodafont-expand:before {
    content: "\4f";
}
.yoodafont-compress:before {
    content: "\50";
}
.yoodafont-ellipsis-v:before {
    content: "\51";
}
.yoodafont-download:before {
    content: "\52";
}
.yoodafont-chevron-down:before {
    content: "\53";
}
.yoodafont-chevron-up:before {
    content: "\54";
}
.yoodafont-arrow-right:before {
    content: "\55";
}
.yoodafont-pencil-square-o:before {
    content: "\57";
}
.yoodafont-close:before {
    content: "\58";
}
.yoodafont-clone:before {
    content: "\59";
}
.yoodafont-android-time:before {
    content: "\5a";
}
.yoodafont-add:before {
    content: "\30";
}
</style>
